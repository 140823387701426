<template>
  <div>
    <page-container />
  </div>
</template>

<script>
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import PageContainer from "./components/page-container.vue";
export default {
  components: {
    PageContainer,
    // HomeContainer,
  },

  data() {
    return {
      VUE_APP_ID,
    };
  },
  computed: {},
  created() {},
};
</script>

<style lang="scss" scoped>
* {
  user-select: none;
  word-break: break-word;
}
</style>
