<template>
  <el-form
    label-position="top"
    class="google-form-cha"
    ref="dBindForm"
    :rules="formRules"
    :model="formData"
    @submit.native.prevent
  >
    <el-form-item prop="google_code" :label="label">
      <el-input
        autocomplete="off"
        v-model="formData.google_code"
        class="google_code"
        maxlength="6"
        :placeholder="message"
        oninput="value=value.replace(/[^\d]/g, '')"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { translateTitle } from "@/assets/js/i18n";
export default {
  props: ["label", "placeholder", "verificate"],
  data() {
    let message = this.translateTitle(this.label);
    return {
      message,
      formData: { google_code: "" },
      formRules: {
        google_code: [
          { required: true, message, trigger: "blur" },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`必须为6位纯数字`),
          },
        ],
      },
    };
  },
  methods: {
    translateTitle,
    clearCode() {
      this.formData.google_code = "";
    },
    submitForm() {
      // dBindForm
      let statusData;
      let { formData } = this;
      this.$refs.dBindForm.validate(valid => {
        if (valid) {
          statusData = formData;
        } else {
          statusData = false;
        }
      });

      return JSON.parse(JSON.stringify(statusData));
    },
  },
};
</script>
<style lang="scss">
.google-form-cha {
  input:focus {
    border-color: #c61b3d;
  }
  .el-form-item {
    margin-bottom: 30px !important;
    .el-form-item__content {
      line-height: 1;
      .label {
        color: #7f86a1;
        font-size: 12px;
        text-align: left;
        &.active {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
