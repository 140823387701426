<template>
  <div class="view-more">
    <div class="view-more-content" @click="gotoMore">
      {{ translateTitle("查看更多") }}
      <img :src="isDarkMode ? rightIcon : rightIconLight" />
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import rightIcon from "@/assets/img/home/trade-chart/rightIcon.png";
import rightIconLight from "@/assets/img/home/trade-chart/rightIcon-light.png";
export default {
  props: ["url", "target"],
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      rightIcon,
      rightIconLight,
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    gotoMore() {
      console.log(this.target);
      window.open(this.url, this.target || "_blank");
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  .view-more-content {
    color: #3348f6;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 100px;
    border: 1px solid #dddddd;
  }
}

.view-more {
  &-content {
    /* color: #3348f6;
      font-size: 20px;
      line-height: 24px; */
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 9px 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 4px;
      width: 13px;
      height: 13px;
      flex-shrink: 0;
    }
  }
}
</style>