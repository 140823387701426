import { apiGetUserInfo, apiGetLoginOut } from "@/model/user/index";
import { isJson } from "@/assets/js/validate";
import Cookies from "js-cookie";
import { DEVICE_ID } from "@/assets/js/stationSetting";
var sensors = require("sa-sdk-javascript");

const getLanguage = () => {
  let tempLang = "en";
  let navLang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
  navLang = navLang.substr(0, 2); //截取lang前2位字符
  navLang = navLang.toLowerCase();
  if (navLang == "zh") {
    tempLang = "zh_CN";
  } else {
    tempLang = navLang;
  }
  if (window.location.hostname.includes("4e.inc")) {
    tempLang = "vi";
  }
  return tempLang;
};
const getLocalStorage = key => {
  const value = localStorage.getItem(key);
  if (isJson(value)) {
    return JSON.parse(value);
  } else {
    return false;
  }
};
const getSessionStorage = key => {
  const value = sessionStorage.getItem(key);
  if (isJson(value)) {
    return JSON.parse(value);
  } else {
    return null;
  }
};
const language = localStorage.getItem("language");
const { currency } = getLocalStorage("currency");
const { environment } = getLocalStorage("environment");
const darkMode = localStorage.getItem("darkMode");
const sessionUserInfo = getSessionStorage("userInfo");
const state = {
  userInfo: sessionUserInfo,
  language: language || getLanguage(),
  darkMode: darkMode || 1,
  currency:
    currency || (window.location.hostname.includes("4e.inc") ? "vnd" : "cny"),
  environment: environment,
  v8show: false,
  isDarkMode: false,
};
const mutations = {
  setThemeMode(state, isDarkMode) {
    state.isDarkMode = isDarkMode;
    localStorage.setItem("theme-mode", isDarkMode ? "dark" : "light");
    document
      .querySelector("html")
      .setAttribute("theme", isDarkMode ? "dark" : "light");
  },
  setUserInfo(state, { data }) {
    state.userInfo = data;
    if (data != null) {
      sessionStorage.setItem("userInfo", JSON.stringify(data));
    }
  },
  setv8show(state, { data }) {
    state.v8show = data;
  },
  changeLanguage(state, language) {
    state.language = language;
    localStorage.setItem("language", language);
  },
  changeDarkMode(state, mode) {
    state.darkMode = mode;
    localStorage.setItem("darkMode", mode);
  },
  changeCurrency(state, currency) {
    state.currency = currency;
    localStorage.setItem("currency", currency);
  },
  changeEnvironment(state, environment) {
    state.environment = environment;
    localStorage.setItem("environment", environment);
  },
};
const actions = {
  getUserInfo({ commit }, preload) {
    return new Promise(async (resolve, reject) => {
      const { status, data } = await apiGetUserInfo(preload);
      if (status == 200) {
        commit("setUserInfo", { data: data });
        resolve(data);
      } else {
        Cookies.remove("topcredit_exchange_t");
        reject(data);
      }
    });
  },
  setThemeMode: ({ commit }, isDarkMode) => {
    commit("setThemeMode", isDarkMode);
  },
  changeLanguage: ({ commit }, language) => {
    commit("changeLanguage", language);
  },
  changeDarkMode: ({ commit }, mode) => {
    commit("changeDarkMode", mode);
  },
  changeCurrency: ({ commit }, currency) => {
    commit("changeCurrency", currency);
  },
  changeEnvironment: ({ commit }, environment) => {
    commit("changeEnvironment", environment);
  },

  // 退出，清除用户信息
  async logout({ commit }) {
    // Account.logOut();
    // 清除本地存储，localStorage,sessionStorage
    // 清除state用户信息
    Cookies.remove("topcredit_exchange_t");
    sessionStorage.clear();
    localStorage.removeItem("userData");
    sensors.logout(true);
    sensors.identify(DEVICE_ID, true);
    commit("setUserInfo", { data: null });
    window.location = "/";
  },
  v8show({ commit }) {
    commit("setv8show", { data: true });
  },
  v8closed({ commit }) {
    commit("setv8show", { data: false });
  },
};
const getters = {
  language: state => state.language,
  darkMode: state => state.darkMode,
  isDarkMode: state => state.isDarkMode,
  userInfo: state => state.userInfo,
  v8show: state => state.v8show,
  currency: state => state.currency,
  environment: state => state.environment,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
