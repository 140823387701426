<template>
  <div>
    <div class="security-panel__container">
      <div class="security-panel__container-title">
        {{ translateTitle("全球合规 安全可靠") }}
      </div>
      <div class="security-panel__container-items">
        <div
          class="security-panel__container-items-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <img :src="item.icon" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import icon1 from "@/assets/img/coop/security/icon1.png";
import icon2 from "@/assets/img/coop/security/icon2.png";
import icon3 from "@/assets/img/coop/security/icon3.png";
import icon4 from "@/assets/img/coop/security/icon4.png";
import icon5 from "@/assets/img/coop/security/icon5.png";
export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {
      items: [
        {
          icon: icon1,
        },
        {
          icon: icon2,
        },
        {
          icon: icon3,
        },
        {
          icon: icon4,
        },
        {
          icon: icon5,
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
.security-panel__container {
  display: flex;
  padding: 0px 0px 100px 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--homeBgColor);
  color: var(--homeFontColor);
  /* &.light-mode {
    background-color: var(--homeBgColor);
  } */
  &-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
    margin-top: 50px;
  }

  &-items {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 1200px;
    flex-wrap: wrap;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      background: transparent;

      margin-bottom: 48px;

      &:not(:last-child) {
        margin-right: 48px;
      }
      > img {
        width: 178px;
        height: 108px;
      }
      &-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 125% */
        margin-top: 32px;
      }
      &-subtitle {
        color: var(--homeFontColor);
        text-align: left;
        /* PF_font/r/t_r20 */
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 130% */
        margin-top: 16px;
      }
    }
  }
}
</style>