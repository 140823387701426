<template>
  <div>
    <div class="honor-panel__container light-mode">
      <div class="honor-panel__container-title">
        {{ translateTitle("4E荣誉") }}
      </div>
      <div class="honor-panel__container-items">
        <div
          class="honor-panel__container-items-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <img :src="isDarkMode ? item.icon : item.iconL" alt="" />
          <div class="honor-panel__container-items-item-title">
            {{ translateTitle(item.title) }}
          </div>

          <div class="honor-panel__container-items-item-subtitle">
            {{ translateTitle(item.subTitle) }}
          </div>
          <div class="honor-panel__container-items-item-subtitle1">
            {{ translateTitle(item.subTitle1) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import icon1 from "@/assets/img/home/honor/icon1.png";
import icon2 from "@/assets/img/home/honor/icon2.png";
import icon3 from "@/assets/img/home/honor/icon3.png";
import icon1Light from "@/assets/img/home/honor/icon1-light.png";
import icon2Light from "@/assets/img/home/honor/icon2-light.png";
import icon3Light from "@/assets/img/home/honor/icon3-light.png";
import { mapGetters } from "vuex";
export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {
      items: [
        {
          title: "冠军优选",
          subTitle: "世界杯冠军阿根廷国家队",
          subTitle1: "唯一指定金融交易平台",
          icon: icon1,
          iconL: icon1Light,
          // src/assets/img/home/ad/ad1.png
        },
        {
          title: "用户为王",
          subTitle: "深度连接全球百万用户",
          subTitle1: "本地化运营300+社群",
          icon: icon2,
          iconL: icon2Light,
        },
        {
          title: "全能平台",
          subTitle: "多元化交易产品",
          subTitle1: "一站式交易体验",
          icon: icon3,
          iconL: icon3Light,
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      isDarkMode: "user/isDarkMode",
    }),
  },
  methods: {},
};
</script>
<style lang="scss">
.honor-panel__container {
  display: flex;
  padding: 0px 0px 100px 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--homeBgLightColor);
  color: var(--homeFontColor);
  &.light-mode {
    background-color: var(--homeBgColor);
  }
  &-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
    margin-top: 50px;
  }

  &-items {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 1200px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      border: var(--homeGrayBorder);
      background: transparent;
      flex: 1;
      padding: 24px;
      &:hover {
        background: var(--homeHoverBgColor);
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-right: 32px;
      }
      > img {
        width: 120px;
        height: 120px;
      }
      &-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 125% */
      }
      &-subtitle1,
      &-subtitle {
        color: var(--homeLightGary);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
      }
      &-subtitle {
        margin-top: 12px;
      }
    }
  }
}
@keyframes expendSize {
  0% {
    background-size: 102% 102%;
  }
  20% {
    background-size: 104% 104%;
  }
  40% {
    background-size: 106% 106%;
    border-radius: 12px;
  }
  80% {
    background-size: 108% 108%;
  }
  100% {
    background-size: 110% 110%;
    border-radius: 12px;
  }
}
@keyframes showSubTitle {
  0% {
    line-height: 0px;
    color: transparent;
  }
  /* 20% {
    line-height: 4px;
    color: transparent;
  }
  40% {
    line-height: 8px;
    color: transparent;
  }
  60% {
    line-height: 12px;
    color: transparent;
  }
  80% {
    line-height: 18px;
    color: transparent;
  } */
  100% {
    line-height: 26px;
    color: #fff;
  }
}
</style>