class EventDispatcher {
  constructor() {
    this.maps = {};
  }
  addEventListener(type, listener, thisObject) {
    if (!this.maps[type]) {
      this.maps[type] = [];
    }
    for (const item of this.maps[type]) {
      if (item.listener === listener && item.thisObject === thisObject) {
        return;
      }
    }
    this.maps[type].push({ listener, thisObject });
  }
  removeEventListener(type, listener, thisObject) {
    if (this.maps[type]) {
      for (const item of this.maps[type]) {
        if (item.listener === listener && item.thisObject === thisObject) {
          const idx = this.maps[type].indexOf(item);
          this.maps[type].splice(idx, 1);
          return;
        }
      }
    }
  }
  dispatchEvent(type, data) {
    if (this.maps[type]) {
      for (const item of this.maps[type]) {
        item.listener.apply(item.thisObject, [data]);
      }
    }
  }
}

export default class Dispatcher {
  static addEventListener(type, listener, thisObject) {
    this.eventDispatcher.addEventListener(type, listener, thisObject);
  }
  static removeEventListener(type, listener, thisObject) {
    this.eventDispatcher.removeEventListener(type, listener, thisObject);
  }
  static dispatchEvent(type, data) {
    this.eventDispatcher.dispatchEvent(type, data);
  }
}
Dispatcher.eventDispatcher = new EventDispatcher();
