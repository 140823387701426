<template>
  <div class="setting-item-k">
    <div class="setting-item-k-l">
      <div class="title-wrap">
        <h3 class="title">{{ title }}</h3>
        <slot name="tag"></slot>
      </div>
      <p class="desc">{{ desc }}</p>
      <!-- <span class="account">{{isMobile ? mobile : email}}</span> -->
    </div>
    <div class="setting-item-k-r">
      <p v-if="fieldVal && btnTColor">{{ fieldVal }}</p>
      <div>
        <!-- <span v-if="!btnText"></span> -->
        <g-button
          v-if="btnText"
          :class="btnTColor ? '' : 'active'"
          :text="btnText"
          :disabled="disabled"
          @click="settingBtnClick"
        />
        <g-button
          v-if="btnRight && googleVerifyStatus"
          :disabled="disabled"
          :text="btnRight"
          @click="btnRightClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GButton from "./elButton";

export default {
  props: [
    "title",
    "desc",
    "isMobile",
    "btnText",
    "btnTColor",
    "btnRight",
    "btnBColor",
    "fieldVal",
    "disabled",
    "googleVerifyStatus",
  ],
  methods: {
    settingBtnClick() {
      this.$emit("settingBtnClick");
    },
    btnRightClick() {
      this.$emit("btnRightClick");
    },
  },
  computed: {
    /* email() {
      if (this.accountInfo) {
        let { email } = this.accountInfo;
        return email;
      }
    },
    mobile() {
      if (this.accountInfo) {
        let { mobile } = this.accountInfo;
        return mobile;
      }
    } */
  },
  components: {
    GButton,
  },
};
</script>

<style lang="scss">
.setting-item-k {
  height: 98px;
  // padding-right: 50px;
  max-width: 49%;
  border-bottom: 1px solid #f3f5f7;
  display: flex;
  justify-content: space-between;

  &-l {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    user-select: none;
  }

  &-r {
    display: flex;
    align-items: center;
    span {
      //   width: 80px;

      display: inline-block;
    }
    button {
      min-width: 140px !important;
      border-radius: 4px;
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .title-wrap {
    display: flex;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    color: rgba(53, 64, 82, 1);
    margin-bottom: 12px;
    margin-right: 10px;
  }

  .desc {
    width: 600px;
    font-size: 14px;
    font-weight: 400;
    color: #9aa1b1;
  }

  .setting-item-r {
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      margin-right: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #9aa1b1;
    }

    button {
      color: #354052;
      border-radius: 3px;
      height: 32px;
      // line-height: 32px;
      border: solid 1px #c2c7d0;
      background-image: linear-gradient(
        68deg,
        #3960fb 21%,
        #165ad4 82%
      ) !important;
      font-weight: 500;
      &:last-child {
        margin-top: 6px;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
    .active {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      border-radius: 3px;
      color: #fff;
      //   border: 1px solid #d60c30;
      background-image: linear-gradient(
        68deg,
        #3960fb 21%,
        #165ad4 82%
      ) !important;
      // background-image: linear-gradient(287deg, #ee244c 10%, #c61b4e 50%);
      box-sizing: border-box;
      &:hover {
        //  border: 1px solid #d60c30;
        //  color: #d60c30;
        // background-image: linear-gradient(287deg, #ee244c 97%, #c61b4e 37%);
        border-radius: 3px;
      }
    }
  }
}
</style>
