<template>
  <div>
    <div class="service-panel__container">
      <div class="service-panel__container-title">
        {{ translateTitle("4E合伙人尊享全方位服务") }}
      </div>
      <div class="service-panel__container-items">
        <div
          class="service-panel__container-items-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <img :src="item.icon" alt="" />
          <div class="service-panel__container-items-item-title">
            {{ translateTitle(item.title) }}
          </div>
          <div class="service-panel__container-items-item-subtitle">
            {{ translateTitle(item.subTitle) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import icon1 from "@/assets/img/coop/service/icon1.png";
import icon2 from "@/assets/img/coop/service/icon2.png";
import icon3 from "@/assets/img/coop/service/icon3.png";
import icon4 from "@/assets/img/coop/service/icon4.png";
export default {
  props: {},
  components: {},
  mixins: [],
  data() {
    return {
      items: [
        {
          title: "专属合伙人后台",
          subTitle:
            "简约高效的可视化数据看板，实时展示收益情况、及时的推广效果分析，让您对粉丝了如指掌",
          icon: icon1,
          // src/assets/img/home/ad/ad1.png
        },
        {
          title: "业内独家成长基金",
          subTitle:
            "优秀合伙人可获得4E提供的成长基金，用于社群推广、线下沙龙、组建团队等，助力您成为顶级合伙人",
          icon: icon2,
        },
        {
          title: "7*24小时专业服务",
          subTitle:
            "专属运营和客服 24 小时为您服务，解决您在使用和推广过程中遇到的任何问题",
          icon: icon3,
        },
        {
          title: "品牌VIP活动，扩大圈内人脉",
          subTitle:
            "丰富多样的品牌活动、业内顶级峰会，帮助您扩大圈内人脉，进一步提高影响力",
          icon: icon4,
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
:root[theme="light"] {
  --servicePanelBg: #f8f9fa;
}
:root[theme="dark"] {
  --servicePanelBg: #141414;
}
.service-panel__container {
  display: flex;
  padding: 0px 0px 100px 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--homeBgColor);
  color: var(--homeFontColor);
  /* &.light-mode {
    background-color: var(--homeBgColor);
  } */
  &-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 120% */
    margin-top: 50px;
  }

  &-items {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 1200px;
    flex-wrap: wrap;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      border: var(--homeGrayBorder);
      background: var(--servicePanelBg);

      padding: 32px;
      width: calc(50% - 24px);
      margin-bottom: 48px;
      &:hover {
        background: var(--homeHoverBgColor);
        border: 1px solid var(--Dark04-text-c_m, #465aff);
        background: linear-gradient(
          131deg,
          rgba(74, 202, 250, 0.08) 0%,
          rgba(236, 54, 235, 0.08) 100%
        );
      }
      &:nth-child(2n + 1) {
        margin-right: 48px;
      }
      > img {
        /* width: 120px;
        height: 120px; */
      }
      &-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 125% */
        margin-top: 32px;
        width: 100%;
      }
      &-subtitle {
        color: var(--homeLightGary);
        text-align: left;
        /* PF_font/r/t_r20 */
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 130% */
        margin-top: 16px;
      }
    }
  }
}
</style>