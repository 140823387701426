<template>
  <el-dialog
    custom-class="downloadTipsVisible"
    :visible.sync="downloadTipsVisible"
    :show-close="true"
    :before-close="closeDownloadTipsDialog"
  >
    <p class="t">{{ translateTitle("APP下载提示") }}</p>
    <p class="c">
      {{
        translateTitle("您可下载") +
        "APP" +
        translateTitle("使用该功能业务,该功能正在开发中,敬请期待!")
      }}
    </p>
    <div class="d">
      <div class="b">
        <span @click.stop="reportParams('apple')">
          <a :href="appstore_url_new" target="_blank">
            <img src="../../assets/img/Apple-store-2.png" class="as" />
          </a>
        </span>
        <span @click.stop="reportParams('android')">
          <a :href="android_url_new" target="_blank">
            <img src="../../assets/img/apk-day.png" class="apk" />
          </a>
        </span>
      </div>
      <div class="q">
        <qrcode-vue
          class="code"
          v-show="oriUrl"
          :value="oriUrl"
          :size="120"
          level="H"
        ></qrcode-vue>
        <span class="qt">{{ translateTitle("扫码下载iOS&Android APP") }}</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { apiWsUrl } from "@/model/exchange/index";
import commonMixin from "@/components/common/commonMixin.vue";
import QrcodeVue from "qrcode.vue";
import { mapGetters } from "vuex";
import { DEVICE_ID, VUE_CHANNEL_ID } from "@/assets/js/stationSetting";
export default {
  mixins: [commonMixin],
  components: {
    QrcodeVue,
  },
  props: {
    downloadTipsVisible: {
      type: Boolean,
      default: false,
    },
    closeDownloadTipsDialog: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      appDownLoadImg: "",
      android_url_new: "",
      appstore_url_new: "",
      oriUrl: "",
    };
  },
  async created() {
    this.initURL();
    let tempUrl = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let temp_url = tempUrl.ori_url;
    // app下载二维码埋点
    if (this.userInfo) {
      this.oriUrl = `${temp_url}?device_id=${DEVICE_ID}&channel_id=${VUE_CHANNEL_ID}&user_id=${this.userInfo.user_id}`;
    } else {
      this.oriUrl = `${temp_url}?device_id=${DEVICE_ID}&channel_id=${VUE_CHANNEL_ID}&user_id=`;
    }
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
    }),
  },
  methods: {
    async initURL() {
      let params = {
        __version: "3.4.3",
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.android_url_new = data.android_url;
        this.appstore_url_new = data.appstore_url;
      }
    },
    reportParams(origin) {
      this.$sensors.track("App_Download", {
        download_entrance: this.downloadOrigin,
        download_click_type: origin,
        invite_code: "",
      });
    },
  },
};
</script>

<style lang="scss">
.downloadTipsVisible.el-dialog {
  z-index: 999;
  width: 500px;
  margin-top: 30vh !important;
  min-height: 360px;
  padding: 30px 60px;
  display: flex;
  color: #112132;
  background: var(--downloadPopupBgColor);
  cursor: pointer;
  /* &.el-dialog {
   
  } */
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .t {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
    color: var(--downloadPopupTitleColor);
  }

  .c {
    font-size: 14px;
    margin-bottom: 35px;
    color: var(--downloadPopupSubTitleColor);
  }

  .d {
    display: flex;
    justify-content: space-between;
    height: 146px;
    width: 380px;

    .b {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      flex-shrink: 0;

      .as {
        width: 150px;
        height: 50px;
      }

      .apk {
        width: 150px;
        height: 50px;
      }
    }

    .q {
      margin-left: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      .code {
        width: 120px;
        height: 120px;
      }

      .qt {
        font-size: 14px;
        line-height: 1.43;
        word-break: break-word;
        text-align: center;
        color: var(--downloadPopupTitleColor);
      }
    }
  }

  .confirm {
    cursor: pointer;
    margin-top: 40px;
    width: 200px;
    height: 40px;
    border-radius: 3px;
    background-image: linear-gradient(77deg, #165ad3 21%, #165ad3 82%);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>