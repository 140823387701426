import Vue from 'vue'
import "element-ui/lib/theme-chalk/index.css"
import {
  Input,
  InputNumber,
  Checkbox,
  Switch,
  Select,
  Option,
  TimeSelect,
  Form,
  FormItem,
  Pagination,
  Radio,
  RadioGroup,
  DatePicker,
  CheckboxGroup,
  Progress,
  Popover,
  Tabs,
  TabPane,
} from "element-ui";

// // 导入vue语言包,全部导入，需要优化
// import locale from "element-ui/lib/locale";
// import zh_CN from "element-ui/lib/locale/lang/zh-CN";
// import en from "element-ui/lib/locale/lang/en";
// import zh_TW from "element-ui/lib/locale/lang/zh-TW";
// import ko from "element-ui/lib/locale/lang/ko";
// import ja from "element-ui/lib/locale/lang/ja";
// import ru from "element-ui/lib/locale/lang/ru-RU";

// const lang = window.APP_CONFIG.LANG;

// // 默认中文
// if (lang == "en") {
//   locale.use(en);
// } else if (lang == "zh") {
//   locale.use(zh_CN);
// } else if (lang == "zh-HK") {
//   locale.use(zh_TW);
// } else if (lang == "kr") {
//   locale.use(ko);
// } else if (lang == "jp") {
//   locale.use(ja);
// } else if (lang == "ru") {
//   locale.use(ru);
// } else {
//   locale.use(en);
// }

Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Input.name, Input);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Switch.name, Switch);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(TimeSelect.name, TimeSelect);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Pagination.name, Pagination);
Vue.component(Radio.name, Radio);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Progress.name, Progress);
Vue.component(Popover.name, Popover);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
