<template>
  <div class="transaction-managment-content-delivery">
    <!-- 列表 -->
    <!-- <div class="content-header">
            {{ translateTitle("我的持有") }}
          </div> -->
    <!-- 变更开始，FIXME -->
    <div class="market-header">
      <ul>
        <li
          class="classify-area"
          :class="{ active: currIndex == item.value }"
          v-for="(item, index) of tabList"
          :key="item.value"
          @click="onAreaChange(item)"
        >
          {{ translateTitle(item.label) }}
          <span v-if="index == 0 && contract_position_num != 0"
            >( {{ contract_position_num }} )</span
          >
          <span v-if="index == 1 && contract_plan_num != 0"
            >( {{ contract_plan_num }} )</span
          >
          <i v-if="currIndex == item.value" class="item-bar"></i>
        </li>
        <li class="hidden-btn"></li>
        <li class="hidden-btn">
          <el-checkbox v-if="currIndex == 3" v-model="hidenCancelChecked">{{
            translateTitle("隐藏已撤销")
          }}</el-checkbox>
          <!-- <el-checkbox v-model="checked">{{
            translateTitle("隐藏其他合约")
          }}</el-checkbox> -->
        </li>
        <li class="header-cancel-btn" v-if="currIndex == 1 && userInfo">
          <span @click="onOpenConfirm(2)" class="cancel-btn-v2">{{
            translateTitle("一键全平")
          }}</span>
        </li>
        <li class="header-cancel-btn" v-if="currIndex == 2">
          <span @click="onOpenConfirm(3)" class="cancel-btn-v2">{{
            translateTitle("一键撤销")
          }}</span>
        </li>
      </ul>
    </div>
    <!-- 变更结束，FIXME -->

    <!-- U本位合约表格开始 -->
    <!-- <div class="table-content" v-loading="orderLoading"> -->
    <div v-show="currIndex == 1" style="min-height: 630px">
      <el-table
        :data="tableData"
        v-loading="orderLoading"
        height="570"
        :row-style="{ height: '50px' }"
        style="width: 100%"
        :key="recordDeliveryTable1"
      >
        <el-table-column
          prop="create_time"
          align="left"
          header-align="left"
          :label="translateTitle('合约')"
          key="1"
          min-width="175"
        >
          <template slot-scope="{ row }">
            <!-- <span class="common-operate" @click="onSymbolClick(row)">{{
              symbolList[row.symbol_id].language["symbol_name_" + language]
            }}</span> -->
            <div class="contract-name" @click="onSymbolClick(row)">
              <img
                class="open-item-img"
                :src="
                  coinData[row.open_item_id]
                    ? coinData[row.open_item_id].itemDetail.coin_url
                    : ''
                "
              />
              <span class="symbol_name">{{
                symbolList[row.symbol_id].language["symbol_name_" + language]
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="open_side"
          key="2"
          :label="translateTitle('方向')"
          min-width="50"
        >
          <template slot-scope="{ row }">
            <span :class="'side_' + row.open_side">{{
              translateTitle(sideMap[row.open_side])
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          key="3"
          align="center"
          prop="open_leverage"
          :label="translateTitle('倍数')"
          min-width="90"
        >
          <template slot-scope="{ row }">
            <span class="DINPro"
              >{{
                row.position_mode == 1
                  ? translateTitle("逐仓")
                  : translateTitle("全仓")
              }}·{{ row.open_leverage }}X</span
            >
          </template>
        </el-table-column>

        <el-table-column
          key="5"
          align="right"
          prop="open_price"
          :label="translateTitle('开仓均价')"
          min-width="95"
        >
          <template slot-scope="{ row }">
            <div>
              <span class="DINPro">{{ row.open_price }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
            v-if="currIndex != 2"
            key="6"
            align="center"
            prop="num"
            label="开仓均价"
            min-width="130"
          >
          </el-table-column> -->
        <el-table-column
          key="7"
          prop="last"
          align="right"
          :label="translateTitle('标记价格')"
          min-width="95"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ row.last_price || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          key="8"
          prop="liquidation_price"
          :label="translateTitle('强平价格')"
          align="right"
          min-width="100"
        >
          <template slot-scope="{ row }">
            <div>
              <span class="DINPro">{{ row.liquidation_price }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="bail_number"
          key="9"
          :label="`${translateTitle('本金')}`"
          align="right"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <div>
              <span class="DINPro"
                >{{ row.bail_number }}
                {{ row.from_symbol_name.toUpperCase() }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          prop="open_interest"
          key="4"
          :label="translateTitle('仓位价值')"
          min-width="140"
        >
          <template slot-scope="{ row }">
            <div>
              <span class="DINPro"
                >{{ row.open_interest }}
                {{ row.from_symbol_name.toUpperCase() }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          key="10"
          prop="fee"
          :label="translateTitle('收益/收益率')"
          min-width="220"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">
              <span
                v-if="
                  row.position_profit_loss == 'NaN' ||
                  row.position_profit_loss == '' ||
                  row.position_profit_loss == undefined
                "
              >
                --
              </span>
              <span
                v-else
                class="DINPro"
                :class="
                  Number(row.position_profit_loss_procent) > 0 ? 'green' : 'red'
                "
              >
                <span v-if="Number(row.position_profit_loss) > 0">+</span
                >{{ row.position_profit_loss }}
                {{ row.from_symbol_name.toUpperCase() }} </span
              >/
              <span
                class="DINPro"
                :class="
                  Number(row.position_profit_loss_procent) > 0 ? 'green' : 'red'
                "
              >
                <span
                  v-if="Number(row.position_profit_loss_procent) > 0"
                  style="margin-right: -3px"
                  >+</span
                >
                <span
                  v-if="
                    row.position_profit_loss_procent == 'NaN' ||
                    row.position_profit_loss_procent == '' ||
                    row.position_profit_loss_procent == undefined
                  "
                >
                  --
                </span>
                <span v-else class="DINPro">
                  {{ row.position_profit_loss_procent }}
                </span>
                %
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          prop="stop_profit_price"
          :label="translateTitle('止盈价')"
          min-width="110"
        >
          <template slot-scope="{ row }">
            <div class="common-right cup" @click="onProfitPriceEdit(row)">
              <span class="DINPro">
                {{ row.stop_profit_price }}
                <i class="iconfont iconbianji"></i>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          key="12"
          prop="stop_loss_price"
          :label="translateTitle('止损价')"
          min-width="110"
        >
          <template slot-scope="{ row }">
            <div class="common-right cup" @click="onLossPriceEdit(row)">
              <span class="DINPro">
                {{ row.stop_loss_price }}
                <i class="iconfont iconbianji"></i>
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :label="translateTitle('操作')"
          key="13"
          align="center"
          min-width="220"
        >
          <template slot-scope="{ row }">
            <el-button @click="onOpenAppendMoney(row)" class="operation">{{
              translateTitle("追加本金")
            }}</el-button>
            <el-button
              @click="onOpenConfirm(1, row.position_order_id)"
              class="operation"
              >{{ translateTitle("平仓") }}</el-button
            >
            <el-button @click="onOpenOrderDetails(53, row)" class="operation">{{
              translateTitle("查看详情")
            }}</el-button>
          </template>
        </el-table-column>
        <no-data
          v-show="!orderLoading && tableData.length == 0"
          slot="empty"
        ></no-data>
      </el-table>
    </div>
    <div v-show="currIndex != 1" style="min-height: 630px">
      <!-- 筛选开始 -->
      <!-- 历史委托筛选开始 -->
      <div class="filter-box" v-show="currIndex == 3">
        <el-date-picker
          v-model="ctimeAndEtime4History"
          type="daterange"
          :range-separator="translateTitle('至')"
          :start-placeholder="translateTitle('开始日期')"
          :end-placeholder="translateTitle('结束日期')"
          format="yyyy-MM-dd"
          value-format="timestamp"
        />
        <!-- <el-select
          v-model="planOrderList_create_time"
          value-key="value"
          :placeholder="translateTitle('请选择日期')"
          :clearable="true"
          class="selectStyle"
        >
          <el-option
            v-for="item in create_time_array"
            :key="item.value"
            :label="translateTitle(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <div class="tenWid"></div>
        <el-select
          v-model="planOrderList_symbol_id"
          value-key="symbol_id"
          filterable
          :placeholder="translateTitle('请选择交易对')"
          :clearable="true"
          class="selectStyle"
        >
          <el-option
            v-for="item in symbolList"
            :key="item.symbol_id"
            :label="item.language[`symbol_name_${language}`]"
            :value="item.symbol_id"
          >
          </el-option>
        </el-select>
        <div class="tenWid"></div>
        <el-select
          v-model="planOrderList_open_side"
          value-key="value"
          :placeholder="translateTitle('请选择方向')"
          :clearable="true"
          class="selectStyle"
        >
          <el-option
            v-for="item in open_side_array"
            :key="item.value"
            :label="translateTitle(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <div class="twentyWid"></div>
        <span class="btn-search" @click="searchPlanOrderList">{{
          translateTitle("查询")
        }}</span>
        <div class="tenWid"></div>
        <span class="btn-reset" @click="resetPlanOrderList">{{
          translateTitle("重置")
        }}</span>
      </div>
      <!-- 历史委托筛选结束 -->
      <!-- 平仓记录筛选开始 -->
      <div class="filter-box" v-show="currIndex == 4">
        <div class="tenWid"></div>
        <el-date-picker
          v-model="ctimeAndEtime"
          type="daterange"
          :range-separator="translateTitle('至')"
          :start-placeholder="translateTitle('开始日期')"
          :end-placeholder="translateTitle('结束日期')"
          format="yyyy-MM-dd"
          value-format="timestamp"
        />
        <div class="tenWid"></div>
        <el-select
          v-model="planOrderList_symbol_id"
          value-key="symbol_id"
          filterable
          :placeholder="translateTitle('请选择交易对')"
          :clearable="true"
          class="selectStyle"
        >
          <el-option
            v-for="item in symbolList"
            :key="item.symbol_id"
            :label="item.language[`symbol_name_${language}`]"
            :value="item.symbol_id"
          >
          </el-option>
        </el-select>
        <div class="tenWid"></div>
        <el-select
          v-model="planOrderList_open_side"
          value-key="value"
          :placeholder="translateTitle('请选择方向')"
          :clearable="true"
          class="selectStyle"
        >
          <el-option
            v-for="item in open_side_array"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <div class="twentyWid"></div>
        <span class="btn-search" @click="searchPlanOrderList">{{
          translateTitle("查询")
        }}</span>
        <div class="tenWid"></div>
        <span class="btn-reset" @click="resetPlanOrderList">{{
          translateTitle("重置")
        }}</span>
      </div>
      <!-- 平仓记录筛选结束 -->
      <!-- 筛选结束 -->
      <el-table
        :data="tableData"
        v-loading="orderLoading"
        height="570"
        style="width: 100%"
        key="recordDeliveryTable2"
      >
        <el-table-column
          prop="create_time"
          align="center"
          :label="translateTitle('下单时间')"
          key="1"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ parseTime(row.create_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          key="2"
          align="center"
          prop="symbol_name"
          :label="translateTitle('合约')"
          min-width="140"
        >
          <template slot-scope="{ row }">
            <!-- <span class="common-operate" @click="onSymbolClick(row)">{{
              symbolList[row.symbol_id].symbol_name
            }}</span> -->
            <span class="symbol_name_ano" @click="onSymbolClick(row)">{{
              symbolList[row.symbol_id].language["symbol_name_" + language]
            }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column
          align="center"
          prop="side"
          key="3"
          :label="translateTitle('方向')"
          min-width="80"
        > -->
        <el-table-column
          align="center"
          prop="side"
          key="3"
          :label="translateTitle('方向')"
          min-width="60"
        >
          <template slot-scope="{ row }">
            <span :class="'side_' + row.open_side">{{
              translateTitle(sideMap[row.open_side])
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex != 4"
          align="center"
          prop="leverage"
          key="4"
          :label="translateTitle('倍数')"
          min-width="90"
        >
          <template slot-scope="{ row }">
            <div>
              <span class="DINPro">{{ row.leverage }}X</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          v-else
          align="center"
          prop="open_leverage"
          key="89"
          :label="translateTitle('倍数')"
          min-width="90"
        > -->
        <el-table-column
          v-else
          align="center"
          prop="open_leverage"
          key="89"
          :label="translateTitle('倍数')"
          min-width="90"
        >
          <template slot-scope="{ row }">
            <div>
              <span class="DINPro"
                >{{
                  row.position_mode == 1
                    ? translateTitle("逐仓")
                    : translateTitle("全仓")
                }}·{{ row.open_leverage }}X</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          key="5"
          v-if="currIndex == 2 || currIndex == 3"
          align="right"
          prop="deal_avg_price"
          :label="translateTitle('触发条件')"
          min-width="110"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">
              <span v-if="row.trigger_side == 1">≤</span>
              <span v-else>≥</span>
              {{ row.trigger_price }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          key="25"
          v-if="currIndex == 3"
          align="right"
          prop="order_price"
          :label="translateTitle('成交均价')"
          min-width="110"
        >
          <template slot-scope="{ row }">
            <span class="DINPro" v-if="row.order_status != 3">
              {{ row.order_price }}
            </span>
            <!-- {{ row.to_symbol_name }} -->
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          key="6"
          v-if="currIndex == 2 || currIndex == 3"
          align="right"
          prop="consumer_costs"
          :label="`${translateTitle('本金')}`"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">
              {{ row.consumer_costs }} {{ row.symbol_name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          key="26"
          v-if="currIndex == 3"
          align="right"
          prop="trade_number"
          :label="translateTitle('成交数量')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span class="DINPro" v-if="row.order_status != 3">
              {{ row.trade_number }} {{ row.from_symbol_name }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          key="7"
          v-if="currIndex == 2"
          prop="stop_profit_price"
          align="right"
          :label="translateTitle('止盈价')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <div class="common-right cup" @click="onProfitPriceEdit(row)">
              <span class="DINPro">
                {{ row.stop_profit_price }}
                <i
                  class="iconfont iconbianji"
                  @click="onProfitPriceEdit(row)"
                ></i>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          key="8"
          v-if="currIndex == 2"
          prop="stop_loss_price"
          align="right"
          :label="translateTitle('止损价')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <div class="common-right cup" @click="onLossPriceEdit(row)">
              <span class="DINPro">
                {{ row.stop_loss_price }}
                <i class="iconfont iconbianji"></i>
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-if="currIndex == 2 || currIndex == 3"
          align="center"
          key="9"
          prop="order_status"
          :label="translateTitle('状态')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span :class="'status_' + row.order_status">{{
              translateTitle(statusMap[row.order_status])
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 3"
          align="center"
          key="24"
          prop="update_time"
          :label="translateTitle('完成时间')"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ parseTime(row.update_time) }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column
            key="16"
            v-if="currIndex == 4"
            prop="profit_loss_ratio"
            align="right"
            :label="translateTitle('回报率')"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <span
                class="DINPro"
                :class="Number(row.profit_loss_ratio) > 0 ? 'green' : 'red'"
              >
                <span
                  v-if="Number(row.profit_loss_ratio) > 0"
                  style="margin-right:-3px"
                  >+</span
                >
                {{ row.profit_loss_ratio }}%</span
              >
            </template>
          </el-table-column> -->
        <el-table-column
          key="17"
          v-if="currIndex == 4"
          prop="open_price"
          align="right"
          :label="translateTitle('开仓均价')"
          min-width="95"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ row.open_price }}</span>
          </template>
        </el-table-column>
        <el-table-column
          key="18"
          v-if="currIndex == 4"
          prop="close_price"
          align="right"
          :label="translateTitle('平仓价格')"
          min-width="95"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ row.close_price }}</span>
          </template>
        </el-table-column>
        <el-table-column
          key="19"
          v-if="currIndex == 4"
          prop="open_interest"
          align="right"
          :label="translateTitle('平仓数量')"
          min-width="130"
        >
          <template slot-scope="{ row }">
            <span class="DINPro"
              >{{ row.open_interest }}
              {{ row.from_symbol_name.toUpperCase() }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          key="15"
          v-if="currIndex == 4"
          prop="profit_loss"
          align="right"
          :label="`${translateTitle('收益/收益率')}`"
          min-width="190"
        >
          <template slot-scope="{ row }">
            <span
              class="DINPro"
              :class="Number(row.profit_loss_ratio) > 0 ? 'green' : 'red'"
              >{{ row.profit_loss }} {{ to_symbol.toUpperCase() }} /
              {{ row.profit_loss_ratio }}%</span
            >
          </template>
        </el-table-column>
        <el-table-column
          key="20"
          v-if="currIndex == 4"
          prop="balance_change_number"
          align="right"
          :label="translateTitle('余额归还')"
          min-width="140"
        >
          <template slot-scope="{ row }">
            <span
              >{{ row.balance_change_number
              }}{{ " " + row.from_symbol_name }}</span
            >
          </template>
        </el-table-column>
        <!-- <el-table-column
          key="21"
          v-if="currIndex == 4"
          prop="close_position_type"
          align="center"
          :label="translateTitle('平仓类型')"
          min-width="120"
        > -->
        <el-table-column
          key="21"
          v-if="currIndex == 4"
          prop="close_position_type"
          align="center"
          :label="translateTitle('平仓类型')"
          min-width="90"
        >
          <template slot-scope="{ row }">
            <span :class="closePositionTypeMaps.cls[row.close_position_type]">{{
              translateTitle(
                closePositionTypeMaps.label[row.close_position_type]
              )
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          key="22"
          v-if="currIndex == 4"
          prop="close_position_time"
          align="center"
          :label="translateTitle('平仓时间')"
          min-width="160"
        > -->
        <el-table-column
          key="22"
          v-if="currIndex == 4"
          prop="close_position_time"
          align="center"
          :label="translateTitle('平仓时间')"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <span class="DINPro">{{ parseTime(row.close_position_time) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          v-if="currIndex == 4"
          :label="translateTitle('操作')"
          key="14"
          align="right"
          min-width="110"
        > -->
        <el-table-column
          v-if="currIndex == 4"
          :label="translateTitle('操作')"
          key="14"
          align="center"
          min-width="100"
        >
          <template slot-scope="{ row }">
            <el-button @click="onOpenOrderDetails(54, row)" class="operation">{{
              translateTitle("查看详情")
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="currIndex == 2"
          :label="translateTitle('操作')"
          key="14"
          align="center"
          min-width="110"
        >
          <template slot-scope="{ row }">
            <el-button
              @click="onOpenConfirm(4, row.plan_order_id)"
              class="operation"
              >{{ translateTitle("撤销") }}</el-button
            >
          </template>
        </el-table-column>
        <no-data
          v-show="!orderLoading && tableData.length == 0"
          slot="empty"
        ></no-data>
      </el-table>
    </div>
    <div class="common-table-pages" v-if="currIndex == 4 || currIndex == 3">
      <p>
        {{ translateTitle("每页") }}
        <span>{{ pageInfo.pageSize }}</span>
        {{ translateTitle(`条，总共`) }}
        <span>{{ pageInfo.pageCount }}</span>
        {{ translateTitle("页") }}
      </p>
      <el-pagination
        class="mt20"
        background
        layout="prev, pager, next"
        :total="pageInfo.pageTotal"
        :page-size="pageInfo.pageSize"
        :current-page.sync="pageInfo.pageCurrent"
        @current-change="onCurrentPageChange"
      ></el-pagination>
    </div>
    <div class="common-table-pages" v-else></div>
    <!-- </div> -->

    <el-dialog
      :title="translateTitle('操作提示')"
      custom-class="confirm-dialog"
      :visible.sync="dialogVisible"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px">{{ confirmMessage }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="dialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onOK">{{
          translateTitle("确认")
        }}</span>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="append-money-dialog"
      :title="translateTitle('追加本金')"
      :visible.sync="appendMoneydialog"
      :before-close="appendMoneydialogClose"
      :close-on-click-modal="false"
      width="500px"
    >
      <p class="first-content">
        <span class="left-item">{{ translateTitle("追加金额") }}</span>
        <span>
          <span class="available-assets"
            >{{ translateTitle("可用") }} {{ assetsInfoShow }}
            {{ from_symbol.toUpperCase() }}
          </span>
          <span class="common-operate" @click="goToRecharge">
            {{ translateTitle("充值") }}</span
          ></span
        >
      </p>
      <div class="second-content">
        <InputNumber
          v-model="bail_number"
          :placeholder="translateTitle('请输入追加金额')"
          maxlength="14"
          :decimals="settlement_precision"
          :suffix="from_symbol.toUpperCase()"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClickAll">{{
          translateTitle("全部")
        }}</span>
      </div>
      <div class="three-content">
        <div class="item-one">
          <div class="left-item">
            <span class="black DINPro">{{ beforeBailNumber }}</span>
            <span>{{ translateTitle("当前本金") }}</span>
          </div>
          <span><img src="@/assets/img/tran-to.png" alt="" /></span>
          <div class="right-item">
            <span class="red DINPro">{{ afterBailNumberShow }}</span>
            <span>{{ translateTitle("追加后本金") }}</span>
          </div>
        </div>
        <div class="item-two">
          <div class="left-item">
            <span class="black DINPro">{{ beforeLiquidationPrice }}</span>
            <span>{{ translateTitle("当前强平价") }}</span>
          </div>
          <span><img src="@/assets/img/tran-to.png" alt="" /></span>
          <div class="right-item">
            <span class="red DINPro">{{ afterLiquidationPriceShow }}</span>
            <span>{{ translateTitle("追加后强平价") }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="appendMoneydialogClose">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onAppendMoney">{{
          translateTitle("确定")
        }}</span>
      </span>
    </el-dialog>
    <el-dialog
      custom-class="set-profitPrice-dialog"
      :title="translateTitle('设置止盈止损')"
      :visible.sync="setProfitPricedialog"
      :before-close="setProfitPricedialogClose"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="profitPrice-three-content">
        <div class="item-one">
          <div class="left-item">
            <span class="black">{{ rowMarketInfoShow.last || "--" }}</span>
            <span>{{ translateTitle("当前价") }}</span>
          </div>
          <div class="center-item">
            <span class="black" v-if="currIndex == 1">{{
              rowData.open_price
            }}</span>
            <span class="black" v-if="currIndex == 2">{{
              rowData.trigger_price
            }}</span>
            <span>{{ translateTitle("开仓价") }}</span>
          </div>
          <div class="right-item">
            <span class="black">{{ rowData.liquidation_price }}</span>
            <span>{{ translateTitle("强平价") }}</span>
          </div>
        </div>
      </div>
      <p class="profitPrice-first-content">
        <span class="left-item">{{ translateTitle("止盈价") }}</span>
        <span class="right-item"
          >{{ translateTitle("预计盈利") }}
          <span class="green">
            {{ expectedProfit }} {{ from_symbol.toUpperCase() }}
            <span v-if="expectedProfitPercent != '--'">
              ({{ expectedProfitPercent }}%)
            </span>
          </span></span
        >
      </p>
      <div class="profitPrice-second-content">
        <InputNumber
          v-model="inputProfitPrice"
          :placeholder="translateTitle('请输入止盈价')"
          maxlength="10"
          decimals="2"
        >
        </InputNumber>
        <span class="all-btn common-operate" @click="onClearAll">{{
          translateTitle("清除")
        }}</span>
      </div>
      <p class="profitPrice-first-content V2">
        <span class="left-item">{{ translateTitle("止损价") }}</span>
        <span class="right-item"
          >{{ translateTitle("预计亏损") }}
          <span class="red"
            >{{ expectedLoss }} {{ from_symbol.toUpperCase() }}

            <span v-if="expectedLossPercent != '--'">
              ({{ expectedLossPercent }}%)
            </span>
          </span></span
        >
      </p>
      <div class="profitPrice-second-content">
        <InputNumber
          v-model="inputLossPrice"
          :placeholder="translateTitle('请输入止损价')"
          maxlength="10"
          decimals="2"
        >
        </InputNumber>
        <span class="all-btn common-operate" @click="onClearAllV2">{{
          translateTitle("清除")
        }}</span>
      </div>

      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="setProfitPricedialog = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onSetPositionProfitLoss">{{
          translateTitle("确定")
        }}</span>
      </span>
    </el-dialog>
    <order-details
      :isStartWS="false"
      :marketListTran="marketList"
      :assetsInfoTran="assetsInfo"
      ref="orderDetails"
    ></order-details>
  </div>
</template>
<script>
import { apiGetTradePairList } from "@/model/exchange/market.js";
import OrderDetails from "../../../bill-center/components/orderDetails.vue";
import { apiGetAssets } from "@/model/exchange/index";
import {
  apiGetOpenPositionList,
  apiGetPlanOrderList,
  apiRevokePlan,
  apiAllClosePosition,
  apiAppendInterest,
  apiClosePosition,
  apiSetPositionProfitLoss,
  apiSetPlanProfitLoss,
  apiRevokeAllPlan,
  apiGetOrderStatistic,
} from "@/model/currencyBasedContract/order.js";
import InputNumber from "@/components/common/InputNumber";
import NoData from "@/components/common/NoData";
import { parseTime } from "@/utils/index.js";
import { mapActions, mapGetters } from "vuex";
import { showMessage } from "@/components/dialog/message.js";
import * as Account from "@/model/user/account";
import { apiGetStaticTableInfo } from "@/model/settings";
import { closePositionTypeMaps } from "@/assets/js/keymap.js";
import { floorNumber, ceilNumber } from "@/assets/js/digital";

import BigNumber from "bignumber.js";

import { translateTitle } from "@/assets/js/i18n";
import commonMixin from "@/components/common/commonMixin.vue";
import { apiWsUrl } from "@/model/currencyBasedContract/index";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import Cookies from "js-cookie";
import Socket from "@/assets/js/socket.js";
import { getStaticCoinList as getCoinList } from "@/model/assets/index";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
    InputNumber,
    OrderDetails,
  },
  data() {
    return {
      tt: {},
      lockReconnect: false,
      recordDeliveryTable1: false,
      planOrderList_create_time: "",
      planOrderList_symbol_id: "",
      planOrderList_open_side: "",
      ctimeAndEtime: [],
      ctimeAndEtime4History: [],

      open_side_array: [
        {
          value: 1,
          label: "买涨",
        },
        {
          value: 2,
          label: "买跌",
        },
      ],
      create_time_array: [
        {
          value: 86400,
          label: "今天",
        },
        {
          value: 7 * 86400,
          label: "7天",
        },
        {
          value: 30 * 86400,
          label: "1月",
        },
        {
          value: 3 * 30 * 86400,
          label: "3月",
        },
      ],

      coinData: {},
      isDestoryWS: false,
      marketList: [],
      // swap_symbol_id: 1010,
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--",
          },
          list: [],
        },
      },
      from_symbol: "",
      to_symbol: "",
      from_item_id: "",
      to_item_id: "",

      closePositionTypeMaps,
      parseTime,
      ws: null,
      tableData: [],
      confirmMessage: "是否确认平仓此订单？",
      tableDataComputed: [],
      orderLoading: true,
      dialogVisible: false,
      appendMoneydialog: false,
      setProfitPricedialog: false,
      row_swap_symbol_id: this.swap_symbol_id,
      beforeBailNumber: "",
      marketPriceNow: "",
      settlement_precision: "",
      fee_rate: "",
      contract_value: "",
      liquidation_risk_rate: "",
      afterBailNumber: "--",
      inputProfitPrice: "",
      inputLossPrice: "",
      price_precision: "",
      contract_plan_num: 0,
      contract_position_num: 0,
      contract_multiple_num: "",
      beforeLiquidationPrice: "",
      plan_order_id: "",
      afterLiquidationPrice: "--",
      rowData: {},
      hidenCancelChecked: false,
      checked: false,
      layout: "total, prev, pager, next",
      symbolId: "",
      position_order_id: "",
      bail_number: "",
      contractMultipleNum: {},
      pageInfo: {
        pageCount: 1,
        pageCurrent: 1,
        pageSize: 10,
        pageTotal: 1,
      },
      apiAssetsInfo: {},
      tabList: [
        {
          value: "1",
          label: "持仓",
        },
        {
          value: "2",
          label: "当前委托",
        },
        {
          value: "3",
          label: "历史委托",
        },
        {
          value: "4",
          label: "平仓记录",
        },
      ],
      currIndex: "1",
      getSymbolList: false,
      symbolList: {},
      coinInfoMap: {},
      triggerSideMap: {
        1: "大于触发价",
        2: "小于触发价",
      },
      tradeMap: {
        1: "市价",
        2: "限价",
      },
      sideMap: {
        1: "买涨",
        2: "买跌",
      },
      statusMap: {
        0: "未成交",
        1: "待成交",
        2: "完全成交",
        3: "已撤销",
        4: "失败",
      },
    };
  },
  async created() {
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      // await this.getToken();
      await this.initURL();
      this.initWs();
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.initWs();
    }
    await this.getItemList();
    await this.getCoinInfoMap();
    await this.getTradePairList();
    this.getOrderNumber();
    this.getAssets();
    let { userInfo } = await Account.getUserInfo();
    if (userInfo) {
      this.initData(this.checked);
      // this.startTimer();
    }
  },
  beforeDestroy() {
    // clearTimeout(timer);
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),

    marketInfoShow() {
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach((ele) => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    rowMarketInfoShow() {
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach((ele) => {
        if (ele.symbol_id == this.row_swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (
        JSON.stringify(this.assetsInfo) != "{}" &&
        this.assetsInfo.remain.list.length > 0
      ) {
        this.assetsInfo.remain.list.forEach((ele) => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else if (
        JSON.stringify(this.apiAssetsInfo) != "{}" &&
        this.apiAssetsInfo.remain.list.length > 0
      ) {
        this.apiAssetsInfo.remain.list.forEach((ele) => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = "--";
      }
      return tempObj;
    },
    afterLiquidationPriceShow() {
      //强平价 =  1 / ((持仓张数×单张合约价值 × 1/开仓价 × (1 做多| -1做空) + 保证金 × (1 − 强平风险率))/持仓张数/单张合约价值 / (1 做多 | -1做空))
      let tempValue = "";
      let side = this.rowData.open_side == 1 ? 1 : -1;
      if (this.bail_number != "") {
        // tempValV2 持仓张数
        let tempOpenPrice = "";
        if (this.currIndex == 1) {
          tempOpenPrice = new BigNumber(this.rowData.open_price);
        } else {
          tempOpenPrice = new BigNumber(this.rowData.trigger_price);
        }
        let tempSide = new BigNumber(side);
        let bigMarketPrice = new BigNumber(this.rowData.open_price);
        let tempMultipleNum = new BigNumber(this.rowData.open_leverage);
        // let tempConsumerCosts = new BigNumber(
        //   Number(this.rowData.consumer_costs) + Number(this.bail_number)
        // );
        let tempConsumerCosts = new BigNumber(this.afterBailNumberShow);
        let tempLiquidationRiskRate = new BigNumber(
          Number(this.liquidation_risk_rate) / 10000
        );
        let tempContractValue = new BigNumber(this.contract_value);
        let tempQty = new BigNumber(this.rowData.qty);
        let one = new BigNumber(1);

        // 强平价 =  1 / { 1 / 开仓价 + [持仓方向 × 保证金 × (1 −强平⻛险率)/ 张数 / 单张合约价值] }

        let tempValV2 = one.div(
          one
            .div(tempOpenPrice)
            .plus(
              tempSide
                .times(tempConsumerCosts)
                .times(one.minus(tempLiquidationRiskRate))
                .div(tempQty)
                .div(tempContractValue)
            )
        );

        tempValue = tempValV2.toString();

        if (this.rowData.open_side == 1) {
          //做多 做多的时候小数位用全进
          tempValue = (
            Math.ceil(tempValue * Math.pow(10, this.price_precision)) /
            Math.pow(10, this.price_precision)
          ).toFixed(this.price_precision);
        } else {
          // 做空的时候用截断
          tempValue = tempValue
            .toString()
            .substring(
              0,
              tempValue.toString().indexOf(".") + this.price_precision + 1
            );
        }
      } else {
        tempValue = this.afterLiquidationPrice;
      }
      if (Number(tempValue) < 0) {
        tempValue = "0";
      }
      return tempValue;
    },
    afterBailNumberShow() {
      return (Number(this.afterBailNumber) + Number(this.bail_number)).toFixed(
        this.settlement_precision
      );
    },
    //  未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)
    expectedProfit() {
      let tempValue = "--";
      let side = this.rowData.open_side == 1 ? 1 : -1;
      let tempOpenPrice = "";
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        if (this.currIndex == 1) {
          tempOpenPrice = new BigNumber(this.rowData.open_price);
        } else {
          tempOpenPrice = new BigNumber(this.rowData.trigger_price);
        }
        let tempInputProfitPrice = new BigNumber(this.inputProfitPrice);
        let tempContractValue = new BigNumber(this.contract_value);
        let tempSide = new BigNumber(side);
        let one = new BigNumber(1);
        let tempQty = new BigNumber(this.rowData.qty);
        // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

        tempValue = tempQty
          .times(tempContractValue)
          .times(one.div(tempOpenPrice).minus(one.div(tempInputProfitPrice)))
          .times(tempSide)
          .toNumber();

        if (this.rowData.open_side == 1) {
          tempValue = Math.ceil(
            tempValue * Math.pow(10, this.settlement_precision)
          );

          tempValue =
            (tempValue * 1.0) / Math.pow(10, this.settlement_precision);
          //做多 做多的时候小数位用全进
          // tempValue = (
          //   Math.ceil(tempValue * Math.pow(10, this.settlement_precision)) /
          //   Math.pow(10, this.settlement_precision)
          // ).toFixed(this.settlement_precision);
        } else {
          // 做空的时候用截断
          tempValue = tempValue
            .toString()
            .substring(
              0,
              tempValue.toString().indexOf(".") + this.settlement_precision + 1
            );
        }

        // 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

        if (Number(tempValue) <= 0) {
          tempValue = "--";
        }
      }
      return tempValue;
    },
    expectedProfitPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }

        tempValue = (this.expectedProfit / this.rowData.bail_number) * 100;
        tempValue = floorNumber(tempValue, 2);
        if (Number(tempValue) <= 0) {
          tempValue = "--";
        } else {
          tempValue = "+" + tempValue;
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    // 平仓盈亏
    expectedLoss() {
      let tempValue = "--";
      let side = this.rowData.open_side == 1 ? 1 : -1;
      let tempOpenPrice = "";
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        if (this.currIndex == 1) {
          tempOpenPrice = new BigNumber(this.rowData.open_price);
        } else {
          tempOpenPrice = new BigNumber(this.rowData.trigger_price);
        }
        let tempInputProfitPrice = new BigNumber(this.inputLossPrice);
        let tempQty = new BigNumber(this.rowData.qty);
        let tempContractValue = new BigNumber(this.contract_value);
        let tempSide = new BigNumber(side);
        let one = new BigNumber(1);
        // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

        tempValue = tempQty
          .times(tempContractValue)
          .times(one.div(tempOpenPrice).minus(one.div(tempInputProfitPrice)))
          .times(tempSide)
          .toNumber();
        if (this.rowData.open_side == 1) {
          if (Number(tempValue) < 0) {
            //做多 做多的时候小数位用全进
            let tempValueABS = Math.abs(tempValue);
            tempValue = Math.ceil(
              tempValueABS * Math.pow(10, this.settlement_precision)
            );

            tempValue =
              (tempValue * 1.0) / Math.pow(10, this.settlement_precision);
            tempValue = tempValue * -1;
          }
          // tempValue =
          //   Math.round(tempValue * Math.pow(10, this.settlement_precision)) /
          //   Math.pow(10, this.settlement_precision);
        } else {
          // 做空的时候用截断
          tempValue = tempValue
            .toString()
            .substring(
              0,
              tempValue.toString().indexOf(".") + this.settlement_precision + 1
            );
        }
        if (Number(tempValue) > 0) {
          tempValue = "--";
        } else if (Number(tempValue * -1) > this.rowData.bail_number) {
          tempValue = this.rowData.bail_number * -1;
        }
      }
      return tempValue;
    },
    expectedLossPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }

        tempValue = (this.expectedLoss / this.rowData.bail_number) * 100;
        tempValue = ceilNumber(tempValue, 2);
        if (Number(tempValue) < -100) {
          tempValue = -100;
        }
        if (Number(tempValue) > 0) {
          tempValue = "--";
        }
      }
      // inputLossPrice
      return tempValue;
    },
  },
  beforeDestroy() {
    this.isDestoryWS = true;
    this.global.ws.close();
  },
  methods: {
    routeSplicing,
    onOpenOrderDetails(assets_type, row) {
      let tempRow = {
        order_info: { position_order_id: row.position_order_id },
        assets_type: assets_type,
      };
      this.$refs.orderDetails.liquidationFuturesInfoClick(tempRow);
    },
    searchPlanOrderList() {
      this.initData();
    },
    resetPlanOrderList() {
      this.planOrderList_create_time = "";
      this.planOrderList_symbol_id = "";
      this.planOrderList_open_side = "";
      this.ctimeAndEtime = [];
      this.ctimeAndEtime4History = [];
      this.initData();
    },

    async getItemList() {
      const { status, data } = await getCoinList();
      if (status == 200) {
        this.coinData = data;
      }
    },
    onOK() {
      if (this.dialogType == 1) {
        this.onClosePosition();
      } else if (this.dialogType == 2) {
        this.onCancelOrder();
      } else if (this.dialogType == 3) {
        this.onRevokeAllOrder();
      } else if (this.dialogType == 4) {
        this.onRevokePlan();
      }
    },
    goToRecharge() {
      window.open(
        this.routeSplicing(this.language, "ucenter/assets/recharge/usdt")
      );
    },
    onClearAll() {
      this.inputProfitPrice = "";
    },
    onClearAllV2() {
      this.inputLossPrice = "";
    },
    setProfitPricedialogClose() {
      this.inputLossPrice = "";
      this.inputProfitPrice = "";
      this.setProfitPricedialog = false;
    },
    appendMoneydialogClose() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    onClickAll() {
      this.bail_number = this.assetsInfoShow;
    },
    reset(type) {
      this.orderLoading = true;
      this.currIndex = type.toString();
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
      this.getOrderNumber();
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    onProfitPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
          this.settlement_precision = this.symbolList[key].settlement_precision;
          this.contract_value = this.symbolList[key].contract_value;
          this.fee_rate = this.symbolList[key].fee_rate;
          this.liquidation_risk_rate =
            this.symbolList[key].liquidation_risk_rate;
        }
      }
      this.marketPriceNow = JSON.parse(
        JSON.stringify(this.rowMarketInfoShow.last)
      );
      this.setProfitPricedialog = true;
    },
    onLossPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
          this.settlement_precision = this.symbolList[key].settlement_precision;
          this.contract_value = this.symbolList[key].contract_value;
          this.fee_rate = this.symbolList[key].fee_rate;
          this.liquidation_risk_rate =
            this.symbolList[key].liquidation_risk_rate;
        }
      }
      this.setProfitPricedialog = true;
    },
    async getCoinInfoMap() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.coinInfoMap = data;
      }
    },
    async getTradePairList() {
      const { status, data } = await apiGetStaticTableInfo(58);
      if (status == 200) {
        this.symbolList = data;
        this.getSymbolList = true;
        // this.contract_value = this.symbolList[
        //   this.swap_symbol_id
        // ].contract_value;
        // this.settlement_precision = this.symbolList[
        //   this.swap_symbol_id
        // ].settlement_precision;
      }
    },
    onSymbolChange(item) {
      this.$emit("on-symbol-change", item);
    },
    onSymbolClick(row) {
      let tempName = "";
      if (row.from_symbol_name) {
        tempName = row.from_symbol_name.toLowerCase() + "usd";
      } else {
        for (const key in this.coinInfoMap) {
          if (key == row.open_item_id) {
            tempName = this.coinInfoMap[key].item_name.toLowerCase() + "usd";
          }
        }
      }
      window.open(this.routeSplicing(this.language, "delivery/" + tempName));
    },
    onOpenConfirm(type, id) {
      this.dialogType = type;
      if (type == 1) {
        this.position_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认平仓此订单？");
      } else if (type == 2) {
        this.confirmMessage = this.translateTitle("是否确认全部平仓？");
      } else if (type == 3) {
        this.confirmMessage = this.translateTitle("是否确认全部撤销？");
      } else if (type == 4) {
        this.plan_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认撤销此委托单？");
      }
      this.dialogVisible = true;
    },
    onOpenAppendMoney(row) {
      this.from_item_id = row.open_item_id;
      this.to_item_id = row.settlement_item_id;
      this.from_symbol =
        this.coinInfoMap[row.open_item_id].item_name.toUpperCase();
      this.to_symbol =
        this.coinInfoMap[row.settlement_item_id].item_name.toUpperCase();
      this.swap_symbol_id = row.symbol_id;
      this.position_order_id = row.position_order_id;
      this.beforeBailNumber = row.bail_number;
      this.afterBailNumber = row.bail_number;
      this.beforeLiquidationPrice = row.liquidation_price;
      this.afterLiquidationPrice = row.liquidation_price;
      this.rowData = row;

      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
          this.settlement_precision = this.symbolList[key].settlement_precision;
          this.contract_value = this.symbolList[key].contract_value;
          this.fee_rate = this.symbolList[key].fee_rate;
          this.liquidation_risk_rate =
            this.symbolList[key].liquidation_risk_rate;
        }
      }
      this.appendMoneydialog = true;
    },
    onClosePosition() {
      let params = {
        position_order_id: this.position_order_id,
      };
      apiClosePosition(params).then((res) => {
        if (res.status == 200) {
          this.dialogVisible = false;
          showMessage({
            message: this.translateTitle("平仓成功"),
            type: "success",
            // duration:10000000,
          });
          this.initData(this.checked);
        }
      });
    },
    onAppendMoney() {
      if (Number(this.bail_number) == 0) {
        showMessage({
          message: this.translateTitle("请输入追加金额"),
          type: "error",
        });
        return;
      }
      let params = {
        position_order_id: this.position_order_id,
        bail_number: this.bail_number,
      };
      apiAppendInterest(params).then((res) => {
        if (res.status == 200) {
          showMessage({
            message: this.translateTitle("追加成功"),
            type: "success",
          });
          this.appendMoneydialog = false;
          this.initData(this.checked);
        }
      });
    },
    async startTimer() {
      const timer = setTimeout(() => {
        this.initData(this.checked);
        // this.getOrderNumber();
        this.getMultipleNum();
        this.startTimer();
      }, 3000);
      this.$once("hook:beforeDestroy", () => {
        clearTimeout(timer);
      });
      this.$once("hook:destroyed", () => {
        clearTimeout(timer);
      });
    },
    async onCancelOrder() {
      const { status, data } = await apiAllClosePosition();
      if (status == 200) {
        this.dialogVisible = false;
        showMessage({
          message: this.translateTitle("平仓成功"),
          type: "success",
        });
        this.initData(this.checked);
      }
    },

    async onRevokeAllOrder() {
      const { status, data } = await apiRevokeAllPlan();
      if (status == 200) {
        showMessage({
          message: this.translateTitle("撤销成功"),
          type: "success",
        });
        this.dialogVisible = false;
        this.initData(this.checked);
      }
    },
    async onRevokePlan() {
      const req = {
        plan_order_id: this.plan_order_id,
      };
      const { status, data } = await apiRevokePlan(req);
      if (status == 200) {
        showMessage({
          message: this.translateTitle("撤销成功"),
          type: "success",
        });
        this.dialogVisible = false;
        this.initData(this.checked);
      }
    },
    onSetPositionProfitLoss() {
      // if (this.inputLossPrice == "" && this.inputProfitPrice == "") {
      //   showMessage({ message: "请输入止盈价或止损价", type: "error" });
      //   return;
      // }
      if (this.currIndex == 1) {
        let params = {
          position_order_id: this.rowData.position_order_id,
          market_price: this.rowMarketInfoShow.last,
          stop_profit_price: this.inputProfitPrice,
          stop_loss_price: this.inputLossPrice,
        };
        apiSetPositionProfitLoss(params).then((res) => {
          if (res.status == 200) {
            showMessage({
              message: this.translateTitle("设置成功"),
              type: "success",
            });
            this.setProfitPricedialogClose();
            this.initData(this.checked);
          }
        });
      } else {
        let params = {
          plan_order_id: this.rowData.plan_order_id,
          market_price: this.rowMarketInfoShow.last,
          stop_profit_price: this.inputProfitPrice,
          stop_loss_price: this.inputLossPrice,
        };
        apiSetPlanProfitLoss(params).then((res) => {
          if (res.status == 200) {
            showMessage({
              message: this.translateTitle("设置成功"),
              type: "success",
            });
            this.setProfitPricedialogClose();
            this.initData(this.checked);
          }
        });
      }
    },
    onAreaChange(item) {
      this.orderLoading = true;
      this.tableData = [];
      this.currIndex = item.value;
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    getMultipleNum() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        symbol_id: this.checked ? this.swap_symbol_id : "",
      };
      apiGetOrderStatistic(params).then((res) => {
        if (res.status == 200) {
          this.contract_multiple_num = JSON.stringify(
            res.data.contract_multiple_num
          );
          localStorage.setItem(
            "delivery-multiple-num",
            this.contract_multiple_num
          );
          this.contract_position_num =
            res.data.contract_position_info.total_num;
          this.contract_plan_num = res.data.contract_plan_info.total_num;
        }
      });
    },
    getOrderNumber() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        symbol_id: this.checked ? this.swap_symbol_id : "",
      };
      apiGetOrderStatistic(params).then((res) => {
        if (res.status == 200) {
          this.contract_position_num =
            res.data.contract_position_info.total_num;
          this.contract_plan_num = res.data.contract_plan_info.total_num;
        }
      });
    },
    async initData(checked = false) {
      this.orderLoading = true;
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        this.orderLoading = false;
        return;
      }
      let tempStatus = this.currIndex;
      if (this.currIndex == 3) {
        tempStatus = "";
      }
      let temp_symbol_id = this.swap_symbol_id;
      let temp_is_hide = 2;
      if (checked) {
        temp_symbol_id = this.swap_symbol_id;
        temp_is_hide = 1;
      } else {
        temp_symbol_id = "";
        temp_is_hide = 2;
      }
      if (this.currIndex == 1) {
        const req = {
          symbol_id: temp_symbol_id,
          position_status: 1,
          page: this.pageInfo.pageCurrent,
          page_size: 100000,
          // page_size: this.pageInfo.pageSize,
          // is_hide: temp_is_hide,
        };
        return apiGetOpenPositionList(req).then((res) => {
          if (res.status == 200 && res.data.list.length > 0) {
            res.data.list.forEach((ele) => {
              // 存各个币种杠杆倍数

              this.contractMultipleNum[ele.symbol_id] = {
                open_leverage: ele.open_leverage,
              };
              for (const key in this.symbolList) {
                if (this.symbolList[key].open_item_id == ele.open_item_id) {
                  ele.settlement_precision =
                    this.symbolList[key].settlement_precision;
                  ele.contract_value = this.symbolList[key].contract_value;
                }
              }
              if (this.marketList.length > 0) {
                this.marketList.forEach((item) => {
                  if (item.symbol_id == ele.symbol_id) {
                    ele.last_price = item.last;
                  }
                });
                // 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价)
                let tempContractValue = new BigNumber(ele.contract_value);
                let side = ele.open_side == 1 ? 1 : -1;
                let tempSide = new BigNumber(side);
                let one = new BigNumber(1);
                let oneHundred = new BigNumber(100);
                let tempQty = new BigNumber(ele.qty);
                let tempOpenPrice = new BigNumber(ele.open_price);
                let tempLastPrice = new BigNumber(ele.last_price);
                let tempSettlementPrecision = new BigNumber(
                  ele.settlement_precision
                );

                // 未实现盈亏
                ele.position_profit_loss = tempQty
                  .times(tempContractValue)
                  .times(one.div(tempOpenPrice).minus(one.div(tempLastPrice)))
                  .times(tempSide)
                  .toNumber()
                  .toFixed(ele.settlement_precision);
                // .toFixed(this.number_precision);
                let tempValue = new BigNumber(ele.position_profit_loss);

                ele.position_profit_loss_procent = tempValue
                  .div(ele.bail_number)
                  .times(oneHundred)
                  .toFixed(2);
              }

              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name =
                    this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            // this.pageInfo = res.data.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 2) {
        const req = {
          symbol_id: temp_symbol_id,
          order_status: 1,
          is_hide: 2,
          page: this.pageInfo.pageCurrent,
          page_size: 100000,
          // page_size: this.pageInfo.pageSize,
        };
        return apiGetPlanOrderList(req).then((res) => {
          if (res.status == 200) {
            for (const key in this.coinInfoMap) {
              res.data.list.forEach((ele) => {
                this.marketList.forEach((item) => {
                  if (item.symbol_id == ele.symbol_id) {
                    ele.last_price = item.last;
                  }
                });
                if (ele.open_item_id == key) {
                  ele.from_symbol_name = ele.symbol_name =
                    this.coinInfoMap[key].item_name.toUpperCase();
                }
              });
            }
            this.tableData = res.data.list;
            // this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 3) {
        let temp_order_status = "2,3,4";
        if (this.hidenCancelChecked) {
          temp_order_status = "2,4";
        } else {
          temp_order_status = "2,3,4";
        }
        // let temp_create_time = "";
        // if (this.planOrderList_create_time) {
        //   temp_create_time =
        //     Date.parse(new Date()) / 1000 - this.planOrderList_create_time;
        // }
        let create_time = "";
        let end_time = "";
        if (this.ctimeAndEtime4History) {
          const [start, end] = this.ctimeAndEtime4History;
          const end2 = end + 86399000;
          create_time = parseInt(start / 1000);
          end_time = parseInt(end2 / 1000);
        }
        const req = {
          symbol_id: this.planOrderList_symbol_id,
          open_side: this.planOrderList_open_side,
          create_time: create_time,
          end_time: end_time,
          // create_time: temp_create_time,
          order_status: temp_order_status,
          is_hide: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize,
        };
        return apiGetPlanOrderList(req).then((res) => {
          if (res.status == 200) {
            res.data.list.forEach((ele) => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name =
                    this.exchangeCoinMap[key].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name =
                    this.coinInfoMap[key].item_name.toUpperCase();
                }
                if (key == ele.settlement_item_id) {
                  ele.to_symbol_name =
                    this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;

            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 4) {
        let create_time = "";
        let end_time = "";
        if (this.ctimeAndEtime) {
          const [start, end] = this.ctimeAndEtime;
          const end2 = end + 86399000;
          create_time = parseInt(start / 1000);
          end_time = parseInt(end2 / 1000);
        }
        const req = {
          symbol_id: this.planOrderList_symbol_id,
          open_side: this.planOrderList_open_side,
          create_time: create_time,
          end_time: end_time,
          position_status: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize,
        };

        return apiGetOpenPositionList(req).then((res) => {
          if (res.status == 200 && res.data.list.length > 0) {
            res.data.list.forEach((ele) => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name =
                    this.exchangeCoinMap[key].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name =
                    this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      }
    },
    onCurrentPageChange() {
      this.initData(this.checked);
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "futureM",
            app_id: VUE_APP_ID,
          },
        },
        callback,
        onConnect,
        mounted,
      };

      let wsNew = new Socket(option).getInstance();
      me.global.setWs(wsNew);
      // 重新连接
      function onConnect() {
        if (me.isDestoryWS) return;
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
      function mounted() {
        // window.ws.sub({
        //   action: "Topic.sub",
        //   data: {
        //     type: "depth",
        //     symbol: me.swap_symbol_id,
        //     // resolution: "D",
        //   },
        // });
        // window.ws.sub({
        //   action: "Topic.sub",
        //   data: {
        //     type: "orderbook",
        //     symbol: me.swap_symbol_id,
        //   },
        // });

        // window.ws.sub({
        //   action: "Topic.sub",
        //   data: {
        //     type: "futureM",
        //     app_id: VUE_APP_ID,
        //   },
        // });
        if (Cookies.get("topcredit_exchange_t")) {
          me.global.ws.sub({
            action: "Topic.sub",
            data: {
              type: "assets",
            },
          });
        }
      }
      function callback(data) {
        const { action } = data;
        if (action == "Pushdata.futureM") {
          if (data.data) {
            let tempArr = data.data;
            tempArr.forEach((ele) => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }
        // if (action == "Pushdata.kline") {
        //   window.resolveKlineDataV4(data);
        // }
        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
        // if (action == "Pushdata.orderbook") {
        //   if (data.data) {
        //     if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
        //       return;
        //     }
        //     me.orderbookList.unshift(...data.data);
        //     if (me.orderbookList.length > 28) {
        //       me.orderbookList = me.orderbookList.slice(0, 28);
        //     }
        //   }
        // }
        // if (action == "Pushdata.depth") {
        //   if (data.data) {
        //     if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
        //       return;
        //     }
        //     let tempObj = data.data;
        //     if (tempObj.a.length > 0) {
        //       tempObj.c = 0;
        //       tempObj.d = 0;
        //       tempObj.a.forEach((ele) => {
        //         tempObj.c += Number(ele[1]);
        //       });
        //       tempObj.b.forEach((ele) => {
        //         tempObj.d += Number(ele[1]);
        //       });
        //     }
        //     me.depthList = tempObj;
        //   }
        // }
      }
    },
  },
  watch: {
    // swap_symbol_id: {
    //   handler(newVal, oldVal) {
    //     if (oldVal == undefined) {
    //       return;
    //     }
    //     if (newVal != oldVal) {
    //       this.symbolId = newVal;
    //       this.orderLoading = true;
    //       this.pageInfo.pageCurrent = 1;
    //       if (this.getSymbolList) {
    //         this.contract_value = this.symbolList[
    //           this.swap_symbol_id
    //         ].contract_value;
    //         this.settlement_precision = this.symbolList[
    //           this.swap_symbol_id
    //         ].settlement_precision;
    //       }
    //       this.initData(this.checked);
    //     }
    //   },
    // },
    hidenCancelChecked() {
      this.initData(this.checked);
      this.getOrderNumber();
    },
    checked() {
      this.initData(this.checked);
      this.getOrderNumber();
    },
    marketList() {
      if (this.currIndex == 1 || this.currIndex == 2) {
        // this.recordDeliveryTable1 = !this.recordDeliveryTable1;
        let tmepData = JSON.parse(JSON.stringify(this.tableData));
        this.tableData = [];
        tmepData.forEach((ele) => {
          this.marketList.forEach((item) => {
            if (item.symbol_id == ele.symbol_id) {
              ele.last_price = item.last;
            }
          });
          // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价)
          let tempContractValue = new BigNumber(ele.contract_value);
          let side = ele.open_side == 1 ? 1 : -1;
          let tempSide = new BigNumber(side);
          let one = new BigNumber(1);
          let oneHundred = new BigNumber(100);
          let tempQty = new BigNumber(ele.qty);
          let tempOpenPrice = new BigNumber(ele.open_price);
          let tempLastPrice = new BigNumber(ele.last_price);
          let tempSettlementPrecision = new BigNumber(ele.settlement_precision);

          // 未实现盈亏
          ele.position_profit_loss = tempQty
            .times(tempContractValue)
            .times(one.div(tempOpenPrice).minus(one.div(tempLastPrice)))
            .times(tempSide)
            .toNumber()
            .toFixed(ele.settlement_precision);
          // .toFixed(this.number_precision);
          let tempValue = new BigNumber(ele.position_profit_loss);
          ele.position_profit_loss_procent = tempValue
            .div(ele.bail_number)
            .times(oneHundred)
            .toFixed(2);
        });
        this.tableData = tmepData;
        // this.$forceUpdate();
      }
    },
  },
};
</script>
<style lang="scss">
.transaction-managment-content-delivery {
  padding: 0px 20px;
  background: #ffffff;
  overflow: hidden;
  margin: 20px;
  min-height: 600px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);

  .filter-box {
    margin: 0px 0px 20px 0px;

    .tenWid {
      width: 10px;
      height: 1px;
      display: inline-block;
      position: relative;
    }

    .twentyWid {
      width: 20px;
      height: 1px;
      display: inline-block;
      position: relative;
    }

    .btn-search {
      user-select: none;
      cursor: pointer;
      width: 120px;
      height: 16px;
      padding: 10px 21px;
      border-radius: 3px;
      background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
      color: #fff;
      font-size: 14px;
    }

    .btn-reset {
      user-select: none;
      cursor: pointer;
      width: 120px;
      height: 16px;
      padding: 9px 20px;
      border-radius: 3px;
      color: #354052;
      font-size: 14px;
      border: solid 1px #c2c7d0;
      box-sizing: border-box;
    }

    .btn-export {
      user-select: none;
      cursor: pointer;
      /* width: 120px; */
      padding: 10px 12px;
      border-radius: 3px;
      color: #354052;
      font-size: 14px;
      border: solid 1px #c2c7d0;
      box-sizing: border-box;
      float: right;

      .export-icon {
        color: #9aa1b1;
        margin-right: 4px;
      }
    }

    .el-input {
      width: 100px;

      .el-input__inner {
        border: solid 1px #e2e2e4;
        box-sizing: border-box;
        border-radius: 3px;
      }
    }

    .el-checkbox {
      margin-left: 20px;

      .el-checkbox__label {
        font-size: 14px;
        color: #354052;
        font-weight: 400;
      }
    }

    .selectStyle {
      width: 180px;

      .el-input.el-input--suffix {
        width: 100%;
      }
    }
  }

  .contract-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .open-item-img {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }

    .symbol_name {
      font-size: 14px;
      font-weight: 500;
      color: #3348f6;
    }
  }

  .symbol_name_ano {
    font-size: 14px;
    font-weight: 500;
    color: #3348f6;
    cursor: pointer;
  }

  .market-header {
    border-bottom: solid 1px #ecedf1;
    margin-bottom: 20px;

    ul {
      display: flex;
    }

    .classify-area {
      user-select: none;
      height: 40px;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #354052;

      padding: 0 15px;
      cursor: pointer;
      text-align: center;
      line-height: 32px;
      font-weight: normal;
      position: relative;
      user-select: none;

      &.active {
        font-weight: 500;
      }

      .item-bar {
        position: absolute;
        // width: calc(100% - 30px);
        width: 42px;
        height: 3px;
        background-color: #e81b40;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .hidden-btn {
      margin: 0 20px 0 auto;
      font-size: 14px;
      color: #354052;
      display: flex;
      align-items: center;

      .el-checkbox__label {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .header-cancel-btn {
      margin: 4px 15px;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;

      .cancel-btn-v2 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 24px;
        padding: 2px 10px 2px;
        border-radius: 2px;
        border: solid 1px #e2e2e4;
        background-color: #ffffff;
        color: #3348f6;
        border-color: #3348f6;
        font-size: 12px;
        line-height: 12px;
        // font-weight: 500;
        outline: none;
        cursor: pointer;

        &:hover {
          background-color: #ffffff;
          color: #3348f6;
          border: solid 1px #e2e2e4;
          outline: none;
        }

        &:focus {
          background-color: #ffffff;
          color: #3348f6;
          border: solid 1px #e2e2e4;
          outline: none;
        }
      }
    }
  }

  .content-header {
    width: 120px;
    height: 40px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #354052;
  }

  .el-table {
    // height: calc(100vh - 692px);
    /* min-height: 624px; */
    min-height: 570px;
  }

  .no-data-box {
    /* min-height: 600px; */
    min-height: 520px;
  }

  .el-table__row {
    height: 50px !important;

    .cell {
      color: #354052;
      font-size: 14px;
      // display: flex;
      align-items: center;

      .red {
        // color: #fa4d56;
        color: #c61b3d;
      }

      .green {
        color: #03ad79;
      }
    }
  }

  .coin-ing {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }

  // .operation {
  //   font-size: 14px;
  //   color: #3348F6;
  //   font-weight: 400;
  //   cursor: pointer;
  //   padding: 0px 5px 0px;
  // }
  .operation {
    cursor: pointer;
    height: 24px;
    padding: 5px 6px;
    border-radius: 2px;
    border: solid 1px #e2e2e4;
    background-color: #ffffff;
    color: #3348f6;
    font-size: 12px;
    font-weight: normal;
    outline: none;

    &:hover {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }

    &:focus {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
  }

  .operation-ban {
    font-size: 14px;
    color: #9aa1b1;
    font-weight: 400;
    padding: 0px 5px 0px;
  }

  .append-money-dialog {
    .el-dialog__header {
      padding: 20px 30px 20px;
    }

    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }

    .el-dialog__body {
      padding: 0px 30px 5px;
    }

    .el-dialog__footer {
      padding: 10px 20px 30px;
    }

    .first-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
      font-size: 12px;

      .available-assets {
        color: #bec1c8;
      }

      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .second-content {
      position: relative;

      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;

        .el-input__suffix-inner {
          font-size: 12px;
        }
      }

      .all-btn {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
      }
    }

    .three-content {
      width: 440px;
      height: 120px;
      margin: 20px 30px 20px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;

      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
          display: block;
          margin-bottom: 2px;
          color: #9aa1b1;
          font-size: 12px;

          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }

          &.red {
            font-weight: 500;
            font-size: 14px;
            color: #f04a5a;
          }
        }

        .left-item {
          width: 150px;
        }

        .right-item {
          width: 150px;
          text-align: right;
        }
      }

      img {
        width: 22px;
        height: 22px;
      }
    }
  }

  .set-profitPrice-dialog {
    .el-dialog__header {
      padding: 20px 30px 5px;
    }

    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }

    .el-dialog__body {
      padding: 0px 30px 5px;
    }

    .el-dialog__footer {
      padding: 35px 20px 30px;
    }

    .profitPrice-first-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }

      .right-item {
        font-size: 12px;

        .green {
          color: #03ad79;
        }

        .red {
          color: #c61b3d;
        }
      }

      &.V2 {
        margin-top: 15px;
      }
    }

    .profitPrice-second-content {
      position: relative;

      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
      }

      .all-btn {
        position: absolute;
        right: 10px;
        top: 9px;
      }
    }

    .profitPrice-three-content {
      width: 440px;
      height: 70px;
      margin: 15px 0px 15px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;

      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
          display: block;
          margin-bottom: 2px;
          color: #7f8490;
          font-size: 12px;

          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }

          &.red {
            font-size: 14px;
            color: #f04a5a;
          }
        }

        .left-item {
          width: 150px;
        }

        .center-item {
          width: 150px;
          text-align: center;
        }

        .right-item {
          width: 150px;
          text-align: right;
        }
      }
    }
  }

  .confirm-dialog {
    // height: 200px;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }

    .el-dialog__body {
      text-align: center;
      color: #354052;
      padding: 20px 20px 30px;
      font-size: 16px;
      font-weight: normal;
    }
  }

  .side_1 {
    color: #03ad79;
  }

  .side_2 {
    color: #c61b3d;
  }

  .status_1 {
    color: #f78700;
  }

  .status_2 {
    color: #03ad79;
  }

  .status_3 {
    color: #bec1c8;
  }

  .add-btn {
    /* width: 70px; */
    cursor: pointer;
    height: 24px;
    padding: 5px 5px 5px;
    // line-height: 24px;
    border-radius: 2px;
    border: solid 1px #e2e2e4;
    background-color: #ffffff;
    color: #3348f6;
    border-color: #3348f6;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    outline: none;

    &:hover {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }

    &:focus {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
  }

  .cancel-btn {
    text-align: center;
    // width: 52px;
    height: 24px;
    padding: 5px 5px 5px;
    // line-height: 24px;
    border-radius: 2px;
    border: solid 1px #e2e2e4;
    background-color: #ffffff;
    color: #3348f6;
    border-color: #3348f6;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    outline: none;

    &:hover {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }

    &:focus {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
  }
}
</style>
