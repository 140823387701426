export function translateTitle(title, dynamicValues) {
  if (dynamicValues && !Array.isArray(dynamicValues)) {
    throw new Error("dynamicValues 必须是数组");
  }

  let content = title;
  if (this.$te(`${title}`)) {
    content = this.$t(`${title}`);
  }
  const tempValues = dynamicValues ? [...dynamicValues] : [];
  if (tempValues.length === 0) return content;
  return content.replace(/@/g, function (chat, index, text) {
    const value = tempValues.shift();
    return value || "";
  });
}
