<template>
  <div>
    <div class="mission-panel__container">
      <div
        class="mission-panel__container-item"
        v-for="(item, index) in mission"
        :key="index"
      >
        <div
          class="mission-panel__container-item-container"
          :class="{
            'last-child': index === mission.length - 1,
          }"
        >
          <div class="mission-panel__container-item-container-wraper">
            <div class="mission-panel__container-item-container-title">
              {{ translateTitle(item.title) }}
              <!-- <img
                class="mission-panel__container-item-container-title-bg"
                :src="item.icon[isDarkMode ? 'dark' : 'light']"
              /> -->
            </div>
            <div class="mission-panel__container-item-container-subtitle">
              {{ translateTitle(item.subTitle) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import secondBgDark from "@/assets/img/home/mission/title-bg-2-dark.png";
import secondBgLight from "@/assets/img/home/mission/title-bg-2-light.png";
export default {
  props: {
    customStyle: {
      type: Object,
      default: () => {},
    },
  },
  filters: {},
  directives: {},
  created() {},
  mounted() {},
  destroyed() {},
  computed: {},
  components: {},
  mixins: [],
  data() {
    return {
      mission: [
        {
          title: "使命",
          subTitle: "成为全球综合金融服务平台的市场领导者",
          icon: {
            dark: secondBgDark,
            light: secondBgLight,
          },
        },
        {
          title: "愿景",
          subTitle: "提供全球用户安全、便捷、创新的金融产品和服务",
          icon: {
            dark: secondBgDark,
            light: secondBgLight,
          },
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
:root[theme="light"] {
  .mission-panel__container {
    background: #fff;
    .mission-panel__container-item-container-subtitle,
    .mission-panel__container-item-container-title {
      color: #000;
    }
    &-item {
      &:hover {
        .mission-panel__container-item-container-title {
          background: url(../../assets/img/home/mission/title-bg-2-dark.png)
            no-repeat !important;
          background-size: contain !important;
          background-position-x: 0px !important;
          background-position-y: -6px !important;
        }
        .mission-panel__container-item-container-subtitle,
        .mission-panel__container-item-container-title {
          color: #fff;
        }
      }
    }
    .mission-panel__container-item-container-title {
      background: url(../../assets/img/home/mission/title-bg-2-light.png)
        no-repeat;
      background-size: contain;
      background-position-x: 0px;
      background-position-y: -6px;
    }
  }
}

#app:not(.zh_CN, .zh_HK) {
}

:root[theme="dark"] {
}
.mission-panel__container {
  height: 400px;
  background: #000;
  color: #fff;
  display: flex;
  &-item {
    width: 50%;
    cursor: pointer;
    &:hover {
      background-image: url(../../assets/img/home/mission/miss-bg.png);
      .mission-panel__container-item-container-title {
        background: url(../../assets/img/home/mission/title-bg-2-dark.png)
          no-repeat !important;
        background-size: contain !important;
        background-position-x: 0px !important;
        background-position-y: -6px !important;
      }
    }
    &:first-child {
      padding-right: 120px;
    }

    &-container {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &.last-child {
        padding-left: 120px;
        justify-content: flex-start;
      }
      &-wraper {
        width: 480px;
        /* position: relative;
        top: -69px; */
      }
      &-title {
        color: #fff;
        text-align: left;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position: relative;
        top: 42px;
        background: url(../../assets/img/home/mission/title-bg-2-dark.png)
          no-repeat;
        background-size: contain;
        background-position-x: 0px;
        background-position-y: -6px;
        /* &-bg {
          width: 92px;
       
          position: absolute;
          left: 6px;
          top: -20px;
        } */
      }
      &-subtitle {
        color: #fff;

        /* PF_font/r/t_r20 */
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 130% */
        margin-top: 62px;
      }
    }
  }
}
</style>