import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export function apiGetOrderList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/getOrderListV2`,
    data,
    type: "POST",
  });
}

export function apiCancelOrde(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/cancelLimitOrder`,
    data,
    type: "POST",
  });
}
// 持仓  平仓记录
export function apiGetOpenPositionList(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/openPositionList`,
    data,
    type: "POST",
  });
}
// 当前委托  历史委托
export function apiGetPlanOrderList(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/planOrderList`,
    data,
    type: "POST",
  });
}
// apiRevokePlan
// 合约撤销计划委托
export function apiRevokePlan(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/revokePlan`,
    data,
    type: "POST",
  });
}
//

// 合约追加持仓
export function apiAppendInterest(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/appendInterest`,
    data,
    type: "POST",
  });
}
// 市价合约下单
export function apiOpenPosition(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/openPosition`,
    data,
    type: "POST",
  });
}
// 限价合约下单
export function apiPlanOrder(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/planOrder`,
    data,
    type: "POST",
  });
}
// 合约平仓
export function apiClosePosition(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/closePosition`,
    data,
    type: "POST",
  });
}
// 合约一键平仓
export function apiAllClosePosition(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/allClosePosition`,
    data,
    type: "POST",
  });
}
// 合约仓位止盈止损
export function apiSetPositionProfitLoss(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/setPositionProfitLoss`,
    data,
    type: "POST",
  });
}
// 合约委托单止盈止损
export function apiSetPlanProfitLoss(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/setPlanProfitLoss`,
    data,
    type: "POST",
  });
}
// 撤销所有计划委托
export function apiRevokeAllPlan(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/revokeAllPlan`,
    data,
    type: "POST",
  });
}
// 用户合约统计数据
export function apiGetOrderStatistic(data = {}) {
  return api({
    url: VUE_APP_APP + `/Contract/statistic`,
    data,
    type: "POST",
  });
}

//币本位合约接口整合 undingRate,planOrderList,openPositionList   当前委托  历史委托   持仓  平仓记录
export function apiGetContract(data = {}) {
  return api({
    url: VUE_APP_APP + `/Merge/contract`,
    data,
    type: "POST",
  });
}
