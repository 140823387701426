import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

//理财产品列表
export function apiGetProductListV2(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/Publics/getFinanceList`,
    data,
    type: "POST",
  });
}
//获取理财产品详情
export function apiGetFinancesProductInfo(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/Finances/getProductInfo`,
    data,
    type: "POST",
  });
}
//获取理财产品详情 - 无登录认证
export function apiGetProductInfo(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/Product/getProductInfo`,
    data,
    type: "POST",
  });
}
// 购买理财产品
export function apiBuyFinances(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/Finances/buy`,
    data,
    type: "POST",
  });
}
// 赎回理财产品
export function apiSellFinances(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/Finances/sell`,
    data,
    type: "POST",
  });
}
// 用户持仓详情
export function apiGetPositionInfo(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/Finances/getPositionInfo`,
    data,
    type: "POST",
  });
}
// DeFi产品列表页
export function apiGetDeFiProductList(data = {}) {
  return api({
    url: VUE_APP_APP + `/DeFi/getProductList`,
    data,
    type: "POST",
  });
}

// 理财产品持仓历史列表
export function apiGetHistoryPositionList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/Finances/historyPositionList`,
    data,
    type: "POST",
  });
}
