import axios from 'axios'
import request from '@/assets/js/api'
// import { Message } from 'element-ui'

//图片上传
export function fileUpload(url, params, error) {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
  } //添加请求头
  return new Promise((resolve) => {
  
    axios.post(url, params, config).then(
      
      (res) => {
        resolve(res)
      },
      (err) => {
        err = error ? error : err
      }
    )
  })
}

export function videoUpload(url, params, error) {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  } //添加请求头
  return new Promise((resolve) => {
    request.post(url, params, config).then(
      (res) => {
        resolve(res)
      },
      (err) => {
        err = error ? error : err
      }
    )
  })
}
