<template>
  <div class="contract-component-history-order">
    <div class="market-header">
      <ul>
        <li
          class="classify-area"
          :class="{ active: currIndex == item.value }"
          v-for="(item, index) of tabList"
          :key="item.value"
          @click="onAreaChange(item)"
        >
          {{ translateTitle(item.label) }}
          <span v-if="index == 0 && contract_position_num != 0"
            >( {{ contract_position_num }} )</span
          >
          <span v-if="index == 1 && contract_plan_num != 0"
            >( {{ contract_plan_num }} )</span
          >
          <i v-if="currIndex == item.value" class="item-bar"></i>
        </li>
        <li class="hidden-btn"></li>
        <li class="hidden-btn">
          <el-checkbox v-if="currIndex == 3" v-model="hidenCancelChecked">{{
            translateTitle("隐藏已撤销")
          }}</el-checkbox>
          <el-checkbox v-model="checked">{{
            translateTitle("隐藏其他合约")
          }}</el-checkbox>
        </li>
        <li class="header-cancel-btn" v-if="currIndex == 1 && userInfo">
          <span @click="onOpenConfirm(2)" class="cancel-btn-v2">{{
            translateTitle("一键全平")
          }}</span>
        </li>
        <li class="header-cancel-btn" v-if="currIndex == 2">
          <span @click="onOpenConfirm(3)" class="cancel-btn-v2">{{
            translateTitle("一键撤销")
          }}</span>
        </li>
      </ul>
    </div>
    <div class="table-content" v-loading="orderLoading">
      <div v-if="currIndex == 1" style="min-height:300px;padding:0 20px">
        <!-- <el-table :data="tableData" height="300" style="width: 100%"> -->
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="create_time"
            align="left"
            :label="translateTitle('合约')"
            key="1"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <span class="common-operate" @click="onSymbolClick(row)">{{
                symbolList[row.symbol_id] &&
                  symbolList[row.symbol_id].language["symbol_name_" + language]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="open_side"
            key="2"
            :label="translateTitle('方向')"
            min-width="60"
          >
            <template slot-scope="{ row }">
              <span :class="'side_' + row.open_side">{{
                translateTitle(sideMap[row.open_side])
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            key="3"
            align="center"
            prop="open_leverage"
            :label="translateTitle('倍数')"
            min-width="90"
          >
            <template slot-scope="{ row }">
              <span class="DINPro"
                >{{
                  row.position_mode == 1
                    ? translateTitle("逐仓")
                    : translateTitle("全仓")
                }}·{{ row.open_leverage }}X</span
              >
            </template>
          </el-table-column>

          <el-table-column
            key="5"
            align="right"
            prop="open_price"
            :label="translateTitle('开仓均价')"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro">{{ row.open_price }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            v-if="currIndex != 2"
            key="6"
            align="center"
            prop="num"
            label="开仓均价"
            min-width="130"
          >
          </el-table-column> -->
          <el-table-column
            key="7"
            prop="last"
            align="right"
            :label="translateTitle('标记价格')"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">{{ row.last_price || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            key="8"
            prop="liquidation_price"
            :label="translateTitle('强平价格')"
            align="right"
            min-width="120"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro">{{ row.liquidation_price }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="bail_number"
            key="9"
            :label="`${translateTitle('本金')}(${to_symbol.toUpperCase()})`"
            align="right"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro">{{ row.bail_number }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            prop="open_interest"
            key="4"
            :label="translateTitle('持仓数量')"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro"
                  >{{ row.open_interest }}
                  {{ row.from_symbol_name.toUpperCase() }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            key="10"
            prop="fee"
            :label="translateTitle('收益/收益率')"
            min-width="200"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">
                <span v-if="row.position_profit_loss.toFixed(2) == 'NaN'">
                  --
                </span>
                <span
                  v-else
                  class="DINPro"
                  :class="
                    Number(row.position_profit_loss_procent) > 0
                      ? 'green'
                      : 'red'
                  "
                >
                  <span v-if="Number(row.position_profit_loss) > 0">+</span
                  >{{ row.position_profit_loss.toFixed(2) }}
                  {{ to_symbol.toUpperCase() }} </span
                >/
                <span
                  class="DINPro"
                  :class="
                    Number(row.position_profit_loss_procent) > 0
                      ? 'green'
                      : 'red'
                  "
                >
                  <span
                    v-if="Number(row.position_profit_loss_procent) > 0"
                    style="margin-right:-3px"
                    >+</span
                  >
                  <span v-if="row.position_profit_loss_procent == 'NaN'">
                    --
                  </span>
                  <span v-else class="DINPro">
                    {{ row.position_profit_loss_procent }}
                  </span>
                  %
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            prop="nick_name"
            :label="translateTitle('交易员')"
            min-width="110"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <div class="common-right">
                <span class="DINPro">
                  {{ row.nick_name || "--" }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            prop="stop_profit_price"
            :label="translateTitle('止盈价')"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <div class="common-right cup" @click="onProfitPriceEdit(row)">
                <span class="DINPro">
                  {{ row.stop_profit_price }}
                  <i class="iconfont iconbianji"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            key="12"
            prop="stop_loss_price"
            :label="translateTitle('止损价')"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <div class="common-right cup" @click="onLossPriceEdit(row)">
                <span class="DINPro">
                  {{ row.stop_loss_price }}
                  <i class="iconfont iconbianji"></i>
                </span>
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column
            :label="translateTitle('操作')"
            key="13"
            align="center"
            min-width="170"
          >
            <template slot-scope="{ row }">
              <el-button @click="onOpenAppendMoney(row)" class="add-btn">{{
                translateTitle("追加本金")
              }}</el-button>
              <el-button
                @click="onOpenConfirm(1, row.position_order_id)"
                class="cancel-btn"
                >{{ translateTitle("平仓") }}</el-button
              >
            </template>
          </el-table-column> -->
          <el-table-column
            :label="translateTitle('操作')"
            key="13"
            align="center"
            min-width="250"
          >
            <template slot-scope="{ row }">
              <el-button @click="onOpenAppendMoney(row)" class="add-btn">{{
                translateTitle("追加本金")
              }}</el-button>
              <el-button
                @click="onOpenConfirm(1, row.position_order_id)"
                class="cancel-btn"
                >{{ translateTitle("平仓") }}</el-button
              >
              <el-button @click="onOpenOrderDetails(35, row)" class="add-btn">{{
                translateTitle("查看详情")
              }}</el-button>
            </template>
          </el-table-column>
          <no-data v-show="!orderLoading" slot="empty"></no-data>
        </el-table>
      </div>
      <div v-show="currIndex != 1" style="min-height:300px;padding: 0 20px">
        <el-table
          :data="tableData"
          v-if="!orderLoading"
          height="300"
          style="width: 100%"
        >
          <!-- <el-table-column
            prop="create_time"
            align="left"
            :label="translateTitle('下单时间')"
            key="1"
            min-width="170"
          > -->
          <el-table-column
            prop="create_time"
            align="left"
            :label="translateTitle('下单时间')"
            key="1"
            min-width="155"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">{{ parseTime(row.create_time) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            key="2"
            align="center"
            prop="symbol_name"
            :label="translateTitle('合约')"
            min-width="130"
          > -->
          <el-table-column
            key="2"
            align="center"
            prop="symbol_name"
            :label="translateTitle('合约')"
            min-width="145"
          >
            <template slot-scope="{ row }">
              <span class="common-operate" @click="onSymbolClick(row)">{{
                symbolList[row.symbol_id] &&
                  symbolList[row.symbol_id].language["symbol_name_" + language]
              }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column
            align="center"
            prop="side"
            key="3"
            :label="translateTitle('方向')"
            min-width="120"
          > -->
          <el-table-column
            align="center"
            prop="side"
            key="3"
            :label="translateTitle('方向')"
            min-width="60"
          >
            <template slot-scope="{ row }">
              <span :class="'side_' + row.open_side">{{
                translateTitle(sideMap[row.open_side])
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="currIndex != 4"
            align="center"
            prop="leverage"
            key="4"
            :label="translateTitle('倍数')"
            min-width="90"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro">{{ row.leverage }}X</span>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            v-else
            align="center"
            prop="open_leverage"
            key="89"
            :label="translateTitle('倍数')"
            min-width="90"
          > -->
          <el-table-column
            v-else
            align="center"
            prop="open_leverage"
            key="89"
            :label="translateTitle('倍数')"
            min-width="90"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro"
                  >{{
                    row.position_mode == 1
                      ? translateTitle("逐仓")
                      : translateTitle("全仓")
                  }}·{{ row.open_leverage }}X</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="5"
            v-if="currIndex == 2 || currIndex == 3"
            align="right"
            prop="deal_avg_price"
            :label="translateTitle('触发条件')"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">
                <span v-if="row.trigger_side == 1">≤</span>
                <span v-else>≥</span>
                {{ row.trigger_price }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            key="25"
            v-if="currIndex == 3"
            align="right"
            prop="order_price"
            :label="translateTitle('成交均价')"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <span class="DINPro" v-if="row.order_status != 3">
                {{ row.order_price }}
              </span>
              <!-- {{ row.to_symbol_name }} -->
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            key="6"
            v-if="currIndex == 2 || currIndex == 3"
            align="right"
            prop="bail_number"
            :label="`${translateTitle('本金')}(${to_symbol.toUpperCase()})`"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">
                {{ row.bail_number }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            key="26"
            v-if="currIndex == 3"
            align="right"
            prop="trade_number"
            :label="translateTitle('成交数量')"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <span class="DINPro" v-if="row.order_status != 3">
                {{ row.trade_number }} {{ row.from_symbol_name }}
              </span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            key="7"
            v-if="currIndex == 2"
            prop="stop_profit_price"
            align="right"
            :label="translateTitle('止盈价')"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <div class="common-right cup" @click="onProfitPriceEdit(row)">
                <span class="DINPro">
                  {{ row.stop_profit_price }}
                  <i
                    class="iconfont iconbianji"
                    @click="onProfitPriceEdit(row)"
                  ></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            key="8"
            v-if="currIndex == 2"
            prop="stop_loss_price"
            align="right"
            :label="translateTitle('止损价')"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <div class="common-right cup" @click="onLossPriceEdit(row)">
                <span class="DINPro">
                  {{ row.stop_loss_price }}
                  <i class="iconfont iconbianji"></i>
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="currIndex == 2 || currIndex == 3"
            align="center"
            key="9"
            prop="order_status"
            :label="translateTitle('状态')"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <span :class="'status_' + row.order_status">{{
                translateTitle(statusMap[row.order_status])
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="currIndex == 3"
            align="center"
            key="24"
            prop="update_time"
            :label="translateTitle('完成时间')"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <span>{{ parseTime(row.update_time) }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column
            key="16"
            v-if="currIndex == 4"
            prop="profit_loss_ratio"
            align="right"
            :label="translateTitle('回报率')"
            min-width="130"
          >
            <template slot-scope="{ row }">
              <span
                class="DINPro"
                :class="Number(row.profit_loss_ratio) > 0 ? 'green' : 'red'"
              >
                <span
                  v-if="Number(row.profit_loss_ratio) > 0"
                  style="margin-right:-3px"
                  >+</span
                >
                {{ row.profit_loss_ratio }}%</span
              >
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            key="17"
            v-if="currIndex == 4"
            prop="open_price"
            align="right"
            :label="translateTitle('开仓均价')"
            min-width="100"
          > -->
          <el-table-column
            key="17"
            v-if="currIndex == 4"
            prop="open_price"
            align="right"
            :label="translateTitle('开仓均价')"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">{{ row.open_price }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            key="18"
            v-if="currIndex == 4"
            prop="close_price"
            align="right"
            :label="translateTitle('平仓价格')"
            min-width="130"
          > -->
          <el-table-column
            key="18"
            v-if="currIndex == 4"
            prop="close_price"
            align="right"
            :label="translateTitle('平仓价格')"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">{{ row.close_price }}</span>
            </template>
          </el-table-column>
          <el-table-column
            key="19"
            v-if="currIndex == 4"
            prop="open_interest"
            align="right"
            :label="translateTitle('平仓数量')"
            min-width="175"
          >
            <template slot-scope="{ row }">
              <span class="DINPro"
                >{{ row.open_interest }}
                {{ row.from_symbol_name.toUpperCase() }}</span
              >
            </template>
          </el-table-column>

          <el-table-column
            key="15"
            v-if="currIndex == 4"
            prop="profit_loss"
            align="right"
            :label="`${translateTitle('收益/收益率')}`"
            min-width="250"
          >
            <template slot-scope="{ row }">
              <span
                class="DINPro"
                :class="Number(row.profit_loss_ratio) > 0 ? 'green' : 'red'"
                >{{ row.profit_loss }} {{ to_symbol.toUpperCase() }} /
                {{ row.profit_loss_ratio }}%</span
              >
            </template>
          </el-table-column>
          <el-table-column
            key="20"
            v-if="currIndex == 4"
            prop="balance_change_number"
            align="right"
            :label="`${translateTitle('余额归还')}(${to_symbol.toUpperCase()})`"
            min-width="180"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">{{ row.balance_change_number }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            key="21"
            v-if="currIndex == 4"
            prop="close_position_type"
            align="center"
            :label="translateTitle('平仓类型')"
            min-width="130"
          > -->
          <el-table-column
            key="21"
            v-if="currIndex == 4"
            prop="close_position_type"
            align="center"
            :label="translateTitle('平仓类型')"
            min-width="100"
          >
            <template slot-scope="{ row }">
              <span
                :class="closePositionTypeMaps.cls[row.close_position_type]"
                >{{
                  translateTitle(
                    closePositionTypeMaps.label[row.close_position_type]
                  )
                }}</span
              >
            </template>
          </el-table-column>
          <!-- <el-table-column
            key="72"
            v-if="currIndex == 4"
            prop="nick_name"
            align="right"
            :label="translateTitle('交易员')"
            min-width="110"
            show-overflow-tooltip
          > -->
          <el-table-column
            key="72"
            v-if="currIndex == 4"
            prop="nick_name"
            align="right"
            :label="translateTitle('交易员')"
            min-width="100"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <div class="common-right">
                <span>{{ row.nick_name || "--" }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            key="22"
            v-if="currIndex == 4"
            prop="close_position_time"
            align="center"
            :label="translateTitle('平仓时间')"
            min-width="180"
          > -->
          <el-table-column
            key="22"
            v-if="currIndex == 4"
            prop="close_position_time"
            align="center"
            :label="translateTitle('平仓时间')"
            min-width="155"
          >
            <template slot-scope="{ row }">
              <span class="DINPro">{{
                parseTime(row.close_position_time)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="currIndex == 2"
            :label="translateTitle('操作')"
            key="14"
            align="center"
            min-width="110"
          >
            <template slot-scope="{ row }">
              <el-button
                @click="onOpenConfirm(4, row.plan_order_id)"
                class="cancel-btn"
                >{{ translateTitle("撤销") }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            v-if="currIndex == 4"
            :label="translateTitle('操作')"
            key="14"
            align="center"
            min-width="100"
          >
            <template slot-scope="{ row }">
              <el-button
                @click="onOpenOrderDetails(36, row)"
                class="cancel-btn"
                >{{ translateTitle("查看详情") }}</el-button
              >
            </template>
          </el-table-column>
          <no-data v-show="!orderLoading" slot="empty"></no-data>
        </el-table>
      </div>
      <div class="common-table-pages" v-if="tableData.length > 0">
        <p>
          {{ translateTitle("共") }}
          <span>{{ pageInfo.pageCount }}</span>
          {{ translateTitle("页") }}
        </p>
        <el-pagination
          class="mt20"
          background
          layout="prev, pager, next"
          :total="pageInfo.pageTotal"
          :page-size="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageCurrent"
          @current-change="onCurrentPageChange"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      :title="translateTitle('操作提示')"
      custom-class="confirm-dialog"
      :visible.sync="dialogVisible"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px;">{{ confirmMessage }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="dialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <el-button
          class="dialog-confirm-btn"
          :loading="liquidationLoading"
          @click="onOK"
          >{{ translateTitle("确认") }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      custom-class="append-money-dialog"
      :title="translateTitle('追加本金')"
      :visible.sync="appendMoneydialog"
      :before-close="appendMoneydialogClose"
      :close-on-click-modal="false"
      width="500px"
    >
      <p class="first-content">
        <span class="left-item">{{ translateTitle("追加金额") }}</span>
        <span>
          <span class="available-assets"
            >{{ translateTitle("可用") }} {{ assetsInfoShow }}
            {{ to_symbol.toUpperCase() }}
          </span>
          <span class="common-operate" @click="goToRecharge">
            {{ translateTitle("充值") }}</span
          ></span
        >
      </p>
      <div class="second-content">
        <InputNumber
          v-model="bail_number"
          :placeholder="translateTitle('请输入追加金额')"
          maxlength="10"
          :decimals="price_precision"
          :suffix="to_symbol.toUpperCase()"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClickAll">{{
          translateTitle("全部")
        }}</span>
      </div>
      <div class="three-content">
        <div class="item-one">
          <div class="left-item">
            <span class="black DINPro">{{ beforeBailNumber }}</span>
            <span>{{ translateTitle("当前本金") }}</span>
          </div>
          <span><img src="../../../assets/img/tran-to.png" alt=""/></span>
          <div class="right-item">
            <span class="red DINPro">{{ afterBailNumberShow }}</span>
            <span>{{ translateTitle("追加后本金") }}</span>
          </div>
        </div>
        <div class="item-two">
          <div class="left-item">
            <span class="black DINPro">{{ beforeLiquidationPrice }}</span>
            <span>{{ translateTitle("当前强平价") }}</span>
          </div>
          <span><img src="../../../assets/img/tran-to.png" alt=""/></span>
          <div class="right-item">
            <span class="red DINPro">{{ afterLiquidationPriceShow }}</span>
            <span>{{ translateTitle("追加后强平价") }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="appendMoneydialogClose">{{
          translateTitle("取消")
        }}</span>
        <el-button
          :loading="appendMoneyLoading"
          class="dialog-confirm-btn"
          @click="onAppendMoney"
          >{{ translateTitle("确定") }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      custom-class="set-profitPrice-dialog"
      :title="translateTitle('设置止盈止损')"
      :visible.sync="setProfitPricedialog"
      :before-close="setProfitPricedialogClose"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="profitPrice-three-content">
        <div class="item-one">
          <div class="left-item">
            <span class="black">{{ rowMarketInfoShow.last || "--" }}</span>
            <span>{{ translateTitle("当前价") }}</span>
          </div>
          <div class="center-item">
            <span class="black" v-if="currIndex == 1">{{
              rowData.open_price
            }}</span>
            <span class="black" v-if="currIndex == 2">{{
              rowData.trigger_price
            }}</span>
            <span>{{ translateTitle("开仓价") }}</span>
          </div>
          <div class="right-item">
            <span class="black">{{ rowData.liquidation_price }}</span>
            <span>{{ translateTitle("强平价") }}</span>
          </div>
        </div>
      </div>
      <p class="profitPrice-first-content">
        <span class="left-item">{{ translateTitle("止盈价") }}</span>
        <span class="right-item"
          >{{ translateTitle("预计盈利") }}
          <span class="green">
            {{ expectedProfit }} {{ to_symbol.toUpperCase() }}
            <span v-if="expectedProfitPercent != '--'">
              ({{ expectedProfitPercent }}%)
            </span>
          </span></span
        >
      </p>
      <div class="profitPrice-second-content">
        <InputNumber
          v-model="inputProfitPrice"
          :placeholder="translateTitle('请输入止盈价')"
          maxlength="10"
          :decimals="price_precision"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClearAll">{{
          translateTitle("清除")
        }}</span>
      </div>
      <p class="profitPrice-first-content V2">
        <span class="left-item">{{ translateTitle("止损价") }}</span>
        <span class="right-item"
          >{{ translateTitle("预计亏损") }}
          <span class="red"
            >{{ expectedLoss }} {{ to_symbol.toUpperCase() }}
            <span v-if="expectedLossPercent != '--'">
              ({{ expectedLossPercent }}%)
            </span>
          </span></span
        >
      </p>
      <div class="profitPrice-second-content">
        <InputNumber
          v-model="inputLossPrice"
          :placeholder="translateTitle('请输入止损价')"
          maxlength="10"
          :decimals="price_precision"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClearAllV2">{{
          translateTitle("清除")
        }}</span>
      </div>

      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="setProfitPricedialog = false">{{
          translateTitle("取消")
        }}</span>
        <el-button
          class="dialog-confirm-btn"
          :loading="profitLossLoading"
          @click="onSetPositionProfitLoss"
          >{{ translateTitle("确定") }}</el-button
        >
      </span>
    </el-dialog>
    <order-details
      :isStartWS="false"
      :marketListTran="marketList"
      :assetsInfoTran="assetsInfo"
      ref="orderDetails"
    ></order-details>
  </div>
</template>
<script>
import { apiGetTradePairList } from "@/model/exchange/market.js";
import OrderDetails from "../../ucenter/pages/bill-center/components/orderDetails.vue";
import { apiGetAssets } from "@/model/exchange/index";
import {
  apiGetOpenPositionList,
  apiGetPlanOrderList,
  apiRevokePlan,
  apiAllClosePosition,
  apiAppendInterest,
  apiClosePosition,
  apiSetPositionProfitLoss,
  apiSetPlanProfitLoss,
  apiRevokeAllPlan,
  apiGetOrderStatistic,
  apiGetContract,
} from "@/model/contractExchange/order.js";
import InputNumber from "@/components/common/InputNumber";
import NoData from "@/components/common/NoData";
import { parseTime } from "@/utils/index.js";
import { mapActions, mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import { showMessage } from "@/components/dialog/message.js";
import * as Account from "@/model/user/account";
import { apiGetStaticTableInfo } from "@/model/settings";
import { closePositionTypeMaps } from "@/assets/js/keymap.js";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  props: {
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    swap_symbol_id: {
      type: [String, Number],
      default: function() {
        return 1010;
      },
    },
    assetsInfo: {
      type: Object,
      default: function() {
        return {
          remain: {
            all_u_valuation: {
              usdt: "--",
            },
            list: [],
          },
        };
      },
    },
    from_symbol: {
      type: [String, Number],
    },
    to_symbol: {
      type: String,
      default: "USDT",
    },
    from_item_id: {
      type: [String, Number],
    },
    to_item_id: {
      type: [String, Number],
    },
  },
  components: {
    NoData,
    InputNumber,
    OrderDetails,
  },
  data() {
    return {
      currRow: {},
      closePositionTypeMaps,
      parseTime,
      ws: null,
      tableData: [],
      confirmMessage: "是否确认平仓此订单？",
      tableDataComputed: [],
      orderLoading: true,
      appendMoneyLoading: false,
      profitLossLoading: false,
      liquidationLoading: false,
      dialogVisible: false,
      appendMoneydialog: false,
      setProfitPricedialog: false,
      row_swap_symbol_id: this.swap_symbol_id,
      beforeBailNumber: "",
      afterBailNumber: "--",
      inputProfitPrice: "",
      inputLossPrice: "",
      price_precision: "2",
      contract_plan_num: 0,
      contract_position_num: 0,
      contract_multiple_num: "",
      beforeLiquidationPrice: "",
      plan_order_id: "",
      afterLiquidationPrice: "--",
      rowData: {},
      hidenCancelChecked: false,
      checked: false,
      layout: "total, prev, pager, next",
      symbolId: "",
      position_order_id: "",
      bail_number: "",
      contractMultipleNum: {},
      pageInfo: {
        pageCount: 1,
        pageCurrent: 1,
        pageSize: 6,
        pageTotal: 6,
      },
      apiAssetsInfo: {},
      tabList: [
        {
          value: "1",
          label: "持仓",
        },
        {
          value: "2",
          label: "当前委托",
        },
        {
          value: "3",
          label: "历史委托",
        },
        {
          value: "4",
          label: "平仓记录",
        },
      ],
      currIndex: "1",
      symbolList: {},
      coinInfoMap: {},
      triggerSideMap: {
        1: "大于触发价",
        2: "小于触发价",
      },
      tradeMap: {
        1: "市价",
        2: "限价",
      },
      sideMap: {
        1: "买涨",
        2: "买跌",
      },
      statusMap: {
        0: "未成交",
        1: "待成交",
        2: "完全成交",
        3: "已撤销",
        4: "失败",
      },
    };
  },
  async created() {
    await this.getCoinInfoMap();
    await this.getTradePairList();
    this.getOrderNumber();
    this.getAssets();
    let { userInfo } = await Account.getUserInfo();
    if (userInfo) {
      this.initData(this.checked);
      this.startTimer();
    }
  },
  beforeDestroy() {
    // clearTimeout(timer);
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),

    marketInfoShow() {
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    rowMarketInfoShow() {
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.row_swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (
        JSON.stringify(this.assetsInfo) != "{}" &&
        this.assetsInfo.remain.list.length > 0
      ) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else if (
        JSON.stringify(this.apiAssetsInfo) != "{}" &&
        this.apiAssetsInfo.remain.list.length > 0
      ) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = "--";
      }
      return tempObj;
    },
    afterLiquidationPriceShow() {
      // 强平价 = 开仓价 - (1 做多 | -1做空)) ×(保证金 × (1 − 强平⻛险率) - 平仓手续费-开仓手续费)/持仓数量
      let tempValue = "";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.bail_number != "") {
        tempValue =
          this.rowData.open_price -
          (tempSide *
            (this.afterBailNumberShow *
              (1 -
                this.symbolList[this.swap_symbol_id].liquidation_risk_rate /
                  10000) -
              this.rowData.close_position_fee -
              this.rowData.open_position_fee)) /
            this.rowData.open_interest;

        if (this.rowData.open_side == 1) {
          //做多 做多的时候小数位用全进
          tempValue = (
            Math.ceil(tempValue * Math.pow(10, this.price_precision)) /
            Math.pow(10, this.price_precision)
          ).toFixed(this.price_precision);
        } else {
          // 做空的时候用截断
          tempValue = tempValue
            .toString()
            .substring(
              0,
              tempValue.toString().indexOf(".") + this.price_precision + 1
            );
        }
      } else {
        tempValue = this.afterLiquidationPrice;
      }
      if (Number(tempValue) < 0) {
        tempValue = "0";
      }
      return tempValue;
    },
    afterBailNumberShow() {
      return (Number(this.afterBailNumber) + Number(this.bail_number)).toFixed(
        2
      );
    },
    // 平仓盈亏 = (inputProfitPrice-开仓价) × (1 做多 | -1做空)) × 持仓数量
    expectedProfit() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }

        tempValue =
          (Number(this.inputProfitPrice) - Number(temp_open_price)) *
          tempSide *
          Number(this.rowData.open_interest);
        tempValue =
          Number(tempValue) > 0
            ? floorNumber(tempValue, 2)
            : ceilNumber(tempValue, 2);
        if (Number(tempValue) <= 0) {
          tempValue = "--";
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    expectedProfitPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }

        tempValue =
          (((this.inputProfitPrice - temp_open_price) *
            tempSide *
            this.rowData.open_interest) /
            this.rowData.bail_number) *
          100;
        tempValue =
          Number(tempValue) > 0
            ? floorNumber(tempValue, 2)
            : ceilNumber(tempValue, 2);
        if (Number(tempValue) <= 0) {
          tempValue = "--";
        } else {
          tempValue = "+" + tempValue;
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    // 平仓盈亏 = (inputLossPrice-开仓价) × (1 做多 | -1做空)) × 持仓数量
    expectedLoss() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }
        tempValue =
          (this.inputLossPrice - temp_open_price) *
          tempSide *
          this.rowData.open_interest;
        tempValue =
          Number(tempValue) > 0
            ? floorNumber(tempValue, 2)
            : ceilNumber(tempValue, 2);
        // tempValue = Math.ceil(tempValue * 100) / 100;
        if (Number(tempValue) < this.rowData.bail_number * -1) {
          tempValue = this.rowData.bail_number * -1;
        }
        if (Number(tempValue) > 0) {
          tempValue = "--";
        }
      }
      // inputLossPrice
      return tempValue;
    },
    expectedLossPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }

        tempValue =
          (((this.inputLossPrice - temp_open_price) *
            tempSide *
            this.rowData.open_interest) /
            this.rowData.bail_number) *
          100;

        tempValue =
          Number(tempValue) > 0
            ? floorNumber(tempValue, 2)
            : ceilNumber(tempValue, 2);
        if (Number(tempValue) < -100) {
          tempValue = -100;
        }
        if (Number(tempValue) >= 0) {
          tempValue = "--";
        }
      }
      // inputLossPrice
      return tempValue;
    },
  },
  methods: {
    routeSplicing,
    onOK() {
      this.liquidationLoading = true;
      if (this.dialogType == 1) {
        this.onClosePosition();
      } else if (this.dialogType == 2) {
        this.onCancelOrder();
      } else if (this.dialogType == 3) {
        this.onRevokeAllOrder();
      } else if (this.dialogType == 4) {
        this.onRevokePlan();
      }
    },
    goToRecharge() {
      window.open(
        this.routeSplicing(this.language, "ucenter/assets/recharge/usdt")
      );
    },
    onClearAll() {
      this.inputProfitPrice = "";
    },
    onClearAllV2() {
      this.inputLossPrice = "";
    },
    setProfitPricedialogClose() {
      this.inputLossPrice = "";
      this.inputProfitPrice = "";
      this.setProfitPricedialog = false;
    },
    appendMoneydialogClose() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    onClickAll() {
      this.bail_number = this.assetsInfoShow;
    },
    reset(type) {
      this.orderLoading = true;
      this.currIndex = type.toString();
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
      this.getOrderNumber();
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    onProfitPriceEdit(row) {
      this.rowData = row;
      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
        }
      }
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      this.setProfitPricedialog = true;
    },
    onLossPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      this.setProfitPricedialog = true;
    },
    async getCoinInfoMap() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.coinInfoMap = data;
      }
    },
    async getTradePairList() {
      const { status, data } = await apiGetStaticTableInfo(49);
      if (status == 200) {
        this.symbolList = data;
      }
    },
    onSymbolChange(item) {
      this.$emit("on-symbol-change", item);
    },
    onSymbolClick(row) {
      let tempName = "";
      if (row.from_symbol_name) {
        tempName = row.from_symbol_name.toLowerCase() + "_" + "usdt";
      } else {
        for (const key in this.coinInfoMap) {
          if (key == row.open_item_id) {
            tempName =
              this.coinInfoMap[key].item_name.toLowerCase() + "_" + "usdt";
          }
        }
      }

      let params = {
        symbol_id: row.symbol_id,
        name: tempName,
        change_item_id: row.open_item_id,
        base_item_id: row.settlement_item_id,
      };
      this.$emit("on-symbol-change", params);
    },
    onOpenConfirm(type, id) {
      this.dialogType = type;
      if (type == 1) {
        this.position_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认平仓此订单？");
      } else if (type == 2) {
        this.confirmMessage = this.translateTitle("是否确认全部平仓？");
      } else if (type == 3) {
        this.confirmMessage = this.translateTitle("是否确认全部撤销？");
      } else if (type == 4) {
        this.plan_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认撤销此委托单？");
      }
      this.dialogVisible = true;
    },
    onOpenOrderDetails(assets_type, row) {
      // this.currRow
      let tempRow = {
        order_info: { position_order_id: row.position_order_id },
        assets_type: assets_type,
      };
      this.$refs.orderDetails.liquidationContractInfoClick(tempRow);
    },
    onOpenAppendMoney(row) {
      this.position_order_id = row.position_order_id;
      this.beforeBailNumber = row.bail_number;
      this.afterBailNumber = row.bail_number;
      this.beforeLiquidationPrice = row.liquidation_price;
      this.afterLiquidationPrice = row.liquidation_price;
      this.rowData = row;

      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
        }
      }
      this.appendMoneydialog = true;
    },
    onClosePosition() {
      let params = {
        position_order_id: this.position_order_id,
      };
      apiClosePosition(params).then(res => {
        if (res.status == 200) {
          this.dialogVisible = false;
          showMessage({
            message: this.translateTitle("平仓成功"),
            type: "success",
            // duration:10000000,
          });
          this.liquidationLoading = false;
          this.initData(this.checked);
        } else {
          this.dialogVisible = false;
          this.liquidationLoading = false;
        }
      });
    },
    onAppendMoney() {
      if (Number(this.bail_number) == 0) {
        showMessage({
          message: this.translateTitle("请输入追加金额"),
          type: "error",
        });
        return;
      }
      this.appendMoneyLoading = true;
      let params = {
        position_order_id: this.position_order_id,
        bail_number: this.bail_number,
      };
      apiAppendInterest(params).then(res => {
        if (res.status == 200) {
          showMessage({
            message: this.translateTitle("追加成功"),
            type: "success",
          });
          this.appendMoneyLoading = false;
          this.appendMoneydialog = false;
          this.initData(this.checked);
        } else {
          this.appendMoneyLoading = false;
        }
      });
    },
    async startTimer() {
      const timer = setTimeout(() => {
        this.loopData(this.checked);
        // this.initData(this.checked);
        // this.getOrderNumber();
        // this.getMultipleNum();
        this.startTimer();
      }, 3000);
      this.$once("hook:beforeDestroy", () => {
        clearTimeout(timer);
      });
      this.$once("hook:destroyed", () => {
        clearTimeout(timer);
      });
    },
    async onCancelOrder() {
      const { status, data } = await apiAllClosePosition();
      if (status == 200) {
        this.dialogVisible = false;

        showMessage({
          message: this.translateTitle("平仓成功"),
          type: "success",
        });
        this.liquidationLoading = false;
        this.initData(this.checked);
      } else {
        this.liquidationLoading = false;
      }
    },

    async onRevokeAllOrder() {
      const { status, data } = await apiRevokeAllPlan();
      if (status == 200) {
        showMessage({
          message: this.translateTitle("撤销成功"),
          type: "success",
        });
        this.dialogVisible = false;
        this.liquidationLoading = false;
        this.initData(this.checked);
      } else {
        this.liquidationLoading = false;
      }
    },
    async onRevokePlan() {
      const req = {
        plan_order_id: this.plan_order_id,
      };
      const { status, data } = await apiRevokePlan(req);
      if (status == 200) {
        showMessage({
          message: this.translateTitle("撤销成功"),
          type: "success",
        });
        this.dialogVisible = false;
        this.liquidationLoading = false;
        this.initData(this.checked);
      } else {
        this.liquidationLoading = false;
      }
    },
    onSetPositionProfitLoss() {
      if (this.currIndex == 1) {
        if (this.rowData.open_side == 1) {
          if (
            Number(this.rowMarketInfoShow.last) <
            Number(this.rowData.open_price)
          ) {
            if (
              Number(this.inputProfitPrice) != 0 &&
              Number(this.inputProfitPrice) <= Number(this.rowData.open_price)
            ) {
              showMessage({
                message: this.translateTitle("买涨时，止盈价需大于开仓价"),
                type: "warning",
              });
              return;
            }
          } else {
            if (
              Number(this.inputProfitPrice) != 0 &&
              Number(this.inputProfitPrice) <=
                Number(this.rowMarketInfoShow.last)
            ) {
              showMessage({
                message: this.translateTitle("买涨时，止盈价需大于当前价"),
                type: "warning",
              });
              return;
            }
          }

          // if(止损价 > 强平价){
          // 	if(当前价 < 开仓价){
          // 		止损价 >= 当前价 ? "提示：买涨时，止损价需小于当前价" ：止损价
          // 	}else{
          // 		止损价 >= 开仓价 ? "提示：买涨时，止损价需小于开仓价" ：止损价
          // 	}
          // esle{
          // 	提示：买涨时，止损价需大于强平价
          // }
          if (
            Number(this.inputLossPrice) != 0 &&
            Number(this.inputLossPrice) >=
              Number(this.rowData.liquidation_price)
          ) {
            if (
              Number(this.rowMarketInfoShow.last) <
              Number(this.rowData.open_price)
            ) {
              if (
                Number(this.inputLossPrice) >=
                Number(this.rowMarketInfoShow.last)
              ) {
                showMessage({
                  message: this.translateTitle("买涨时，止损价需小于当前价"),
                  type: "warning",
                });
                return;
              }
            } else {
              if (
                Number(this.inputLossPrice) >= Number(this.rowData.open_price)
              ) {
                showMessage({
                  message: this.translateTitle("买涨时，止损价需小于开仓价"),
                  type: "warning",
                });
                return;
              }
            }
          } else if (Number(this.inputLossPrice) != 0) {
            Number(this.inputLossPrice) <
              Number(this.rowData.liquidation_price);
            showMessage({
              message: this.translateTitle("买涨时，止损价需大于强平价"),
              type: "warning",
            });
            return;
          }
        } else {
          // 卖跌
          if (Number(this.inputProfitPrice) > 0) {
            if (
              Number(this.rowMarketInfoShow.last) <
              Number(this.rowData.open_price)
            ) {
              if (
                Number(this.inputProfitPrice) >=
                Number(this.rowMarketInfoShow.last)
              ) {
                showMessage({
                  message: this.translateTitle("买跌时，止盈价需小于当前价"),
                  type: "warning",
                });
                return;
              }
            } else {
              if (
                Number(this.inputProfitPrice) >= Number(this.rowData.open_price)
              ) {
                showMessage({
                  message: this.translateTitle("买跌时，止盈价需小于开仓价"),
                  type: "warning",
                });
                return;
              }
            }
          } else if (Number(this.inputProfitPrice) < 0) {
            showMessage({
              message: this.translateTitle("买跌时，止盈价需大于0"),
              type: "warning",
            });
            return;
          }

          // if(止损价 < 强平价){
          // 	if(当前价 < 开仓价){
          // 		止损价  <= 开仓价 ? "提示：买跌时，止损价需大于开仓价" ：止损价
          // 	}else{
          // 		止损价 <= 当前价 ? "提示：买跌时，止损价需大于当前价" ：止损价
          // 	}
          // esle{
          // 	提示：买跌时，止损价需小于强平价
          // }

          if (
            Number(this.inputLossPrice) != 0 &&
            Number(this.inputLossPrice) <=
              Number(this.rowData.liquidation_price)
          ) {
            if (
              Number(this.rowMarketInfoShow.last) <
              Number(this.rowData.open_price)
            ) {
              if (
                Number(this.inputLossPrice) <= Number(this.rowData.open_price)
              ) {
                showMessage({
                  message: this.translateTitle("买跌时，止损价需大于开仓价"),
                  type: "warning",
                });
                return;
              }
              if (
                Number(this.inputLossPrice) <=
                Number(this.rowMarketInfoShow.last)
              ) {
                showMessage({
                  message: this.translateTitle("买跌时，止损价需大于当前价"),
                  type: "warning",
                });
                return;
              }
            }
          } else if (Number(this.inputLossPrice) != 0) {
            showMessage({
              message: this.translateTitle("买跌时，止损价需小于强平价"),
              type: "warning",
            });
            return;
          }
        }
        let tempInputProfitPrice = "";
        if (Number(this.inputProfitPrice) == 0) {
          tempInputProfitPrice = "";
        } else {
          tempInputProfitPrice = this.inputProfitPrice;
        }

        let tempInputLossPrice = "";
        if (Number(this.inputLossPrice) == 0) {
          tempInputLossPrice = "";
        } else {
          tempInputLossPrice = this.inputLossPrice;
        }
        this.profitLossLoading = true;
        let params = {
          position_order_id: this.rowData.position_order_id,
          market_price: this.rowMarketInfoShow.last,
          stop_profit_price: tempInputProfitPrice,
          stop_loss_price: tempInputLossPrice,
        };

        apiSetPositionProfitLoss(params).then(res => {
          if (res.status == 200) {
            showMessage({
              message: this.translateTitle("设置成功"),
              type: "success",
            });
            this.profitLossLoading = false;
            this.setProfitPricedialogClose();
            this.initData(this.checked);
          } else {
            this.profitLossLoading = false;
          }
        });
      } else {
        //委托单 止盈止损
        if (this.rowData.open_side == 1) {
          ////委托单  买涨

          // 止盈价 <= 开仓价 ? "提示：买涨时，止盈价需大于开仓价" ：止盈价
          if (
            Number(this.inputProfitPrice) != 0 &&
            Number(this.inputProfitPrice) <= Number(this.rowData.trigger_price)
          ) {
            showMessage({
              message: this.translateTitle("买涨时，止盈价需大于开仓价"),
              type: "warning",
            });
            return;
          }

          //  if(止损价 > 强平价){
          //         止损价 >= 开仓价 ? "提示：买涨时，止损价需小于开仓价" ：止损价
          // esle{
          // 	提示：买涨时，止损价需大于强平价
          // }
          if (
            Number(this.inputLossPrice) != 0 &&
            Number(this.inputLossPrice) >=
              Number(this.rowData.liquidation_price)
          ) {
            if (
              Number(this.inputLossPrice) >= Number(this.rowData.trigger_price)
            ) {
              showMessage({
                message: this.translateTitle("买涨时，止损价需小于开仓价"),
                type: "warning",
              });
              return;
            }
          } else if (Number(this.inputLossPrice) != 0) {
            showMessage({
              message: this.translateTitle("买涨时，止损价需大于强平价"),
              type: "warning",
            });
            return;
          }
        } else {
          //委托单  //买跌 开仓价 trigger_price

          if (Number(this.inputProfitPrice) > 0) {
            if (
              Number(this.inputProfitPrice) >=
              Number(this.rowData.trigger_price)
            ) {
              showMessage({
                message: this.translateTitle("买跌时，止盈价需小于开仓价"),
                type: "warning",
              });
              return;
            }
          } else if (Number(this.inputProfitPrice) < 0) {
            showMessage({
              message: this.translateTitle("买跌时，止盈价需大于0"),
              type: "warning",
            });
            return;
          }
          //liquidation_price 强平价
          // if(止损价 < 强平价  ){
          // 	止损价 <= 开仓价 ? "提示：买跌时，止损价需大于开仓价" ：止损价

          // esle{
          // 	提示：买跌时，止损价需小于强平价 liquidation_price
          // }

          if (
            Number(this.inputLossPrice) != 0 &&
            Number(this.inputLossPrice) <=
              Number(this.rowData.liquidation_price)
          ) {
            if (
              Number(this.inputLossPrice) <= Number(this.rowData.trigger_price)
            ) {
              showMessage({
                message: this.translateTitle("买跌时，止损价需大于开仓价"),
                type: "warning",
              });
              return;
            }
          } else if (Number(this.inputLossPrice) != 0) {
            showMessage({
              message: this.translateTitle("买跌时，止损价需小于强平价"),
              type: "warning",
            });
            return;
          }
        }

        let tempInputProfitPrice = "";
        if (Number(this.inputProfitPrice) == 0) {
          tempInputProfitPrice = "";
        } else {
          tempInputProfitPrice = this.inputProfitPrice;
        }

        let tempInputLossPrice = "";
        if (Number(this.inputLossPrice) == 0) {
          tempInputLossPrice = "";
        } else {
          tempInputLossPrice = this.inputLossPrice;
        }
        this.profitLossLoading = true;
        let params = {
          plan_order_id: this.rowData.plan_order_id,
          market_price: this.rowMarketInfoShow.last,
          stop_profit_price: tempInputProfitPrice,
          stop_loss_price: tempInputLossPrice,
        };
        apiSetPlanProfitLoss(params).then(res => {
          if (res.status == 200) {
            showMessage({
              message: this.translateTitle("设置成功"),
              type: "success",
            });
            this.profitLossLoading = false;
            this.setProfitPricedialogClose();
            this.initData(this.checked);
          } else {
            this.profitLossLoading = false;
          }
        });
      }
    },
    onAreaChange(item) {
      this.orderLoading = true;
      this.tableData = [];
      this.currIndex = item.value;
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    getMultipleNum() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        symbol_id: this.checked ? this.swap_symbol_id : "",
      };
      apiGetOrderStatistic(params).then(res => {
        if (res.status == 200) {
          this.contract_multiple_num = JSON.stringify(
            res.data.contract_multiple_num
          );
          localStorage.setItem(
            "contract-multiple-num",
            this.contract_multiple_num
          );
          this.contract_position_num =
            res.data.contract_position_info.total_num;
          this.contract_plan_num = res.data.contract_plan_info.total_num;
        }
      });
    },
    getOrderNumber() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        symbol_id: this.checked ? this.swap_symbol_id : "",
      };
      apiGetOrderStatistic(params).then(res => {
        if (res.status == 200) {
          this.contract_position_num =
            res.data.contract_position_info.total_num;
          this.contract_plan_num = res.data.contract_plan_info.total_num;
        }
      });
    },
    statisticAndFundingRate(data) {
      this.$emit("changeFundingRate", {
        fundForm: data.fundingRate,
        fundTime: data.fundingRate.time,
      });
      this.contract_multiple_num = JSON.stringify(
        data.statistic.contract_multiple_num
      );
      localStorage.setItem("contract-multiple-num", this.contract_multiple_num);
      this.contract_position_num =
        data.statistic.contract_position_info.total_num;
      this.contract_plan_num = data.statistic.contract_plan_info.total_num;
    },
    async loopData(checked = false) {
      if (!this.swap_symbol_id) {
        this.orderLoading = false;
        return;
      }
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        if (this.swap_symbol_id) {
          let params = {
            module: "fundingRate",
            "fundingRate-symbol_id": this.swap_symbol_id,
          };
          const { status, data } = await apiGetContract(params);
          if (status == 200) {
            this.$emit("changeFundingRate", {
              fundForm: data.fundingRate,
              fundTime: data.fundingRate.time,
            });
          }
        }
        this.orderLoading = false;
        return;
      }
      let tempStatus = this.currIndex;
      if (this.currIndex == 3) {
        tempStatus = "";
      }
      let temp_symbol_id = this.swap_symbol_id;
      let temp_is_hide = 2;
      if (checked) {
        temp_symbol_id = this.swap_symbol_id;
        temp_is_hide = 1;
      } else {
        temp_symbol_id = "";
        temp_is_hide = 2;
      }
      if (this.currIndex == 1) {
        const req = {
          module: "fundingRate,openPositionList,statistic",
          "openPositionList-symbol_id": temp_symbol_id,
          "openPositionList-position_status": 1,
          "openPositionList-page": this.pageInfo.pageCurrent,
          "openPositionList-page_size": this.pageInfo.pageSize,
          "statistic-symbol_id": this.checked ? this.swap_symbol_id : "",
          "fundingRate-symbol_id": this.swap_symbol_id,
          // is_hide: temp_is_hide,
        };
        //  return apiGetOpenPositionList(req).then((res) => {
        return apiGetContract(req).then(res => {
          if (res.status == 200) {
            this.statisticAndFundingRate(res.data);
          }
          if (
            res.status == 200 &&
            res.data.openPositionList &&
            res.data.openPositionList.list.length > 0
          ) {
            res.data.openPositionList.list.forEach(ele => {
              // 存各个币种杠杆倍数

              this.contractMultipleNum[ele.symbol_id] = {
                open_leverage: ele.open_leverage,
              };

              // 持仓盈亏 = (市价-开仓价) × (1 做多 | -1做空)) × 持仓数量
              this.marketList.forEach(item => {
                if (item.symbol_id == ele.symbol_id) {
                  ele.last_price = item.last;
                }
              });
              let tempSide = ele.open_side == 1 ? 1 : -1;
              // 未实现盈亏
              ele.position_profit_loss =
                (ele.last_price - ele.open_price) *
                tempSide *
                ele.open_interest;
              ele.position_profit_loss_procent = (
                (((ele.last_price - ele.open_price) *
                  tempSide *
                  ele.open_interest) /
                  ele.bail_number) *
                100
              ).toFixed(2);
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[
                    key
                  ].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.openPositionList.list;
            this.pageInfo = res.data.openPositionList.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 2) {
        const req = {
          module: "fundingRate,planOrderList,statistic",
          "planOrderList-symbol_id": temp_symbol_id,
          "planOrderList-order_status": 1,
          "planOrderList-is_hide": 2,
          "planOrderList-page": this.pageInfo.pageCurrent,
          "planOrderList-page_size": this.pageInfo.pageSize,
          "statistic-symbol_id": this.checked ? this.swap_symbol_id : "",
          "fundingRate-symbol_id": this.swap_symbol_id,
        };
        // return apiGetPlanOrderList(req).then((res) => {
        return apiGetContract(req).then(res => {
          if (res.status == 200) {
            this.statisticAndFundingRate(res.data);
            for (const key in this.exchangeCoinMap) {
              res.data.planOrderList.list.forEach(ele => {
                this.marketList.forEach(item => {
                  if (item.symbol_id == ele.symbol_id) {
                    ele.last_price = item.last;
                  }
                });
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              });
            }
            this.tableData = res.data.planOrderList.list;
            this.pageInfo = res.data.planOrderList.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 3) {
        let temp_order_status = "2,3,4";
        if (this.hidenCancelChecked) {
          temp_order_status = "2,4";
        } else {
          temp_order_status = "2,3,4";
        }
        const req = {
          module: "fundingRate,planOrderList,statistic",
          "planOrderList-symbol_id": temp_symbol_id,
          "planOrderList-order_status": temp_order_status,
          "planOrderList-is_hide": 2,
          "planOrderList-page": this.pageInfo.pageCurrent,
          "planOrderList-page_size": this.pageInfo.pageSize,
          "statistic-symbol_id": this.checked ? this.swap_symbol_id : "",
          "fundingRate-symbol_id": this.swap_symbol_id,
        };
        // return apiGetPlanOrderList(req).then((res) => {
        return apiGetContract(req).then(res => {
          if (res.status == 200) {
            this.statisticAndFundingRate(res.data);
            res.data.planOrderList.list.forEach(ele => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[
                    key
                  ].item_name.toUpperCase();
                }
                if (key == ele.settlement_item_id) {
                  ele.to_symbol_name = this.coinInfoMap[
                    key
                  ].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.planOrderList.list;
            this.pageInfo = res.data.planOrderList.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 4) {
        const req = {
          module: "fundingRate,openPositionList,statistic",
          "openPositionList-symbol_id": temp_symbol_id,
          "openPositionList-position_status": 2,
          "openPositionList-page": this.pageInfo.pageCurrent,
          "openPositionList-page_size": this.pageInfo.pageSize,
          "statistic-symbol_id": this.checked ? this.swap_symbol_id : "",
          "fundingRate-symbol_id": this.swap_symbol_id,
        };

        // return apiGetOpenPositionList(req).then((res) => {
        return apiGetContract(req).then(res => {
          if (res.status == 200) {
            this.statisticAndFundingRate(res.data);
          }
          if (
            res.status == 200 &&
            res.data.openPositionList &&
            res.data.openPositionList.list.length > 0
          ) {
            res.data.openPositionList.list.forEach(ele => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[
                    key
                  ].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.openPositionList.list;
            this.pageInfo = res.data.openPositionList.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      }
    },

    async initData(checked = false) {
      if (!this.swap_symbol_id) {
        this.orderLoading = false;
        return;
      }
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        this.orderLoading = false;
        return;
      }
      let tempStatus = this.currIndex;
      if (this.currIndex == 3) {
        tempStatus = "";
      }
      let temp_symbol_id = this.swap_symbol_id;
      let temp_is_hide = 2;
      if (checked) {
        temp_symbol_id = this.swap_symbol_id;
        temp_is_hide = 1;
      } else {
        temp_symbol_id = "";
        temp_is_hide = 2;
      }
      if (this.currIndex == 1) {
        const req = {
          symbol_id: temp_symbol_id,
          position_status: 1,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize,
          // is_hide: temp_is_hide,
        };
        return apiGetOpenPositionList(req).then(res => {
          if (res.status == 200 && res.data.list.length > 0) {
            res.data.list.forEach(ele => {
              // 存各个币种杠杆倍数

              this.contractMultipleNum[ele.symbol_id] = {
                open_leverage: ele.open_leverage,
              };

              // 持仓盈亏 = (市价-开仓价) × (1 做多 | -1做空)) × 持仓数量
              this.marketList.forEach(item => {
                if (item.symbol_id == ele.symbol_id) {
                  ele.last_price = item.last;
                }
              });
              let tempSide = ele.open_side == 1 ? 1 : -1;
              // 未实现盈亏
              ele.position_profit_loss =
                (ele.last_price - ele.open_price) *
                tempSide *
                ele.open_interest;
              ele.position_profit_loss_procent = (
                (((ele.last_price - ele.open_price) *
                  tempSide *
                  ele.open_interest) /
                  ele.bail_number) *
                100
              ).toFixed(2);
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[
                    key
                  ].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 2) {
        const req = {
          symbol_id: temp_symbol_id,
          order_status: 1,
          is_hide: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize,
        };
        return apiGetPlanOrderList(req).then(res => {
          if (res.status == 200) {
            for (const key in this.exchangeCoinMap) {
              res.data.list.forEach(ele => {
                this.marketList.forEach(item => {
                  if (item.symbol_id == ele.symbol_id) {
                    ele.last_price = item.last;
                  }
                });
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              });
            }
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 3) {
        let temp_order_status = "2,3,4";
        if (this.hidenCancelChecked) {
          temp_order_status = "2,4";
        } else {
          temp_order_status = "2,3,4";
        }
        const req = {
          symbol_id: temp_symbol_id,
          order_status: temp_order_status,
          is_hide: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize,
        };
        return apiGetPlanOrderList(req).then(res => {
          if (res.status == 200) {
            res.data.list.forEach(ele => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[
                    key
                  ].item_name.toUpperCase();
                }
                if (key == ele.settlement_item_id) {
                  ele.to_symbol_name = this.coinInfoMap[
                    key
                  ].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 4) {
        const req = {
          symbol_id: temp_symbol_id,
          position_status: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize,
        };

        return apiGetOpenPositionList(req).then(res => {
          if (res.status == 200 && res.data.list.length > 0) {
            res.data.list.forEach(ele => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[
                    key
                  ].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[
                    key
                  ].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      }
    },
    onCurrentPageChange() {
      this.orderLoading = true;
      this.initData(this.checked);
    },
  },
  watch: {
    swap_symbol_id: {
      handler(newVal, oldVal) {
        if (oldVal == undefined) {
          return;
        }
        if (newVal != oldVal) {
          this.symbolId = newVal;
          this.orderLoading = true;
          this.pageInfo.pageCurrent = 1;
          this.initData(this.checked);
        }
      },
    },
    hidenCancelChecked() {
      this.initData(this.checked);
      this.getOrderNumber();
    },
    checked() {
      this.initData(this.checked);
      this.getOrderNumber();
    },
    marketList() {
      if (this.currIndex == 1 || this.currIndex == 2) {
        this.tableData.forEach(ele => {
          this.marketList.forEach(item => {
            if (item.symbol_id == ele.symbol_id) {
              ele.last_price = item.last;
            }
          });
          let tempSide = ele.open_side == 1 ? 1 : -1;
          // 未实现盈亏
          ele.position_profit_loss =
            (ele.last_price - ele.open_price) * tempSide * ele.open_interest;
          ele.position_profit_loss_procent = (
            (((ele.last_price - ele.open_price) *
              tempSide *
              ele.open_interest) /
              ele.bail_number) *
            100
          ).toFixed(2);
        });
      }
    },
  },
};
</script>
<style lang="scss">
.contract-component-history-order {
  margin: 10px 0px;
  height: 400px;
  border-radius: 2px;
  background-color: #ffffff;
  // padding: 0px 0px 0px 15px;
  position: relative;
  min-width: 1760px;
  overflow-x: auto;
  .el-table__empty-text {
    user-select: none;
  }
  .el-table {
    min-height: 290px;
    overflow-y: auto;
    .el-table__body-wrapper {
      overflow-x: hidden;
    }
    td,
    th {
      padding: 8px 0;
    }
    .cell {
      .red {
        color: #fa4d56;
      }
      .green {
        color: #03ad79;
      }
      .iconfont {
        cursor: pointer;
      }
      .number {
        display: inline-block;
        width: 80px;
        margin-right: 10px;

        .el-input {
          .el-input__inner {
            height: 30px;
          }
        }
      }
    }
  }
  .market-header {
    // padding-left: 15px;
    background-color: #f5f5f7;
    ul {
      display: flex;
    }
    .classify-area {
      user-select: none;
      padding: 0 10px;
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      width: 120px;
      text-align: center;
      line-height: 32px;
      font-weight: 500;
      color: #7f8490;
      position: relative;
      &.active {
        background-color: #ffffff;
        color: black;
      }
      .item-bar {
        position: absolute;
        width: 42px;
        height: 2px;
        background-color: #1f2533;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
    }
    .hidden-btn {
      margin: 0 20px 0 auto;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      color: #354052;
      .el-checkbox__label {
        font-size: 12px;
        font-weight: normal;
      }
    }
    .header-cancel-btn {
      margin: 4px 15px;
      user-select: none;
      .cancel-btn-v2 {
        cursor: pointer;
        text-align: center;
        width: 60px;
        height: 20px;
        padding: 2px 10px 2px;
        // line-height: 24px;
        border-radius: 2px;
        border: solid 1px #3348f6;
        background-color: #ffffff;
        color: #3348f6;
        font-size: 12px;
        font-weight: normal;
        outline: none;
        &:hover {
          background-color: #ffffff;
          color: #3348f6;
          outline: none;
        }
        &:focus {
          background-color: #ffffff;
          color: #3348f6;
          outline: none;
        }
      }
    }
  }
  .table-content {
    min-height: 366px;
  }
  .market-search {
    margin-top: 17px;
    input {
      background-color: #f1f2f5;
      border: none;
      outline: none;
      height: 24px;
      border-radius: 24px;
      width: 272px;
    }
  }
  .market-title {
    display: flex;
  }
  .market-content {
    .area-title {
      li {
        display: flex;
        .pair-name-title {
          width: 200px;
        }
        .pair-price-title {
          width: 200px;
          text-align: right;
        }
        .pair-updown-title {
          width: 200px;
          text-align: right;
        }
      }
    }
    .depth-sub-title {
      padding: 0 13px 0 16px;
      display: flex;
      color: #7f8490;
      font-size: 12px;
      font-weight: 500;
      height: 36px;
      line-height: 36px;
      .time {
        width: 108px;
      }
      .price {
        width: 108px;
      }
      .num {
        width: 108px;
        text-align: right;
      }
    }
    .depth-sub-content {
      padding: 0 13px 4px 16px;
      min-height: 500px;
      li {
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        .time {
          width: 108px;
          color: #1f2533;
        }
        .price {
          width: 108px;
        }
        .num {
          width: 108px;
          color: #080c34;
          text-align: right;
          &.red {
            color: #fa4d56;
          }
          &.green {
            color: #03ad79;
          }
        }
      }
    }
  }
  .pair-wrap {
    .pair-list {
      display: flex;
      height: 26px;
      line-height: 26px;
      .pair-name {
        width: 200px;
      }
      .pair-price {
        width: 200px;
        text-align: right;
      }
      .pair-updown {
        width: 200px;
        text-align: right;
      }
    }
  }
  .side_1 {
    color: #03ad79;
  }
  .side_2 {
    color: #c61b3d;
  }
  .status_1 {
    color: #f78700;
  }
  .status_2 {
    color: #03ad79;
  }
  .status_3 {
    color: #bec1c8;
  }
  .add-btn {
    // width: 80px;
    cursor: pointer;
    height: 24px;
    padding: 5px 14px 5px;
    // line-height: 24px;
    border-radius: 2px;
    border: solid 1px #e2e2e4;
    background-color: #ffffff;
    color: #3348f6;
    font-size: 12px;
    font-weight: normal;
    outline: none;
    &:hover {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
    &:focus {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
  }
  .cancel-btn {
    text-align: center;
    // width: 52px;
    height: 24px;
    padding: 5px 14px 5px;
    // line-height: 24px;
    border-radius: 2px;
    border: solid 1px #e2e2e4;
    background-color: #ffffff;
    color: #3348f6;
    font-size: 12px;
    font-weight: normal;
    outline: none;
    &:hover {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
    &:focus {
      background-color: #ffffff;
      color: #3348f6;
      border: solid 1px #e2e2e4;
      outline: none;
    }
  }

  .append-money-dialog {
    .el-dialog__header {
      padding: 20px 30px 20px;
    }
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 30px 5px;
    }
    .el-dialog__footer {
      padding: 10px 20px 30px;
    }
    .first-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
      font-size: 12px;
      .available-assets {
        color: #bec1c8;
      }
      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
        .el-input__suffix-inner {
          font-size: 12px;
        }
      }
      .all-btn {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
      }
    }
    .three-content {
      width: 440px;
      height: 120px;
      margin: 20px 30px 20px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #9aa1b1;
          font-size: 12px;
          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
          &.red {
            font-weight: 500;
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
  .set-profitPrice-dialog {
    .el-dialog__header {
      padding: 20px 30px 5px;
    }
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 30px 5px;
    }
    .el-dialog__footer {
      padding: 35px 20px 30px;
    }
    .profitPrice-first-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .left-item {
        color: #354052;
        font-size: 16px;
        font-weight: 500;
      }
      .right-item {
        font-size: 12px;
        .green {
          color: #03ad79;
        }
        .red {
          color: #c61b3d;
        }
      }
      &.V2 {
        margin-top: 15px;
      }
    }
    .profitPrice-second-content {
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
      }
      .all-btn {
        position: absolute;
        right: 10px;
        top: 9px;
      }
    }
    .profitPrice-three-content {
      width: 440px;
      height: 70px;
      margin: 15px 0px 15px 0px;
      padding: 14px 15px;
      border-radius: 4px;
      background-color: #f8f8f8;
      .item-one,
      .item-two {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          display: block;
          margin-bottom: 2px;
          color: #7f8490;
          font-size: 12px;
          &.black {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }
          &.red {
            font-size: 14px;
            color: #f04a5a;
          }
        }
        .left-item {
          width: 150px;
        }
        .center-item {
          width: 150px;
          text-align: center;
        }
        .right-item {
          width: 150px;
          text-align: right;
        }
      }
    }
  }
  .confirm-dialog {
    // height: 200px;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      text-align: center;
      color: #354052;
      padding: 20px 20px 30px;
      font-size: 16px;
      font-weight: normal;
    }
  }
}
</style>
