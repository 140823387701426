import api from "@/assets/js/api";
import Cookies from "js-cookie";
import { VUE_APP_UCENTER } from "@/assets/js/stationSetting";

export function apiGetUserInfo(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Users/getUserInfo`,
    data,
    type: "POST",
  });
}

export function getUserInfo(data = {}) {
  if (!Cookies.get("topcredit_exchange_t")) return {};
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Users/getUserInfo`,
    data,
    type: "POST",
  });
}

//登出
export function apiGetLoginOut(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/User/loginOut`,
    data,
    type: "POST",
  });
}
