<template>
  <div class="assets">
    <div class="assets-content">
      <top-title title="资产管理">
        <div class="total" slot="view">
          <p>
            <span>资产估值</span>
          </p>
          <span>
            <span class="green">{{
              assetsList.usdt ? assetsList.usdt : "--"
            }}</span>
            USDT
          </span>
          /
          <span>
            <span class="green">{{
              assetsList.cny ? assetsList.cny : "--"
            }}</span>
            CNY
          </span>
        </div>
      </top-title>
      <div class="assets-snippets">
        <template v-for="(val, keys) in sortCoinData">
          <asset-snippet :data="val" :key="keys" :assetsList="assetsList" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AssetSnippet from "./components/AssetSnippet";
import { getCoinInfo, getAssetsData } from "./common/getAssetsCoin";
import TopTitle from "./components/Title";
// import { getCoinList } from "@/model/assets/index";
import { getStaticCoinList as getCoinList } from "@/model/assets/index";

const refreshTime = 5000;
export default {
  data() {
    return {
      coinData: {}, // 币种数据列表
      assetsList: {}, // 资产数据列表
      assetsListTimer: null,
    };
  },
  async created() {
    // this.coinData = JSON.parse(sessionStorage.getItem("itemList"));
    await this.getItemList();
    // this.coinData = await getCoinInfo();
    this.assetsList = await getAssetsData();
    this.loopRefreshAssets();
  },
  beforeDestroy() {
    clearInterval(this.assetsListTimer);
  },
  destroyed() {
    clearInterval(this.assetsListTimer);
  },
  computed: {
    sortCoinData() {
      return this.sortObj(this.coinData);
      // return this.coinData;
    },
  },
  methods: {
    sortObj(obj) {
      if (obj[10007]) {
        obj[10007].order_by = -1;
      }
      const keys = Object.keys(obj).sort(function(a, b) {
        return obj[a].order_by - obj[b].order_by;
      });
      var newObj = [];
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];
        newObj.push(obj[key]);
      }
      return newObj;
    },

    async getItemList() {
      const req = {
        __version: "3.4.3",
        __channel: "2",
      };
      const { status, data } = await getCoinList(req);
      if (status == 200) {
        this.coinData = data;
      }
    },
    loopRefreshAssets() {
      this.assetsListTimer = setInterval(
        async () => (this.assetsList = await getAssetsData()),
        refreshTime
      );
    },
  },
  components: {
    TopTitle,
    AssetSnippet,
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.assetsListTimer);
    next();
  },
};
</script>

<style lang="scss">
.assets {
  // display: flex;
  // justify-content: center;
  // padding: 20px 0px;
  background: #f1f2f5;
  // min-height: 900px;
  .assets-content {
    height: 100%;
    // width: 1035px;
  }
  &-snippets {
    margin-top: 12px;
  }
  .content-title {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    // overflow: hidden;
  }
  .total {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    color: #354052;
    font-size: 14px;
    text-align: right;
  }

  .green {
    color: #03ad79 !important;
  }
  .assets-snippets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
