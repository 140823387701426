<template>
  <!-- true false -->
  <!-- 0 不需要验证 1 需要验证 -->
  <div class="security-setting-kyc">
    <div class="setting-content">
      <template>
        <top-title :title="translateTitle('KYC认证')"> </top-title>
        <el-alert
          :title="translateTitle('审核失败') + ': ' + errorTitle"
          type="error"
          v-if="reviewStatus == 2"
        ></el-alert>
        <div class="app-uncter-content ke">
          <div class="setting-items">
            <setting-item
              :title="translateTitle('身份信息')"
              :desc="translateTitle('立即认证，享更多账户权限')"
              :btnText="reviewStatusList[reviewStatus]"
              :disabled="!reviewRed"
              @settingBtnClick="onBindMobileClick"
            >
              <template v-slot:tag>
                <status-tag :has-point="reviewRed" />
              </template>
            </setting-item>
            <!-- 绑定/修改手机 -->
            <setting-item
              :title="translateTitle('证件照片')"
              :desc="translateTitle('立即认证，享更多账户权限')"
              :btnText="reviewStatusList[reviewStatus]"
              :disabled="!reviewRed"
              @settingBtnClick="onBindMobileClick"
            >
              <template v-slot:tag>
                <status-tag :has-point="reviewRed" />
              </template>
            </setting-item>
            <!-- 绑定/解绑邮箱 -->
            <setting-item
              :title="translateTitle('视频认证')"
              :desc="translateTitle('立即认证，享更多账户权限')"
              :btnText="reviewStatusList[reviewStatus]"
              :disabled="!reviewRed"
              @settingBtnClick="onBindMobileClick"
            >
              <template v-slot:tag>
                <status-tag :has-point="reviewRed" />
              </template>
            </setting-item>
            <!-- 绑定/修改谷歌认证 -->
          </div>
        </div>
      </template>
    </div>
    <el-dialog
      @open="onDialogOpen"
      width="680px"
      class="change-mobile-email"
      :destroy-on-close="true"
      :top="'15vh'"
      :title="translateTitle('KYC认证')"
      :visible.sync="bindDialogVisible"
      :close-on-click-modal="false"
      :before-close="onCancel"
      v-if="bindDialogVisible"
    >
      <IInfo
        v-if="bindDialogVisible"
        @update-success="onUpdateSuccess"
        @update-cancel="onUpdateCancel"
      >
      </IInfo>
    </el-dialog>
  </div>
</template>

<script>
import TopTitle from "./components/Title";
import SettingItem from "./components/elSettingItem";
import StatusTag from "./components/StatusTag";
// import ChangePwd from "./components/DChangePwd";
import DBind from "./components/DBind";
import IInfo from "./components/Identity-Information.vue";
// import DGoogle from "./components/DGoogle";
// import GButton from "./components/Button";
// import GoogleBind from "./components/google/GoogleBind";
// import GoogleDialog from "./components/google/GoogleDialog";
import { showMessage } from "@/components/dialog/message";
import * as Account from "@/model/user/account";
import * as Common from "@/model/common";
import { getUserInfo } from "@/model/ucenter/ucenter";
import commonMixin from "@/components/common/commonMixin.vue";
import md5 from "js-md5";
import {
  updatePassword,
  bindMobile,
  bindEmail,
  undataMobile,
  bingGoogleVerifyStatus,
} from "@/model/ucenter/security";
import globalFun from "@/assets/js/globalFun.js";

export default {
  mixins: [commonMixin],
  data() {
    return {
      pwdDialogVisible: false,
      bindDialogVisible: false,
      verificationVisible: false,
      reviewStatus: false,
      bindType: "mobile", // 绑定/修改 类型
      googleVerifyStatus: true,
      accountEmailFlag: true,
      accountMobileFlag: true,
      active: 0,
      mobileBind: false, // 手机绑定状态
      emailBind: false, // 邮箱绑定状态
      mobileStatus: false, // 手机验证状态 false:
      emailStatus: false, // 邮箱验证状态
      openGoogle: false, // 谷歌验证状态
      googleStatus: 1, // 谷歌验证状态
      all_areacode: {}, // 所有区号
      openSetGoogle: false, // 打开设置谷歌
      errorTitle: "",
      googleValidator: [
        { img: "./../../assets/img/AppleStore.png", link: "" },
        { img: "./../../assets/img/AppleStore.png", link: "" },
        { img: "./../../assets/img/AppleStore.png", link: "" },
      ],
      reviewStatusList: {
        0: this.translateTitle("前往认证"),
        1: this.translateTitle("等待审核"),
        2: this.translateTitle("重新认证"),
        3: this.translateTitle("已认证"),
      },
      accountInfo: {},
      authInfo: {},
      reviewRed: false,
      isBindEmail: false, // 是否在绑定邮箱
      changeDBindMobile: false, // 是否修改
      confirmLoading: false,
      is_bind_security: 0,
      voucherInterval: null,
      globalFun,
    };
  },
  async created() {
    this.initList();
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  methods: {
    onDialogOpen() {
      this.getUserInfo();
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    onChangePassWord() {
      if (this.is_bind_security == 1) {
        showMessage({
          message: this.translateTitle(`请先绑定谷歌、手机或者邮箱`),
          type: "warning",
        });
      } else {
        this.pwdDialogVisible = true;
      }
    },
    async getUserInfo() {
      let { authInfo, accountInfo } = sessionStorage.userInfo
        ? JSON.parse(sessionStorage.userInfo)
        : {};
      this.accountInfo = accountInfo;
      this.authInfo = authInfo;
      if (authInfo) {
        this.is_bind_security = authInfo.is_bind_security;
        this.mobileStatus = authInfo.open_mobile === 0 ? false : true;
        this.emailStatus = authInfo.open_email === 0 ? false : true;
        this.openGoogle = authInfo.open_google === 0 ? false : true;
        this.googleStatus = authInfo.open_google;
        if (
          this.openGoogle == true &&
          this.mobileStatus == false &&
          this.emailStatus == false
        ) {
          this.googleVerifyStatus = false;
        } else {
          this.googleVerifyStatus = true;
        }
      }
      if (accountInfo) {
        this.mobileBind = accountInfo.mobile ? true : false;
        this.emailBind = accountInfo.email ? true : false;
      }
    },
    async getGooglStatus() {
      let { status, data, msg } = await bingGoogleVerifyStatus();
      if (status !== 200) {
        return;
      }
      this.googleStatus = data.bing_google_status;
    },
    onUpdateSuccess() {
      this.bindDialogVisible = false;
      this.initList();
    },
    onUpdateCancel() {
      this.bindDialogVisible = false;
    },
    async initList() {
      const { data, status } = await Common.apiKycInfo();
      if (status == 200) {
        const { status } = data;
        this.reviewStatus = status;
        this.reviewRed = [0, 2].includes(status);
        if (status == 2) {
          this.errorTitle = data.reason;
        }
      }
    },
    // 绑定/修改手机
    onBindMobileClick() {
      this.$nextTick(() => (this.bindDialogVisible = true));
    },
    // 绑定邮箱
    onBindEmailClick() {
      this.bindType = "email";
      let username = this.accountInfo.username;
      let open_mobile = this.authInfo.open_mobile;
      if (username && open_mobile === 0) {
        this.accountMobileFlag = false;
        this.accountEmailFlag = true;
      }
      this.$nextTick(() => (this.bindDialogVisible = true));
      this.isBindEmail = true;
    },
    // 打开设置绑定谷歌
    onBindGoogleClick() {
      this.openSetGoogle = true;
    },
    // 关闭谷歌认证
    closeGoogle() {
      this.verificationVisible = true;
    },
    //
    async subGoogleSuccess() {
      this.getGooglStatus();
      await this.newGetUserInfo();
      this.openSetGoogle = false;
    },
    onCancel() {
      if (this.$refs.changePwd_ref) {
        this.$refs.changePwd_ref.clearCode();
      }
      if (this.$refs.google_ref) {
        this.$refs.google_ref.clearCode();
      }
      this.pwdDialogVisible = false;
      this.bindDialogVisible = false;
      this.verificationVisible = false;
      this.changeDBindMobile = false;
      this.isBindEmail = false;
    },
    // 确认提交
    async onOk() {
      if (this.active++ > 2) {
        this.active = 0;
      }
      let params = {};
      let changePwd_status,
        mobile_status,
        change_mobile_status,
        email_status,
        google_status;

      changePwd_status = this.$refs.changePwd_ref
        ? this.$refs.changePwd_ref.submitForm()
        : true;
      mobile_status = this.$refs.mobile_ref
        ? this.$refs.mobile_ref.submitForm()
        : true;
      change_mobile_status = this.$refs.mobileChange_ref
        ? this.$refs.mobileChange_ref.submitForm()
        : true;
      email_status = this.$refs.email_ref
        ? this.$refs.email_ref.submitForm()
        : true;
      google_status = this.$refs.google_ref
        ? this.$refs.google_ref.submitForm()
        : true;

      if (
        !(
          changePwd_status &&
          mobile_status &&
          change_mobile_status &&
          email_status &&
          google_status
        )
      )
        return;

      /* 密码 */
      if (this.$refs.changePwd_ref) {
        let data = this.$refs.changePwd_ref.submitForm();
        params = { ...data };
        delete params.confirmNewPwd;
        params.old_password = md5(params.old_password);
        params.password = md5(params.password);
      }
      /* 手机 */
      if (this.$refs.mobile_ref) {
        let data = this.$refs.mobile_ref.submitForm();
        params = Object.assign(params, { ...data });
      }
      /* 新手机 */
      if (this.$refs.mobileChange_ref) {
        let data = this.$refs.mobileChange_ref.submitForm();
        data.code = data.mobile_code;
        delete data.mobile_code;
        params = Object.assign(params, { ...data });
      }
      /* 邮箱 */
      if (this.$refs.email_ref) {
        let data = this.$refs.email_ref.submitForm();
        params = Object.assign(params, { ...data });
      }
      /* 谷歌 */
      if (this.$refs.google_ref) {
        let data = this.$refs.google_ref.submitForm();
        params = Object.assign(params, { ...data });
      }

      /* 提交请求 */
      // 修改密码
      if (this.$refs.changePwd_ref) {
        await updatePassword(params).then((res) =>
          this.successTip(res, this.translateTitle(`密码修改成功`))
        );
        return;
      }
      // 绑定手机
      if (!this.mobileStatus && !this.isBindEmail) {
        await bindMobile(params).then((res) =>
          this.successTip(res, this.translateTitle(`手机绑定成功`))
        );
        return;
      }
      // 修改手机
      if (this.$refs.mobileChange_ref) {
        params["old_mobile_code"] = params["mobile_code"];
        params["mobile_code"] = params["code"];
        await undataMobile(params).then((res) =>
          this.successTip(res, this.translateTitle(`修改手机号成功`))
        );
        return;
      }
      // 绑定邮箱
      if (this.$refs.email_ref && this.isBindEmail) {
        await bindEmail(params).then((res) =>
          this.successTip(res, this.translateTitle(`邮箱绑定成功`))
        );
        return;
      }
    },
    // 密码修改状态判断
    checkPwdStatus(callback) {
      let changePwd_status = this.$refs.changePwd_ref
        ? this.$refs.changePwd_ref.submitForm()
        : true;
      let status = changePwd_status ? true : false;
      callback(status);
    },
    // 成功提醒
    successTip(res, message) {
      this.confirmLoading = false;
      if (res.status === 200) {
        this.onCancel();
        showMessage({ message, type: "success" });
        this.newGetUserInfo();
      } else if (res.status === -6071004) {
        this.$refs.mobile_ref ? this.$refs.mobile_ref.clearCode() : null;
        this.$refs.mobileChange_ref
          ? this.$refs.mobileChange_ref.clearCode()
          : null;
        this.$refs.email_ref ? this.$refs.email_ref.clearCode() : null;
      } else {
        showMessage({ message: res.msg ? res.msg : res.status });
      }
    },
    // 获取新的用户信息
    async newGetUserInfo() {
      // 从新获取用户信息
      await getUserInfo().then(({ data, msg, status }) => {
        if (status === 200) {
          if (data != null) {
            sessionStorage.setItem("userInfo", JSON.stringify(data));
          }
          this.getUserInfo();
        } else {
          showMessage({ message: msg ? msg : status });
        }
      });
    },
  },
  computed: {
    googleBtnText() {
      let tempVal = "";
      let { googleStatus } = this;
      switch (googleStatus) {
        case 1:
          tempVal = this.translateTitle(`关闭认证`);
        case 2:
          tempVal = this.translateTitle(`关闭`);
      }
      return tempVal;
    },
  },
  components: {
    TopTitle,
    SettingItem,
    StatusTag,
    IInfo,
  },
};
</script>

<style lang="scss">
.setting-item-r {
  button {
    user-select: none;
  }
}

.setting-item-k-r {
  button {
    user-select: none;
  }
}

input {
  user-select: auto;
}

textarea {
  user-select: auto;
}

.change-mobile-email {
  .el-dialog {
    /* display: flex;
    flex-direction: column;
    margin: 0 !important; */
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px); */
  }

  .el-dialog__header {
    height: 0px !important;
    padding: 19px 0px 30px 30px !important;
  }

  .el-dialog__body {
    padding: 0px 30px 30px 30px !important;
  }
}

.security-setting-kyc {
  min-height: 1010px !important;
  border-radius: 4px;
  overflow: hidden;

  .el-select-dropdown__list {
    width: 420px;
  }

  .setting-content {
    // height: 1010px;
    .setting-items {
      margin: 20px 10px;
      display: flex;
      flex-wrap: wrap;

      .setting-item-k {
        background: #ffffff;
        padding: 0px 20px;
        min-width: 766px;
        flex: 1;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        height: 100px;
        margin: 0px 10px 20px 10px;
      }
    }
  }

  &-items {
    margin-left: 60px;
  }

  // dialog
  .g-dialog {
    .el-dialog__header {
      height: 0px !important;
      padding: 19px 0px 30px 30px !important;
    }

    .el-dialog__body {
      padding: 0px 30px 30px 30px !important;
    }

    .el-dialog {
      display: flex;
      flex-direction: column;
      margin: 0 !important;
      position: absolute;
      /* top: 50%;
      left: 50%; */
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      /*height:600px;*/
      max-height: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }

    &.change-pwd {
      // .el-dialog{
      //   margin-top: 8vh !important;
      // }
      .el-form-item {
        margin-bottom: 15px !important;
      }
    }

    .el-dialog__header {
      height: 68px;
      padding: 19px 0;
      padding-left: 31px;
      box-sizing: border-box;
      text-align: left;
    }

    .el-dialog__body {
      // padding: 2px 50px 39px 30px;
      padding: 2px 30px 30px 30px;

      .footer {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }

      .el-form-item__label {
        text-align: left;
      }

      button {
        width: 160px;
        height: 40px;
      }

      .cancelBtn {
        margin-right: 10px;
        border-radius: 3px;
        border: solid 1px #c2c7d0;
        color: #354052 !important;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          color: #354052 !important;
          background: #ffffff;
          border: solid 1px #c2c7d0;
        }
      }

      .confrimBtn {
        color: #fff;
        border-radius: 3px;
        border: 0px;
        font-size: 14px;
        font-weight: 400;
        background-image: linear-gradient(282deg, #ee244c 21%, #c61b4e 82%);

        &:hover {
          color: #fff;
          background-image: linear-gradient(282deg, #ee244c 21%, #c61b4e 82%);
        }
      }
    }
  }

  .verification {
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;

      .common-save {
        padding: 0 !important;
      }
    }
  }

  .open-google {
    width: 100%;
    font-size: 14px;
    line-height: 42px;

    &:hover {
      background-color: rgba(39, 198, 131, 0.8);
    }
  }

  input {
    border-radius: 2px;
  }
}
</style>
