<template>
  <div class="kyc-notice"></div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
  },
  created() {},
  methods: {
    translateTitle,
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="scss">
.kyc-notice {
  background: #fff;
  //   height: 100%;
  .el-menu {
    border-right: none;
    .el-menu-item {
      padding-left: 0 !important;
      text-align: left;
      color: #354052;
      line-height: 2;
    }
    .el-menu-item.is-active {
      font-weight: 600;
      background-color: white !important;
    }
  }
  .header {
    height: 80px;
    background: url("../../assets/img/notice/notice-bg.png") 0 0 repeat;
    .header-content {
      color: #fff;
      width: 1200px;
      margin: 0 auto;
      font-size: 19px;
      font-weight: 600;
      line-height: 80px;
    }
  }
  .notice-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 42px;
    display: flex;
    justify-content: space-between;
  }
  .left-menu {
    width: 200px;
  }
  .right-content {
    width: 930px;
    color: #354052;
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #354052;
      margin-bottom: 24px;
    }
    .second-title {
      margin: 15px 0;
    }
    .third-title {
      margin-left: 25px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .four-title {
      margin: 5px 0;
      margin-left: 50px;
    }
    .five-title {
      margin: 5px 0;
      margin-left: 70px;
    }
    .six-title {
      margin: 5px 0;
      margin-left: 90px;
    }
    .content {
      margin-left: 10px;
      p {
        margin-top: 5px;
        margin-bottom: 5px;
        text-indent: 25px;
      }
      &.V2 {
        margin-left: 15px;
      }
      &.V3 {
        margin-left: 35px;
      }
      &.V4 {
        margin-left: 50px;
      }
      &.V5 {
        margin-left: 75px;
      }
      &.V6 {
        margin-left: 90px;
      }
    }
  }
}
</style>
