const md5 = require("md5");

import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export default (params, app_id) => {
  var appkey = VUE_APP_KEY;
  var timestamp = Math.round(new Date().getTime() / 1000) + "";
  params["nonce"] = timestamp.substr(-6);
  params["app_id"] = app_id;
  for (let key in params) {
    params[key] =
      params[key] || params[key] === 0 || params[key] === false
        ? params[key]
        : "";
  }
  let keys = Object.keys(params);
  let length = keys.length;
  keys = keys.sort();
  let sign = "";
  for (let i = 0; i < length; i++) {
    if (sign != "") sign = sign + "&";
    sign = sign + keys[i] + "=" + params[keys[i]];
  }
  //add appkey
  sign = sign + appkey;
  sign = md5(sign).toString();

  return sign;
};
