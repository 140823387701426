<template>
  <div class="uc-financail-managment">
    <div class="trade-container">
      <div class="header">
        <!-- <top-title :title="translateTitle('金融理财')"> </top-title> -->
        <div class="content-title">
          <h2>{{ translateTitle("金融理财") }}</h2>
          <slot name="view"></slot>
        </div>
        <!-- <div class="tab-content">
          <ul>
            <li
              class="classify-area"
              :class="{ active: currIndex == item.value }"
              v-for="item of tabList"
              :key="item.value"
              @click="onAreaChange(item)"
            >
              {{ translateTitle(item.label) }}
              <i v-if="currIndex == item.value" class="item-bar"></i>
            </li>
          </ul>
        </div> -->
        <div class="count-content">
          <div class="count-item">
            <p class="item-name">{{ translateTitle("总存款") }}</p>
            <p class="num DINPro">
              {{ assetsShowCurrency.usd || "--" }} <span>USDT</span>
            </p>
            <p class="num2 DINPro">
              ≈ {{ assetsShowCurrency[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
          <div class="count-item">
            <p class="item-name">{{ translateTitle("昨日收益") }}</p>
            <p class="num DINPro">{{ last_profit }} <span>USDT</span></p>
            <p class="num2 DINPro">
              ≈ {{ all_last_valuation[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
          <div class="count-item">
            <p class="item-name">{{ translateTitle("到期收益") }}</p>
            <p class="num DINPro">
              {{ pre_earnings_amount }} <span>USDT</span>
            </p>
            <p class="num2 DINPro">
              ≈ {{ all_pre_earnings_valuation[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
          <div class="count-item">
            <p class="item-name">{{ translateTitle("总收益") }}</p>
            <p class="num DINPro">{{ total_profit }} <span>USDT</span></p>
            <p class="num2 DINPro">
              ≈ {{ all_total_valuation[systemCurrency] || "--" }}
              <span>{{ systemCurrency.toUpperCase() }}</span>
            </p>
          </div>
        </div>
        <div class="financail-managment-content">
          <!-- 列表 -->
          <!-- <div class="content-header">
            {{ translateTitle("我的持有") }}
          </div> -->
          <!-- 变更开始，FIXME -->
          <div class="market-header">
            <ul>
              <li
                class="classify-area"
                :class="{ active: currIndexPosition == item.value }"
                v-for="item of tabListPosition"
                :key="item.value"
                @click="onPositionChange(item)"
              >
                {{ translateTitle(item.label) }}
                <i v-if="currIndexPosition == item.value" class="item-bar"></i>
              </li>
            </ul>
          </div>
          <!-- 变更结束，FIXME -->
          <!-- 持仓表格开始 -->
          <el-table
            :data="dataList"
            v-loading="tableLoading"
            v-show="currIndexPosition == 1"
          >
            <el-table-column
              class="coin-cell"
              min-width="160px"
              align="left"
              prop="itemDetail.logic_name"
              :show-overflow-tooltip="true"
              :label="translateTitle('存款类型')"
            >
              <template slot-scope="{ row }">
                <span>{{ row.product_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              min-width="120px"
              align="right"
              :label="translateTitle('存入金额')"
            >
              <template slot-scope="{ row }">
                <div class="DINPro" v-if="row.product_type == 4">
                  {{ row.ico_real_amount }} {{ row.to_item_name }}
                </div>
                <div class="DINPro" v-else>
                  {{ row.amount }} {{ row.item_name }}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="over"
              min-width="80px"
              :label="translateTitle('固定年化')"
              align="right"
            >
              <template slot-scope="{ row }">
                <span class="DINPro">{{ row.over }}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="over"
              min-width="120px"
              :label="translateTitle('累计收益')"
              align="right"
            >
              <template slot-scope="{ row }">
                <span v-if="row.product_type == 5"> -- </span>
                <span class="DINPro" v-else>
                  {{ row.total_earnings }} {{ row.item_name }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="pre_year_earnings"
              align="right"
              min-width="120px"
              :label="translateTitle('距离到期')"
            >
              <template slot-scope="{ row }">
                <div>
                  <span v-if="row.product_type == 2">--</span>
                  <span
                    v-else-if="
                      row.stop_time > 0 &&
                      Number(row.stop_time) - Number(row.now_time) > 0
                    "
                  >
                    {{
                      Math.ceil(
                        (Number(row.stop_time) - Number(row.now_time)) / 86400
                      )
                    }}
                    {{ translateTitle("天") }}
                  </span>
                  <span v-else-if="row.stop_time == 0"
                    >0 {{ translateTitle("天") }}</span
                  >
                  <span v-else>--</span>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="pre_year_earnings"
              align="right"
              min-width="120px"
              :label="translateTitle('预计年收益')"
            >
              <template slot-scope="{ row }">
                <div>
                  <span v-if="row.product_type == 2"
                    >{{ row.pre_year_earnings }} {{ row.item_name }}</span
                  >
                  <span v-else>--</span>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="pre_earnings_amount"
              align="right"
              min-width="160px"
              :label="translateTitle('到期收益')"
            >
              <template slot-scope="{ row }">
                <div>
                  <span v-if="row.product_type == 2">
                    ({{ translateTitle("预计年收益") }})
                    {{ row.pre_year_earnings }}
                    {{ row.item_name }}
                  </span>

                  <span
                    v-else-if="row.product_type != 2 && row.product_type != 4"
                    >{{ row.pre_earnings_amount }} USDT</span
                  >
                  <span v-else>--</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              width="300"
              :label="translateTitle('操作')"
              align="center"
            >
              <template slot-scope="{ row }" style="text-align: center">
                <span class="operation" @click="productInfoClick(row)">{{
                  translateTitle("产品详情")
                }}</span>
                <span class="operation" @click="holdDetail(row)">{{
                  translateTitle("持有详情")
                }}</span>
                <span
                  class="operation"
                  v-if="row.buy_access == 1"
                  @click="onGoOnBuy(row)"
                  >{{ translateTitle("继续存入") }}</span
                >
                <span class="operation-ban" v-else>{{
                  translateTitle("继续存入")
                }}</span>
                <!-- <span class="operation-ban" v-if="row.sell_switch == 2">{{
                  translateTitle("取出")
                }}</span>
                <span @click="onTakeOut(row)" class="operation" v-else>{{
                  translateTitle("取出")
                }}</span> -->
              </template>
            </el-table-column>
            <no-data
              slot="empty"
              v-show="!tableLoading && dataList.length == 0"
            />
          </el-table>
          <!-- 持仓表格结束 -->
          <!-- 历史持仓表格开始 -->
          <el-table
            :data="historyPositionDataList"
            v-loading="historytableLoading"
            v-show="currIndexPosition == 2"
          >
            <el-table-column
              class="coin-cell"
              min-width="180px"
              align="left"
              prop="itemDetail.logic_name"
              :show-overflow-tooltip="true"
              :label="translateTitle('存款类型')"
            >
              <template slot-scope="{ row }">
                <span>{{ row.product_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              min-width="170px"
              align="right"
              :label="translateTitle('存入金额')"
            >
              <template slot-scope="{ row }">
                <div class="DINPro">{{ row.amount }} {{ row.item_name }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="annual_rate"
              min-width="170px"
              :label="translateTitle('年化')"
              align="right"
            >
              <template slot-scope="{ row }">
                <span class="DINPro">{{
                  (row.annual_rate * 100).toFixed(2) + "%"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="over"
              min-width="170px"
              :label="translateTitle('累计收益')"
              align="right"
            >
              <template slot-scope="{ row }">
                <div class="DINPro">
                  {{ row.total_earnings }} {{ row.item_name }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              width="300"
              :label="translateTitle('操作')"
              align="center"
            >
              <template slot-scope="{ row }" style="text-align: center">
                <span class="operation" @click="productInfoClick(row)">{{
                  translateTitle("产品详情")
                }}</span>
                <span class="operation" @click="holdDetail(row)">{{
                  translateTitle("持有详情")
                }}</span>
              </template>
            </el-table-column>
            <no-data
              slot="empty"
              v-show="
                !historytableLoading && historyPositionDataList.length == 0
              "
            />
          </el-table>
          <!-- 历史持仓表格结束 -->
          <div class="common-table-pages">
            <p v-show="currIndexPosition == 1 && dataList.length > 0">
              {{ translateTitle("每页") }}
              <span>{{ pageInfo.pageSize }}</span>
              {{ translateTitle(`条，总共`) }}
              <span>{{ pageInfo.pageCount }}</span>
              {{ translateTitle("页") }}
            </p>
            <p
              v-show="
                currIndexPosition == 2 && historyPositionDataList.length > 0
              "
            >
              {{ translateTitle("每页") }}
              <span>{{ historyPositionPageInfo.pageSize }}</span>
              {{ translateTitle(`条，总共`) }}
              <span>{{ historyPositionPageInfo.pageCount }}</span>
              {{ translateTitle("页") }}
            </p>
            <el-pagination
              v-show="currIndexPosition == 1 && dataList.length > 0"
              class="mt20"
              background
              layout="prev, pager, next"
              :total="pageInfo.pageTotal"
              :page-size="pageInfo.pageSize"
              :current-page.sync="pageInfo.pageCurrent"
              @current-change="onPageChange"
            ></el-pagination>
            <el-pagination
              v-show="
                currIndexPosition == 2 && historyPositionDataList.length > 0
              "
              class="mt20"
              background
              layout="prev, pager, next"
              :total="historyPositionPageInfo.pageTotal"
              :page-size="historyPositionPageInfo.pageSize"
              :current-page.sync="historyPositionPageInfo.pageCurrent"
              @current-change="onHistoryPositionPageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      width="1000"
      custom-class="finance-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="financialDialogVisible"
    >
      <financial-dialog
        v-if="financialDialogVisible"
        @onProductInfoChange="onProductInfoChange"
        @onDialogClose="onDialogClose"
        @onConfirm="onConfirm"
        :productId="productId"
      ></financial-dialog>
    </el-dialog>
    <el-dialog
      width="470px"
      :title="confirmDialogTitle"
      custom-class="confirm-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="confirmDialogVisible"
    >
      <confirm-dialog-content
        v-loading="confirmDialogLoading"
        v-if="confirmDialogVisible"
        :productId="productId"
        :productInfo="apiProductInfo"
        @onCancel="onConfirmCancel"
        @onConfirm="onConfirmOK"
      >
      </confirm-dialog-content>
    </el-dialog>
    <el-dialog
      width="440px"
      :before-close="onCancelClick"
      :title="takeOutDialogTitle"
      custom-class="take-out-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="takeOutDialogVisible"
    >
      <div class="take-out-dialog-content">
        <div class="first-content">
          <div class="left-content">
            <img :src="apiProductInfo.item_url" alt="" />
            <div class="product-name">
              <div class="top">
                {{ apiProductInfo.product_name }}
              </div>
              <div
                v-if="
                  apiProductInfo.product_type != 2 &&
                  apiProductInfo.period != 2 &&
                  apiProductInfo.status == 3
                "
                class="schedule recruit"
              >
                {{ translateTitle("募集中") }}
              </div>

              <div class="schedule storage" v-if="apiProductInfo.period == 2">
                {{ translateTitle("随时可存") }}
              </div>
              <p class="bottom" v-if="apiProductInfo.product_type == 3">
                {{ translateTitle("产品到期") }}
                {{ apiProductInfo.quant_remain_end_earning_day }}
                <!-- <span
                  v-if="
                    Number(productInfo.stop_time) -
                      Number(productInfo.now_time) >
                      0
                  "
                >
                  {{
                    Math.ceil(
                      (Number(productInfo.stop_time) -
                        Number(productInfo.now_time)) /
                        86400
                    )
                  }}
                </span> -->
                {{ translateTitle("天") }}
              </p>
              <p
                class="bottom text-overflow"
                style="max-width: 250px"
                v-else-if="apiProductInfo.product_type == 2"
              >
                {{ translateTitle("当日存入次日起息，滚动复利灵活存取") }}
              </p>
              <p
                class="bottom"
                v-else-if="
                  (apiProductInfo.product_type == 4 ||
                    apiProductInfo.product_type == 1) &&
                  apiProductInfo.amount != 0
                "
              >
                {{ translateTitle("剩余") }}
                {{ apiProductInfo.amount - apiProductInfo.total_amount
                }}{{ apiProductInfo.item_name }} ({{
                  ((apiProductInfo.amount - apiProductInfo.total_amount) /
                    apiProductInfo.amount) *
                  100
                }}%)
              </p>
              <p
                class="bottom"
                v-else-if="
                  (apiProductInfo.product_type == 4 ||
                    apiProductInfo.product_type == 1) &&
                  apiProductInfo.amount == 0
                "
              >
                {{ translateTitle("不限额") }}
              </p>
            </div>
          </div>
          <div
            class="right-content"
            v-if="
              apiProductInfo.product_type == 3 ||
              apiProductInfo.product_type == 2
            "
          >
            <p class="red-test" v-if="apiProductInfo.annual_rate == '--'">
              --%
            </p>
            <p class="red-test" v-else>
              {{ Number(apiProductInfo.annual_rate) }}%
            </p>

            <p
              class="bottom-text"
              v-if="
                (apiProductInfo.product_type == 2 ||
                  apiProductInfo.product_type == 3) &&
                apiProductInfo.rate_type == 1
              "
            >
              {{ translateTitle("当前年化利率") }}
            </p>
            <p
              class="bottom-text"
              v-else-if="
                apiProductInfo.product_type == 3 &&
                apiProductInfo.rate_type == 2
              "
            >
              {{ translateTitle("固定年化") }}
            </p>
            <p
              class="bottom-text"
              v-else-if="
                apiProductInfo.product_type == 2 &&
                apiProductInfo.rate_type == 2
              "
            >
              {{ translateTitle("历史最高年化") }}
            </p>
          </div>
          <div class="right-content" v-if="apiProductInfo.product_type == 1">
            <p class="red-test" style="text-align: right">
              {{ Number(apiProductInfo.annual_rate).toFixed(2) }}%
            </p>
            <p class="bottom-text V2">
              {{ translateTitle("年化利率") }}
            </p>
          </div>
          <!-- <div
            class="right-content"
            v-if="
              apiProductInfo.product_type == 1 && apiProductInfo.lock_period
            "
          >
            <p class="red-test" style="text-align:right">
              {{ apiProductInfo.lock_period }}
              {{ translateTitle("天") }}
            </p>
            <p class="bottom-text">
              {{ translateTitle("锁定期") }}
            </p>
          </div> -->

          <div
            class="right-content"
            style="margin-right: -15px"
            v-if="apiProductInfo.product_type == 4"
          >
            <p class="red-test" v-if="apiProductInfo.to_item_id_price == '--'">
              --{{ apiProductInfo.item_name }}
            </p>
            <p class="red-test" v-else>
              {{ Number(apiProductInfo.to_item_id_price).toFixed(2) }}
              {{ apiProductInfo.item_name }}
            </p>
            <p class="bottom-text">
              {{ translateTitle("兑换价格") }}
            </p>
          </div>
          <div class="right-content" v-if="apiProductInfo.product_type == 4">
            <p class="red-test" v-if="apiProductInfo.annual_rate == '--'">
              --{{ translateTitle("天") }}
            </p>
            <p class="red-test" v-else>
              {{ Number(holderPositionInfo.lock_period)
              }}{{ translateTitle("天") }}
            </p>
            <p class="bottom-text">
              {{ translateTitle("交付期") }}
            </p>
          </div>
        </div>
        <div class="second-content">
          <div class="content-text">
            <div class="left">
              {{ translateTitle("取出金额") }}
            </div>
            <div class="right">
              {{ translateTitle("可取出") }} {{ apiAssetsInfo }}
              {{ apiProductInfo.item_name }}
            </div>
          </div>
          <div class="input-content">
            <InputNumber
              v-model="sellNumber"
              :placeholder="translateTitle('请输入取出金额')"
              maxlength="10"
              decimals="2"
              :suffix="apiProductInfo.item_name"
            ></InputNumber>
            <span class="all-btn common-operate" @click="onClickAll">
              {{ translateTitle("全部") }}
            </span>
          </div>
        </div>
        <div class="item-content" v-if="apiProductInfo.product_type != 2">
          <div class="tip-box">
            <div class="line-item">
              <div class="pre">{{ translateTitle("结算收益") }}</div>
              <div class="next">
                {{ dailyIncome }} {{ apiProductInfo.item_name }}
              </div>
            </div>
            <div class="line-item">
              <div class="pre">
                {{ translateTitle("取出部分将按活期年利率")
                }}{{ ceilNumber(apiProductInfo.current_rate * 100, 2) }}%{{
                  translateTitle("立即结算收益")
                }}
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="common-dialog-footer">
          <span class="dialog-cancel-btn" @click="onCancelClick">{{
            translateTitle("取消")
          }}</span>
          <el-button
            class="dialog-confirm-btn"
            :loading="btnLoading"
            @click="onOK"
            >{{ translateTitle("确定取出") }}</el-button
          >
        </span>
      </div>
    </el-dialog>
    <el-dialog
      width="500px"
      :title="translateTitle('持有详情')"
      custom-class="holder-detail-dialog"
      :close-on-click-modal="false"
      :before-close="onHolderDialogClose"
      :destroy-on-close="true"
      :visible.sync="detailDialogVisible"
    >
      <div v-loading="detailLoading">
        <div class="first-content-1" v-if="productInfo.product_type == 1">
          <div>
            <div>
              <img :src="productInfo.item_url" alt="" />
              <span class="first-test">{{ productInfo.product_name }}</span>
              <!-- <div
                v-if="
                  productInfo.product_type == 1 &&
                    productInfo.period == 1 &&
                    productInfo.status == 1
                "
                class="schedule warming"
              >
                {{ translateTitle("募集预热中") }}
              </div>
              <div
                v-else-if="
                  productInfo.product_type == 1 && productInfo.status == 3
                "
                class="schedule recruit"
              >
                {{ translateTitle("募集中") }}
              </div>
              <div class="schedule warming" v-if="productInfo.period == 2">
                {{ translateTitle("随时可存") }}
              </div> -->
            </div>
            <!-- <p class="product-date">
              {{ translateTitle("高收益，无风险，到期自动回款") }}
            </p> -->
          </div>
          <div>
            <p class="number" style="text-align: left">
              {{ (productInfo.annual_rate * 100).toFixed(2) }}%
            </p>
            <p class="label" v-if="productInfo.product_type == 1">
              {{ translateTitle("固定年化") }}
            </p>
            <p class="label" v-if="productInfo.product_type == 2">
              {{ translateTitle("历史年化") }}
            </p>
          </div>
          <div style="margin-left: 20px" v-if="productInfo.lock_period">
            <p class="number">{{ productInfo.lock_period }}天</p>
            <p class="label">{{ translateTitle("锁定期") }}</p>
          </div>
        </div>
        <div
          class="first-content-2-3"
          v-if="productInfo.product_type == 2 || productInfo.product_type == 3"
        >
          <div>
            <p>
              <img :src="productInfo.item_url" alt="" />
              <span class="first-test">{{ productInfo.product_name }}</span>
            </p>
            <!-- <p class="product-date" v-if="productInfo.product_type == 3">
              {{ translateTitle("产品到期") }}
              {{ apiProductInfo.quant_remain_end_earning_day || "--" }}天
            </p> -->
          </div>
          <div>
            <p class="number">
              {{ ceilNumber(productInfo.annual_rate * 100, 2) }}%
            </p>
            <p class="label" v-if="productInfo.rate_type == 1">
              {{ translateTitle("固定年化") }}
            </p>
            <p class="label" v-else-if="productInfo.rate_type == 2">
              {{ translateTitle("历史最高年化") }}
            </p>
          </div>
        </div>
        <div class="first-content-4" v-if="productInfo.product_type == 4">
          <div>
            <p>
              <img :src="productInfo.pre_earnings_item_url" alt="" />
              <span class="first-test">{{ productInfo.product_name }}</span>
              <!-- <span
                class="first-type-1 common-btn"
                v-if="productInfo.status > 1 || productInfo.status > 4"
                >募集中</span
              > -->
            </p>
            <!-- <p class="product-date">
              剩余 {{ productInfo.amount - productInfo.total_amount }}
              {{ productInfo.item_name }}({{
                ((productInfo.amount - productInfo.total_amount) /
                  productInfo.amount) *
                  100
              }}%)
            </p> -->
          </div>
        </div>
        <div v-if="productInfo.product_type == 4">
          <div class="four-content">
            <div class="step-graph">
              <i
                :class="[
                  'step-circle',
                  'step-circle1',
                  stepActive == 1 || stepActive == 2 || stepActive == 3
                    ? 'active'
                    : '',
                ]"
              ></i>
              <i
                :class="[
                  'step-circle',
                  'step-circle2',
                  stepActive == 2 || stepActive == 3 ? 'active' : '',
                ]"
              ></i>
              <i
                :class="[
                  'step-circle',
                  'step-circle3',
                  stepActive == 3 ? 'active' : '',
                ]"
              ></i>
              <div
                :class="[
                  'step-line',
                  'step-line1',
                  stepActive == 1 || stepActive == 2 || stepActive == 3
                    ? 'active'
                    : '',
                ]"
              >
                <i :style="{ width: `${rate1 * 100}%` }"></i>
              </div>
              <div
                :class="[
                  'step-line',
                  'step-line2',
                  stepActive == 2 || stepActive == 3 ? 'active' : '',
                ]"
              >
                <i :style="{ width: `${rate2 * 100}%` }"></i>
              </div>
            </div>

            <div class="step-text-box">
              <div class="step-block step-block1">
                <span class="step-text">{{ translateTitle("募集开始") }}</span>
                <span class="step-time">{{
                  timestampToDate(productInfo.period_start_time)
                }}</span>
              </div>
              <div class="step-block step-block2">
                <span class="step-text">{{ translateTitle("交付开始") }}</span>
                <span class="step-time">{{
                  timestampToDate(productInfo.period_stop_time)
                }}</span>
              </div>
              <div class="step-block step-block3">
                <span class="step-text">{{ translateTitle("交付完成") }}</span>
                <span class="step-time">{{
                  timestampToDate(productInfo.end_time)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="second-content"
          v-if="
            (productInfo.product_type == 1 || productInfo.product_type == 3) &&
              productInfo.amount != 0
          "
        >
          <div class="total-rate">
            <div class="total-num-box">
              <span class="already-num">
                {{ translateTitle("已募集") }} {{ rate }} %
              </span>
              <span class="total-num">100%</span>
            </div>
            <div class="total-line-box">
              <i :style="{ width: `${rate}%` }"></i>
            </div>
          </div>
        </div> -->
        <div
          class="four-content"
          v-if="productInfo.product_type == 1 || productInfo.product_type == 3"
        >
          <div class="step-graph">
            <i
              :class="[
                'step-circle',
                'step-circle1',
                stepActive == 1 || stepActive == 2 || stepActive == 3
                  ? 'active'
                  : '',
              ]"
            ></i>
            <i
              :class="[
                'step-circle',
                'step-circle2',
                stepActive == 2 || stepActive == 3 ? 'active' : '',
              ]"
            ></i>
            <i
              :class="[
                'step-circle',
                'step-circle3',
                stepActive == 3 ? 'active' : '',
              ]"
            ></i>
            <div
              :class="[
                'step-line',
                'step-line1',
                stepActive == 1 || stepActive == 2 || stepActive == 3
                  ? 'active'
                  : '',
              ]"
            >
              <i :style="{ width: `${rate1 * 100}%` }"></i>
            </div>
            <div
              :class="[
                'step-line',
                'step-line2',
                stepActive == 2 || stepActive == 3 ? 'active' : '',
              ]"
            >
              <i :style="{ width: `${rate2 * 100}%` }"></i>
            </div>
          </div>

          <div class="step-text-box">
            <div class="step-block step-block1" v-if="productInfo.period == 2">
              <span class="step-text">{{ translateTitle("存入") }}</span>
              <span class="step-time">{{
                timestampToDay(productInfo.create_time)
              }}</span>
            </div>
            <div
              class="step-block step-block1"
              v-else-if="productInfo.period == 1"
            >
              <span class="step-text">{{ translateTitle("募集开始") }}</span>
              <span class="step-time">{{
                timestampToDate(productInfo.start_time)
              }}</span>
            </div>
            <div
              class="step-block step-block2"
              v-if="productInfo.product_type == 3"
            >
              <span class="step-text" v-if="productInfo.period == 1">{{
                translateTitle("募集结束,开始计息")
              }}</span>
              <span class="step-text" v-else>{{
                translateTitle("开始计息")
              }}</span>
              <span class="step-time">{{
                timestampToDate(productInfo.start_time)
              }}</span>
            </div>
            <div
              class="step-block step-block2"
              v-if="productInfo.product_type == 1"
            >
              <span class="step-text" v-if="productInfo.period == 1">{{
                translateTitle("募集结束,开始计息")
              }}</span>
              <span class="step-text" v-else>{{
                translateTitle("开始计息")
              }}</span>
              <span class="step-time">{{
                timestampToDate(productInfo.start_time)
              }}</span>
            </div>
            <div class="step-block step-block3">
              <span class="step-text">{{ translateTitle("产品到期") }}</span>
              <span class="step-time" v-if="productInfo.product_type == 3">{{
                timestampToDate(productInfo.stop_time)
              }}</span>
              <span class="step-time" v-else>{{
                timestampToDate(productInfo.stop_time)
              }}</span>
            </div>
          </div>
        </div>
        <div class="info-content">
          <div class="line-item">
            <div class="pre">{{ translateTitle("存入金额") }}</div>
            <div class="next">
              <span v-if="productInfo.product_type == 4">
                {{ productInfo.ico_real_amount }}
              </span>
              <span v-else>
                {{ productInfo.amount }}
              </span>
              {{ productInfo.real_item_name }}
            </div>
          </div>

          <div class="line-item" v-if="productInfo.product_type == 2">
            <div class="pre">{{ translateTitle("昨日收益") }}</div>
            <div class="next">
              {{ productInfo.last_earnings }} {{ productInfo.real_item_name }}
            </div>
          </div>
          <div class="line-item" v-if="productInfo.product_type == 2">
            <div class="pre">{{ translateTitle("累计收益") }}</div>
            <div class="next">
              {{ productInfo.total_earnings }} {{ productInfo.real_item_name }}
            </div>
          </div>
          <div class="line-item" v-if="productInfo.product_type == 2">
            <div class="pre">{{ translateTitle("预计年收益") }}</div>
            <div class="next">
              {{ productInfo.pre_year_earnings }}
              {{ productInfo.real_item_name }}
            </div>
          </div>
          <div
            class="line-item"
            v-if="
              productInfo.product_type == 1 || productInfo.product_type == 3
            "
          >
            <div class="pre">{{ translateTitle("距离到期") }}</div>
            <div class="next">
              <span
                v-if="
                  Number(productInfo.stop_time) - Number(productInfo.now_time) >
                  0
                "
              >
                {{
                  Math.ceil(
                    (Number(productInfo.stop_time) -
                      Number(productInfo.now_time)) /
                      86400
                  )
                }}
              </span>
              <span v-else>0</span>

              {{ translateTitle("天") }}
            </div>
          </div>
          <div
            class="line-item"
            v-if="
              productInfo.product_type == 1 || productInfo.product_type == 3
            "
          >
            <div class="pre">{{ translateTitle("到期收益") }}</div>
            <div class="next">
              {{ productInfo.pre_earnings_amount }}
              {{ productInfo.real_item_name }}
            </div>
          </div>
          <div
            class="line-item"
            v-if="
              productInfo.product_type == 1 || productInfo.product_type == 3
            "
          >
            <div class="pre">{{ translateTitle("预计年收益") }}</div>
            <div class="next">
              {{ productInfo.pre_year_earnings }}
              {{ productInfo.real_item_name }}
            </div>
          </div>
          <div class="line-item" v-if="productInfo.product_type == 4">
            <div class="pre">{{ translateTitle("距离交付") }}</div>
            <!-- <div class="next">{{ productInfo.days }} 天</div> -->
            <div class="next">
              <span
                v-if="
                  productInfo.stop_time > 0 &&
                  Number(productInfo.stop_time) - Number(productInfo.now_time) >
                    0
                "
              >
                {{
                  Math.ceil(
                    (Number(productInfo.stop_time) -
                      Number(productInfo.now_time)) /
                      86400
                  )
                }}
                {{ translateTitle("天") }}
              </span>
              <span v-else-if="productInfo.stop_time == 0"
                >0 {{ translateTitle("天") }}</span
              >
              <span v-else>--</span>
            </div>
          </div>
          <div class="line-item" v-if="productInfo.product_type == 4">
            <div class="pre">{{ translateTitle("代币购买数量") }}</div>
            <div class="next">
              {{ productInfo.amount }}
              {{ productInfo.pre_earnings_item_name }}
            </div>
          </div>
          <div class="line-item" v-if="productInfo.product_type == 4">
            <div class="pre">{{ translateTitle("价格") }}</div>
            <div class="next">
              {{ productInfo.to_item_id_price }}
              {{ productInfo.real_item_name }}
            </div>
          </div>
        </div>
        <div class="nav">
          <div class="list-type">
            <div
              v-for="item in typeList"
              :key="item.value"
              class="tab"
              :class="{ active: item.value == typeActive }"
              @click="onTypeChange(item.value)"
            >
              <span>{{ translateTitle(item.label) }}</span>
            </div>
          </div>
        </div>
        <div class="record-list scrollbar-default-show">
          <div
            class="list-content"
            v-for="item in recordListComputed"
            :key="item.ufat_no"
          >
            <div class="list-item">
              <div class="left">
                <span class="title">{{
                  translateTitle(financialHoldStatusMaps.label[item.asset_type])
                }}</span>
                <span class="time">
                  {{ timestamp(item.create_time, "yyyy-MM-dd HH:mm:ss") }}
                </span>
              </div>
              <div class="right red" v-if="item.side == 2">
                -
                {{ item.amount }} {{ item.item_name }}
              </div>
              <!-- v-if="item.asset_type == 1 || item.asset_type == 2 || item.asset_type == 5||item.asset_type == 6" -->
              <div v-else class="right green">
                +
                {{ item.amount }} {{ item.item_name }}
              </div>
            </div>
          </div>
          <no-data
            class="record-no-data"
            slot="empty"
            v-show="recordListComputed.length == 0"
          />
        </div>
        <span
          slot="footer"
          class="common-dialog-footer"
          v-if="rowData.sell_switch != 2"
        >
          <span
            class="dialog-confirm-btn"
            v-if="rowData.buy_access == 1"
            @click="onGoOnBuy(rowData)"
            >{{ translateTitle("继续存入") }}</span
          >
          <span
            class="dialog-confirm-btn"
            v-if="rowData.buy_access == 1"
            @click="onTakeOut(rowData)"
            >{{ translateTitle("取出") }}</span
          >
        </span>
        <span
          class="common-btn btn"
          @click="onGoOnBuy(rowData)"
          v-else-if="rowData.buy_access == 1"
        >
          {{ translateTitle("继续存入") }}
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import TopTitle from "../../components/Title";
import NoData from "@/components/common/NoData";
import { apiGetAssets } from "@/model/exchange/index";
import {
  apiGetPositionInfo,
  apiGetFinancesProductInfo,
  apiSellFinances,
  apiGetHistoryPositionList,
} from "@/model/finance/finance";
import { apiGetStaticTableInfo } from "@/model/settings";
import commonMixin from "@/components/common/commonMixin.vue";
import { showMessage } from "@/components/dialog/message";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import { getAssetsList } from "@/model/assets/index";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import {
  timestampToDate,
  timestampToDay,
  timestamp,
} from "@/assets/js/formatDate";
import InputNumber from "@/components/common/InputNumber";
import { translateTitle } from "@/assets/js/i18n";
import FinancialDialog from "../../../finances/components/financial-management/financial-dialog";
import ConfirmDialogContent from "../../../finances/components/financial-management/confirm-dialog.vue";
import { financialHoldStatusMaps } from "@/assets/js/keymap.js";
import { VUE_APP_ID, VUE_APP_APP } from "@/assets/js/stationSetting";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
    InputNumber,
    FinancialDialog,
    ConfirmDialogContent,
  },
  data() {
    return {
      financialHoldStatusMaps,
      coinData: {}, // 币种数据列表
      assetsList: {}, // 资产数据列表
      historyPositionList: [], // 理财产品历史持仓数据列表
      symbolList: {}, // 交易币种表
      confirmDialogTitle: this.translateTitle(`量化理财`),
      takeOutDialogTitle: this.translateTitle(`定期存款取出`),
      takeOutDialogVisible: false,
      takeOutDialogLoading: true,
      active: 1,
      typeActive: 1,
      productType: 1,
      contractMap: {},
      rowData: {},
      item_name: "",
      u_valuation: "",
      last_profit: "",
      pre_earnings_amount: "",
      total_profit: "",
      assetsShow: "",
      sellNumber: "",
      dataList: [],
      historyPositionDataList: [],
      recordList: [],
      confirmDialogLoading: true,
      btnLoading: false,
      isChecked: false,
      detailLoading: true,
      oldTableList: [],
      stepActive: 1,
      rate: 1,
      rate1: 1,
      rate2: 1,
      currTab: 1,
      all_last_valuation: {
        aed: "--",
        cny: "--",
        cnye: "--",
        hkd: "--",
        jpy: "--",
        krw: "--",
        usd: "--",
        usdt: "--",
      },
      all_total_valuation: {
        aed: "--",
        cny: "--",
        cnye: "--",
        hkd: "--",
        jpy: "--",
        krw: "--",
        usd: "--",
        usdt: "--",
      },
      all_pre_earnings_valuation: {
        aed: "--",
        cny: "--",
        cnye: "--",
        hkd: "--",
        jpy: "--",
        krw: "--",
        usd: "--",
        usdt: "--",
      },
      assetsShowCurrency: {
        aed: "--",
        cny: "--",
        cnye: "--",
        hkd: "--",
        jpy: "--",
        krw: "--",
        usd: "--",
        usdt: "--",
      },
      digitalCoinList: {
        list: {},
      }, //加密货币
      legalCoinList: { list: {} }, //法币
      currIndex: 1,
      currIndexPosition: 1,
      tabList: [
        {
          value: "1",
          label: "金融理财",
        },
        // {
        //   value: "2",
        //   label: "矿池",
        // },
      ],
      tabListPosition: [
        {
          value: "1",
          label: "持仓中",
        },
        {
          value: "2",
          label: "历史持仓",
        },
      ],
      typeList: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "存入记录",
        },
        {
          value: "3",
          label: "收益明细",
        },
        {
          value: "4",
          label: "取出明细",
        },
      ],
      tableLoading: true,
      historytableLoading: true,
      financialDialogVisible: false,
      confirmDialogVisible: false,
      successDialogVisible: false,
      detailDialogVisible: false,
      productId: "",
      apiAssetsInfo: "",
      holderPositionInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 1,
        rate_type: 1,
        quant_tag: 0,
        annual_rate: 1,
        min_fluctuation_annual_rate: "0.0000",
        max_fluctuation_annual_rate: "0.0000",
        day_rate: "--",
        current_rate: "--",
        period: 1,
        start_time: 1620370800,
        stop_time: 1620975600,
        lock_period: 7,
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: 1000,
        max_amount: 200000,
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url:
            "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang:
            "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33",
        },
        item_name: "USDT",
        item_url:
          "https://imgs-dev.aevwjlae.com/admin/20201113/03397693702.png",
        to_item_url:
          "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
        to_item_name: "--",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0,
      },
      productInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 1,
        rate_type: 1,
        quant_tag: 0,
        annual_rate: 1,
        min_fluctuation_annual_rate: "0.0000",
        max_fluctuation_annual_rate: "0.0000",
        day_rate: "--",
        current_rate: "--",
        period: 1,
        start_time: 1620370800,
        stop_time: 1620975600,
        lock_period: 7,
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: 1000,
        max_amount: 200000,
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url:
            "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang:
            "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33",
        },
        item_name: "USDT",
        item_url:
          "https://imgs-dev.aevwjlae.com/admin/20201113/03397693702.png",
        to_item_url:
          "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
        to_item_name: "--",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0,
      },
      apiProductInfo: {
        product_id: 1,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 1,
        rate_type: 1,
        quant_tag: 0,
        annual_rate: "--",
        min_fluctuation_annual_rate: "0.0000",
        max_fluctuation_annual_rate: "0.0000",
        day_rate: "--",
        current_rate: "--",
        period: 1,
        start_time: 1620370800,
        stop_time: 1620975600,
        lock_period: 7,
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: 1000,
        max_amount: 200000,
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url:
            "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang:
            "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33",
        },
        item_name: "USDT",
        item_url:
          "https://imgs-dev.aevwjlae.com/admin/20201113/03397693702.png",
        to_item_url:
          "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
        to_item_name: "--",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0,
      },
      coinInfoMap: {},
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      historyPositionPageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      voucherInterval: null,
      voucherIntervalV2: null,
    };
  },

  async created() {
    await this.getStaticTableInfo(13);
    await this.getAssetsList();
    this.getAllItemDataOnce();

    this.voucherInterval = setInterval(async () => {
      await this.getAssetsList();
      this.getAllItemData();
    }, 3000);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    dailyIncome() {
      let tempValue = "--";
      if (this.sellNumber != "") {
        //  计息中取出才计算取出收益：  持仓金额*取出年化/365*已经计息的天数(days)
        tempValue =
          ((this.sellNumber * this.holderPositionInfo.annual_rate) / 365) *
          this.holderPositionInfo.days;
        // 截断
        tempValue = tempValue
          .toString()
          .substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    },
    sortCoinData() {
      return this.sortObj(this.coinData);
    },
    recordListComputed() {
      let tempArr = [];
      if (this.typeActive == 1) {
        tempArr = this.recordList;
      } else if (this.typeActive == 2) {
        this.recordList.forEach(element => {
          if (
            element.asset_type == 1 ||
            element.asset_type == 2 ||
            element.asset_type == 8
          ) {
            tempArr.push(element);
          }
        });
      } else if (this.typeActive == 3) {
        this.recordList.forEach(element => {
          if (element.asset_type == "3" || element.asset_type == "4") {
            tempArr.push(element);
          }
        });
      } else if (this.typeActive == 4) {
        this.recordList.forEach(element => {
          if (element.asset_type == "5" || element.asset_type == "6") {
            tempArr.push(element);
          }
        });
      }
      return tempArr;
    },
  },
  methods: {
    routeSplicing,
    timestampToDate,
    timestampToDay,
    timestamp,
    translateTitle,
    onClickAll() {
      this.sellNumber = this.apiAssetsInfo;
    },
    onCancelClick() {
      this.takeOutDialogVisible = false;
      this.sellNumber = "";
    },
    onHolderDialogClose() {
      this.detailDialogVisible = false;
      this.typeActive = 1;
    },
    onProductInfoChange(productInfo) {
      this.productInfo = productInfo;
    },
    onDialogClose() {
      this.financialDialogVisible = false;
    },
    async onConfirm() {
      await this.getProductInfo();
      this.confirmDialogVisible = true;
    },
    onConfirmCancel() {
      this.confirmDialogVisible = false;
    },
    onConfirmOK() {
      this.confirmDialogVisible = false;
      this.financialDialogVisible = false;
      showMessage({
        message: this.translateTitle(`存入成功`),
        type: "success",
      });
      this.holdDetail(this.rowData);
      // this.successDialogVisible = true;
    },
    onTakeOut(row) {
      this.productId = row.product_id;
      this.takeOutDialogLoading = true;
      this.rowData = row;
      this.apiAssetsInfo = row.amount;
      if (row.product_type == 3) {
        this.takeOutDialogTitle = this.translateTitle(`量化理财取出`);
      } else if (row.product_type == 2) {
        this.takeOutDialogTitle = this.translateTitle(`活期存款取出`);
      } else if (row.product_type == 1) {
        this.takeOutDialogTitle = this.translateTitle(`定期存款取出`);
      } else if (row.product_type == 4) {
        this.takeOutDialogTitle = this.translateTitle(`量化取出`);
      }

      let params = {
        ufp_id: row.ufp_id,
      };
      apiGetPositionInfo(params).then(res => {
        if (res.status == 200) {
          this.holderPositionInfo = res.data;
          this.takeOutDialogVisible = true;
        }
      });
      this.getProductInfo();
      setTimeout(() => {
        this.takeOutDialogLoading = false;
      }, 500);
    },
    onOK() {
      if (this.sellNumber == "") {
        showMessage({
          message: this.translateTitle("请输入取出金额"),
          type: "error",
        });
        return;
      }
      if (Number(this.sellNumber) > Number(this.apiAssetsInfo)) {
        showMessage({
          message: this.translateTitle("取出数量不能超过总持仓"),
          type: "warning",
        });
        return;
      }

      this.btnLoading = true;
      let params = {
        ufp_id: this.rowData.ufp_id,
        num: this.sellNumber,
      };
      apiSellFinances(params).then(res => {
        if (res.status == 200) {
          this.sellNumber = "";
          showMessage({
            message: this.translateTitle(`取出成功`),
            type: "success",
          });
          // setTimeout(async () => {
          this.getAssetsList();
          this.holdDetail(this.rowData);
          this.getAllItemData();
          // }, 100);
          this.takeOutDialogVisible = false;
        }
        this.btnLoading = false;
      });
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    async onPositionChange(item) {
      this.currIndexPosition = item.value;
      if (item.value == 2) {
        this.historytableLoading = true;
        await this.getHistoryPositionList();
        this.getAllHistoryPositionItemData();
      }
    },
    async getAssetsData() {
      const { status, data } = await getAssetsList();
      if (status == 200) {
        this.assetsList = data;
      }
    },
    rechargeClick(row) {
      if (row.in_status === 0) {
        let coinName = row.item_name.toUpperCase();
        showMessage({
          message: coinName + this.translateTitle(`暂不支持充币`),
        });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(row));
        localStorage.setItem("currentCoin", JSON.stringify(row));
        this.$router.push({
          path: this.routeSplicing(
            this.language,
            "ucenter/assets/recharge/" + row.item_name.toLowerCase()
          ),
        });
      }
    },
    async onGoOnBuy(row) {
      if (row) {
        this.productId = row.product_id;
      } else {
        this.productId = this.rowData.product_id;
      }
      await this.getProductInfo();
      if (row.product_type == 3) {
        this.confirmDialogTitle = this.translateTitle(`量化理财`);
      } else if (row.product_type == 2) {
        this.confirmDialogTitle = this.translateTitle(`活期存款`);
      } else if (row.product_type == 1) {
        this.confirmDialogTitle = this.translateTitle(`定期存款`);
      } else if (row.product_type == 4) {
        this.confirmDialogTitle = "Launchpad";
      }

      this.confirmDialogVisible = true;
    },
    // 产品详情弹窗
    productInfoClick(row) {
      this.productId = row.product_id;
      this.financialDialogVisible = true;
    },
    // 持有详情
    holdDetail(row) {
      this.rowData = row;
      this.detailLoading = true;
      let params = {
        ufp_id: row.ufp_id,
      };
      apiGetPositionInfo(params).then(res => {
        if (res.status == 200) {
          this.productInfo = res.data;
          if (res.data.turnover.length > 0) {
            let tempArr = res.data.turnover;
            tempArr.forEach(ele => {
              for (const key in this.coinInfoMap) {
                if (ele.item_id == key) {
                  ele.item_name = this.coinInfoMap[key].item_name.toUpperCase();
                  ele.item_url = this.coinInfoMap[key].itemDetail.coin_url;
                }
              }
            });
            this.recordList = tempArr;
          }
          const now_time = (new Date().getTime() / 1000).toFixed();
          this.productInfo.now_time = now_time;
          if (this.productInfo.product_type == 1) {
            this.rate1 =
              (now_time - this.productInfo.period_start_time) /
              (this.productInfo.period_stop_time -
                this.productInfo.period_start_time);
            this.rate1 = this.rate1 > 1 ? 1 : this.rate1;

            this.rate2 =
              (now_time - this.productInfo.start_time) /
              (this.productInfo.stop_time - this.productInfo.start_time);
            this.rate2 = this.rate2 > 1 ? 1 : this.rate2;
          } else if (this.productInfo.product_type == 3) {
            this.rate1 =
              (now_time - this.productInfo.create_time) /
              (this.productInfo.start_time - this.productInfo.create_time);
            this.rate1 = this.rate1 > 1 ? 1 : this.rate1;

            this.rate2 =
              (now_time - this.productInfo.start_time) /
              (this.productInfo.stop_time - this.productInfo.start_time);
            this.rate2 = this.rate2 > 1 ? 1 : this.rate2;
          } else if (this.productInfo.product_type == 4) {
            this.rate1 =
              (now_time - this.productInfo.create_time) /
              (this.productInfo.start_time - this.productInfo.create_time);
            this.rate1 = this.rate1 > 1 ? 1 : this.rate1;
            this.rate2 =
              (now_time - this.productInfo.period_start_time) /
              (this.productInfo.end_time - this.productInfo.period_start_time);
            this.rate2 = this.rate2 > 1 ? 1 : this.rate2;
          }
          // 1为未开始，2为计息中，3为已完成,4为收益已发放
          if (this.productInfo.status == 1) {
            this.stepActive = 1;
          } else if (this.productInfo.status == 2) {
            this.stepActive = 2;
          } else if (this.productInfo.status >= 3) {
            this.stepActive = 3;
          }
          for (const key in this.coinInfoMap) {
            if (key == this.productInfo.ico_real_amount_item_id) {
              this.productInfo.real_item_name =
                this.coinInfoMap[key].item_name.toUpperCase();
            }
            if (key == this.productInfo.item_id) {
              this.productInfo.item_name =
                this.coinInfoMap[key].item_name.toUpperCase();
            }

            if (this.productInfo.pre_earnings_item_id == key) {
              this.productInfo.pre_earnings_item_name =
                this.coinInfoMap[key].item_name.toUpperCase();
              this.productInfo.pre_earnings_item_url =
                this.coinInfoMap[key].itemDetail.coin_url;
            }
          }
        }
        this.detailLoading = false;
      });
      this.detailDialogVisible = true;
    },
    async getProductInfo() {
      let params = {
        product_id: this.productId,
      };
      this.confirmDialogLoading = true;
      const { status, data } = await apiGetFinancesProductInfo(params);
      if (status == 200) {
        let tempObj = data.productInfo;
        tempObj.annual_rate = tempObj.annual_rate * 100;
        this.apiProductInfo = tempObj;
      }
      this.confirmDialogLoading = false;
    },

    getAllItemDataOnce() {
      this.tableLoading = true;
      this.getAllItemData();
    },

    getAllItemData() {
      let tempAssets = [];
      tempAssets = this.assetsList.list;
      tempAssets.forEach(ele => {
        for (const key in this.symbolList) {
          if (ele.item_id == key) {
            ele.item_name = this.symbolList[key].item_name.toUpperCase();
          }
          if (ele.ico_base_item_id == key) {
            ele.to_item_name = this.symbolList[key].item_name.toUpperCase();
          }
        }
        const now_time = (new Date().getTime() / 1000).toFixed();
        ele.now_time = now_time;
        ele.remain_time = ele.start_time * 1000 - now_time;
      });
      this.pageInfo.pageTotal = tempAssets.length;
      this.pageInfo.pageCount = Math.ceil(
        this.pageInfo.pageTotal / this.pageInfo.pageSize
      );
      this.dataList = tempAssets.slice(
        (this.pageInfo.pageCurrent - 1) * this.pageInfo.pageSize,
        this.pageInfo.pageCurrent * this.pageInfo.pageSize
      );
      this.tableLoading = false;
    },
    //理财产品历史持仓数据处理
    getAllHistoryPositionItemData() {
      let tempHistoryPositionList = [];
      tempHistoryPositionList = this.historyPositionList;
      tempHistoryPositionList.forEach(ele => {
        for (const key in this.symbolList) {
          if (ele.item_id == key) {
            ele.item_name = this.symbolList[key].item_name.toUpperCase();
          }
        }
      });
      this.historyPositionPageInfo.pageTotal = tempHistoryPositionList.length;
      this.historyPositionPageInfo.pageCount = Math.ceil(
        this.historyPositionPageInfo.pageTotal /
          this.historyPositionPageInfo.pageSize
      );
      this.historyPositionDataList = tempHistoryPositionList.slice(
        (this.historyPositionPageInfo.pageCurrent - 1) *
          this.historyPositionPageInfo.pageSize,
        this.historyPositionPageInfo.pageCurrent *
          this.historyPositionPageInfo.pageSize
      );
      this.historytableLoading = false;
    },

    // 获取资产列表
    async getAssetsList() {
      let params = {
        type: "finance",
      };
      const { status, data } = await apiGetAssets(params);
      if (status === 200) {
        this.assetsList = data.finance;
        this.last_profit = data.finance.last_profit;
        this.total_profit = data.finance.total_profit;
        this.u_valuation = data.finance.u_valuation;
        this.pre_earnings_amount = data.finance.pre_earnings_amount;
        this.all_last_valuation = data.finance.all_last_valuation;
        this.all_total_valuation = data.finance.all_total_valuation;
        this.all_pre_earnings_valuation =
          data.finance.all_pre_earnings_valuation;
        this.assetsShowCurrency = data.finance.all_u_valuation;
      }
    },
    // 获取理财产品历史持仓列表
    async getHistoryPositionList() {
      const { status, data } = await apiGetHistoryPositionList();
      if (status === 200) {
        this.historyPositionList = data.list;
      }
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.symbolList = JSON.parse(JSON.stringify(data));
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
      }
    },
    sortObj(obj) {
      if (obj[10007]) {
        obj[10007].order_by = -1;
      }
      const keys = Object.keys(obj).sort(function (a, b) {
        return obj[a].order_by - obj[b].order_by;
      });
      var newObj = [];
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];
        newObj.push(obj[key]);
      }
      return newObj;
    },
    onTypeChange(val) {
      this.typeActive = val;
    },
    onPageChange() {
      this.getAllItemData();
    },
    onHistoryPositionPageChange() {
      this.getAllHistoryPositionItemData();
    },
  },
};
</script>
<style lang="scss">
.uc-financail-managment {
  min-width: 1040px;
  .finance-dialog {
    width: 1000px;
    // height: 680px;
    &.active3 {
      height: 475px;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      display: flex;
      border-radius: 8px;
      justify-content: space-between;
    }
  }
  .confirm-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 10px;
    }
    .el-dialog__body {
      padding: 10px 30px 30px;
    }
  }
  .trade-container {
    .header {
      // height: calc(100vh - 301px);
      border-radius: 4px;
      .content-title {
        background-color: #fff;
        position: relative;
        display: flex;
        box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
        align-items: center;
        background-color: #ffffff;
        user-select: none;
        // border-radius: 4px;
        h2 {
          font-size: 26px;
          font-weight: 600;
          color: #354052;
          text-align: left;
          height: 80px;
          line-height: 80px;
          padding-left: 24px;
          // padding: 21px 0 21px 24px;
        }
      }
      .tab-content {
        padding-left: 15px;
        background-color: #fff;
        ul {
          display: flex;
        }
        .classify-area {
          padding: 10px 10px;
          cursor: pointer;
          font-size: 16px;
          // height: 32px;
          line-height: 32px;
          color: #354052;
          position: relative;
          &.active {
            font-weight: 500;
            background-color: #ffffff;
            color: #354052;
          }
          .item-bar {
            position: absolute;
            width: 70%;
            height: 3px;
            background-color: #e81b40;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
    }
    .count-content {
      // width: 1552px;
      margin: 20px;
      height: 118px;
      padding: 20px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      background-color: #ffffff;
      .count-item {
        width: 240px;
        color: #9aa1b1;
        display: inline-block;
        overflow: hidden;
        .item-name {
          font-size: 14px;
          user-select: none;
        }
        .num {
          color: #354052;
          font-size: 24px;
          font-weight: bolder;
          margin: 5px 0;
          span {
            font-size: 12px;
          }
        }
        .num2 span {
          font-size: 12px;
        }
      }
    }
    .financail-managment-content {
      padding: 0px 20px;
      background: #ffffff;
      overflow: hidden;
      margin: 20px;
      min-height: 600px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      .market-header {
        border-bottom: solid 1px #ecedf1;
        margin-bottom: 20px;
        ul {
          display: flex;
        }
        .classify-area {
          height: 40px;
          margin-top: 10px;
          font-size: 16px;
          font-weight: 500;
          color: #354052;

          padding: 0 10px;
          cursor: pointer;
          text-align: center;
          line-height: 32px;
          font-weight: normal;
          position: relative;
          user-select: none;
          &.active {
            font-weight: 500;
          }
          .item-bar {
            position: absolute;
            width: calc(100% - 20px);
            height: 3px;
            background-color: #e81b40;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
      .content-header {
        width: 120px;
        height: 40px;
        margin-top: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
      .el-table {
        // height: calc(100vh - 692px);
        /* min-height: 624px; */
        min-height: 570px;
      }
      .no-data-box {
        /* min-height: 600px; */
        min-height: 520px;
      }
      .el-table__row {
        height: 50px !important;
        .cell {
          color: #354052;
          font-size: 14px;
          // display: flex;
          align-items: center;
        }
      }
      .coin-ing {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      .filter-box {
        padding: 20px 0px 15px 0px;
        .el-input {
          width: 200px;

          .el-input__inner {
            border: solid 1px #e2e2e4;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }
        .el-checkbox {
          margin-left: 20px;
          .el-checkbox__label {
            font-size: 14px;
            color: #354052;
            font-weight: 400;
          }
        }
      }
      .operation {
        font-size: 14px;
        color: #3348f6;
        font-weight: 400;
        cursor: pointer;
        padding: 0px 0px 0px 20px;
        /* padding: 0px 20px 0px 0px; */
      }
      .operation-ban {
        font-size: 14px;
        color: #9aa1b1;
        font-weight: 400;
        padding: 0px 0px 0px 20px;
        /* padding: 0px 20px 0px 0px; */
      }
    }
  }
  .holder-detail-dialog {
    margin-top: 10vh !important;
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 0;
    }
    .el-dialog__body {
      padding: 20px 30px 30px;
    }
    .first-content-1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      overflow: hidden;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 280px;
        display: inline-block;
        vertical-align: middle;
        user-select: none;
      }
      .schedule {
        height: 20px;
        line-height: 20px;
        padding: 0px 5px;
        vertical-align: middle;
        color: white;
        font-size: 12px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: inline-block;
        margin-right: 10px;
        // background-image: linear-gradient(266deg, #579fff 39%, #0068f3 120%);
        &.accruing {
          background: rgb(196, 196, 198);
        }
        &.warming {
          background-image: linear-gradient(to right, #f78700 0%, #ffb152 100%);
        }
        &.recruit {
          background-image: linear-gradient(259deg, #ff486d 20%, #c61b3e 140%);
        }
        &.storage {
          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
        }
        &.issuing {
          background: rgb(196, 196, 198);
        }
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        margin-left: 38px;
      }
      .number {
        font-size: 18px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        text-align: right;
      }
    }
    .first-content-2-3 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        font-weight: 500;
        color: #354052;
        margin-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 350px;
        display: inline-block;
        vertical-align: middle;
      }
      .first-type-1 {
        height: 20px;
        margin: 2px 0 6px 0px;
        padding: 2px 5px;
        color: white;
        font-size: 12px;
        border-radius: 4.4px;
        vertical-align: text-top;
        background-image: linear-gradient(266deg, #579fff 39%, #0068f3 120%);
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-left: 38px;
      }
      .number {
        font-size: 24px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        text-align: right;
      }
    }
    .first-content-4 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
        margin-right: 10px;
      }
      .first-test {
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        font-weight: 500;
        color: #354052;
        max-width: 280px;
        overflow: hidden;
        vertical-align: middle;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
      }
      .first-type-1 {
        height: 20px;
        line-height: 20px;
        margin: 2px 0 6px 0px;
        padding: 2px 5px;
        color: white;
        font-size: 12px;
        border-radius: 4.4px;
        vertical-align: middle;
      }
      .product-date {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        margin-left: 38px;
      }
      .number {
        font-size: 18px;
        font-weight: bold;
        text-align: right;
        color: #f04a5a;
      }
      .label {
        font-size: 14px;
        color: #354052;
        height: 20px;
        margin-top: 5px;
        text-align: right;
        &.V2 {
          text-align: left;
        }
      }
    }
    .second-content {
      overflow: hidden;
      margin-top: 10px;
      width: 100%;
      height: 50px;
      .total-rate {
        width: px2rem(590);
        margin: px2rem(28) auto 0;
        .total-num-box {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          height: 15px;
          line-height: 15px;
          font-weight: 500;
          .total-num {
            color: #9fa2aa;
          }
          .already-num {
            color: #15d39a;
          }
        }
        .total-line-box {
          width: 100%;
          height: 6px;
          border-radius: 5px;
          background-color: #e2e2e4;
          margin-top: 8px;
          i {
            display: block;
            height: 100%;
            border-radius: 5px;
            background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
            width: 60%;
          }
        }
      }
    }
    .four-content {
      margin-bottom: 20px;
      .step-graph {
        position: relative;
        height: 18px;
        .step-circle {
          position: absolute;
          width: 18px;
          height: 18px;
          top: 0;
          &.step-circle1 {
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
            left: 0;
          }
          &.step-circle2 {
            left: 50%;
            margin-left: -9px;
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
          }
          &.step-circle3 {
            right: 0;
            background: url("~@/assets/img/financial-detail/icon-3@3x.png")
              no-repeat;
            background-size: 100%;
          }
          &.active {
            background: url("~@/assets/img/financial-detail/icon-2@3x.png")
              no-repeat;
            background-size: 100%;
          }
        }
        .step-line {
          position: absolute;
          width: 196px;
          height: 2px;
          border-radius: 2px;
          background-color: #e2e2e4;
          top: 8px;
          overflow: hidden;
          i {
            display: block;
            height: 100%;
            position: absolute;
            left: 0;
            background: #e2e2e4;
          }
          &.active {
            // background-color: #15d39a;
            i {
              background-color: #15d39a;
            }
          }
          &.step-line1 {
            left: 18px;
          }
          &.step-line2 {
            right: 18px;
          }
        }
      }
      .step-text-box {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .step-block {
          font-size: 12px;
          width: 100%;
          white-space: nowrap;
          .step-text {
            display: block;
            color: #354052;
            height: 15px;
            line-height: 15px;
          }
          .step-time {
            display: block;
            color: #9fa2aa;
            height: 15px;
            line-height: 15px;
            margin-top: 2px;
          }
          &.step-block1 {
            text-align: left;
          }
          &.step-block2 {
            text-align: center;
          }
          &.step-block3 {
            text-align: right;
          }
        }
      }
    }
    .info-content {
      width: 440px;
      height: 120px;
      margin: 10px 5px 19px 0;
      padding: 10px 15px 12px;
      border-radius: 4px;
      background-color: #f3f7fc;
      .line-item {
        display: flex;
        justify-content: space-between;
        color: #9aa1b1;
        font-size: 14px;
        line-height: 1.8;
        user-select: none;
        .next {
          user-select: text;
          max-width: 278px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .record-list {
      min-height: 200px;
      max-height: 350px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .common-btn {
      &.btn {
        width: 300px;
        display: block;
        text-align: center;
        margin: 20px auto 10px;
        cursor: pointer;
        user-select: none;
      }
      &.disabled {
        width: 300px;
        display: block;
        text-align: center;
        margin: 20px auto 10px;
        user-select: none;
        background: #9aa1b1;
      }
    }
    .nav {
      display: flex;
      flex: 1;
      justify-content: space-between;
      font-size: 14px;
      .list-type {
        .tab {
          user-select: none;
          width: fit-content;
          height: 32px;
          line-height: 32px;
          margin-right: 10px;
          text-align: center;
          display: inline-block;
          padding: 0px 16px;
          background: #f1f5f9;
          border-radius: 17px;
          border: solid 1px #f1f5f9;
          cursor: pointer;
          span {
            color: #354052;
            font-size: 14px;
            font-weight: 500;
          }
          &:hover {
            color: #354052;
            &:after {
              content: "";
              height: 2px;
              width: 80%;
              background-color: #354052;
            }
          }
          &.active {
            background-color: white;
            color: #c61b3d;
            border: solid 1px #c61b3d;
            span {
              color: #c61b3d;
            }
          }
        }
      }
    }
    .list-content {
      .list-item {
        width: 440px;
        height: 50px;
        margin: 15px 5px 15px 0;
        padding: 7px 20px 8px 15px;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 3px 5px 0 rgba(53, 64, 82, 0.04);
        background-color: #ffffff;
        .left {
          user-select: none;
          span {
            display: block;
          }
          .title {
            color: #354052;
            font-size: 14px;
            font-weight: 500;
          }
          .time {
            color: #c2c7d0;
            font-size: 12px;
            font-weight: normal;
          }
        }
        .right {
          line-height: 35px;
          &.green {
            color: #28ba98;
          }
          &.red {
            color: #f04a5a;
          }
        }
      }
    }
    .common-dialog-footer {
      margin-top: 20px;
    }
  }
  .take-out-dialog {
    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px;
    }
    .el-dialog__body {
      padding: 0px 30px 20px;
    }
    .take-out-dialog-content {
      .first-content {
        display: flex;
        justify-content: space-between;
        .left-content {
          img {
            width: 26px;
            height: 26px;
            display: inline-block;
          }
          .product-name {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            max-width: 260px;
            .top {
              font-size: 18px;
              font-weight: 500;
              color: #354052;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 186px;
              display: inline-block;
              vertical-align: middle;
            }

            .schedule {
              height: 24px;
              line-height: 20px;
              padding: 2px 5px;
              color: white;
              font-size: 12px;
              border-top-left-radius: 5px;
              border-bottom-right-radius: 5px;
              vertical-align: middle;
              margin-left: 5px;
              display: inline-block;
              margin-right: 10px;
              &.accruing {
                background: rgb(196, 196, 198);
              }
              &.warming {
                background-image: linear-gradient(
                  to right,
                  #f78700 0%,
                  #ffb152 100%
                );
              }
              &.recruit {
                background-image: linear-gradient(
                  259deg,
                  #ff486d 20%,
                  #c61b3e 140%
                );
              }
              &.storage {
                background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
              }
              &.issuing {
                background: rgb(196, 196, 198);
              }
            }
            .bottom {
              margin-top: 5px;
              font-size: 14px;
              color: #354052;
            }
          }
        }
        .right-content {
          .red-test {
            font-size: 18px;
            font-weight: bold;
            color: #f04a5a;
            text-align: right;
            user-select: text;
          }
          .bottom-text {
            margin-top: 5px;
            font-size: 14px;
            color: #354052;
          }
        }
      }
      .second-content {
        margin-top: 20px;
        .content-text {
          display: flex;
          justify-content: space-between;
          .left {
            font-size: 16px;
            font-weight: 500;
            color: #354052;
          }
          .right {
            font-size: 14px;
            text-align: right;
            color: #354052;
          }
        }
        .input-content {
          margin-top: 7px;
          position: relative;
          .el-input__suffix {
            line-height: 40px;
            margin-right: 40px;
            color: #354052;
            .el-input__suffix-inner {
              font-size: 12px;
            }
          }
          .all-btn {
            position: absolute;
            right: 15px;
            top: 12px;
            font-size: 12px;
          }
        }
      }
      .item-content {
        .tip-box {
          background-color: #f3f7fc;
          margin: 0 auto;
          margin-top: 10px;
          padding: 10px 15px;
          position: relative;
          &:before {
            content: "";
            width: 200%;
            height: 200%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0.5);
            box-sizing: border-box;
          }
          .line-item {
            display: flex;
            line-height: 24px;
            justify-content: space-between;
            color: #9aa1b1;
            font-size: 14px;
            .next {
              max-width: 200px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
      .common-dialog-footer {
        margin-top: 28px;
      }
    }
  }
}
</style>
