import { render, staticRenderFns } from "./DChangePwd.vue?vue&type=template&id=8ea72112"
import script from "./DChangePwd.vue?vue&type=script&lang=js"
export * from "./DChangePwd.vue?vue&type=script&lang=js"
import style0 from "./DChangePwd.vue?vue&type=style&index=0&id=8ea72112&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports