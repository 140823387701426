<template>
  <div
    class="trade-data__container"
    :style="{ backgroundImage: `url(${isDarkMode ? worldDark : worldLight})` }"
    ref="tradeData"
  >
    <!-- <div class="trade-data__container-title">
      {{ translateTitle("要交易 用4E") }}
    </div>
    <div class="trade-data__container-tips">
      {{ translateTitle("一站式投资全球热门资产 投资无界限") }}
    </div> -->
    <div class="trade-data__container-items">
      <div
        class="trade-data__container-items-item"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="trade-data__container-items-item-title">
          <span :id="'item_' + index">{{ item.title }}</span
          >{{ translateTitle(item.unit) }}

          <span class="trade-data__container-items-item-title-suffix">
            {{ item.suffix }}
          </span>
        </div>
        <div class="trade-data__container-items-item-subtitle">
          {{ translateTitle(item.subTitle) }}
        </div>
      </div>
    </div>
    <register-panel v-if="!userInfoShow" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";
import RegisterPanel from "@/components/home/register-panel.vue";
import commonMixin from "@/components/common/commonMixin.vue";
import worldDark from "@/assets/img/world.png";
import worldLight from "@/assets/img/world.png";
import { CountUp } from "countup.js";
export default {
  props: {},
  components: {
    RegisterPanel,
  },
  mixins: [commonMixin],
  data() {
    return {
      worldDark,
      worldLight,
      items: [],
    };
  },
  async created() {},
  destroyed() {},
  mounted() {
    this.$nextTick(() => {
      const options = {
        root: null, // 视窗的根节点，默认为浏览器视窗
        threshold: 0.5, // 目标元素可见的比例阈值，默认为0
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!this.items.length) {
              this.items = [
                {
                  title: "340",
                  subTitle: "优质金融资产",
                  suffix: "+",
                },
                {
                  title: ["zh_CN", "zh_HK"].includes(this.language)
                    ? "100"
                    : "1",
                  unit: ["zh_CN", "zh_HK"].includes(this.language) ? "万" : "M",
                  subTitle: "全球用户",
                  suffix: "+",
                },
                {
                  title: "20",
                  subTitle: "国家分布",
                  suffix: "+",
                },
                {
                  title: "7*24H",
                  subTitle: "服务支持",
                },
              ];
            }
            this.showCountUpNumber();
          } else {
            console.log("元素离开可视区域");
          }
        });
      }, options);

      observer.observe(this.$refs.tradeData); // 开始观察目标元素
    });
  },
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      isDarkMode: "user/isDarkMode",
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },
  methods: {
    showCountUpNumber() {
      this.$nextTick(() => {
        const options = {
          suffix: "",
        };
        let demo = new CountUp(document.querySelector("#item_0"), 340, options);
        if (!demo.error) {
          demo.start();
        } else {
          console.error(demo.error);
        }
        let demo1 = new CountUp(
          document.querySelector("#item_1"),
          ["zh_CN", "zh_HK"].includes(this.language) ? 100 : 1,
          options
        );
        if (!demo1.error) {
          demo1.start();
        } else {
          console.error(demo1.error);
        }
        let demo2 = new CountUp(document.querySelector("#item_2"), 20, options);
        if (!demo2.error) {
          demo2.start();
        } else {
          console.error(demo2.error);
        }
      });
    },
  },
};
</script>
<style lang="scss">
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root[theme="light"] {
  .trade-data__container {
    background-color: transparent;
    .trade-data__container-items-item-subtitle {
      color: #5c5f69;
    }
  }
}
.trade-data__container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  height: 525px;
  padding-top: 92px;
  padding-bottom: 41px;
  background-color: var(--homeBgLightColor);
  /* background-image: var(
    --homeWroldImg
  ); //url(../../../assets/img/world-light.jpg); */
  color: var(--homeFontColor);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &-title {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    margin-bottom: 18px;
  }
  &-tips {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 80px;
    color: var(--homeLightGary);
  }
  &-items {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 264px;
      height: 132px;
      /* border: var(--homeGrayBorder); */
      border-radius: 12px;
      padding: 24px;
      /* margin-left: 20px; */
      /* margin-top: 20px; */
      &:hover {
        /* background: var(--homeHoverBgColor); */
      }
      &-title {
        font-size: 50px;
        font-weight: 600;
        line-height: 46px;
        margin-bottom: 10px;
        &-suffix {
          font-weight: 500;
          line-height: 48px;
          font-size: 32px;
          position: relative;
          left: -12px;
        }
      }
      &-subtitle {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        /* color: var(--homeLightGary); */
        color: rgba(255, 255, 255, 0.65);

        text-align: center;
      }
    }
  }
}
</style>