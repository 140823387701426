import api from "@/assets/js/api";
// const { VUE_APP_UCENTER } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

// //获取手机验证码 (非登录态)
// export function sendSignSmsCode(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Account/sendSignSmsCode`,
//     data,
//     type: "POST",
//   });
// }
//获取手机验证码 (非登录态)
export function sendSignSmsCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/Forget/sms`,
    data,
    type: "POST",
  });
}

// 获取手机验证码（二次校验）

export function loginSendSmsCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/LoginVerify/sendSmsCode`,
    data,
    type: "POST",
  });
}

// 获取手机验证码（完全登录态）

export function sendSmsCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/sendSmsCode`,
    data,
    type: "POST",
  });
}
// // 获取邮箱验证码（非登录态）
// export function sendSignEmailCode(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Account/sendSignEmailCode`,
//     data,
//     type: "POST",
//   });
// }
// 获取邮箱验证码（非登录态）
export function sendSignEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/Forget/email`,
    data,
    type: "POST",
  });
}
// 获取邮箱验证码（二次校验）

export function loginSendEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/LoginVerify/sendEmailCode`,
    data,
    type: "POST",
  });
}
// 获取邮箱验证码(完全登录态)

export function sendEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/sendEmailCode`,
    data,
    type: "POST",
  });
}

// // 修改密码
// export function updatePassword(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updatePassword`,
//     data,
//     type: "POST",
//   });
// }

// 修改密码
export function updatePassword(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/updatePassword`,
    data,
    type: "POST",
  });
}

// // 绑定手机
// export function bindMobile(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindMobile`,
//     data,
//     type: "POST",
//   });
// }

// 绑定手机
export function bindMobile(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/bindMobile`,
    data,
    type: "POST",
  });
}

// // 绑定邮箱
// export function bindEmail(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindEmail`,
//     data,
//     type: "POST",
//   });
// }

// 绑定邮箱
export function bindEmail(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/bindEmail`,
    data,
    type: "POST",
  });
}

// // 修改绑定的手机
// export function undataMobile(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updateMobile`,
//     data,
//     type: "POST",
//   });
// }

// 修改绑定的手机
export function undataMobile(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/changeMobile`,
    data,
    type: "POST",
  });
}

// // 获取谷歌令牌
// export function getGoogleSecret(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/getGoogleSecret`,
//     data,
//     type: "POST",
//   });
// }

// 获取谷歌令牌
export function getGoogleSecret(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/bindGoogleCode`,
    data,
    type: "POST",
  });
}

// // 绑定谷歌令牌
// export function bindGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }

// 绑定谷歌令牌
export function bindGoogleVerify(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/bindGoogle`,
    data,
    type: "POST",
  });
}

// // 修改谷歌令牌
// export function updateGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updateGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }
// 修改谷歌令牌
export function updateGoogleVerify(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/changeGoogle`,
    data,
    type: "POST",
  });
}
// // 关闭谷歌认证
// export function closeGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/closeGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }

// 关闭谷歌认证
export function closeGoogleVerify(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/closeGoogle`,
    data,
    type: "POST",
  });
}
// // 开启谷歌认证
// export function openGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/openGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }
// 开启谷歌认证
export function openGoogleVerify(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/V3/UCenter/User/openGoogle`,
    data,
    type: "POST",
  });
}
// 获取谷歌绑定状态
export function bingGoogleVerifyStatus(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Security/bingGoogleVerifyStatus`,
    data,
    type: "POST",
  });
}
