<template>
  <div class="number">
    <el-input
      :value="value"
      :placeholder="placeholder"
      @input="decimalLimit"
      :autocomplete="autocomplete"
      :maxlength="maxlength"
      :max="max"
      :disabled="disabled"
      ref="input"
    >
      <span v-if="frontSuffix" slot="prefix" style="margin-left:5px">{{
        frontSuffix
      }}</span>
      <span v-if="suffix" slot="suffix" style="margin-right:5px">{{
        suffix
      }}</span>
      <template v-if="prefix" slot="append">{{ prefix }}</template>
    </el-input>
  </div>
</template>

<script>
/* 
 该输入框不可使用科学技术法计算
 value：输入框值
 placeholder：占位符
 maxlength：最大长度
 decimals：小数位，为 0 或 不穿则表示无小数
 */
export default {
  props: [
    "value",
    "max",
    "maxlength",
    "decimals",
    "placeholder",
    "disabled",
    "autocomplete",
    "prefix",
    "frontSuffix",
    "suffix",
  ],
  data() {
    return {};
  },
  methods: {
    onFocus() {
      this.$refs.input.focus();
    },
    decimalLimit(val) {
      // RegExp
      let regDicimal = new RegExp(
        "^(\\-)*(\\d+)\\.(\\d{" + this.decimals + "}).*$"
      );
      val = val.replace(/。/g, ".");
      val = val.replace(/^\./g, "");
      val = val
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      val = this.decimals
        ? val.replace(/[^\d.]/g, "")
        : val.replace(/[^\d]/g, "");

      // //如果第一位是0，第二位不是点，就用数字把点替换掉
      // var len1 = val.substr(0, 1);
      // var len2 = val.substr(1, 1);
      // if (val.length > 1 && len1 == 0 && len2 != ".") {
      //   val = parseFloat(val);
      // }

      val = val.replace(regDicimal, "$1$2.$3");
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss">
.number{

}
</style>
