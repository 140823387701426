<template>
  <div class="status-tag">
    <!-- {{ status }} -->
    <i v-if="hasPoint" class="point"></i>
  </div>
</template>
<script>
export default {
  props: {
    status: String,
    hasPoint: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.status-tag {
  height:24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .point {
    width:6px;
    height:6px;
    background:#f04a5a;
    border-radius: 50%;
    position: absolute;
    left: -8px;
    top: 4px;
  }
}
</style>