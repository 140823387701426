<template>
  <div class="mp-confirm-dialog-main-content">
    <div class="first-content">
      <div class="left-content">
        <img :src="productInfo.item_url" alt="" />
        <div class="product-name">
          <div class="top">
            {{ productInfo.product_name }}
            <div
              v-if="
                productInfo.product_type != 2 &&
                  productInfo.period != 2 &&
                  productInfo.status == 3
              "
              class="schedule recruit"
            >
              募集中
            </div>

            <div class="schedule storage" v-if="productInfo.period == 2">
              随时可存
            </div>
          </div>
          <p class="bottom" v-if="productInfo.product_type == 3">
            产品到期 {{ productInfo.lock_period }}天
          </p>
          <p
            class="bottom text-overflow"
            style="max-width:200px"
            v-else-if="productInfo.product_type == 2"
          >
            当日存入次日起息，滚动复利灵活存取
          </p>
          <p
            class="bottom text-overflow"
            style="max-width:200px"
            v-else-if="productInfo.product_type == 1"
          >
            高收益，无风险，到期自动回款
          </p>
          <p
            class="bottom"
            v-else-if="productInfo.product_type == 4 && productInfo.amount != 0"
          >
            剩余 {{ productInfo.amount - productInfo.total_amount
            }}{{ productInfo.item_name }} ({{
              ((productInfo.amount - productInfo.total_amount) /
                productInfo.amount) *
                100
            }}%)
          </p>
          <p
            class="bottom"
            v-else-if="productInfo.product_type == 4 && productInfo.amount == 0"
          >
            剩余 -- ( --%)
          </p>
        </div>
      </div>
      <div
        class="right-content"
        v-if="productInfo.product_type == 3 || productInfo.product_type == 2"
      >
        <p class="red-test" v-if="productInfo.annual_rate == '--'">
          --%
        </p>
        <p class="red-test" v-else>{{ Number(productInfo.annual_rate) }}%</p>

        <p
          class="bottom-text"
          v-if="
            (productInfo.product_type == 2 || productInfo.product_type == 3) &&
              productInfo.rate_type == 1
          "
        >
          当前年化利率
        </p>
        <p
          class="bottom-text"
          v-else-if="
            productInfo.product_type == 3 && productInfo.rate_type == 2
          "
        >
          固定年化
        </p>
        <p
          class="bottom-text"
          v-else-if="
            productInfo.product_type == 2 && productInfo.rate_type == 2
          "
        >
          历史最高年化
        </p>
      </div>
      <div
        class="right-content"
        style="margin-right:-5px"
        v-if="productInfo.product_type == 1"
      >
        <p class="red-test" style="text-align:left">
          {{ productInfo.annual_rate }}%
        </p>
        <p class="bottom-text V2">年化利率</p>
      </div>
      <div class="right-content" v-if="productInfo.product_type == 1">
        <p class="red-test" style="text-align:right">
          {{ productInfo.lock_period }}天
        </p>
        <p class="bottom-text">锁定期</p>
      </div>

      <div
        class="right-content"
        style="margin-right:-35px"
        v-if="productInfo.product_type == 4"
      >
        <p class="red-test" v-if="productInfo.to_item_id_price == '--'">
          --{{ productInfo.item_name }}
        </p>
        <p class="red-test" v-else>
          {{ Number(productInfo.to_item_id_price).toFixed(2)
          }}{{ productInfo.item_name }}
        </p>
        <p class="bottom-text">
          兑换价格
        </p>
      </div>
      <div class="right-content" v-if="productInfo.product_type == 4">
        <p class="red-test" v-if="productInfo.annual_rate == '--'">--天</p>
        <p class="red-test" v-else>{{ Number(productInfo.annual_rate) }}天</p>
        <p class="bottom-text">
          交付期
        </p>
      </div>
    </div>
    <div class="second-content">
      <div class="content-text">
        <div class="left">
          {{ translateTitle("存入金额") }}
        </div>
        <div class="right">
          可用 {{ apiAssetsInfo }} {{ productInfo.item_name }}
        </div>
      </div>
      <div class="input-content">
        <InputNumber
          v-model="buyNumber"
          :placeholder="translateTitle('请输入存入金额')"
          maxlength="10"
          decimals="2"
          :suffix="productInfo.item_name"
        ></InputNumber>
        <span class="all-btn common-operate" @click="onClickAll">
          全部
        </span>
      </div>
    </div>
    <div class="item-content">
      <div class="tip-box">
        <div class="line-item">
          <div class="pre">{{ translateTitle("单笔限额") }}</div>
          <div class="next">
            {{ productInfo.min_amount }} - {{ productInfo.max_amount }}
            {{ productInfo.item_name }}
          </div>
        </div>
        <div class="line-item" v-if="productInfo.product_type == 3">
          <div class="pre" v-if="productInfo.rate_type == 2">预计收益</div>
          <div class="pre" v-else>到期收益</div>
          <div class="next">
            {{ dailyIncome }}
            <span v-if="dailyIncome != '--'">{{ productInfo.item_name }}</span>
          </div>
        </div>
        <div class="line-item" v-if="productInfo.product_type == 2">
          <div class="pre">{{ translateTitle("预计每日收益") }}</div>
          <div class="next">
            {{ currentDailyIncome }}
            <span v-if="currentDailyIncome != '--'">{{
              productInfo.item_name
            }}</span>
          </div>
        </div>
        <div class="line-item" v-if="productInfo.product_type == 1">
          <div class="pre">{{ translateTitle("到期收益") }}</div>
          <div class="next">
            {{ fixedDailyIncome == "NaN" ? "" : fixedDailyIncome }}
            <div v-if="fixedDailyIncome == 'NaN'" class="dot"></div>
            <span v-if="dailyIncome != '--'">{{ productInfo.item_name }}</span>
          </div>
        </div>
        <div class="line-item" v-if="productInfo.product_type == 3">
          <div class="pre">到期时间</div>
          <div class="next">
            {{ timestampToYear(productInfo.quant_end_earning_time) }}
          </div>
        </div>
        <div class="line-item" v-if="productInfo.product_type == 1">
          <div class="pre">到期时间</div>
          <div class="next">
            {{ timestampToYear(productInfo.end_time) }}
          </div>
        </div>
        <div class="line-item" v-if="productInfo.product_type == 4">
          <div class="pre">兑换数量</div>
          <div class="next">
            {{ hxDailyIncome }}
            <span v-if="hxDailyIncome != '--'">{{
              productInfo.to_item_name
            }}</span>
          </div>
        </div>
        <div class="line-item" v-if="productInfo.product_type == 4">
          <div class="pre">交付时间</div>
          <div class="next">{{ timestampToYear(productInfo.end_time) }}</div>
        </div>
      </div>
    </div>
    <span slot="footer" class="common-dialog-footer">
      <span class="dialog-cancel-btn" @click="onCancelClick">{{
        translateTitle("取消")
      }}</span>
      <el-button
        class="dialog-confirm-btn"
        :loading="btnLoading"
        @click="onOK"
        >{{ translateTitle("确定存入") }}</el-button
      >
    </span>
  </div>
</template>

<script>
import { timestampToYear } from "@/assets/js/formatDate";
import InputNumber from "@/components/common/InputNumber";
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
import { apiGetAssets } from "@/model/exchange/index";
import { showMessage } from "@/components/dialog/message";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import {
  apiBuyFinances,
  apiGetFinancesProductInfo,
} from "@/model/finance/finance.js";
export default {
  components: { InputNumber },
  props: {
    productId: {
      type: [String, Number],
      default: "",
    },
    productInfo: {
      type: Object,
      default: function() {
        return {
          product_id: 33,
          product_name: "--",
          item_id: 10007,
          channel_id: 101,
          item_type: 1,
          to_item_id: 10069,
          to_item_id_price: "--",
          product_type: 4,
          rate_type: 1,
          quant_tag: 0,
          annual_rate: "--",
          min_fluctuation_annual_rate: "--",
          max_fluctuation_annual_rate: "--",
          day_rate: "--",
          current_rate: "--",
          period: 1,
          start_time: 1620370800,
          stop_time: 1620975600,
          lock_period: "--",
          amount: 0,
          lowest_amount: 0,
          total_amount: "--",
          min_amount: "--",
          max_amount: "--",
          per_min_num: 1,
          per_max_num: 200,
          number_precision: 2,
          price_precision: 2,
          amount_precision: 2,
          trader_profit: 0,
          status: 7,
          union_product_id: 0,
          union_order_by: 1,
          is_union_master: 0,
          public_raise_amount: "--",
          ico_buy_condition: 1,
          ico_user_register_time: 0,
          is_hide: 0,
          is_show_list: 1,
          is_recommand: 1,
          is_delete: 0,
          access_type: 1,
          order_by: 1,
          i18n_lang: "static_finances_product_product_name_330",
          create_time: 1620324487,
          update_time: 1620975363,
          detail: {
            product_id: 33,
            product_intro: "",
            inout_rule: "",
            earnings_rule: "",
            product_code: "--",
            strategy: "",
            ico_url:
              "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
            ico_eg: "",
            buy_rule: "--",
            risk_tip: "--",
            join_rule: "",
            i18n_lang:
              "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33",
          },
          item_name: "USDT",
          item_url: require("../../../../assets/img/holder-coin-ico.png"),
          to_item_url:
            "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
          to_item_name: "--",
          end_time: 1621580400,
          user_profit: 1,
          show_profit: 0,
        };
      },
    },
  },
  data() {
    return {
      apiAssetsInfo: "--",
      buyNumber: "",
      dialogForm: {
        min_amount: "",
        max_amount: "",
        item_name: "",
      },
      btnLoading: false,
      apiProductInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 4,
        rate_type: 1,
        quant_tag: 0,
        annual_rate: "--",
        min_fluctuation_annual_rate: "--",
        max_fluctuation_annual_rate: "--",
        day_rate: "--",
        current_rate: "--",
        period: 1,
        start_time: 1620370800,
        stop_time: 1620975600,
        lock_period: "--",
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: "--",
        max_amount: "--",
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url:
            "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang:
            "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33",
        },
        item_name: "USDT",
        item_url: require("../../../../assets/img/holder-coin-ico.png"),
        to_item_url:
          "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
        to_item_name: "--",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
    }),
    dailyIncome() {
      let tempValue = "--";
      if (this.buyNumber != "") {
        // 年化 *数量*用户分成比例*锁仓天数/365
        tempValue =
          (this.buyNumber *
            this.productInfo.annual_rate *
            this.productInfo.user_profit *
            this.apiProductInfo.quant_real_position_Lock_day) /
          365 /
          100;
        // 截断
        tempValue = tempValue
          .toString()
          .substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    },
    currentDailyIncome() {
      let tempValue = "--";
      if (this.buyNumber != "") {
        // 存入金额 * 当前年化率 / 365
        tempValue = (this.buyNumber * this.productInfo.annual_rate) / 100 / 365;
        // 截断
        tempValue = tempValue
          .toString()
          .substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    },
    fixedDailyIncome() {
      // 定期: 年化 *数量*锁仓天数/365
      let tempValue = "--";
      if (this.buyNumber != "") {
        tempValue =
          (this.productInfo.annual_rate *
            this.buyNumber *
            this.productInfo.lock_period) /
          100 /
          365;
        // 截断
        tempValue = tempValue
          .toString()
          .substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    },
    hxDailyIncome() {
      let tempValue = "--";
      if (this.buyNumber != "") {
        //  数量/toItemIdPrice
        tempValue = this.buyNumber / this.productInfo.to_item_id_price;
        // 截断
        tempValue = tempValue
          .toString()
          .substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    },
  },
  created() {
    this.getProductInfo();
    this.getAssets();
  },
  methods: {
    translateTitle,
    timestampToYear,
    onClickAll() {
      if (Number(this.apiAssetsInfo > Number(this.productInfo.max_amount))) {
        this.buyNumber = this.productInfo.max_amount;
      } else {
        this.buyNumber = this.apiAssetsInfo;
      }
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        let tempData = data.remain.list;
        tempData.forEach(ele => {
          if (ele.item_id == this.productInfo.item_id) {
            this.apiAssetsInfo = ele.over;
          }
        });
      }
    },
    getProductInfo() {
      if (this.productInfo.product_type != 3) {
        return;
      }
      let params = {
        product_id: this.productId,
      };
      this.pageLoading = true;
      apiGetFinancesProductInfo(params).then(res => {
        if (res.status == 200) {
          // this.productInfo = res.data.productInfo;
          this.apiProductInfo = res.data.productInfo;
          const now_time = (new Date().getTime() / 1000).toFixed();

          let tempRate =
            (this.productInfo.total_amount / this.productInfo.amount) * 100;
          this.rate = ceilNumber(tempRate, 2);

          // this.rate = (
          //   (this.productInfo.total_amount / this.productInfo.amount) *
          //   100
          // ).toFixed(2);
          this.rate1 =
            (now_time - this.productInfo.start_time) /
            (this.productInfo.stop_time - this.productInfo.start_time);
          this.rate1 = this.rate1 > 1 ? 1 : this.rate1;
          this.rate2 =
            (now_time - this.productInfo.stop_time) /
            (this.productInfo.end_time - this.productInfo.stop_time);
          this.rate2 = this.rate2 > 1 ? 1 : this.rate2;

          if (this.productInfo.status == 3) {
            this.stepActive = 1;
          } else if (
            this.productInfo.status >= 4 &&
            this.productInfo.status < 7
          ) {
            this.stepActive = 2;
          } else if (this.productInfo.status == 7) {
            this.stepActive = 3;
          }
        } else {
          showMessage({
            message: res.msg,
            type: "error",
          });
        }
        this.pageLoading = false;
      });
    },
    onCancelClick() {
      this.$emit("onCancel");
    },
    onOK() {
      if (this.buyNumber == "") {
        showMessage({
          message: this.translateTitle("请输入存入金额"),
          type: "error",
        });
        return;
      }
      if (Number(this.buyNumber) > Number(this.apiAssetsInfo)) {
        showMessage({
          message: this.translateTitle("可用资产不足"),
          type: "error",
        });
        return;
      }
      if (Number(this.buyNumber) < Number(this.productInfo.min_amount)) {
        showMessage({
          message: this.translateTitle("存入金额不能小于最小单笔限额"),
          type: "warning",
        });
        return;
      }
      if (Number(this.buyNumber) > Number(this.productInfo.max_amount)) {
        showMessage({
          message: this.translateTitle("存入金额不能大于最大单笔限额"),
          type: "warning",
        });
        return;
      }
      this.btnLoading = true;
      let params = {
        product_id: this.productInfo.product_id,
        num: this.buyNumber,
      };
      apiBuyFinances(params).then(res => {
        if (res.status == 200) {
          this.buyNumber = "";
          this.$emit("onConfirm");
        }
        this.btnLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.mp-confirm-dialog-main-content {
  .first-content {
    display: flex;
    justify-content: space-between;
    .left-content {
      img {
        width: 26px;
        height: 26px;
        display: inline-block;
      }
      .product-name {
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;
        .top {
          font-size: 18px;
          font-weight: 500;
          color: #354052;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 220px;
          .schedule {
            height: 24px;
            line-height: 20px;
            padding: 2px 5px;
            color: white;
            font-size: 12px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            vertical-align: text-bottom;
            display: inline-block;
            margin-right: 10px;
            // background-image: linear-gradient(266deg, #579fff 39%, #0068f3 120%);
            &.accruing {
              background: rgb(196, 196, 198);
            }
            &.warming {
              background-image: linear-gradient(
                to right,
                #f78700 0%,
                #ffb152 100%
              );
            }
            &.recruit {
              background-image: linear-gradient(
                259deg,
                #ff486d 20%,
                #c61b3e 140%
              );
            }
            &.storage {
              background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
            }
            &.issuing {
              background: rgb(196, 196, 198);
            }
          }
        }
        .bottom {
          margin-top: 5px;
          font-size: 14px;
          color: #354052;
        }
      }
    }
    .right-content {
      .red-test {
        font-size: 18px;
        font-weight: bold;
        color: #f04a5a;
        text-align: right;
        user-select: text;
      }
      .bottom-text {
        margin-top: 5px;
        font-size: 14px;
        color: #354052;
      }
    }
  }
  .second-content {
    margin-top: 20px;
    .content-text {
      display: flex;
      justify-content: space-between;
      .left {
        font-size: 16px;
        font-weight: 500;
        color: #354052;
      }
      .right {
        font-size: 14px;
        text-align: right;
        color: #354052;
        user-select: text;
      }
    }
    .input-content {
      margin-top: 7px;
      position: relative;
      .el-input__suffix {
        line-height: 40px;
        margin-right: 40px;
        color: #354052;
        .el-input__suffix-inner {
          font-size: 12px;
        }
      }
      .all-btn {
        position: absolute;
        right: 15px;
        top: 12px;
        font-size: 12px;
      }
    }
  }
  .item-content {
    .tip-box {
      background-color: #f3f7fc;
      margin: 0 auto;
      margin-top: 10px;
      padding: 10px 15px;
      position: relative;
      .line-item {
        display: flex;
        line-height: 24px;
        justify-content: space-between;
        color: #9aa1b1;
        font-size: 14px;
        .next {
          max-width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          user-select: text;
        }
      }
    }
  }
  .common-dialog-footer {
    margin-top: 28px;
  }
  .dot {
    display: inline-block;
    height: 1em;
    line-height: 1;
    text-align: left;
    vertical-align: -0.25em;
    overflow: hidden;
    margin-right: 5px;
  }

  .dot::before {
    display: block;
    content: "...\A..\A.";
    white-space: pre-wrap;
    animation: doting 2s infinite step-start both;
  }

  @keyframes doting {
    33% {
      transform: translateY(-2em);
    }
    66% {
      transform: translateY(-1em);
    }
  }
}
</style>
