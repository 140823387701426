<template>
  <div v-cloak class="asset-snippet">
    <div class="coin">
      <div class="coin-info">
        <img :src="data.itemDetail.coin_url" />
        <h3>{{ data.itemDetail.name.toUpperCase() }}</h3>
      </div>
      <div class="coin-operate">
        <button
          :class="getClassName(data.itemDetail.in_status)"
          class="recharge-btn"
          @click="rechargeClick"
        >
          充币
        </button>
        <button
          :class="getClassName(data.itemDetail.out_status)"
          class="recharge-btn"
          @click="withdrawalClick"
        >
          提币
        </button>
      </div>
    </div>
    <div class="asset">
      <div class="snippet">
        <span class="type">可用</span>
        <span class="num">{{
          assetsList.list ? assetsList.list[data.itemDetail.name].over : "--"
        }}</span>
      </div>
    </div>
    <div class="detail">
      <div class="asset">
        <div class="snippet">
          <span class="type">锁仓数量</span>
          <span class="num">{{
            assetsList.list
              ? assetsList.list[data.itemDetail.name].storage
              : "--"
          }}</span>
        </div>
      </div>
      <div class="asset">
        <div class="snippet">
          <span class="type">冻结</span>
          <span class="num">{{
            assetsList.list ? assetsList.list[data.itemDetail.name].lock : "--"
          }}</span>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="asset">
        <div class="snippet">
          <span class="type">资产CNY</span>
          <span class="num green"
            >≈{{
              assetsList.list ? assetsList.list[data.itemDetail.name].cny : "--"
            }}</span
          >
        </div>
      </div>
      <div class="asset">
        <div class="snippet">
          <span class="type">总量</span>
          <span class="num green">{{
            assetsList.list
              ? assetsList.list[data.itemDetail.name].coin_num
              : "--"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { showMessage } from "@/components/dialog/message";
import routeSplicing from "@/assets/js/routeSplicing";
import { mapGetters } from "vuex";
export default {
  props: ["data", "assetsList"],
  created() {},
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  methods: {
    routeSplicing,
    // 充币
    rechargeClick() {
      if (this.data.itemDetail.in_status === 0) {
        let coinName = this.data.itemDetail.name.toUpperCase();
        // showMessage({ message: coinName+'暂不支持充币，'+coinName+'的资产管理请在“兑换中心”中操作' });
        showMessage({ message: coinName + "暂不支持充币" });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(this.data));
        localStorage.setItem("currentCoin", JSON.stringify(this.data));
        this.$router.push({
          path: this.routeSplicing(
            this.language,
            "ucenter/assets/recharge/" + this.data.itemDetail.name
          ),
        });
      }
    },
    // 提币
    withdrawalClick() {
      if (this.data.itemDetail.out_status === 0) {
        let coinName = this.data.itemDetail.name.toUpperCase();
        // showMessage({ message: coinName+'暂不支持提币，'+coinName+'的资产管理请在“兑换中心”中操作' });
        showMessage({ message: coinName + "暂不支持提币" });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(this.data));
        localStorage.setItem("currentCoin", JSON.stringify(this.data));
        this.$router.push({
          path: this.routeSplicing(
            this.language,
            "ucenter/assets/withdrawal/" + this.data.itemDetail.name
          ),
        });
      }
    },
    getClassName(val) {
      if (val == 0) {
        return "ban";
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.asset-snippet {
  width: 331px;
  height: 240px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 20px;
  margin-right: 12px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid rgba(230, 230, 230, 1);
  [v-cloak] {
    display: none;
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
  &:nth-last-child(3) {
    margin-bottom: 0;
  }
  &:nth-last-child(2) {
    margin-bottom: 0;
  }
  &:last-child {
    margin: 0;
  }

  .coin {
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f3f5f7;
    .coin-info {
      padding-bottom: 0;
    }

    .coin-operate {
      button + button {
        margin-left: 6px;
      }

      .recharge-btn {
        width: 54px;
        // height: 26px;
        color: #27c683;
        text-align: center;
        font-size: 14px;
        // line-height: 26px;
        border: 1px solid #27c683;
        cursor: pointer;
        border-radius: 3px;
        box-sizing: border-box;
        background-color: #ffffff;
        outline: none;
      }
      .recharge-btn:hover {
        background-color: rgba(39, 198, 131, 0.2);
      }
      .ban {
        width: 54px;
        height: 26px;
        color: #b4bac6;
        text-align: center;
        font-size: 14px;
        line-height: 26px;
        display: inline-block;
        border-radius: 3px;
        box-sizing: border-box;
        background-color: #ebeff3;
        border: solid 1px #e4e4e4;
        &:hover {
          background-color: #ebeff3;
        }
      }
    }

    &-info {
      height: 100%;
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }

      h3 {
        color: #354052;
        font-size: 18px;
        margin-left: 12px;
        font-weight: initial;
      }
    }
  }

  .asset {
    margin-top: 10px;

    .snippet {
      display: flex;
      flex-direction: column;
      text-align: left;

      .type {
        color: #b4bac6;
        font-size: 14px;
        text-align: left;
        line-height: 1em;
        margin-bottom: 8px;
      }
      .num {
        color: #354052;
        font-size: 14px;
        text-align: left;
        line-height: 1em;
      }
    }
  }

  .detail {
    display: flex;
    margin-top: 10px;
    .asset {
      flex: 1;
    }
  }

  .green {
    color: #03ad79 !important;
  }
}
</style>
