import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export function apiGetDefaultToken(data = {}) {
  return api({
    url: VUE_APP_APP + `/Publics/getDefaultToken`,
    data,
    type: "POST",
  });
}

export function apiWsUrl(data = {}) {
  return api({
    url: VUE_APP_APP + `/Publics/getWebInitInfo`,
    data,
    type: "POST",
  });
}
// 币币交易
export function apiSwitchCoin(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/switchCoin`,
    data,
    type: "POST",
  });
}
// 限价委托 （触发单）
export function apiLimitOrder(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/limitOrder`,
    data,
    type: "POST",
  });
}
