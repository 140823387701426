import api from "@/assets/js/api";
// const { VUE_APP_UCENTER,VUE_APP_APP } = process.env

import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";
//获取币种详情
export function apiGetCoinInfo(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/App/Product/getCoinInfo`,
    data,
  });
}
//增加行情自选
export function apiAddOption(data = {}) {
  return api({
    url: `${VUE_APP_APP}/Common/addOption`,
    data,
  });
}
//自选
export function apiGetOptionList(data = {}) {
  return api({
    url: `${VUE_APP_APP}/Common/getOptionList`,
    data,
  });
}
//合约获取资金费率
export function apiGetFundingRate(data = {}) {
  return api({
    url: `${VUE_APP_APP}/Contract/getFundingRate`,
    data,
  });
}
//币本位合约获取资金费率
export function apiGetDeliveryFundingRate(data = {}) {
  return api({
    url: `${VUE_APP_APP}/Futures/Trace/getFundingRate`,
    data,
  });
}
