// import Ucenter from "./index";
import Situation from "./situation";
import SecuritySetting from "./security-setting";
import KYCSetting from "./kyc-setting.vue";
// import Assets from "./assets";
import RechargeWithdrawal from "./recharge_withdrawal";
// import Exchange from "./exchange";
// import ExchangeDetail from "./exchange-detail";
// import Address from "./address";
// import FinanceManager from "./financeManager";
// import MyExtend from "./myExtend";
// import Auth from "./auth";
// //import Invite from './invite'
//import InviteStats from './invite-stats'
//import InviteDetail from './invite-detail'
import Contract from "./pages/tradeMoney/contract.vue";
import Transaction from "./pages/tradeMoney/transaction-managment.vue";
import CoinManager from "./pages/tradeManger/coinManager.vue";
import FinanManagement from "./pages/financail-managment/financail-managment";
import CouponCenter from "./pages/coupon-center/coupon-center";
import SpreadCenter from "./pages/spread-center/spread-center";

import BillCenter from "./pages/bill-center/bill-center";
import TradeManagment from "./pages/tradeMoney/transaction-managment.vue";

import Index from "./index.vue";
// 用户中心路由
export default {
  path: "/ucenter",
  redirect: "/ucenter/situation",
  component: Index,

  children: [
    {
      path: "/ucenter/situation/:lang?",
      meta: {
        title: "个人总览",
      },
      component: Situation,
      //   children: [

      //   ],
    },
    {
      path: "/ucenter/safe-setting/:lang?",
      meta: {
        title: "安全设置",
      },
      component: SecuritySetting,
    },
    {
      path: "/ucenter/kyc-setting/:lang?",
      meta: {
        title: "KYC认证",
      },
      component: KYCSetting,
    },

    // 充提币都属于资产下
    {
      name: "recharge",
      meta: {
        title: "充币",
      },
      path: "/ucenter/assets/recharge/:coin/:lang?",
      component: RechargeWithdrawal,
      props: true,
    },
    {
      name: "withdrawal",
      meta: {
        title: "提币",
      },
      path: "/ucenter/assets/withdrawal/:coin/:lang?",
      component: RechargeWithdrawal,
      props: true,
    },
    {
      path: "/ucenter/contract/:lang?",
      meta: {
        title: "合约持仓",
      },
      component: Contract,
    },
    {
      path: "/ucenter/tradeManagment/:lang?",
      meta: {
        title: "交易管理",
      },
      component: Transaction,
    },
    // 资产管理
    {
      path: "/ucenter/assets/:lang?",
      meta: {
        title: "资产管理",
      },
      component: CoinManager,
    },
    // 交易管理
    {
      path: "/ucenter/tradeManagment/:lang?",
      meta: {
        title: "交易管理",
      },
      component: TradeManagment,
    },
    // 理财管理
    {
      path: "/ucenter/finanManagementTemp/:lang?",
      meta: {
        title: "理财管理",
      },
      component: FinanManagement,
    },
    // 金融理财
    {
      path: "/ucenter/finanManagement/:lang?",
      meta: {
        title: "金融理财",
      },
      component: FinanManagement,
    },
    // 推广中心
    {
      path: "/ucenter/spread-center/:lang?",
      meta: {
        title: "推广中心",
      },
      component: SpreadCenter,
    },
    // 卡券中心
    {
      path: "/ucenter/coupon-center/:lang?",
      meta: {
        title: "卡券中心",
      },
      component: CouponCenter,
    },
    // 账单
    {
      path: "/ucenter/bill-center/:lang?",
      meta: {
        title: "账单",
      },
      component: BillCenter,
    },
  ],
};
