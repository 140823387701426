let once = true;
import { arrayBuffer } from "js-md5";
import Pako from "pako";
import BigNumber from "bignumber.js";
export default class Socket {
  constructor(option) {
    this.option = option;
    this.init();
  }
  getInstance() {
    return this.socket;
  }
  sub(data, option = {}) {
    // console.info("socket.readyState:", this.socket.readyState);
    // callback 返回的参数 1.正常 2.断线 3.ws链接异常
    if (!window.navigator.onLine) {
      option.callback && option.callback(2);
      return;
    }
    if (this.socket.readyState == this.socket.OPEN) {
      if (typeof data == "object") {
        this.socket.send(JSON.stringify(data));
      } else {
        this.socket.send(data);
      }
      option.callback && option.callback(1);
    } else {
      option.callback && option.callback(3);
    }
  }
  pingpong() {
    // console.log("this.socket.readyState:", this.socket.readyState);
    this.pingpongTimer = setInterval(() => {
      if (!window.navigator.onLine) {
        this.option.offLineCallback && this.option.offLineCallback();
      }
      if (this.socket.readyState == 1) {
        this.socket.sub("ping");
      } else {
        clearInterval(this.pingpongTimer);
      }
    }, 6 * 1000);
  }
  init() {
    once = true;
    const {
      url,
      // k,
      // m,
      // app_id,
      // to_user_id,
      // request_source
    } = this.option;
    const link = `${url}`;
    this.socket = new WebSocket(link);
    this.socket.onmessage = this.onmessage.bind(this);
    this.socket.onopen = this.onopen.bind(this);
    this.socket.onerror = this.onerror.bind(this);
    this.socket.onclose = this.onclose.bind(this);
    this.socket.sub = this.sub.bind(this);
  }
  // transferToNumber(inputNumber) {
  //   if (isNaN(inputNumber)) {
  //     return inputNumber;
  //   }
  //   inputNumber = "" + inputNumber;
  //   inputNumber = parseFloat(inputNumber);
  //   let eformat = inputNumber.toExponential(); // 转换为标准的科学计数法形式（字符串）
  //   let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/); // 分离出小数值和指数值
  //   let number = inputNumber.toFixed(
  //     Math.max(0, (tmpArray[1] || "").length - tmpArray[2])
  //   );
  //   return number;
  // }
  async judgeWSType(res) {
    let data = null;
    return new Promise((resolve, reject) => {
      if (typeof res.data == "string") {
        if (res.data == "pong") {
          return resolve("--");
        }
        data = JSON.parse(res.data);
        resolve(data);
      } else {
        let reader = new FileReader(); //创建文件类型
        reader.readAsArrayBuffer(res.data); // 启动读取指定的 Blob 读取完成并触发 load 事件 （result是ArrayBuffer类型）
        reader.onload = function() {
          // 用第三方包解压
          let txt = Pako.ungzip(this.result, { to: "string" });
          if (txt == "pong") {
            // 返回错误
            return resolve("--");
          }
          data = JSON.parse(txt);

          function transferToNumber(inputNumber) {
            if (isNaN(inputNumber)) {
              return inputNumber;
            }
            inputNumber = "" + inputNumber;
            inputNumber = parseFloat(inputNumber);
            let eformat = inputNumber.toExponential(); // 转换为标准的科学计数法形式（字符串）
            let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/); // 分离出小数值和指数值
            let number = inputNumber.toFixed(
              Math.max(0, (tmpArray[1] || "").length - tmpArray[2])
            );
            return number;
          }

          if (data.data.data && data.data.field) {
            //此处针对 ws2 =>ws3 数据格式转化
            function formattedArrayData(data, name) {
              //   console.log("name:", name, data);
              let arr = [];
              let copyArr = [];
              const title = data.field.split(",");
              data["data"].forEach((item, i) => {
                const line = item.split(",");
                arr[i] = new Object();
                arr[i]["currency"] = new Object();
                title.forEach((m, n) => {
                  arr[i][m] = transferToNumber(line[n]);
                  arr[i]["currency"][m] = transferToNumber(line[n]);
                });
              });
              copyArr = arr;
              return copyArr;
            }
            let bease = data;
            bease.data = formattedArrayData(data.data, bease.action);
            return resolve(bease);
          }
          resolve(data);
        };
        reader.onerror = function(e) {
          reject(e);
        };
      }
    });
  }

  async onmessage(res) {
    const data = await this.judgeWSType(res);
    if (data == "--") {
      return;
    }
    // console.log("-->--:", data);
    this.option.callback && this.option.callback(data);
    if (once) {
      this.option.connectSuccessCallback &&
        this.option.connectSuccessCallback(data);
    }
    once = false;
  }
  onopen() {
    this.option.mounted && this.option.mounted();
    this.socket.sub(this.option.subData);
    this.pingpong();
  }
  onerror() {
    console.info("onerror");
    this.option.onConnect();
  }
  onclose() {
    console.info("onclose");
    this.option.onConnect();
  }
}
