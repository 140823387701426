<template>
  <div>
    <home-container></home-container>
  </div>
</template>

<script>
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import HomeContainer from "./components/home-container.vue";
export default {
  components: {
    HomeContainer,
  },

  data() {
    return {
      VUE_APP_ID,
    };
  },
  computed: {},
  created() {},
};
</script>

<style lang="scss" scoped>
* {
  user-select: none;
  word-break: break-word;
}
</style>

