<template>
  <div class="kyc-setting">
    <div class="el-step">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step :title="translateTitle('身份信息')"></el-step>
        <el-step :title="translateTitle('证件照片')"></el-step>
        <el-step :title="translateTitle('视频认证')"></el-step>
      </el-steps>
    </div>
    <el-form
      class="g-form-v2"
      label-position="top"
      ref="dBindForm"
      :rules="formRules"
      :model="formData"
    >
      <div v-if="active == 0">
        <el-form-item prop="ssc_id" :label="translateTitle('国家/地区')">
          <el-select
            id="areaSelect"
            v-model="formData.ssc_id"
            :no-match-text="translateTitle('无搜索项，请重新搜索')"
            filterable
            :placeholder="translateTitle('请选择国家/地区')"
          >
            <el-option
              v-for="(item, index) in all_areacode"
              :key="index"
              :value="item.ssc_id"
              :label="item[language]"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="card_type" :label="translateTitle('证件类型')">
          <el-select
            v-model="formData.card_type"
            filterable
            :no-match-text="translateTitle('无搜索项，请重新搜索')"
            :placeholder="translateTitle('请选择证件类型')"
          >
            <el-option
              v-for="(k, v) in card_type_list"
              :key="k + v"
              :value="v"
              :label="k"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="full_name"
          :label="translateTitle('姓名')"
          class="mobile"
        >
          <el-input
            v-model="formData.full_name"
            :placeholder="translateTitle('请输入姓名')"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item prop="passport" :label="translateTitle('证件编号')">
          <el-input
            v-model="formData.passport"
            :placeholder="translateTitle('请输入证件编号')"
            maxlength="30"
          ></el-input>
        </el-form-item>
      </div>
      <div v-if="active == 1">
        <div>
          <p>{{ translateTitle("证件信息") }}</p>
          <div class="uploadkung">
            <div class="upload">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :on-success="handleAvatarSuccessFront_photo"
                :http-request="picUploadFront"
                :before-upload="beforeAvatarUpload"
                :on-error="errorUpload"
              >
                <img v-if="front_photo" :src="front_photo" class="avatar" />
                <div v-else class="shang">
                  <img src="@/assets/img/uld.png" alt="上传" />
                  <span>{{ translateTitle("上传证件正面") }}</span>
                </div>
              </el-upload>
            </div>
            <div class="upload">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :on-success="handleAvatarSuccessBack_photo"
                :http-request="picUploadBack"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="back_photo" :src="back_photo" class="avatar" />
                <div v-else class="shang">
                  <img src="@/assets/img/uld.png" alt="上传" />
                  <span>{{ translateTitle("上传证件背面") }}</span>
                </div>
                <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
              </el-upload>
            </div>
          </div>
        </div>
        <div>
          <p>{{ translateTitle("手持证件信息") }}</p>
          <div class="uploadkung">
            <div class="upload">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :on-success="handleAvatarSuccessHold_photo"
                :http-request="picUploadHold"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="hold_photo" :src="hold_photo" class="avatar" />
                <div v-else class="shang">
                  <img src="@/assets/img/uld.png" alt="上传" />
                  <span>{{ translateTitle("上传手持证件照片") }}</span>
                </div>
              </el-upload>
            </div>
            <div class="upload-shang">
              <img src="@/assets/img/shili1.png" alt="上传" />
              <span>{{ translateTitle("手持证件照示例图") }}</span>
            </div>
          </div>
        </div>
        <div class="tishi">
          <p>
            {{
              translateTitle(
                "请确保照片”人脸与手持证件”清晰。避免照片模糊与反光导致认证失败！拍摄时请确保证件照边框完整，字体清晰，亮度均匀！"
              )
            }}
          </p>
          <ul>
            <li>
              <img src="@/assets/img/zqsl.png" alt="" />
              <i class="el-icon-check"></i>
              <span>{{ translateTitle("正确实例") }}</span>
            </li>
            <li>
              <img src="@/assets/img/mbk.png" alt="" />
              <i class="el-icon-close"></i>
              <span>{{ translateTitle("边框缺失") }}</span>
            </li>
            <li>
              <img src="@/assets/img/mht.png" alt="" />
              <i class="el-icon-close"></i>
              <span>{{ translateTitle("照片模糊") }}</span>
            </li>
            <li>
              <img src="@/assets/img/fan.png" alt="" />
              <i class="el-icon-close"></i>
              <span>{{ translateTitle("反光强烈") }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="active == 2 || active == 3">
        <div>
          <p>{{ translateTitle("视频信息") }}</p>
          <div class="uploadvloadkung">
            <div>
              <div class="upload">
                <el-upload
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccessVideo"
                  :http-request="picUploadVideo"
                  :before-upload="beforeAvatarUploadVideo"
                >
                  <video
                    v-if="hold_video"
                    autoplay
                    width="240px"
                    height="168px"
                    class="video img-kyc"
                    controls
                  >
                    <source :src="hold_video" type="video/mp4" />
                  </video>
                  <!-- <img v-if="hold_video" :src="hold_video" class="avatar" /> -->
                  <div v-else class="shang">
                    <img src="@/assets/img/sprz.png" alt="上传" />
                    <span>{{ translateTitle("点击上传视频") }}</span>
                  </div>
                </el-upload>
              </div>
              <!-- <img src="@/assets/img/sprz.png" alt="" /> -->
            </div>
            <p>
              {{ translateTitle("请手持证件照对准手机，读出")
              }}<span class="font-color"> {{ this.formData.rand_no }} </span
              >{{ translateTitle("录制视频并上传") }}
            </p>
            <div class="viod-upload">
              <ul>
                <li>
                  <i class="el-icon-warning"></i>
                  <span>{{ translateTitle("光线充足") }}</span>
                </li>
                <li>
                  <i class="el-icon-warning"></i>
                  <span>{{ translateTitle("避免逆光") }}</span>
                </li>
                <li>
                  <i class="el-icon-warning"></i>
                  <span>{{ translateTitle("保持网络通畅") }}</span>
                </li>
                <li>
                  <i class="el-icon-warning"></i>
                  <span>{{ translateTitle("避免复杂环境拍摄") }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="footer">
      <el-button class="common-cancel" @click="onCancel">
        {{ active == 0 ? translateTitle("取消") : translateTitle("上一步") }}
      </el-button>
      <el-button class="kyc-save" @click="onSubmit" :loading="btnLoading">
        {{ active >= 2 ? translateTitle("提交") : translateTitle("继续") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { showMessage } from "@/components/dialog/message";
import { apiGetStaticTableInfo, SETTING_SV_ID } from "@/model/settings";
import { mapActions, mapGetters } from "vuex";
import { checkMobile } from "@/assets/js/checkInput";
import commonMixin from "@/components/common/commonMixin.vue";
import globalFun from "@/assets/js/globalFun.js";
import getSign from "@/assets/js/getSign";
const md5 = require("md5");
import {
  VUE_APP_UCENTER,
  VUE_APP_ID,
  VUE_APP_KEY,
} from "@/assets/js/stationSetting";
import * as Common from "@/model/common";
export default {
  mixins: [commonMixin],
  props: [],
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  data() {
    const validatorMobild = (rule, value, callback) => {
      const result = checkMobile(value);
      if (result && result.tip) {
        callback(new Error(result.tip));
      } else {
        callback();
      }
    };
    return {
      VUE_APP_UCENTER,
      isFetchCode: false,
      countDown: -1, // 倒计时
      active: 0,
      dsabled: false,
      countDownTimer: null, // 倒计时容器
      confirmLoading: false,
      btnLoading: false,
      isMobil: false, // 是否用于手机号输入框
      all_areacode: {}, // 所有区号
      formData: {
        area: "",
        mobile: "",
        email: "",
        mobile_code: "",
        email_code: "",
      },
      card_type_list: {
        1: this.translateTitle("身份证"),
        2: this.translateTitle("护照"),
        3: this.translateTitle("驾照"),
        4: this.translateTitle("绿卡"),
      },
      imageUrl: "",
      formRules: {
        ssc_id: [
          {
            required: true,
            message: this.translateTitle(`请选择国家/地区`),
            trigger: "change",
          },
        ],
        passport: [
          {
            required: true,
            message: this.translateTitle(`请输入证件号码`),
            trigger: "change",
          },
        ],
        card_type: [
          {
            required: true,
            message: this.translateTitle(`请选择证件类型`),
            trigger: "change",
          },
        ],
        full_name: [
          {
            required: true,
            message: this.translateTitle(`请输入姓名`),
            trigger: "change",
          },
        ],
      },
      checkPwdStatus: false,
      isShowGeet: false,
      geetParam: null,
      hold_photo: "",
      front_photo: "",
      back_photo: "",
      hold_video: "",
      globalFun,
    };
  },
  async created() {
    await this.getAreaCode();
    await this.getNumRand();
  },
  mounted() {
    this.dom();
  },
  methods: {
    errorUpload(a, b, c) {
      this.front_photo = "";
    },
    async getNumRand() {
      const { status, data, msg } = await Common.getRandNo();
      this.formData.rand_no = data?.rand_no;
    },
    handleAvatarSuccessBack_photo(res, file) {
      this.back_photo = URL.createObjectURL(file.raw);
    },
    async picUploadBack({ file }) {
      this.rulseUpLoad(file, "back_photo");
    },
    handleAvatarSuccessFront_photo(res, file) {
      this.front_photo = URL.createObjectURL(file.raw);
    },
    async picUploadFront({ file }) {
      this.rulseUpLoad(file, "front_photo");
    },
    picUploadVideo({ file }) {
      this.rulseUpLoad(file, "hold_video", 1);
    },
    handleAvatarSuccessHold_photo(res, file) {
      this.hold_photo = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccessVideo(res, file) {
      this.hold_video = URL.createObjectURL(file.raw);
    },
    async picUploadHold({ file }) {
      this.rulseUpLoad(file, "hold_photo");
    },
    beforeAvatarUpload(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.uploadImgLoading = false;
        showMessage({ message: this.translateTitle("上传图片不能大于10M") });
        return false;
      }
      if (
        [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/bmp",
          "image/jp2",
          "image/wbmp",
        ].indexOf(file.type) < 0
      ) {
        this.uploadImgLoading = false;
        showMessage({
          message: this.translateTitle(
            "只支持上传 jpg / jpeg/ png / gif / bmp / jp2 / wbmp 类型图片"
          ),
          duration: 2000,
        });
        return false;
      }
    },
    beforeAvatarUploadVideo(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.uploadImgLoading = false;
        showMessage({ message: this.translateTitle("上传视频不能大于10M") });
        return;
      }
      if (
        ["application/pdf", "video/mp4", "video/quicktime"].indexOf(file.type) <
        0
      ) {
        this.uploadImgLoading = false;
        showMessage({
          message: this.translateTitle("只支持上传 mp4 / mov 类型"),
          duration: 2000,
        });
        return false;
      }
      this.hold_video = URL.createObjectURL(file);
    },
    async rulseUpLoad(file, value, type) {
      const token = window.$.cookie("topcredit_exchange_t") || "";
      let formData = new window.FormData();
      formData.append("app_id", VUE_APP_ID);
      formData.append("file", file);
      formData.append("token", token);
      var timestamp = Math.round(new Date().getTime() / 1000) + "";
      const nonce = timestamp.substr(-6);
      formData.append("nonce", nonce);
      function getSign1(params, app_id) {
        var appkey = VUE_APP_KEY;
        params["app_id"] = app_id;
        for (let key in params) {
          params[key] =
            params[key] || params[key] === 0 || params[key] === false
              ? params[key]
              : "";
        }
        let keys = Object.keys(params);
        let length = keys.length;
        keys = keys.sort();
        let sign = "";
        for (let i = 0; i < length; i++) {
          if (sign != "") sign = sign + "&";
          sign = sign + keys[i] + "=" + params[keys[i]];
        }
        //add appkey
        sign = sign + appkey;
        sign = md5(sign).toString();

        return sign;
      }
      formData.append(
        "_CDCODE",
        getSign1({ token, app_id: VUE_APP_ID, nonce }, VUE_APP_ID)
      );
      if (!type) {
        this.btnLoading = true;
        const { status, data, msg } = await Common.kycFileUpload(formData);
        this.btnLoading = false;
        if (data.status == 200) {
          const url = data.data.url;
          this.formData[value] = data.data.url;
          showMessage({
            message: this.translateTitle(`图片上传成功`),
            type: "success",
          });
          this.uploadImgLoading = false;

          // 貼上圖片url
        } else {
          showMessage({ message: data.msg });
          this.uploadImgLoading = false;
        }
        return;
      }
      this.btnLoading = true;
      const { status, data, msg } = await Common.kycFileUploadVideo(formData);
      this.btnLoading = false;
      if (data.status == 200) {
        const url = data.data.url;
        this.formData[value] = data.data.url;
        this.hold_video = data.data.url;
        showMessage({
          message: this.translateTitle(`视频上传成功`),
          type: "success",
        });
        this.uploadImgLoading = false;
        // 貼上圖片url
      } else {
        showMessage({ message: data.msg });
        this.uploadImgLoading = false;
      }
      return;
    },

    dom() {
      if (this.isMobile) {
        const input = document.querySelector("#areaSelect");
        input.setAttribute("maxLength", 5);
      }
    },
    async onSubmit() {
      if (this.active == 0) {
        this.$refs.dBindForm.validate((valid) => {
          if (!valid) {
            return;
          }
          this.active = 1;
        });
        return;
      }
      if (this.active == 1) {
        if (
          !(
            this.formData.hold_photo &&
            this.formData.front_photo &&
            this.formData.back_photo
          )
        ) {
          showMessage({
            message: this.translateTitle("请上传证件信息和手持证件照片"),
          });
          return;
        }
        this.active = 2;
        return;
      }
      if (this.active == 2) {
        if (!this.formData.hold_video) {
          showMessage({ message: this.translateTitle("请上传视频") });
          return;
        }
        this.active = 3;
      }
      if (this.active == 3) {
        this.btnLoading = true;
        const { data, status } = await Common.apiApplyKyc({ ...this.formData });
        this.btnLoading = false;
        if (status == 200) {
          this.$emit("update-success");
        }
      }
    },

    // 清除验证码
    clearCode() {
      this.formData.mobile_code = "";
      this.formData.email_code = "";
    },
    // 获取用户信息
    async getUserInfo() {},
    // 获取手机区号
    async getAreaCode() {
      await apiGetStaticTableInfo(SETTING_SV_ID.static_sms_country).then(
        (res) => {
          if (res.status === 200) {
            this.all_areacode = res.data;
          } else {
            showMessage({ message: res.msg });
          }
        }
      );
    },
    onCancel() {
      if (this.active == 0) {
        this.$emit("update-cancel");
      }
      if (this.active != 0) {
        this.active--;
      }
    },
    changePwdStatus(status) {
      return status;
    },
    filterData(val) {
      var str = this.$refs.searchSelect.$data.selectedLabel;
      if (str.length > 5) {
        this.$refs.searchSelect.$data.selectedLabel = str.substr(0, 4);
      }
    },
    success(geetParam) {
      this.geetParam = geetParam;
      this.getCode();
    },
    // 发送成功
    submitForm() {},
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.countDownTimer);
    next();
  },
};
</script>
<style lang="scss">
.kyc-setting {
  input:focus {
    border-color: #c61b3d;
  }

  .el-step {
    margin: 10px auto 0 auto;
    width: 530px;
  }

  .el-step__title {
    font-size: 14px !important;
  }

  .el-select {
    width: 100%;
  }

  .font-color {
    color: #165ad4;
  }

  .el-step__icon {
    width: 22px;
    height: 22px;
  }

  .el-step__line {
    height: 1px !important;
  }

  .kyc-save {
    width: 160px;
    height: 40px;
    border-radius: 3px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    padding: 0 !important;
    font-size: 14px;
    background-image: linear-gradient(74deg, #204daa 21%, #165ad4 82%);
  }

  .el-form-item {
    position: relative;
    margin-bottom: 10px !important;
  }

  .uploadkung {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;

    .upload-shang {
      width: 240px;
      height: 148px;
      position: relative;

      span {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%);
        color: #165ad4;
      }
    }

    .upload {
      width: 240px;
      height: 148px;
      padding: 18px 28px;
      border-radius: 4px;
      background-color: #f3f5f6;

      .avatar-uploader {
        border: dashed 1px #165ad4;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-align: center;

        .shang {
          margin-top: 20px;
          text-align: center;

          img {
            width: 48px;
            display: block;
            margin: 0px auto;
          }

          span {
            text-align: center;
            font-size: 14px;
            display: inline-block;
            padding: 0 10px;
            font-weight: 500;
            color: #165ad3;
          }
        }
      }
    }
  }

  .tishi {
    padding: 10px 50px;

    p {
      font-size: 12px;
      line-height: 1.4;
      color: #9aa1b1;
      max-width: 385px;
    }

    ul {
      margin-top: 10px;
      display: flex;

      li {
        margin-right: 33px;

        img {
          width: 65.9px;
          display: block;
        }

        i {
          display: block;
          margin: 6px auto;
          font-weight: bold;
          color: #f04a5a;
          text-align: center;
        }

        &:first-child {
          i {
            color: #69cc83;
          }
        }

        span {
          width: 100%;
          display: inline-block;
          text-align: center;
          font-size: 12px;
          color: #9aa1b1;
        }
      }
    }
  }

  .uploadvloadkung {
    text-align: center;

    img {
      width: 240px;
      display: block;
      margin: 0 auto;
    }

    p {
      margin-top: 20px;
      font-size: 14px;
      text-align: center;
      color: #354052;
    }

    .viod-upload {
      text-align: center;

      ul {
        // display: flex;
        display: inline-block;
        margin: 20px auto;
        text-align: center;

        li {
          display: inline-block;
          margin-right: 10px;

          span {
            color: #9aa1b1;
          }

          i {
            color: #9aa1b1;
          }
        }
      }
    }
  }

  .footer {
    text-align: center;
    margin-top: 40px;
  }
}
</style>
