<template>
  <div class="register-panel__container">
    <div class="register-panel__container-content">
      <div class="register-panel__container-content-title">
        {{ translateTitle("即刻注册4E平台，追逐投资热点！") }}
      </div>
      <div class="register-panel__container-content-inputs">
        <input
          class="register-panel__container-content-inputs-input"
          v-model="registerInput"
          :placeholder="translateTitle('邮箱/手机号')"
          placeholder-class="input-placeholder"
        />
        <span
          @click="onRegister(2)"
          class="register-panel__container-content-inputs-btn"
          >{{ translateTitle("立即注册") }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      registerInput: "",
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    onRegister(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.registerInput;
      } else {
        inputVal = this.registerInput;
      }
      var emailReg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      );
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      // 埋点
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal,
        },
        query: {
          ...this.$route.query,
        },
      });

      this.$sensors.track("Register_Click", {
        invite_code: "",
        channel_type: "自然流量",
        second_channel_type: "WEB",
      });
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  .register-panel__container {
    .register-panel__container-content-inputs-input {
      border: 1px solid #ddd;
    }
  }
}
.register-panel__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: var(--homeBgLightColor); */
  color: var(--homeFontColor);
  &-content {
    width: 1200px;
    /* padding: 100px 0; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 48px; /* 120% */
      margin-bottom: 32px;
      text-align: center;
    }
    &-inputs {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      &-input {
        width: 336px;
        height: 52px;
        border-radius: 30px;
        padding: 0 20px;
        font-size: 24px;
        border-radius: 66px;
        margin-right: 16px;
        border: 1px solid #ddd; //var(--homeFontColor); var(--homeGrayBorder);
        color: var(--homeFontColor);
        padding-bottom: 6px;
        &:focus,
        &:hover {
          border: 2px solid var(--homeLightBtnBorder);
        }
        &::placeholder {
          color: var(--Light04-text-c_t03, #8b909c);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px; /* 131.25% */
        }
      }
      &-btn {
        display: flex;
        min-width: 120px;
        line-height: 52px;

        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
        border-radius: 66px;
        background: var(--Light04-text-c_m, #3348f6);
        &:hover {
          background: var(--blueBtnHover);
        }
      }
    }
  }
}
</style>
