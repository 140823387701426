<template>
  <div class="contract-component-deal">
    <div v-if="showTitle" class="depth-header">
      {{ translateTitle("实时成交") }}
    </div>
    <div class="depth-content">
      <ul class="depth-sub-title">
        <li class="time">{{ translateTitle("时间") }}</li>
        <li class="price">
          {{ translateTitle("价格") }}({{ to_symbol_show }})
        </li>
        <li class="num">
          {{ translateTitle("数量") }}({{ from_symbol_show }})
        </li>
      </ul>
      <ul class="depth-sub-content" v-loading="loading">
        <li v-for="(item, index) in showList" v-bind:key="index">
          <div class="time DINPro" v-if="item.p == '--'">--</div>
          <div class="time DINPro" v-else>{{ getValueTime(item.T) }}</div>
          <div
            class="price DINPro"
            :class="Number(item.m) == 1 ? 'green' : 'red'"
          >
            {{ item.p }}
          </div>
          <div class="num DINPro">{{ item.q }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getValueTime } from "@/assets/js/getTime";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  props: {
    orderbookList: {
      type: Array,
      default: function() {
        return [
          { T: "--", p: "--", q: "--" },
          { T: "--", p: "--", q: "--" },
          { T: "--", p: "--", q: "--" },
          { T: "--", p: "--", q: "--" },
          { T: "--", p: "--", q: "--" },
        ];
      },
    },
    showTitle: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
    swap_symbol_id: {
      type: [String, Number],
    },
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    from_symbol: {
      type: [String, Number],
      default: function() {
        return "";
      },
    },
    to_symbol: {
      type: [String, Number],
      default: function() {
        return "";
      },
    },
  },
  methods: {
    getValueTime,
  },
  computed: {
    loading() {
      let val = 1;
      if (this.orderbookList[0]) {
        if (this.orderbookList[0].T == "--") {
          val = 1;
        } else {
          val = 0;
        }
      } else {
        val = 0;
      }
      return val;
    },
    showList() {
      return this.orderbookList;
    },
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.contract-component-deal {
  user-select: none;
  .depth-header {
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    background-color: #f5f5f7;
    font-size: 14px;
    font-weight: 600;
    color: #1f2533;
    padding-left: 14px;
  }
  .depth-content {
    .depth-sub-title {
      padding: 0 13px 0 16px;
      display: flex;
      color: #7f8490;
      font-size: 12px;
      font-weight: 500;
      height: 36px;
      line-height: 36px;
      .time {
        width: 65px;
      }
      .price {
        color: #7f8490;
        width: 110px;
        text-align: right;
      }
      .num {
        width: 124px;
        text-align: right;
      }
    }
    .depth-sub-content {
      padding: 0 13px 4px 16px;
      li {
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        .time {
          width: 65px;
          color: #1f2533;
        }
        .price {
          width: 110px;
          text-align: right;
          &.red {
            color: #c61b3d;
          }
          &.green {
            color: #03ad79;
          }
        }
        .num {
          width: 124px;
          color: #354052;
          text-align: right;
        }
      }
    }
  }
}
</style>
