<template>
  <div class="content-title">
    <h2>{{ title }}</h2>
    <slot name="view"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  user-select: none;
  // border-radius: 4px;
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: #354052;
    text-align: left;
    height: 80px;
    line-height: 80px;
    padding-left: 24px;
    // padding: 21px 0 21px 24px;
  }
}
</style>
