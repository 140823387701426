<template>
  <div class="countdown">
    <slot v-if="format" :time="formatCountdown(timeDiff)"></slot>
    <slot v-else :time="timeDiff"></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    time: {
      type: Number,
      default: function() {
        return 60;
      }
    },
    step: {
      type: Number,
      default: function() {
        return 1;
      }
    },
    switch: {
      type: Boolean,
      default: function() {
        return null;
      }
    },
    format: {
      type: String
    }
  },
  data() {
    return {
      timeDiff: 0,
      timer: null
    };
  },
  watch: {
    time: {
      handler: "onTimeChange",
      immediate: true
    },
    switch: {
      handler: "onSwitchChange"
    }
  },
  methods: {
    computedStep() {
      return this.step * 1000;
    },
    onTimeChange(val) {
      this.timeDiff = val;
      const isSwitch = typeof this.switch === "boolean";
      if (val && !this.timer) {
        if (isSwitch) {
          if (this.switch) {
            this.triggerTimer();
          } else {
            this.clearTimer();
          }
        } else {
          this.triggerTimer();
        }
      } else {
        this.clearTimer();
      }
    },

    onSwitchChange(val) {
      if (val) {
        this.triggerTimer();
      } else {
        this.clearTimer();
      }
    },

    beforeDestroy() {
      this.clearTimer();
    },

    // 清除定时器
    clearTimer() {
      // if (this.timer) clearTimeout(this.timer);
    },

    // 触发倒计时函数
    triggerTimer() {
      this.timer = setTimeout(() => {
        this.timeDiff--;
        if (this.timeDiff <= 0) {
          this.$emit("on-end");
        } else {
          this.$emit("on-countdown", this.timeDiff);
          this.triggerTimer();
        }
      }, this.computedStep());
    },

    // 格式化时间戳
    formatCountdown(timeDiff) {
      // 获取还剩多少小时
      const hour = parseInt((Number(timeDiff) / 60 / 60).toString());
      // 获取还剩多少分钟
      let minute;
      if (this.format.includes("hh") || this.format.includes("HH")) {
        minute = parseInt(((Number(timeDiff) / 60) % 60).toString());
      } else {
        minute = parseInt((Number(timeDiff) / 60).toString());
      }
      // 获取还剩多少秒
      let second;
      if (this.format.includes("mm") || this.format.includes("mm")) {
        second = Number(timeDiff) % 60;
      } else {
        second = timeDiff;
      }
      let result = this.format;
      result = result.replace(/(hh|HH)/g, this.paddingZero(hour));
      result = result.replace(/(mm|MM)/g, this.paddingZero(minute));
      result = result.replace(/(ss|ss)/g, this.paddingZero(second));
      return result;
    },

    // 补零
    paddingZero(val) {
      if (val <= 0) {
        return "00";
      } else if (val < 10) {
        return `0${val}`;
      } else {
        return val.toString();
      }
    },

    // 重新倒计时
    reCountdown() {
      this.timeDiff = this.time;
    }
  }
};
</script>

<style lang="scss" scoped>
.countdown {
  display: inline-block;
}
</style>
