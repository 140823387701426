<template>
  <div class="super-lever">
    <div class="trade-container">
      <div class="header">
        <div class="title">合约</div>
        <div class="profit-content">
          <div class="left item">
            <div class="sub-title">持仓估值</div>
            <div class="profit">
              <span>{{ contractMap.u_valuation }} </span>
              <span class="unit">USDT</span>
              <span> ≈ </span>
              <span>55550.00 </span>
              <span class="unit">CNY</span>
            </div>
          </div>
          <div class="bottom item">
            <div class="sub-title">当前盈亏</div>
            <div class="profit">
              <span>{{contractMap.current_profit}} </span>
              <span class="unit">USDT</span>
              <!-- <span> ≈ </span>
              <span>55550.00 </span>
              <span class="unit">CNY</span> -->
            </div>
          </div>
          <div class="right item">
            <div class="sub-title">盈亏率</div>
            <div class="profit">
              <span>{{ contractMap.profit_rate }} </span>
              <span class="unit">USDT</span>
              <!-- <span> ≈ </span>
              <span>55550.00 </span>
              <span class="unit">CNY</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="trade-content">
        <div class="nav">
          <div class="list-type">
            <div
              v-for="item in listType"
              :key="item.val"
              class="tab"
              :class="{ active: item.val === active }"
              @click="onTypeChange(item.val)"
            >
              <span>{{ item.text }}</span>
              <i class="type-indicator"></i>
            </div>
          </div>
        </div>
        <div class="content">
          <position v-if="active == 1"></position>
          <record v-if="active == 2"></record>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Position from "./components/contract/position";
import Record from "./components/contract/record.vue";
import { apiGetAssets } from "@/model/exchange/index";
export default {
  data() {
    return {
      active: 1,
      contractMap: {},
      listType: [
        { text: "当前持仓", val: 1 },
        { text: "交易记录", val: 2 },
      ],
    };
  },
  components: {
    Position,
    Record,
  },
  created() {
    this.getAssets();
    this.startAssetsTimer();
  },
  methods: {
    onTypeChange(val) {
      this.active = val;
    },
    startAssetsTimer() {
      this.getAssets().then(() => {
        const timer = setTimeout(() => {
          this.startAssetsTimer();
        }, 500);
        this.$once("hook:beforeDestroy", () => {
          clearTimeout(timer);
        });
      });
    },
    async getAssets() {
      // if (!this.userInfo) {
      //   return;
      // }
      let params = {
        type: "all",
      };
      const { status, data } = await apiGetAssets(params);
      if (status == 200) {
        this.contractMap = data.contract;
      }
    },
  },
};
</script>
<style lang='scss'>
.super-lever {
  .trade-container {
    .header {
      // width: 1035px;
      height: 160px;
      border-radius: 4px;
      border: solid 1px #e6e6e6;
      background-color: #ffffff;
      padding: 1px 20px 23px;
      .title {
        margin-right: 20px;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
        border-bottom: solid 1px #f3f5f8;
        box-sizing: border-box;
      }
      .profit-content {
        height: 99px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .item {
          width: 330px;
          height: 60px;
          .sub-title {
            font-size: 14px;
            color: #354052;
            font-weight: 400;
            height: 30px;
            line-height: 30px;
          }
          .profit {
            color: #354052;
            font-size: 18px;
            font-weight: 500;
            .unit {
              font-size: 14px;
            }
          }
        }
      }
    }
    .trade-content {
      margin-top: 10px;
      // width: 1035px;
      height: 790px;
      //   padding: 1px 20px 23px;
      border-radius: 4px;
      border: solid 1px #e6e6e6;
      background-color: #ffffff;
      .nav {
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-size: 14px;
        .list-type {
          display: flex;
          .tab {
            position: relative;
            padding: 19px 11px 10px 22px;
            color: #959ba7;
            line-height: 22px;
            cursor: pointer;
            span {
              font-size: 16px;
              font-weight: 400;
            }
            &:hover {
              color: #354052;
              &:after {
                content: "";
                height: 2px;
                width: 80%;
                background-color: #c61b3d;
              }
            }
            &.active {
              color: #354052;
              font-weight: 400;
              font-size: 16px;
              &:after {
                content: "";
                height: 3px;
                width: 20px;
                background-color: #c61b3d;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
      .content {
        margin-top: 16px;
      }
    }
  }
}
</style>