<template>
  <div class="contract-order">
    <div class="common-filter-box">
      <el-select
        class="common-select"
        v-model="queryList.time"
        collapse-tags
        clearable
        size="medium"
        style="width: 147px; font-size: 12px"
        placeholder="时间"
      >
        <el-option
          v-for="item in timeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        class="common-select"
        v-model="queryList.item"
        collapse-tags
        clearable
        size="medium"
        style="width: 147px; font-size: 12px"
        placeholder="币种"
      >
        <el-option
          v-for="item in itemList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        class="common-select"
        v-model="queryList.currency"
        collapse-tags
        clearable
        size="medium"
        style="width: 147px; font-size: 12px"
        placeholder="币种"
      >
        <el-option
          v-for="item in currencyList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        class="common-select"
        v-model="queryList.side"
        collapse-tags
        clearable
        size="medium"
        style="width: 147px; font-size: 12px"
        placeholder="方向"
      >
        <el-option
          v-for="item in sideOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        class="common-query-button"
        style="margin-left: 10px"
        :loading="queryLoading"
        @click="onQuery"
        >查询</el-button
      >
      <el-button
        class="common-reset-button"
        style="margin-left: 10px"
        @click="onReset"
        :loading="restLoading"
        >重置</el-button
      >
    </div>
    <div style="min-height: 528px" v-loading="tableLoading">
      <div class="common-table scrollbar-default-show">
        <el-table
          class="el-table data-view-table scrollbar-default-show"
          :data="tableList"
          row-class-name="row-table"
          v-show="!tableLoading"
        >
          <el-table-column
            align="left"
            prop="create_time"
            label="时间"
            min-width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="方向"
            min-width="60"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="交易对"
            min-width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="杠杆倍数"
            min-width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="本金"
            min-width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="平仓盈亏"
            min-width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="平仓盈亏率"
            min-width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="成交均价"
            min-width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="成交数量"
            min-width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="成交手续费"
            min-width="90"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="余额归还"
            min-width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="agent_name"
            label="订单"
            min-width="80"
          ></el-table-column>

          <no-data
            slot="empty"
            style="display: none"
            v-show="!tableLoading"
          ></no-data>
        </el-table>
      </div>
      <div class="pages" v-if="pageInfo.pageCount && !tableLoading">
        <p>
        {{translateTitle('每页')}}
        <span>{{ pageInfo.pageSize }}</span>
        {{translateTitle('条，总共')}}
        <span>{{ pageInfo.pageCount }}</span>
        {{translateTitle('页')}}
      </p>
        <el-pagination
          class="mt20"
          background
          layout="prev, pager, next"
          :total="pageInfo.pageTotal"
          :page-size="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageCurrent"
          @current-change="onPageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import NoData from "@/components/common/NoData";
export default {
  data() {
    return {
      queryList: {
        time: "",
        side: "",
        currency: 10007,
        item: "",
      },
      tableList: [],
      sideOptions: [
        { value: "", label: "全部方向" },
        { value: "1", label: "买涨" },
        { value: "2", label: "买跌" },
      ],
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 4,
        pageCurrent: 1,
      },
      itemList: [],
      timeList: [
        { value: "", label: "全部" },
        { value: "1", label: "今天" },
        { value: "2", label: "7天" },
        { value: "3", label: "1月" },
        { value: "4", label: "3月" },
      ],
      currencyList: [{ value: 10007, label: "USDT" }],

      queryLoading: false,
      restLoading: false,
      tableLoading: false,
    };
  },
  components: { NoData },
  methods: {
    onReset() {},
    onQuery() {},
    onPageChange() {},
  },
};
</script>
<style lang="scss">
.contract-order {
  margin: 0px 20px;
  .el-table {
    .el-table__body-wrapper {
      min-height: 560px !important;
    }
  }
  .pages {
    margin: 0px 20px;
    border-top: 1px solid #f3f5f8;
  }
  .no-data-box{
   min-height: 623px !important;
  }
}
</style>