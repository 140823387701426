<template>
  <button :class="{
    plain: plain
  }" @click="onClick">{{ text }}</button>
</template>
<script>
export default {
  props: {
    text: String,
    plain: Boolean,
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>
<style lang="scss" scoped>
button {
  color: #354052;
  width: 82px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border-radius: 2px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #27c683;
  transition-duration: 0.2s;
  cursor: pointer;
  outline: none;
 
}


</style>