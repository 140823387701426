<template>
  <div class="stock-component-market">
    <div class="market-header">
      <ul>
        <li
          class="classify-area"
          :class="{ active: currIndex == item.value }"
          v-for="item of tabList"
          :key="item.value"
          @click="onAreaChange(item)"
        >
          {{ translateTitle(item.label) }}
          <i v-if="currIndex == item.value" class="item-bar"></i>
        </li>
      </ul>
      <div class="stock-images">
        <img class="stock-market-img" src="../../../assets/img/stock_us.png" />
        <img class="stock-market-img" src="../../../assets/img/stock_hk.png" />
      </div>
    </div>
    <div class="select-symbol">
      <el-input
        v-model="selectSymbol"
        maxlength="15"
        :placeholder="translateTitle('搜索')"
        clearable
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <div class="stock-radios">
        <el-radio v-model="stockType" label="1">涨跌</el-radio>
        <el-radio v-model="stockType" label="2">成交量</el-radio>
      </div>
    </div>
    <div class="market-content">
      <el-table
        @row-click="onSymbolChange"
        v-loading="tableLoading"
        ref="marketTable"
        :data="showList"
        :row-key="getRowKeys"
        :row-class-name="tableRowClassName"
      >
        <!-- style="zoom:0.8" -->
        <el-table-column
          :label="translateTitle('股票')"
          align="left"
          sortable
          min-width="110"
          prop="from_symbol"
        >
          <template slot-scope="{ row }">
            <div class="coin-item">
              <img
                class="select-icon"
                src="../../../assets/img/exchange/active.png"
                @click="addOption(row, 2)"
                v-if="selfOptionsList.indexOf(row.symbol_id.toString()) > -1"
                alt=""
              />
              <img
                class="select-icon"
                src="../../../assets/img/exchange/noActive.png"
                @click="addOption(row, 1)"
                v-else
                alt=""
              />
              <span
                class="coin-content"
                style="cursor: pointer"
                @click="onSymbolChange(row)"
              >
                <div class="form-content">
                  <div class="from-symbol DINPro" style="margin: 0 3px 0 6px">
                    <!-- {{
                      CoinItemIdMap[row.change_item_id].item_name.toUpperCase()
                    }} -->
                    {{ coinInfoMap[row.symbol_id].symbol_name.toUpperCase() }}
                  </div>
                  <div class="from-symbol DINPro" style="margin: 0 3px 0 6px">
                    <img
                      class="stock-icon"
                      src="../../../assets/img/stock_us.png"
                    />
                    <!-- {{
                      CoinItemIdMap[row.change_item_id].item_name.toUpperCase()
                    }} -->
                    {{ coinInfoMap[row.symbol_id].symbol.toUpperCase() }}
                  </div>
                </div>
                <!-- <span class="dark-color">{{ translateTitle("永续") }}</span> -->
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="translateTitle('最新价')"
          align="left"
          sortable
          class-name="new-price"
          prop="lastV2"
          min-width="80"
        >
          <template slot-scope="{ row }">
            <div class="new-price-content">
              <div class="new-price-usdt">
                <span class="DINPro dark-color"> {{ row.last }} </span
                ><span class="unit">USDT</span>
              </div>
              <div class="new-price-usdt">
                <span class="DINPro grey-color">¥1.23</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="translateTitle('涨跌')"
          align="right"
          sortable
          prop="price_change_percentV2"
          min-width="80"
        >
          <template slot-scope="{ row }">
            <span
              :class="row.price_change_percent > 0 ? 'green' : 'red'"
              class="DINPro"
              ><span class="green-plus" v-show="row.price_change_percent > 0">
                + </span
              >{{ row.price_change_percent }} % &nbsp;&nbsp;
            </span>
          </template>
        </el-table-column>
        <no-data slot="empty" v-show="!tableLoading" />
      </el-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { apiAddOption, apiGetOptionList } from "@/model/markets/markets";
import NoData from "@/components/common/NoData";
import * as Account from "@/model/user/account";
import { apiGetStaticTableInfo } from "@/model/settings";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
  },
  props: {
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    tableDataComputed: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  // watch:{
  //   tableData:{
  //     handler:function(){
  //       this.$nextTick(function (){
  //         this.$refs.marketTable.setCurrentRow(this.tableData[0])
  //       })
  //     }
  //   }
  // },
  data() {
    return {
      ws: null,
      tradePair: [],
      tradePairArray: [],
      tableData: [],
      // tableDataComputed: [],
      selectSymbol: "",
      symbol: "",
      coinInfoMap: {},
      CoinItemIdMap: {},
      tabList: [
        {
          value: "self",
          label: "自选",
        },
        {
          value: "usdt",
          label: "美股",
        },
        {
          value: "gg",
          label: "港股",
        },
        // {
        //   value: "btc",
        //   label: "BTC",
        // },
      ],
      currIndex: "usdt",
      selfOptionsList: [],
      stockType: "1", //
    };
  },
  async created() {
    // this.initWS();
    await this.getExchangeItemId();
    // await this.getStaticTableInfo(46);
    let tempSymbol = this.$route.params.symbol || "btcusdt";
    this.symbol = tempSymbol;
    this.getOptionList();
    // const [coinFrom, coinTo] = tempSymbol.split("_");
    // if (coinTo.toLowerCase() == "btc") {
    //   this.currIndex = "btc";
    // } else {
    //   this.currIndex = "usdt";
    // }
  },
  mounted() {
    // this.$refs.marketTable.setCurrentRow(this.tableData[0],true)
  },
  methods: {
    routeSplicing,
    async getExchangeItemId() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.CoinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
      }
    },
    tableRowClassName({ row, rowIndex }) {
      let trade_pair = this.symbol.toLowerCase();
      if (row.symbol.toLowerCase() == trade_pair) {
        return "warning-row";
      }
      return "";
    },
    getRowKeys(row) {
      return row.symbol_id;
    },

    addOption(row, type) {
      if (this.userInfo) {
        let params = {
          symbol_id: row.symbol_id,
          option: type,
          swap_type: 2,
        };
        apiAddOption(params).then(res => {
          if (res.status == 200) {
            this.$emit("onAddOption");
            this.getOptionList();
            this.$message({
              message:
                params.option == 1
                  ? this.translateTitle(`添加自选成功`)
                  : this.translateTitle(`删除自选成功`),
              type: params.option == 1 ? "success" : "success",
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
      }
    },
    onSymbolChange(item, column) {
      if (column != undefined && column.label == "币种") {
        return;
      }
      let trade_pair = item.name.replace("_", "").toLowerCase();
      this.symbol = trade_pair;
      this.$router.push(`/stock-exchange/${trade_pair}`);
      this.$emit("on-symbol-change", item);
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },

    async getOptionList() {
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      apiGetOptionList().then(res => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    tableLoading() {
      let val = 1;
      if (this.marketList.length > 0) {
        val = 0;
      } else {
        val = 1;
      }
      return val;
    },
    //FIXME 交易对搜索输入框输入ETH 永续搜索不出来内容,原因：使用元素的symbol进行对比，应用使用symbol_name，但国际化时？
    showList() {
      let tempMarket = [];
      this.marketList.forEach(item => {
        for (const key in this.coinInfoMap) {
          if (item.symbol_id == key) {
            tempMarket.push({
              ...item,
              ...this.coinInfoMap[key],
            });
          }
        }
      });

      let tempArr = [];
      tempArr = tempMarket.filter(ele => {
        if (this.currIndex == "self") {
          if (this.selectSymbol != "") {
            if (
              ele.symbol_name
                .toLowerCase()
                .indexOf(this.selectSymbol.toLowerCase()) != -1
            ) {
              return true;
            }
          } else {
            return this.selfOptionsList.indexOf(ele.symbol_id.toString()) > -1;
          }
        } else {
          if (this.selectSymbol != "") {
            if (
              ele.symbol_name
                .toLowerCase()
                .indexOf(this.selectSymbol.toLowerCase()) != -1
            ) {
              return true;
            }
          } else {
            return ele;
          }
        }
      });
      return tempArr;
    },
  },
};
</script>
<style lang="scss">
.stock-component-market {
  user-select: none;
  height: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  .market-header {
    padding-left: 15px;
    background-color: #f5f5f7;
    ul {
      display: flex;
    }
    .stock-images {
      width: 35px;
      height: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background-color: #03ad79;
      float: right;
      margin-top: -15px;
      margin-right: 46px;
      .stock-market-img {
        width: 15px;
        height: 10px;
      }
    }
    .classify-area {
      padding: 0 10px;
      cursor: pointer;
      font-size: 12px;
      min-width: 69px;
      height: 32px;
      line-height: 32px;
      font-weight: 500;
      color: #7f8490;
      position: relative;
      text-align: center;
      &.active {
        background-color: #ffffff;
        color: #000;
      }
      .item-bar {
        position: absolute;
        width: 24px;
        height: 2px;
        background-color: #1f2533;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .select-symbol {
    width: 270px;
    height: 24px;
    margin: 17px 15px 5px 15px;
    .el-input {
      max-width: 130px;
      .el-input__inner {
        width: 130px;
        height: 24px;
        font-size: 12px;
        padding: 5px 10px 5px 30px;
        border-radius: 16px;
        background-color: #f1f2f5;
      }
      .el-input__icon {
        line-height: 24px;
      }
    }
    .stock-radios {
      width: 118px;
      height: 24px;
      float: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 3px;
      .el-radio {
        margin-right: 0px;
        .el-radio__input {
          padding-top: 2px;
          height: 24px;
          .el-radio__inner {
            width: 16px;
            height: 16px;
          }
        }
        .el-radio__label {
          padding-left: 6px;
          font-size: 12px;
          font-weight: normal;
          color: #354052;
        }
        .el-radio__inner:hover {
          border-color: #c61b3d;
        }
        .is-checked {
          .el-radio__label {
            color: #354052;
          }
          .el-radio__inner {
            border-color: #c61b3d;
            background: #fff;
          }
          .el-radio__inner::after {
            background-color: #c61b3d;
            width: 7px;
            height: 7px;
          }
        }
      }
    }
  }
  .market-title {
    display: flex;
  }
  .market-content {
    // padding: 0 2px;
    .el-table {
      min-height: 659px;
      // padding: 0 10px;
      .ascending {
        .sort-caret.ascending {
          border-bottom-color: #354052;
        }
      }
      .el-table__header,
      .el-table__body {
        width: 100% !important;
      }
      .new-price {
        .caret-wrapper {
          width: 15.5px;
        }
      }

      .cell {
        font-size: 12px;
        padding: 0;
        .new-price-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .new-price-usdt {
            height: 18px;
            .dark-color {
              color: #354052;
            }
            .grey-color {
              color: #9aa1b1;
            }
            .unit {
              font-size: 12px;
              color: #9aa1b1;
            }
          }
        }
      }
      tbody {
        tr {
          cursor: pointer;
        }
      }
      .warning-row {
        background-color: #f5f5f7;
      }
      .el-table__body tr.current-row > td {
        background-color: #f5f5f7;
      }
      .select-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        vertical-align: -45%;
        // margin-top: 10px;
      }
      th > .cell {
        font-size: 12px;
        color: #7f8490;
        // padding-left: 15px;
      }
      th:first-child > .cell {
        font-size: 12px;
        color: #7f8490;
        padding-left: 15px;
      }
      &::before {
        height: 0px;
      }
      td,
      th.is-leaf {
        border-bottom: none;
      }
      td,
      th {
        padding: 3px 0px 5px 0px;
        &:first-child {
          padding: 3px 0px 5px 10px;
        }
        &:last-child {
          padding: 3px 10px 5px 0px;
        }
      }
      .cell {
        cursor: pointer;
        .coin-item {
          img {
            // width: 14.55%;
          }
          .coin-content {
            display: inline-block;
            width: 85.45%;
            .form-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              .from-symbol {
                font-size: 12px;
                color: #354052;
                height: 18px;
                .stock-icon {
                  width: 15px;
                  height: 10px;
                }
              }
            }
          }
        }
        .red {
          color: #c61b3d;
        }
        .green {
          color: #28ba98;
        }
        .green-plus {
          margin-right: -3px;
        }
        .active {
          color: #fa4d56;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }
        .el-icon-star-on,
        .el-icon-star-off {
          cursor: pointer;
          margin-right: 10px;
        }
        .el-table__expand-icon {
          display: none;
        }
        .dark-color {
          color: #354052;
        }
      }
      .no-data-box {
        margin-top: 200px;
      }
    }
    .area-title {
      li {
        display: flex;
        .pair-name-title {
          width: 200px;
        }
        .pair-price-title {
          width: 200px;
          text-align: right;
        }
        .pair-updown-title {
          width: 200px;
          text-align: right;
        }
      }
    }
    .depth-sub-title {
      padding: 0 15px 0 15px;
      display: flex;
      color: #7f8490;
      font-size: 12px;
      font-weight: 500;
      height: 36px;
      line-height: 36px;
      .time {
        width: 108px;
      }
      .price {
        width: 108px;
      }
      .num {
        width: 108px;
        text-align: right;
      }
    }
    .depth-sub-content {
      padding: 0 13px 4px 16px;
      min-height: 500px;
      li {
        cursor: pointer;
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        .time {
          width: 108px;
          color: #1f2533;
        }
        .price {
          width: 108px;
        }
        .num {
          width: 108px;
          color: #080c34;
          text-align: right;
          &.red {
            color: #fa4d56;
          }
          &.green {
            color: #03ad79;
          }
        }
      }
    }
  }
  .pair-wrap {
    .pair-list {
      display: flex;
      height: 26px;
      line-height: 26px;
      .pair-name {
        width: 200px;
      }
      .pair-price {
        width: 200px;
        text-align: right;
      }
      .pair-updown {
        width: 200px;
        text-align: right;
      }
    }
  }
}
</style>
