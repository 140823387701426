<template>
  <div>
    <div class="articles-panel__container">
      <div class="articles-panel__container-items">
        <div
          class="articles-panel__container-items-links"
          v-if="noticeItems.length"
        >
          <div class="articles-panel__container-items-links-left">
            <div class="articles-panel__container-items-links-left-icon">
              <img :src="!isDarkMode ? warnIcon : warnIconLight" />
            </div>
            <div class="articles-panel__container-items-links-left-items">
              <div class="scroll-content">
                <template v-for="(item, index) in noticeItems.slice(0, 5)">
                  <span v-bind:key="index">
                    <!-- <span v-if="item.tag"> {{ item.tag }} | </span> -->
                    <span
                      style="cursor: pointer; margin-right: 100px"
                      @click="goToArticle(item)"
                      class="notices-title"
                    >
                      {{ item.title || "--" }}
                    </span>
                  </span>
                </template>
              </div>
            </div>
          </div>
          <div class="articles-panel__container-items-links-right">
            <img
              :src="!isDarkMode ? rightIcon : rightIconLight"
              @click="gotoNoticeCenter"
            />
          </div>
        </div>

        <div
          class="articles-panel__container-items-swiper"
          v-if="imgList.length"
        >
          <div
            v-if="imgList.length > 4"
            class="swiper-prev"
            slot="button-prev"
          ></div>
          <swiper
            :options="swiperOption"
            :autoplay="false"
            ref="mySwiper"
            class="swiper-container"
            @afterInit="afterInit"
          >
            <swiper-slide
              class="swiper-item"
              v-for="(item, index) of imgList"
              :key="index"
            >
              <img
                class="swiper-img"
                :src="getImgSrc(item)"
                :data-href="getUrl(item)"
                alt=""
                @error="imgErrorLoad"
              />
            </swiper-slide>
          </swiper>
          <div
            v-if="imgList.length > 4"
            class="swiper-next"
            slot="button-next"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { apiGetBannerList } from "@/model/home/index";
import commonMixin from "@/components/common/commonMixin.vue";
import axios from "axios";
import rightIcon from "@/assets/img/home/articles/rightIcon.png";
import rightIconLight from "@/assets/img/home/articles/rightIcon-light.png";
import warnIcon from "@/assets/img/home/articles/warnIcon.png";
import warnIconLight from "@/assets/img/home/articles/warnIcon-light.png";

import leftDark from "@/assets/img/home/articles/left-dark.png";
import leftLight from "@/assets/img/home/articles/left-light.png";
import rightDark from "@/assets/img/home/articles/right-dark.png";
import rightLight from "@/assets/img/home/articles/right-light.png";

export default {
  props: {},
  components: {
    swiper,
    swiperSlide,
  },
  mixins: [commonMixin],
  data() {
    return {
      showBanner: false,
      imgList: [],
      detailsUrl: "",
      rightIcon,
      rightIconLight,
      warnIcon,
      warnIconLight,
      leftDark,
      leftLight,
      rightDark,
      rightLight,
      tipUrl: "",
      noticeItems: [],

      initSwiperOption: {
        initialSlide: 0,

        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },

        on: {
          click: function (e) {
            if (e.target.dataset.href) window.open(e.target.dataset.href);
          },
        },

        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 4,
        slidesPerGroup: 1,

        loopFillGroupWithBlank: true,
        infinite: 1, // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        autoplayDisableOnInteraction: false,
        loop: true,
      },
    };
  },
  async created() {
    this.init();
    this.getBannerData();
  },
  mounted() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    swiperOption() {
      const options = { ...this.initSwiperOption };
      options.loop = this.imgList.length > 4;
      options.loopedSlides = this.imgList.length;
      return options;
    },
  },
  methods: {
    getUrl(item) {
      return item.language
        ? item.language[`url_${this.language}`]
        : item.param?.url;
    },
    getImgSrc(item) {
      const url = item.language?.["img_url_" + this.language];
      return /^http(s)?:\/\//.test(url)
        ? url
        : require("../../assets/img/home/articles/emptyImg.jpg");
    },
    afterInit() {},
    imgErrorLoad(e) {
      e.target.src = require("../../assets/img/home/articles/emptyImg.jpg");
    },
    prevClick() {
      this.$refs.mySwiper.swiper.slideNext();
    },
    nextClick() {
      this.$refs.mySwiper.swiper.slidePrev();
    },

    async getBannerData() {
      const req = {
        type: 1,
      };
      const { status, data } = await apiGetBannerList(req);
      if (status == 200) {
        let len = data.length;
        this.showBanner = true;
        if (len) {
          this.imgList = [...data];
          // if (len < 4) {
          //   // 用默认的进行填充
          //   this.imgList = [...data];
          //   for (let i = len; i < 4; i++) {
          //     this.imgList.push({});
          //   }
          // } else {
          //   this.imgList = [...data];
          // }
        }
      }
    },
    init() {
      let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
      if (zendesk_notice.language) {
        this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`]
          ? zendesk_notice.language[`notice_url_${this.language}`]
          : zendesk_notice.language[`notice_url_en`];
        this.detailsUrl = zendesk_notice.language[
          `articles_url_${this.language}`
        ]
          ? zendesk_notice.language[`articles_url_${this.language}`]
          : zendesk_notice.language[`articles_url_en`];
      } else {
        this.tipUrl = zendesk_notice.notice_url;
        this.detailsUrl = zendesk_notice.articles_url;
      }
      let about = JSON.parse(sessionStorage.getItem("about"));
      if (about[0].language) {
        this.tipUrl = about[0].language[`url_${this.language}`]
          ? about[0].language[`url_${this.language}`]
          : about[0].language[`url_en`];
      } else {
        this.tipUrl = about[0].url;
      }
      this.getDeatilsData();
    },
    gotoImgUrl(item) {
      const url = item.language?.[`url_${this.language}`] || item.param.url;
      if (url) {
        window.open(url, "_blank");
      } else {
        return false;
      }
    },
    goToArticle(item) {
      window.open(item.html_url, "_blank");
    },
    gotoNoticeCenter() {
      window.open(this.tipUrl, "_blank");
    },
    async getDeatilsData() {
      var that = this;
      let helpLang = ["zh_CN", "zh_HK", "vi", "th"].includes(this.language)
        ? this.language
        : "en";
      let topDomain = document.domain.split(".").slice(-2).join(".");
      if (topDomain.includes("localhost")) {
        topDomain = "4ezh8.com";
      }
      let protocol = location.protocol;
      let host = location.host;
      let jumpUrl =
        protocol +
        "//" +
        host +
        "/help-center/" +
        this.language +
        "?path=category&lang=" +
        helpLang +
        "&code=NOTICE%3ANews&showContent=true&articleId=";

      const isHcapi = /^http(s)?:\/\//.test(this.detailsUrl) ? false : true;
      let newsUrl = !isHcapi
        ? this.detailsUrl
        : "https://hcapi." + topDomain + this.detailsUrl;
      axios
        .get(newsUrl)
        .then(function (response) {
          const items = (
            response.data.data ||
            response.data.articles ||
            []
          ).map((item) => {
            if (isHcapi) {
              return {
                html_url: jumpUrl + item["attributes"]["uuid"],
                title: item["attributes"]["title"],
                tag: item["attributes"]?.["menu2"]?.["data"]?.["attributes"]?.[
                  "name" || ""
                ],
              };
            }
            return {
              html_url: item.html_url,
              title: item.name,
              tag: "最新公告",
            };
          });
          that.noticeItems = items;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  .articles-panel__container {
    background: #fff;
    color: #000;
    .swiper-next {
      background-image: url(../../assets/img/home/articles/right-light.png);
      &:hover {
        background-image: url(../../assets/img/home/articles/right-light-hover.png);
      }
    }
    .swiper-prev {
      background-image: url(../../assets/img/home/articles/left-light.png);
      &:hover {
        background-image: url(../../assets/img/home/articles/left-light-hover.png);
      }
    }
  }
}
.articles-panel__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding-top: 10px;
  .notice-swiper-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .notices-title {
    margin-right: 20px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &-items {
    width: 1200px;
    &-links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      height: 52px;
      background: linear-gradient(
        90deg,
        rgba(70, 90, 255, 0.02) 0%,
        rgba(145, 155, 239, 0.8) 48.81%,
        rgba(70, 90, 255, 0.02) 100%
      );
      &-left {
        display: flex;
        flex: 1;
        &-items {
          overflow: hidden;
          width: calc(var(--marquee-width) - 56px);
          position: relative;
          display: flex;
          .scroll-content {
            /* position: absolute; */
            display: flex;
            align-items: center;
            flex: 1;
            width: 1120px;
            text-overflow: ellipsis;
            /* overflow: hidden; */
            white-space: nowrap;
            --item-size: 5;
            --time: calc(var(--item-size) * 8s);
            animation: newIndex_marquee__9Kpa8 linear infinite normal;
            animation-duration: var(--time);
            &:hover {
              animation-play-state: paused;
            }
          }

          /* animation: noticesTrans 0.5s linear; */
          &-text {
            /* margin-right: 47px; */
            cursor: pointer;
            margin-right: 47px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            margin-right: 10px;
          }
        }
      }
      &-right {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          cursor: pointer;
        }
      }
      /*  */
    }
    &-swiper {
      /* padding-top: 29px; */
      padding-bottom: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .swiper-wrapper {
        padding-top: 29px;
      }
      .swiper-container {
        flex: 1;
        /* padding-left: 20px;
        padding-right: 20px; */
      }
      .swiper-next,
      .swiper-prev {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 34px;
        width: 34px;
        cursor: pointer;
      }
      .swiper-prev {
        /* padding-right: 20px; */
        position: absolute;
        left: -50px;
        background-image: url(../../assets/img/home/articles/left-dark.png);
        &:hover {
          background-image: url(../../assets/img/home/articles/left-dark-hover.png);
        }
      }
      .swiper-next {
        position: absolute;
        right: -50px;
        background-image: url(../../assets/img/home/articles/right-dark.png);
        &:hover {
          background-image: url(../../assets/img/home/articles/right-dark-hover.png);
        }
      }
      .swiper-item {
        position: relative;
        &:hover {
          /* width: 110% !important; */
          animation: expendPosition 0.5s linear;
          animation-fill-mode: forwards;
        }
        .el-image.swiper-img {
          width: 100%;
        }
      }
      .swiper-img {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        width: 286px;
        height: 142px;
        &:hover {
          /* position: relative; */
          /* top: -10px; */
        }
      }
    }
  }
}
@keyframes expendPosition {
  0% {
    top: 0;
  }

  100% {
    top: -10px;
  }
}
@keyframes newIndex_marquee__9Kpa8 {
  0% {
    transform: translateZ(0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
</style>