import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import i18n from "./i18n";
import store from "./store";
import router from "./router/index.js";
import echarts from "echarts";
import VueAwesomeSwiper from "vue-awesome-swiper";
import UniqueId from "vue-unique-id";
import VueClipboard from "vue-clipboard2";
import ElementUI from "element-ui";
import global from "@/assets/js/global.js";
import VueSocialSharing from "@/assets/social-sharing/vue-social-sharing";
import "@/3rd/element-ui.js";
import dinpro from "@/assets/din-pro/font.scss";
import pingfang from "@/assets/pingfang/font.scss";
import opensans from "@/assets/opensans/font.scss";
import "@/assets/css/reset.css";
import "@/assets/css/common.scss";
import "@/assets/iconfont/iconfont.css";
import Meta from "vue-meta";
import "../node_modules/swiper/swiper-bundle.css";
import iframeResize from "iframe-resizer/js/iframeResizer";
import { translateTitle } from "@/assets/js/i18n";

require("@/assets/js/gt4.js");
const requireTheme = require.context(
  "./assets/styles/themes",
  false,
  /\.scss$/
);
requireTheme.keys().forEach(fileName => {
  requireTheme(fileName);
});
Vue.prototype.translateTitle = translateTitle;
Vue.prototype.global = global;
Vue.prototype.$echarts = echarts;
Vue.prototype.$initGeetest4 = initGeetest4;
Vue.use(VueRouter);
Vue.use(VueAwesomeSwiper);
Vue.use(UniqueId);
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.use(require("vue-wechat-title"));
Vue.use(VueSocialSharing);
Vue.use(pingfang);
Vue.use(dinpro);
Vue.use(opensans);
Vue.use(Meta);
Vue.directive("resize", {
  bind: function (el, { value = {} }) {
    el.addEventListener("load", () => iframeResize(value, el));
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  },
});

var sensors = require("sa-sdk-javascript");
Vue.prototype.$sensors = sensors;
// https://manual.sensorsdata.cn/sa/latest/zh_cn/web-7545024.html 官方文档
sensors.init({
  server_url: "https://data-api.lmsensor.com/sa?project=production", // 正式
  // server_url: "https://data-api.lmsensor.com/sa?project=default", // 测试
  is_track_single_page: true,
  use_client_time: true,
  send_type: "beacon",
  heatmap: {
    clickmap: "default",
    scroll_notice_map: "default",
  },
});
sensors.registerPage({
  platform_type: "Web",
  is_login: sessionStorage.getItem("userInfo") ? true : false,
  language: localStorage.getItem("language") || "zh_CN",
  current_page: window.location.pathname,
});
sensors.quick("autoTrack");
sensors.quick("isReady", function () {
  var anonymousID = sensors.quick("getAnonymousID");
});

// 添加极验风控SDK
let GeeGuard = document.createElement("script");
GeeGuard.src = "https://static.geetest.com/g5/gd.js";
GeeGuard.onload = () => {
  if (window.initGeeGuard && typeof window.initGeeGuard == "function") {
    window.initGeeGuard({ appId: "rcjuiipacta8tlciqvf6bbqx3llarywy" }, data => {
      if (data.status === "success") {
        Vue.prototype.$gee_data = data.data;
        window.geetestScriptLoaded = true;
      }
    });
  }
};
document.head.appendChild(GeeGuard);

new Vue({
  render: h => h(App),
  i18n,
  store,
  router,
}).$mount("#app");
