<template>
  <div>
    <div class="about-panel__container-new">
      <div class="about-panel__container-new-title">
        {{ translateTitle("4E优势") }}
      </div>
      <div class="about-panel__container-new-content">
        <div
          class="about-panel__container-new-content-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <img :src="item.icon[isDarkMode ? 'dark' : 'light']" alt="" />
          <div class="about-panel__container-new-content-item-title">
            {{ translateTitle(item.title) }}
          </div>
          <div class="about-panel__container-new-content-item-content">
            {{ translateTitle(item.subTitle) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import aboutLogo from "@/assets/img/brand/about-logo.png";
import commonMixin from "@/components/common/commonMixin.vue";
import aboutLogoDark from "@/assets/img/brand/about-logo-dark.png";
import icon1Dark from "@/assets/img/home/about/dark/1.png";
import icon1Light from "@/assets/img/home/about/light/1.png";
import icon2Dark from "@/assets/img/home/about/dark/2.png";
import icon2Light from "@/assets/img/home/about/light/2.png";
import icon3Dark from "@/assets/img/home/about/dark/3.png";
import icon3Light from "@/assets/img/home/about/light/3.png";
import icon4Dark from "@/assets/img/home/about/dark/4.png";
import icon4Light from "@/assets/img/home/about/light/4.png";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      items: [
        {
          title: "Empower 扶摇直上",
          subTitle: "4E致力赋能用户，掌握人生财富主权",
          icon: {
            dark: icon1Dark,
            light: icon1Light,
          },
        },
        {
          title: "Enrich 锦上添花",
          subTitle: "4E专注用户体验，创造更多价值与满足",
          icon: {
            dark: icon2Dark,
            light: icon2Light,
          },
        },
        {
          title: "Evolve 与时俱进",
          subTitle: "4E紧握时代脉搏，引领全球交易新风向",
          icon: {
            dark: icon3Dark,
            light: icon3Light,
          },
        },
        {
          title: "Exchange 携手共赢",
          subTitle: "4E促进交流合作，与用户共享经验资源",
          icon: {
            dark: icon4Dark,
            light: icon4Light,
          },
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      isDarkMode: "user/isDarkMode",
    }),
    aboutLogo() {
      return this.isDarkMode ? aboutLogoDark : aboutLogo;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
:root[theme="dark"] {
  .about-panel__container-new-title {
    color: #fff;
  }
  .about-panel__container-new-content-item-title {
    color: #fff;
  }
  .about-panel__container-new-content-item-content {
    color: rgba(255, 255, 255, 0.65);
  }
}
.about-panel__container-new {
  display: flex;
  padding: 0px 0px 100px 0;
  flex-direction: column;
  align-items: center;
  background-color: var(--homeBgLightColor);
  color: var(--homeFontColor);
  &-title {
    margin-top: 30px;
    color: #000;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &-content {
    width: 1200px;
    display: flex;
    &-item {
      width: 300px;
      /* background: red; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 42px;
      &-title {
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px; /* 87.5% */
        margin-top: 16px;
      }
      &-content {
        color: #5c5f69;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;
        padding-left: 4px;
        padding-right: 4px;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
}
</style>