<template>
  <div class="menu-outer">
    <SideMenuInner></SideMenuInner>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import SideMenuInner from "@/pages/ucenter/components/SideMenu-inner.vue";
export default {
  mixins: [],

  data() {
    return {};
  },
  components: { SideMenuInner },
  computed: {},
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss">
.menu-outer {
  padding: 0 0;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
  z-index: 999;
  height: auto;
  float: left;
}
</style>
