import api from "@/assets/js/api";
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";
// const { VUE_APP_APP } = process.env;

export function apiLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/appUniversalLogin`,
    data,
  });
}

export function apiGoogleLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/googleVerifyLogin`,
    data,
  });
}
export function universalLogin(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/universalLogin`,
    data,
  });
}
//登录不需要谷歌码
export function universalAccountV2Login(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/AccountV2/universalLogin`,
    data,
  });
}
//登录不需要谷歌码
export function universalAccountV2LoginGeet(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Login/universal`,
    data,
  });
}
//用户安全遗失提交工单
export function apiSafelyLost(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/safelyLost`,
    data,
  });
}
