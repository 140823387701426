import api from "@/assets/js/api";
// const { VUE_APP_APP } = process.env

import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export function apiHistoryData(data = {}) {
  return api({
    url: VUE_APP_APP + `/Lever/kline`,
    data,
    type: "POST",
  });
}

// 获取kline精度
export function apiSymbolInfo(data = {}) {
  return api({
    url: VUE_APP_APP + `/Lever/symbols`,
    data,
    type: "POST",
  });
}
