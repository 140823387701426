<template>
  <div class="component-market">
    <div class="market-header">
      <ul>
        <li
          class="classify-area"
          :class="{ active: currIndex == item.value }"
          v-for="item of tabList"
          :key="item.value"
          @click="onAreaChange(item)"
        >
          {{ item.label }}
          <i v-if="currIndex == item.value" class="item-bar"></i>
        </li>
      </ul>
    </div>
    <div class="select-symbol">
      <el-input
        v-model="selectSymbol"
        maxlength="12"
        :placeholder="translateTitle('搜索')"
        clearable
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>
    <div class="market-content">
      <el-table
        v-loading="tableLoading"
        ref="marketTable"
        :data="showList"
        :row-key="getRowKeys"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          :label="translateTitle(`交易对`)"
          align="left"
          sortable
          min-width="110"
          prop="from_symbol"
        >
          <template slot-scope="{ row }">
            <div class="coin-item">
              <img
                class="select-icon"
                src="../../../assets/img/exchange/active.png"
                @click.stop="addOption(row, 2)"
                v-if="selfOptionsList.indexOf(row.symbol_id.toString()) > -1"
                alt=""
              />
              <img
                class="select-icon"
                src="../../../assets/img/exchange/noActive.png"
                @click.stop="addOption(row, 1)"
                v-else
                alt=""
              />
              <span
                class="coin-content"
                style="cursor: pointer"
                @click.stop="onSymbolChange(row)"
              >
                <span class="from-symbol DINPro" style="margin-left: 6px">{{
                  row.from_symbol
                }}</span>
                <span class="dark-color DINPro">/{{ row.to_symbol }}</span>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="translateTitle(`最新价`)"
          align="right"
          sortable
          class-name="new-price"
          prop="lastV2"
          min-width="80"
        >
          <template slot-scope="{ row }">
            <div>
              <span class="DINPro dark-color"> {{ row.last }} </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="translateTitle(`涨幅`)"
          align="right"
          sortable
          prop="price_change_percentV2"
          min-width="80"
        >
          <template slot-scope="{ row }">
            <span
              class="DINPro"
              :class="row.price_change_percent > 0 ? 'green' : 'red'"
              ><span class="green-plus" v-show="row.price_change_percent > 0">
                + </span
              >{{ row.price_change_percent }} % &nbsp;&nbsp;
            </span>
          </template>
        </el-table-column>
        <no-data slot="empty" v-show="!tableLoading" />
      </el-table>
    </div>
  </div>
</template>
<script>
import Socket from "@/assets/js/socket.js";
import { mapGetters } from "vuex";
import { apiAddOption, apiGetOptionList } from "@/model/markets/markets";
import NoData from "@/components/common/NoData";
import * as Account from "@/model/user/account";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
  },
  props: {
    marketList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      ws: null,
      tradePair: [],
      tradePairArray: [],
      selectSymbol: "",
      symbol: "",
      fail_reason: "",
      currIndex: "usdt",
      selfOptionsList: [],
    };
  },
  created() {
    let tempSymbol = this.$route.params.symbol || "btc_usdt";
    this.symbol = tempSymbol;
    this.getOptionList();
    const [coinFrom, coinTo] = tempSymbol.split("_");
    if (coinTo.toLowerCase() == "btc") {
      this.currIndex = "btc";
    } else if (coinTo.toLowerCase() == "busd") {
      this.currIndex = "busd";
    } else {
      this.currIndex = "usdt";
    }
  },

  methods: {
    routeSplicing,
    tableRowClassName({ row, rowIndex }) {
      let trade_pair = this.symbol.toLowerCase();
      if (row.info.name.toLowerCase() == trade_pair) {
        return "warning-row";
      } else {
        return "";
      }
    },
    getRowKeys(row) {
      return row.symbol_id;
    },
    async addOption(row, type) {
      let tempSymbol = this.$route.params.symbol || "btc_usdt";
      const [coinFrom, coinTo] = tempSymbol.split("_");
      if (this.userInfo) {
        let params = {
          symbol_id: row.symbol_id,
          option: type,
          swap_type: 2,
        };
        const { status, data, msg } = await apiAddOption(params);
        this.fail_reason = msg;
        if (status == 200) {
          this.$emit("onAddOption");
          this.getOptionList();
          this.$message({
            message:
              params.option == 1
                ? this.translateTitle(`添加自选成功`)
                : this.translateTitle(`删除自选成功`),
            type: params.option == 1 ? "success" : "success",
          });
          if (params.option == 1) {
            this.$sensors.track("Transaction_Click", {
              first_level_tab: "cryptoCurrency",
              second_level_tab: "spot_trading",
              symbol_name: coinFrom + "/" + coinTo,
              price: " ",
              quantity: " ",
              button_name: "add into collection",
              is_success: true,
              fail_reason: "",
            });
          } else {
            this.$sensors.track("Transaction_Click", {
              first_level_tab: "cryptoCurrency",
              second_level_tab: "spot_trading",
              symbol_name: coinFrom + "/" + coinTo,
              price: " ",
              quantity: " ",
              button_name: "delete from collection",
              is_success: true,
              fail_reason: "",
            });
          }
        } else {
          this.$sensors.track("Transaction_Click", {
            first_level_tab: "cryptoCurrency",
            second_level_tab: "spot_trading",
            symbol_name: this.coinFrom + "/" + this.coinTo,
            price: " ",
            quantity: " ",
            button_name: "add into collection",
            is_success: false,
            fail_reason: this.fail_reason,
          });
        }
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
        this.$sensors.track("Transaction_Click", {
          first_level_tab: "cryptoCurrency",
          second_level_tab: "spot_trading",
          symbol_name: this.coinFrom + "/" + this.coinTo,
          price: " ",
          quantity: " ",
          button_name: "add into collection",
          is_success: true,
          fail_reason: "Not logged in",
        });
      }
    },
    // onSymbolChange(item, column) {
    onSymbolChange(row) {
      // if (column != undefined && column.label == "币种") {
      //   return;
      // }
      this.$sensors.track("Transaction_Click", {
        first_level_tab: "cryptoCurrency",
        second_level_tab: "spot_trading",
        symbol_name: row.from_symbol + "/" + row.to_symbol,
        price: " ",
        quantity: " ",
        button_name: "switch-pairs",
        is_success: true,
        fail_reason: "",
      });
      let trade_pair = row.info.name.toLowerCase();
      this.symbol = trade_pair;
      this.$router.push(
        this.routeSplicing(this.language, `exchange/${trade_pair}`)
      );
      this.$emit("on-symbol-change", row);
      let params = {
        row: row,
      };
      this.tableRowClassName(params);
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },

    async getOptionList() {
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      apiGetOptionList().then((res) => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
    compareOrderBy(property) {
      return function (a, b) {
        if (
          a &&
          b &&
          a["info"] &&
          b["info"] &&
          a["info"][property] &&
          b["info"][property]
        ) {
          let value1 = a["info"][property];
          let value2 = b["info"][property];
          if (value1 - value2 != 0) {
            return value1 - value2;
          } else {
            let temp1 = a["from_symbol"];
            let temp2 = b["from_symbol"];
            if (temp1 < temp2) {
              // 按某种排序标准进行比较, a 小于 b
              return -1;
            }
            if (temp1 > temp2) {
              return 1;
            }
            return 0;
          }
        } else {
          return 0;
        }
      };
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),
    tableLoading() {
      let val = 1;
      if (this.marketList.length > 0) {
        val = 0;
      } else {
        val = 1;
      }
      return val;
    },
    showList() {
      let tempArr = [];
      tempArr = this.marketList.filter((ele) => {
        if (this.currIndex == "self") {
          if (this.selectSymbol != "") {
            if (
              ele.from_symbol
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 ||
              `${ele.from_symbol}${ele.to_symbol}`
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 ||
              (`${ele.from_symbol}/${ele.to_symbol}`
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 &&
                this.selfOptionsList.indexOf(ele.symbol_id.toString() > -1))
            ) {
              return true;
            }
          } else {
            return this.selfOptionsList.indexOf(ele.symbol_id.toString()) > -1;
          }
        } else if (this.currIndex == "usdt") {
          if (this.selectSymbol != "") {
            if (
              ele.from_symbol
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 ||
              `${ele.from_symbol}${ele.to_symbol}`
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 ||
              `${ele.from_symbol}/${ele.to_symbol}`
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1
            ) {
              return true;
            }
          } else {
            if (ele.info) {
              return ele.info.coin_to == "10007";
            }
          }
        } else if (this.currIndex == "busd") {
          if (this.selectSymbol != "") {
            if (
              ele.from_symbol
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 ||
              `${ele.from_symbol}${ele.to_symbol}`
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 ||
              `${ele.from_symbol}/${ele.to_symbol}`
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1
            ) {
              return true;
            }
          } else {
            if (ele.info) {
              return ele.info.coin_to == "10123";
            }
          }
        } else {
          if (this.selectSymbol != "") {
            if (
              ele.from_symbol
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 ||
              `${ele.from_symbol}${ele.to_symbol}`
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1 ||
              `${ele.from_symbol}/${ele.to_symbol}`
                .toLowerCase()
                .indexOf(this.selectSymbol.trim().toLowerCase()) != -1
            ) {
              return true;
            }
          } else {
            if (ele.info) {
              return ele.info.coin_to == "10005";
            }
          }
        }
      });
      tempArr.sort(this.compareOrderBy("order_by"));

      return tempArr;
    },
    tabList() {
      let tempList = [
        {
          value: "self",
          label: this.translateTitle("自选"),
        },
        {
          value: "usdt",
          label: "USDT",
        },
        {
          value: "btc",
          label: "BTC",
        },
      ];
      let tempArr = [];
      this.marketList.forEach((ele) => {
        tempArr.push(ele.to_symbol);
      });
      if (tempArr.indexOf("BUSD") >= 0) {
        tempList = [
          {
            value: "self",
            label: this.translateTitle("自选"),
          },
          {
            value: "usdt",
            label: "USDT",
          },
          {
            value: "busd",
            label: "BUSD",
          },
          {
            value: "btc",
            label: "BTC",
          },
        ];
      } else {
        tempList = [
          {
            value: "self",
            label: this.translateTitle("自选"),
          },
          {
            value: "usdt",
            label: "USDT",
          },
          {
            value: "btc",
            label: "BTC",
          },
        ];
      }
      return tempList;
    },
  },
};
</script>
<style lang="scss">
.component-market {
  user-select: none;
  height: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  // overflow-y: auto;
  padding-bottom: 10px;

  .market-header {
    padding-left: 15px;
    background-color: #f5f5f7;

    ul {
      display: flex;
    }

    .classify-area {
      padding: 0 10px;
      cursor: pointer;
      font-size: 12px;
      height: 32px;
      line-height: 32px;
      font-weight: 500;
      color: #7f8490;
      position: relative;

      &.active {
        background-color: #ffffff;
        color: #000;
      }

      .item-bar {
        position: absolute;
        width: 24px;
        height: 2px;
        background-color: #1f2533;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

  .select-symbol {
    width: 270px;
    height: 24px;
    margin: 17px 15px 5px 15px;

    .el-input {
      .el-input__inner {
        width: 270px;
        height: 24px;
        font-size: 12px;
        padding: 5px 10px 5px 30px;
        border-radius: 16px;
        background-color: #f1f2f5;
      }

      .el-input__icon {
        line-height: 24px;
      }
    }
  }

  .market-title {
    display: flex;
  }

  .market-content {
    padding: 0 0 10px 0;

    .el-table {
      // min-height: 659px;
      // padding: 0 10px;
      overflow-y: auto;
      padding-right: 5px;

      .el-table__body-wrapper {
        height: 667px;
        overflow-y: auto;
      }

      /* .el-table__body-wrapper {
        overflow-y: overlay;
      } */
      /*
       *改变浏览器默认的滚动条样式
       */
      .el-table__body-wrapper::-webkit-scrollbar-track-piece {
        background-color: #fff;
      }

      .el-table__body-wrapper::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: #dddddd;
        border-radius: 100px;
      }

      .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
      }

      .ascending {
        .sort-caret.ascending {
          border-bottom-color: #354052;
        }
      }

      .el-table__header,
      .el-table__body {
        width: 100% !important;
      }

      .new-price {
        .caret-wrapper {
          width: 15.5px;
        }
      }

      .cell {
        font-size: 12px;
        padding: 0;

        .dark-color {
          color: #354052;
        }
      }

      tbody {
        tr {
          cursor: pointer;
        }
      }

      .warning-row {
        background-color: #f2f6fc;
      }

      .el-table__body tr.current-row > td {
        background-color: #f2f6fc;
      }

      .select-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
        vertical-align: middle;
        margin-top: -2px;
      }

      th > .cell {
        font-size: 12px;
        color: #7f8490;
      }

      &::before {
        height: 0px;
      }

      td,
      th.is-leaf {
        border-bottom: none;
      }

      td,
      th {
        padding: 5px 0px 5px 10px;

        &:first-child {
          padding: 5px 0px 5px 10px;
        }

        &:last-child {
          padding: 5px 15px 5px 10px;
        }
      }

      .cell {
        cursor: pointer;

        .coin-item {
          img {
            // width: 14.55%;
          }

          .coin-content {
            display: inline-block;
            width: 85.45%;

            .from-symbol {
              font-size: 12px;
              color: #354052;
            }
          }
        }

        .red {
          color: #c61b3d;
        }

        .green {
          color: #28ba98;
        }

        .green-plus {
          margin-right: -3px;
        }

        .active {
          color: #fa4d56;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }

        .el-icon-star-on,
        .el-icon-star-off {
          cursor: pointer;
          margin-right: 10px;
        }

        .el-table__expand-icon {
          display: none;
        }

        .dark-color {
          color: #354052;
        }
      }

      .no-data-box {
        // margin-top: 200px;
      }
    }

    .area-title {
      li {
        display: flex;

        .pair-name-title {
          width: 200px;
        }

        .pair-price-title {
          width: 200px;
          text-align: right;
        }

        .pair-updown-title {
          width: 200px;
          text-align: right;
        }
      }
    }

    .depth-sub-title {
      padding: 0 15px 0 15px;
      display: flex;
      color: #7f8490;
      font-size: 12px;
      font-weight: 500;
      height: 36px;
      line-height: 36px;

      .time {
        width: 108px;
      }

      .price {
        width: 108px;
      }

      .num {
        width: 108px;
        text-align: right;
      }
    }

    .depth-sub-content {
      padding: 0 13px 4px 16px;
      min-height: 500px;

      li {
        cursor: pointer;
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;

        .time {
          width: 108px;
          color: #1f2533;
        }

        .price {
          width: 108px;
        }

        .num {
          width: 108px;
          color: #080c34;
          text-align: right;

          &.red {
            color: #fa4d56;
          }

          &.green {
            color: #03ad79;
          }
        }
      }
    }
  }

  .pair-wrap {
    .pair-list {
      display: flex;
      height: 26px;
      line-height: 26px;

      .pair-name {
        width: 200px;
      }

      .pair-price {
        width: 200px;
        text-align: right;
      }

      .pair-updown {
        width: 200px;
        text-align: right;
      }
    }
  }

  .pair-wrap {
    .pair-list {
      display: flex;
      height: 26px;
      line-height: 26px;

      .pair-name {
        width: 200px;
      }

      .pair-price {
        width: 200px;
        text-align: right;
      }

      .pair-updown {
        width: 200px;
        text-align: right;
      }
    }
  }
}
</style>
