<template>
  <div>
    <SpreadCenterInner></SpreadCenterInner>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import SpreadCenterInner from "@/pages/ucenter/pages/spread-center/spread-center-inner.vue";
export default {
  mixins: [],

  data() {
    return {};
  },
  components: { SpreadCenterInner },
  computed: {},
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
