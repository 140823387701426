<template>
  <div class="verification-two">
    <p class="title">二次验证</p>
    <div class="content">
      <DBind
        :accountInfo="accountInfo"
        v-if="emailStatus"
        type="email"
        :type_num="emailType"
        :disabled="true"
        :verificate="true"
        ref="email_ref"
        :bgColor="true"
      ></DBind>
      <DBind
        :accountInfo="accountInfo"
        v-if="mobileStatus"
        type="mobile"
        :type_num="mobileType"
        :disabled="true"
        :verificate="true"
        ref="mobile_ref"
        :bgColor="true"
      ></DBind>
      <DGoogle
        v-if="googleStatus !== 0"
        label="谷歌验证码"
        ref="google_ref"
      ></DGoogle>
      <div slot:footer class="button">
        <GButton
          class="common-cancel"
          text="取消"
          @click="$emit('onCancel')"
        ></GButton>
        <el-button
          class="common-save"
          @click="onConfirm"
          :loading="confirmLoading"
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import DBind from "../DBindNew";
import DGoogle from "../DGoogle";
import GButton from "../Button";
import { showMessage } from "@/components/dialog/message";
import { closeGoogleVerify, openGoogleVerify } from "@/model/ucenter/security";

export default {
  components: { DBind, DGoogle, GButton },
  props: ["accountInfo", "emailStatus", "mobileStatus", "googleStatus"],
  data() {
    return {
      confirmLoading: false,
    };
  },
  created() {},
  methods: {
    async onConfirm() {
      let params = {};
      let emailCheck, mobileCheck, googleCheck;
      emailCheck = this.$refs.email_ref
        ? this.$refs.email_ref.submitForm()
        : true;
      mobileCheck = this.$refs.mobile_ref
        ? this.$refs.mobile_ref.submitForm()
        : true;
      googleCheck = this.$refs.google_ref
        ? this.$refs.google_ref.submitForm()
        : true;
      if (!(emailCheck && mobileCheck && googleCheck)) return;

      if (this.$refs.email_ref) {
        let data = this.$refs.email_ref.submitForm();
        delete data.email;
        params = { ...data };
      }
      if (this.$refs.mobile_ref) {
        let data = this.$refs.mobile_ref.submitForm();
        delete data.area;
        delete data.mobile;
        params = Object.assign(params, { ...data });
      }
      if (this.$refs.google_ref) {
        let data = this.$refs.google_ref.submitForm();
        params = Object.assign(params, { ...data });
      }

      let result;
      // 提交，关闭谷歌认证
      if (this.googleStatus == 1) {
        result = await closeGoogleVerify(params);
        this.subTips(result, "关闭谷歌认证成功");
      } else if (this.googleStatus == 2) {
        result = await openGoogleVerify(params);
        this.subTips(result, "开启谷歌认证成功");
      }
    },
    subTips(res, msg) {
      if (res.status == 200) {
        showMessage({ message: msg, type: "success" });
        this.$emit("subSuccess");
        this.$emit("onCancel");
      }
    },
  },
  computed: {
    emailType() {
      return this.googleStatus == 2 ? "27" : "29";
    },
    mobileType() {
      return this.googleStatus == 2 ? "28" : "30";
    },
  },
};
</script>

<style lang="scss">
// 二次验证
.verification-two {
  text-align: left;
  .title {
    color: #354052;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    padding-left: 30px;
    font-weight: 500;
  }
  .content {
    // padding: 0px 50px 40px 30px;
    padding: 0px 30px 30px;
    p {
      color: #354052;
      font-size: 12px;
      // height: 12px;
      margin-bottom: 15px;
      &:first-child {
        margin-bottom: 6px;
      }
    }
    .button {
      margin-top: 25px;
      display: flex;
      justify-content: center;

      button {
        width: 170px;
        height: 40px;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
