/* 保留小数精度 (单纯截取，不向上向下取整) */
export const accuracy = (num, digit) => {
  num = num.toString();
  
  if (num.indexOf('.') === -1) {
    if (digit !== 0) {
      num = num + '.';
      num = loopAddZero(num, digit);
    }
  } else {
    let digitLength = num.split('.')[1].length;
    if (digitLength < digit) {
      num = loopAddZero(num, digit - digitLength);
    } else {
      if (digit === 0) {
        num = num.slice(0, num.indexOf('.') + digit);
      } else {
        num = num.slice(0, num.indexOf('.') + digit + 1);
      }
    }
  }
  
  return num;
}

// 补零
const loopAddZero = (num, frequency) => {
  let zero = '';
  for (let i=0; i < frequency; i++) {
    zero = zero + '0';
  }
  num = num + zero
  return num;
}