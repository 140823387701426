import store from "@/store";
import Cookies from "js-cookie";
// 未登录可以访问的页面
const whiteList = [
  "/",
  "/login",
  "/register",
  "/login/mobile",
  "/loginSecurity",
];
const notLoginList = [
  "/login",
  "/ucenter/assets",
  "/ucenter/security-setting",
  "/ucenter/situation",
  "/ucenter/finanManagement",
  "/ucenter/spread-center",
  "/ucenter/coupon-center",
  "/ucenter/safe-setting",
];
export default function (router) {
  router.beforeEach((to, from, next) => {
    // 白名单不做登录验证
    // if (whiteList.some(path => to.path.includes(path))) {
    //   next();
    // } else {
    //   let temmToken = Cookies.get("topcredit_exchange_t");
    //   if (temmToken) {
    //     store.dispatch("user/getUserInfo");
    //   } else {
    //     if (notLoginList.some(path => to.path.includes(path))) {
    //       next({
    //         path: "/login",
    //       });
    //     } else {
    //       next();
    //     }
    //   }
    //   next();
    // }
    if (whiteList.some(path => to.path.includes(path))) {
      if (to.query.ref) {
        sessionStorage.setItem("invite_code", to.query.ref);
      }
      if (to.query.forward_url == 2) {
        next({
          path: "/register",
        });
      }
      next();
    } else {
      let temmToken = Cookies.get("topcredit_exchange_t");
      if (temmToken) {
        store.dispatch("user/getUserInfo");
      } else {
        if (notLoginList.some(path => to.path.includes(path))) {
          next({
            path: "/login",
          });
        } else {
          next();
        }
      }
      next();
    }
  });
}
