<template>
  <div class="stock-component-panel">
    <div>
      <div class="first-item">
        <div class="coin-item panel-item">
          {{ from_symbol_show }}
        </div>
        <!-- <div class="price-item panel-item V2">
            <span class="multiple-num">
              {{ translateTitle("杠杆") }} {{ multipleNum }} X
              <img src="../../../assets/img/arrow-down_clear.png" alt="" />
            </span>
          </div> -->
        <div class="stock-code">
          <img class="stock-icon" src="../../../assets/img/stock_us.png" />
          PBR
        </div>
      </div>
      <div class="price-item panel-item V3">
        <div class="price DINPro" :class="colorClass">
          <div class="market-last-price">{{ marketInfoShow.last || "--" }}</div>
          <span class="price-cny">
            ≈
            {{ systemCurrencyMaps[systemCurrency] }}
            <span class="DINPro">{{
              marketInfoShow.currency[systemCurrency] || "--"
            }}</span>
          </span>
        </div>
        <!-- <p class="price-cny">
            ≈
            {{ systemCurrencyMaps[systemCurrency] }}
            <span class="DINPro">{{
              marketInfoShow.currency[systemCurrency] || "--"
            }}</span>
          </p> -->
      </div>
      <div class="panel-item">
        <div class="price DINPro" :class="colorClass">
          <span class="value increase-item DINPro" :class="className">
            <span
              v-if="marketInfoShow.price_change_percent > 0"
              style="margin-right: -2px; width: 7px; display: inline-block"
              >+</span
            >
            {{ marketInfoShow.price_change_percent || "--" }}%
          </span>
          <span class="arrow" v-if="colorClass == 'green'">↑</span>
          <span class="arrow" v-else-if="colorClass == 'red'">↓</span>
        </div>
        <div class="price DINPro" :class="colorClass">
          <span class="value increase-item DINPro" :class="className">
            3517.80
          </span>
        </div>
      </div>
      <div class="market-time">
        <div class="time-text">交易中</div>
        <div class="time-text">13:05:52 EDT</div>
      </div>
      <div class="heigh-item panel-item">
        <p class="item">{{ translateTitle("24H高") }}</p>
        <p class="value DINPro">{{ marketInfoShow.high || "--" }}</p>
      </div>
      <div class="low-item panel-item">
        <p class="item">{{ translateTitle("24H低") }}</p>
        <p class="value DINPro">{{ marketInfoShow.low || "--" }}</p>
      </div>

      <!-- <div class="low-item panel-item">
        <p class="item">{{ translateTitle("24H量") }}</p>
        <p class="value">
          <span class="DINPro" v-if="Number(marketInfoShow.vol) > 0">
            {{ Number(marketInfoShow.vol).toFixed(2) }}</span
          >
          <span class="DINPro" v-else>--</span>
          {{ from_symbol_show }}
        </p>
      </div> -->
      <!-- <div class="low-item panel-item">
        <p class="item">{{ translateTitle("本期资金费率") }}</p>
        <p class="value red">
          <span class="DINPro"> {{ fundForm.fund_rate || "--" }}</span>
        </p>
      </div> -->
      <!-- <div class="low-item panel-item">
        <p class="item">{{ translateTitle("倒计时") }}</p>
        <p class="value red">
          <span class="DINPro"> {{ formatSeconds(fundForm.time) }}</span>
        </p>
      </div> -->
    </div>
    <div class="collet-item">
      <div
        class="border V2"
        v-if="selfOptionsList.indexOf(swap_symbol_id.toString()) > -1"
      >
        <img
          class="select-icon"
          src="../../../assets/img/exchange/active.png"
          @click="addOption(2)"
          alt=""
        />
      </div>
      <div class="border" v-else>
        <img
          class="select-icon"
          src="../../../assets/img/exchange/noActive.png"
          @click="addOption(1)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  apiAddOption,
  apiGetOptionList,
  apiGetFundingRate,
} from "@/model/markets/markets";
import * as Account from "@/model/user/account";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import { mapGetters } from "vuex";
// import { formatSeconds } from "@/assets/js/formatDate";
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  props: {
    swap_symbol_id: {
      type: [String, Number],
      default: function() {
        return 100901;
      },
    },
    tradePairArray: {
      type: Array,
      default: function() {
        return [];
      },
    },
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    from_symbol: {
      type: [String, Number],
    },
    to_symbol: {
      type: [String, Number],
    },
  },
  data() {
    return {
      systemCurrencyMaps,
      multipleNum: 20,
      timer: null,
      multipleNumBtn: false,
      colorClass: "",
      fundForm: {
        fund_rate: "",
        time: 0,
      },
      selfOptionsList: [],
    };
  },
  created() {
    // let tempMultipleNum = localStorage.getItem("stock-multiple-num");
    // if (tempMultipleNum) {
    //   this.multipleNum = tempMultipleNum;
    // }
    this.getOptionList();
    // this.getFundingRate();
    // this.startTimer();
  },
  methods: {
    routeSplicing,
    //将秒数转换为时分秒格式
    formatSeconds(s) {
      var t;
      if (s > -1) {
        var hour = Math.floor(s / 3600);
        var min = Math.floor(s / 60) % 60;
        var sec = s % 60;
        if (hour < 10) {
          t = "0" + hour + ":";
        } else {
          t = hour + ":";
        }

        if (min < 10) {
          t += "0";
        }
        t += min + ":";
        if (sec < 10) {
          t += "0";
        }
        t += sec.toFixed(0);
      }
      if (t == "00:00:00") {
        t = "--";
      }
      return t;
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.getFundingRate();
        this.startTimer();
      }, 1000);

      this.$once("hook:beforeDestroy", () => {
        clearTimeout(this.timer);
      });
    },
    // onMultipleNumClick() {
    //   this.$emit("onMultipleNumClick", this.multipleNum);
    // },
    setMultipleNum(value) {
      this.multipleNum = value;
    },
    getFundingRate() {
      if (this.swap_symbol_id) {
        let params = {
          symbol_id: this.swap_symbol_id,
        };
        return apiGetFundingRate(params).then(res => {
          if (res.status == 200) {
            this.fundForm = res.data;
          }
        });
      }
    },
    async getOptionList() {
      let { userInfo } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      apiGetOptionList().then(res => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
    addOption(type) {
      if (this.userInfo) {
        let params = {
          symbol_id: this.swap_symbol_id,
          option: type,
          swap_type: 2,
        };
        apiAddOption(params).then(res => {
          if (res.status == 200) {
            this.getOptionList();
            this.$emit("onAddOption");
            this.$message({
              message:
                params.option == 1
                  ? this.translateTitle(`添加自选成功`)
                  : this.translateTitle(`删除自选成功`),
              type: params.option == 1 ? "success" : "success",
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
      }
    },
  },
  watch: {
    swap_symbol_id() {
      let tempMultipleNum = JSON.parse(
        localStorage.getItem("stock-multiple-num")
      );
      for (const key in tempMultipleNum) {
        if (key == this.swap_symbol_id) {
          this.multipleNumBtn = true;
          this.multipleNum = Number(tempMultipleNum[key]);
          setTimeout(() => {
            this.multipleNumBtn = false;
          }, 1000);
        }
      }
      if (this.multipleNumBtn == false) {
        this.multipleNum = 20;
      }
    },
    marketInfoShow(newValue, oldValue) {
      if (Number(newValue.last) - Number(oldValue.last) > 0) {
        this.colorClass = "green";
      } else if (Number(newValue.last) - Number(oldValue.last) < 0) {
        this.colorClass = "red";
      }
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language",
    }),
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    marketInfoShow() {
      // this.pageLoading = true;
      let marketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      // this.pageLoading = false;
      return marketInfo;
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
  },
};
</script>
<style lang="scss">
.stock-component-panel {
  height: 50px;
  background-color: #f5f5f7;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: space-between;
  .first-item {
    display: inline-block;
  }
  .panel-item {
    display: inline-block;
    .red {
      color: #c61b3d;
    }
    .green {
      color: #03ad79;
    }
  }
  .collet-item {
    margin-right: 10px;
    margin-top: -10px;
    .border {
      width: 24px;
      height: 24px;
      border-radius: 2px;
      border: solid 1px #bec1c8;
      display: flex;
      justify-content: center;
      align-items: center;
      &.V2 {
        border: solid 1px #3348f6;
      }
      .select-icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
        // vertical-align: middle;
        // margin: 3px 3px 4px;
      }
    }
  }
  .coin-item {
    min-width: 86px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
    width: fit-content;
    margin-right: 10px;
    // margin-top: 8px;
    color: #354052;
    // height: 39px;
    vertical-align: middle;
  }
  .stock-code {
    font-size: 12px;
    color: #354052;
    height: 18px;
    margin-left: 15px;
    .stock-icon {
      width: 15px;
      height: 10px;
    }
  }
  .area-btn {
    width: 36px;
    height: 18px;
    line-height: 16px;
    border-radius: 2px;
    border: solid 1px #3348f6;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    color: #3348f6;
    font-weight: 500;
  }
  .price-item {
    min-width: 110px;
    // margin-left: 12px;
    &.V3 {
      margin-left: 15px;
      min-width: 90px;
    }
    &.V2 {
      min-width: 80px;
      // margin-top: -15px;
      vertical-align: middle;
      // .multiple-num {
      //   cursor: pointer;
      //   width: 68px;
      //   height: 18px;
      //   margin: 7px 20px 1px 5px;
      //   padding: 0px 8px 0 8px;
      //   border-radius: 2px;
      //   background-color: #3348F6;
      //   font-size: 12px;
      //   font-weight: 600;
      //   color: #ffffff;
      //   text-align: center;
      //   line-height: 18px;
      //   img {
      //     vertical-align: 10%;
      //     width: 6px;
      //     height: 4px;
      //   }
      // }
    }
    .price {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;

      color: #354052;
      &.green {
        color: #03ad79;
      }
      &.red {
        color: #c61b3d;
      }
      // .arrow {
      //   display: inline-block;
      //   width: 15px;
      //   height: 19px;
      //   font-size: 12px;
      //   text-align: right;
      //   &.V2 {
      //     color: #f5f5f7;
      //   }
      // }
      .market-last-price {
      }
    }
    .price-cny {
      display: inline-block;
      color: #7f8490;
      font-size: 12px;
    }
  }
  .arrow {
    display: inline-block;
    width: 15px;
    height: 19px;
    font-size: 12px;
    text-align: left;
    margin-left: -2px;
    &.V2 {
      color: #f5f5f7;
    }
  }
  .increase-item {
    font-size: 12px;
    margin-left: 3px;
    .item {
      color: #7f8490;
    }
    .value {
      margin-top: 3px;
      &.green {
        color: #03ad79;
      }
      &.red {
        color: #c61b3d;
      }
    }
  }
  .market-time {
    width: 90px;
    height: 40px;
    background-color: #fff;
    border-radius: 3px;
    border: solid 1px #3348f6;
    display: inline-block;
    vertical-align: 5%;
    margin-left: 10px;
    margin-right: 37px;
    padding: 3px 0 2px;
    .time-text {
      font-size: 12px;
      font-weight: 500;
      color: #3348f6;
      text-align: center;
    }
  }
  .heigh-item,
  .low-item {
    margin-left: 23px;
    font-size: 12px;
    margin-bottom: 10px;
    .item {
      color: #7f8490;
    }
    .value {
      margin-top: 3px;
      color: #1f2533;
      &.red {
        color: #c61b3d;
      }
    }
  }
}
</style>
