<template>
  <div id="side-menu" class="side-menu-inner">
    <!-- <div class="nav"> -->
    <el-menu default-active="2" class="el-menu-vertical-demo">
      <!-- <ul> -->
      <el-submenu index="1">
        <template slot="title">
          <router-link
            :to="routeSplicing(language, 'ucenter/situation')"
            tag="li"
          >
            <i class="iconfont iconmenu-1"></i>
            {{ translateTitle("个人总览") }}
          </router-link>
        </template>
        <el-menu-item index="1-1">
          <router-link
            class="hide-icon"
            :to="routeSplicing(language, 'ucenter/safe-setting')"
            tag="li"
            v-if="getShowHideMenu(5)"
          >
            <i class="iconfont icongerenzhongxin-5 hide-icon"></i>
            {{ translateTitle("安全设置") }}
          </router-link>
          <router-link
            class="hide-icon"
            v-if="false"
            :to="routeSplicing(language, 'ucenter/kyc-setting')"
            tag="li"
          >
            <i class="iconfont icongerenzhongxin-5 hide-icon"></i>
            {{ translateTitle("KYC设置") }}
          </router-link>
        </el-menu-item>
      </el-submenu>

      <el-menu-item index="2">
        <router-link
          index="2"
          tag="li"
          :to="routeSplicing(language, 'ucenter/assets')"
        >
          <i class="iconfont iconmenu-6"></i>
          {{ translateTitle("资产管理") }}
        </router-link>
      </el-menu-item>
      <el-menu-item index="3">
        <router-link
          index="3"
          tag="li"
          :to="routeSplicing(language, 'ucenter/tradeManagment')"
        >
          <i class="iconfont iconswap-1"></i>
          {{ translateTitle("交易管理") }}
        </router-link>
      </el-menu-item>

      <el-submenu index="4" v-if="!is8Coin">
        <template slot="title">
          <router-link
            :to="routeSplicing(language, 'ucenter/finanManagementTemp')"
            tag="li"
          >
            <i class="iconfont iconmenu-8"></i>
            {{ translateTitle("理财管理") }}
          </router-link>
        </template>
        <el-menu-item index="4-1">
          <router-link
            class="hide-icon"
            :to="routeSplicing(language, 'ucenter/finanManagement')"
            tag="li"
          >
            <i class="iconfont hide-icon"></i>
            {{ translateTitle("金融理财") }}
          </router-link>
        </el-menu-item>
      </el-submenu>

      <el-menu-item index="5">
        <router-link
          index="5"
          tag="li"
          :to="routeSplicing(language, 'ucenter/spread-center')"
        >
          <i class="iconfont iconicon-rebate"></i>
          {{ translateTitle("推广中心") }}
        </router-link>
      </el-menu-item>
      <el-menu-item index="7" @click="report()">
        <router-link
          index="7"
          tag="li"
          :to="routeSplicing(language, 'ucenter/bill-center')"
        >
          <i class="iconfont iconmenu-5"></i>
          {{ translateTitle("账单") }}
        </router-link>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import * as Account from "@/model/user/account";
import { mapActions, mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  props: {
    data: Array,
  },
  data() {
    return {
      getShowHideMenu: Account.getShowHideMenu,
      isCollapse: false,
      is8Coin: true,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      environment: "user/environment",
    }),
  },
  created() {},
  methods: {
    routeSplicing,
    report() {
      this.$sensors.track("Asset_Click", {
        first_level_tab: "spot",
        second_level_tab: "",
        button_name: "bill",
        currency_type: "",
      });
    },
  },
};
</script>
<style lang="scss">
.side-menu-inner {
  // min-height: 989px;
  user-select: none;
  width: 206px;

  .el-menu {
    border-right: solid 0px #e6e6e6;
  }

  ul {
    position: relative;

    .el-submenu {
      padding-left: 0px !important;
      border-left: 0px;

      .el-submenu__title {
        padding: 0px !important;
        height: auto;
        line-height: 44px;

        &:hover {
          background-color: #fff;
        }
      }

      .el-menu {
        .el-menu-item {
          padding: 0px !important;
          border-left: 0px;
          height: auto;
          line-height: 44px;

          &:focus,
          &:hover {
            outline: 0;
            background-color: #fff;
          }
        }

        .is-active {
          background-color: #fff !important;
        }
      }
    }

    .el-menu-item {
      padding: 0px !important;
      border-left: 0px;
      height: auto;
      line-height: 44px;

      &:focus,
      &:hover {
        outline: 0;
        background-color: #fff;
      }
    }

    .is-active {
      background-color: #fff !important;
    }

    li {
      padding-left: 15px;
      width: 100%;
      border-left: 4px solid #ffffff;
      box-sizing: border-box;
      line-height: 44px;
      color: #959ba7;
      font-size: 14px;
      cursor: pointer;

      &.hide-icon {
        span {
          font-weight: 400;
        }

        i {
          margin-right: 30px;
          width: 15px;
          visibility: hidden;
        }
      }

      i {
        margin-right: 13px;
      }

      span {
        font-weight: 600;
      }

      .iconfont {
        width: 17px;
        font-size: 17px;
        display: inline-block;
      }

      .iconfont:before {
        font-size: 15px;
      }

      .icongerenzhongxin-5:before {
        font-size: 15px;
      }

      .icongerenzhongxin-3:before {
        font-size: 15px;
      }

      .icongerenzhongxin-7:before {
        font-size: 15px;
      }

      .icongerenzhongxin-8:before {
        font-size: 15px;
      }

      .icongerenzhongxin-6:before {
        font-size: 13px;
      }

      .iconqudaodaili:before {
        font-size: 14px;
      }
    }

    .router-link-active {
      font-weight: 500;
      background-color: #f1f2f5;
      color: #354052;
      border-left: 4px solid #d60c30;
      box-sizing: border-box;

      i {
        color: #d60c30;
      }
    }
  }

  // .el-menu-item {
  //   background: #ffffff !important;
  //   .router-link-active {
  //     color: #354052 !important;
  //     background: #ffffff !important;
  //     border: 0 !important;
  //     box-sizing: border-box;
  //   }
  // }
  // .el-menu {
  //   .el-submenu {
  //     text-align: left;
  //   }

  //   .el-submenu__title {
  //     padding-left: -20px !important;
  //     padding: 0px !important;
  //     height: 40px;
  //     line-height: 40px;
  //     color: #959ba7 !important;
  //     font-size: 14px;
  //     cursor: pointer;
  //     &:hover {
  //       background: #ffffff;
  //     }
  //     a {
  //       color: #959ba7 !important;
  //       font-size: 14px;
  //       cursor: pointer;
  //     }
  //   }
  //   .el-menu--inline {
  //     .el-menu-item {
  //       height: 40px;
  //       padding-left: 30px !important;
  //       &:hover {
  //         background: #ffffff;
  //       }
  //       &.is-active {
  //         background: #ffffff;
  //       }
  //       span {
  //         i {
  //           color: #959ba7 !important;
  //           font-size: 14px !important;

  //           cursor: pointer;
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>
