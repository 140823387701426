import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";
//用户卡券中心
export function apiGetUserVoucherList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UserVoucher/getList`,
    data,
    type: "POST",
  });
}
