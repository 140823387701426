<template>
  <div class="changePwd">
    <el-form
      label-position="top"
      class="pwd-form"
      ref="changePwdForm"
      :rules="formRules"
      :model="formData"
    >
      <el-form-item :label="translateTitle('原登录密码')" prop="old_password"
        ><el-input
          v-model.trim="formData.old_password"
          autocomplete="off"
          :placeholder="translateTitle('请输入原登录密码')"
          type="password"
          maxlength="20"
        ></el-input
      ></el-form-item>
      <el-form-item
        :label="translateTitle('新登录密码')"
        prop="password"
        class="nthChild2"
        ><el-input
          v-model.trim="formData.password"
          autocomplete="off"
          type="password"
          :placeholder="translateTitle('请输入新登录密码')"
          maxlength="20"
        ></el-input
      ></el-form-item>
      <el-form-item prop="confirmNewPwd" class="lastChild">
        <div slot="label">
          <span style="color: #F56C6C">*</span>
          {{ translateTitle("确认新密码") }}
        </div>
        <el-input
          v-model.trim="formData.confirmNewPwd"
          autocomplete="off"
          type="password"
          :placeholder="translateTitle('请输入确认新密码')"
          maxlength="20"
        ></el-input
      ></el-form-item>
    </el-form>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";

export default {
  mixins: [commonMixin],
  data() {
    return {
      formData: {
        old_password: "",
        password: "",
        confirmNewPwd: "",
      },
      formRules: {
        old_password: [
          {
            required: true,
            message: this.translateTitle(`请输入原登录密码`),
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: this.translateTitle(`密码长度为 6-20 位字符`),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.translateTitle(`请输入新登录密码`),
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: this.translateTitle(`密码长度为 6-20 位字符`),
            trigger: "blur",
          },
          {
            pattern: /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/,
            message: this.translateTitle(
              `密码必须包含英文和数字，不能包含空格`
            ),
          },
          { validator: this.confirmOldNew, trigger: "blur" },
        ],
        confirmNewPwd: [{ validator: this.confirmBlur, trigger: "blur" }],
      },
    };
  },
  methods: {
    clearCode() {
      this.formData = {
        old_password: "",
        password: "",
        confirmNewPwd: "",
      };
    },
    // 重复新/旧密码校验
    confirmOldNew(rule, value, callback) {
      let { old_password } = this.formData;

      if (value === "" || !value) {
        callback(new Error(this.translateTitle("请输入新登录密码")));
      } else if (value === old_password) {
        callback(new Error(this.translateTitle("新密码不能与原密码相同")));
      } else {
        callback();
      }
    },
    // 重复密码校验
    confirmBlur(rule, value, callback) {
      let { password } = this.formData;

      if ((value === "") | !value) {
        callback(new Error(this.translateTitle("请输入确认密码")));
      } else if (value !== password) {
        callback(new Error(this.translateTitle("两次输入密码不一致!")));
      } else {
        callback();
      }
    },
    // 提交表单方法
    submitForm() {
      let statusData;
      let { formData } = this;
      this.$refs.changePwdForm.validate(valid => {
        if (valid) {
          statusData = formData;
        } else {
          statusData = false;
        }
      });

      return JSON.parse(JSON.stringify(statusData));
    },
  },
};
</script>
<style lang="scss">
.changePwd {
  .g-form {
    input:focus {
      border-color: #27c683;
    }
    .el-form-item__label {
      color: #9aa1b1 !important;
    }
    .el-form-item {
      margin-bottom: 15px;
      .el-form-item__label {
        color: #354052;
      }
    }
  }
}
</style>
