<template>
  <div class="stock-component-deal">
    <div v-if="showTitle" class="depth-header">
      {{ translateTitle("实时成交") }}
    </div>
    <div class="depth-percent">
      <div class="transaction-parties">
        <div class="buyer">买20.00%</div>
        <div class="seller">卖80.00%</div>
      </div>
      <div class="stock-parties">
        <div class="buyer">2210股</div>
        <div class="seller">2210股</div>
      </div>
      <div class="stock-percent-bar">
        <div class="buyer" :style="{ width: 20 + '%' }"></div>
        <div class="seller" :style="{ width: 80 + '%' }"></div>
      </div>
      <div class="value-parties">
        <div class="item">56971.20</div>
        <div class="item">60334.20</div>
      </div>
    </div>
    <div class="depth-content">
      <ul class="depth-sub-title">
        <li class="time">{{ translateTitle("时间") }}</li>
        <li class="price">
          {{ translateTitle("价格") }}({{ to_symbol_show }})
        </li>
        <li class="num">
          <!-- {{ translateTitle("数量") }}({{ from_symbol_show }}) -->
          {{ translateTitle("数量") }}(股)
        </li>
      </ul>
      <ul class="depth-sub-content" v-loading="loading">
        <li v-for="(item, index) in showList" v-bind:key="index">
          <div class="time DINPro" v-if="item.p == '--'">--</div>
          <div class="time DINPro" v-else>{{ getValueTime(item.T) }}</div>
          <div
            class="price DINPro"
            :class="Number(item.m) == 1 ? 'green' : 'red'"
          >
            {{ item.p }}
          </div>
          <div class="num DINPro">{{ item.q }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getValueTime } from "@/assets/js/getTime";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  props: {
    orderbookList: {
      type: Array,
      default: function() {
        return [
          { T: "--", p: "--", q: "--" },
          { T: "--", p: "--", q: "--" },
          { T: "--", p: "--", q: "--" },
          { T: "--", p: "--", q: "--" },
          { T: "--", p: "--", q: "--" },
        ];
      },
    },
    showTitle: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
    swap_symbol_id: {
      type: [String, Number],
    },
    marketList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    from_symbol: {
      type: [String, Number],
      default: function() {
        return "";
      },
    },
    to_symbol: {
      type: [String, Number],
      default: function() {
        return "";
      },
    },
  },
  methods: {
    getValueTime,
  },
  computed: {
    loading() {
      let val = 1;
      if (this.orderbookList[0]) {
        if (this.orderbookList[0].T == "--") {
          val = 1;
        } else {
          val = 0;
        }
      } else {
        val = 0;
      }
      return val;
    },
    showList() {
      return this.orderbookList.slice(0, 21);
    },
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
  },
};
</script>
<style lang="scss">
.stock-component-deal {
  user-select: none;
  .depth-header {
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    background-color: #f5f5f7;
    font-size: 14px;
    font-weight: 600;
    color: #1f2533;
    padding-left: 14px;
  }
  .depth-percent {
    height: 103px;
    padding: 9px 15px 0;
    border-bottom: solid 1px #f1f2f5;
    .transaction-parties {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 26px;
      font-size: 14px;
      .buyer {
        color: #e81b40;
      }
      .seller {
        color: #03ad79;
      }
    }
    .stock-parties {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 18px;
      font-size: 12px;
      .buyer {
        color: #e81b40;
      }
      .seller {
        color: #03ad79;
      }
    }
    .stock-percent-bar {
      width: 100%;
      height: 4px;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      .buyer {
        height: 100%;
        background-color: #e81b40;
      }
      .seller {
        height: 100%;
        background-color: #03ad79;
      }
    }
    .value-parties {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 18px;
      font-size: 12px;
      margin-top: 6px;
      .item {
        color: #354052;
      }
    }
  }
  .depth-content {
    .depth-sub-title {
      padding: 0 13px 0 16px;
      display: flex;
      color: #7f8490;
      font-size: 12px;
      font-weight: 500;
      height: 36px;
      line-height: 36px;
      .time {
        width: 65px;
      }
      .price {
        color: #7f8490;
        width: 110px;
        text-align: right;
      }
      .num {
        width: 124px;
        text-align: right;
      }
    }
    .depth-sub-content {
      padding: 0 13px 4px 16px;
      li {
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        .time {
          width: 65px;
          color: #1f2533;
        }
        .price {
          width: 110px;
          text-align: right;
          &.red {
            color: #c61b3d;
          }
          &.green {
            color: #03ad79;
          }
        }
        .num {
          width: 124px;
          color: #354052;
          text-align: right;
        }
      }
    }
  }
}
</style>
