<template>
  <div class="trade-title__container">
    <div class="trade-title__container-title">
      {{ translateTitle("要交易 用4E") }}
    </div>
    <div class="trade-title__container-tips">
      {{ translateTitle("一站式投资全球热门资产 投资无界限") }}
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";

export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {};
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.trade-title__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 498px; */
  padding-top: 63px;
  background-color: var(--homeChartBgColor);
  /* background-image: var(
    --homeWroldImg
  ); //url(../../../assets/img/world-light.jpg); */
  color: var(--homeFontColor);
  &-title {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    margin-bottom: 18px;
  }
  &-tips {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 28px;
    color: var(--homeLightGary);
  }
}
</style>
