import api from "@/assets/js/api";
import Cookies from "js-cookie";

import { VUE_APP_UCENTER } from "@/assets/js/stationSetting";
// 获取用户信息
export const getUserInfo = (data = {}) => {
  if (!Cookies.get("topcredit_exchange_t")) return;
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/getUserInfo`,
    data,
    type: "POST",
  });
};

// 修改用户信息
export const editUserInfo = (data = {}) => {
  if (!Cookies.get("topcredit_exchange_t")) return;
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/editUserInfo`,
    data,
    type: "POST",
  });
};
// 获取静态表
export const getStaticTableList = () => {
  return api(VUE_APP_UCENTER + "/Settings/getStaticTableList");
};
// 获取静态表详情
export const getStaticTableInfo = data => {
  return api(VUE_APP_UCENTER + "/Settings/getStaticTableInfo", data);
};
