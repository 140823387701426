<template>
  <div class="security">
    <div class="security-content">
      <div class="security-title">{{ translateTitle("安全项丢失") }}</div>
      <div class="sub-title">
        {{ translateTitle("点击安全项丢失后，48小时内禁止提币和OTC") }}
      </div>
      <div class="step-content">
        <div class="step-two">
          <div class="title">{{ translateTitle("请选择遗失的安全项") }}</div>
          <el-radio-group v-model="checkRadio">
            <el-radio v-if="bindEmail" :label="1">{{
              translateTitle("邮箱")
            }}</el-radio>
            <el-radio v-if="bindMobile" :label="2">{{
              translateTitle("手机")
            }}</el-radio>
            <el-radio v-if="bindGoogle" :label="3">{{
              translateTitle("谷歌")
            }}</el-radio>
          </el-radio-group>

          <div class="title">
            {{ translateTitle("您的联络方式") }}
            <span v-if="checkRadio == 1"> ({{ translateTitle("邮箱") }}) </span>
            <span v-if="checkRadio == 2"> ({{ translateTitle("手机") }}) </span>
          </div>
          <div class="contact">
            <el-input
              @input="onFilterContact"
              clearable
              v-model="contact"
              :placeholder="translateTitle('您的联络方式')"
            ></el-input>
          </div>

          <div class="start-btns">
            <div
              :loading="submitLoading"
              v-if="getIsApply"
              @click="onStartApply"
              class="start is-apply theme-btn"
            >
              {{ translateTitle("确定") }}
            </div>
            <div v-else class="start">{{ translateTitle("确定") }}</div>
          </div>
          <div @ class="cancel">
            <span>
              <router-link class="cancel" :to="routeSplicing(language, 'login')"
                >{{ translateTitle("取消") }}
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
    <Vcode
      :imgs="[img1]"
      :show="isShow"
      @success="success"
      :successText="translateTitle('验证通过')"
      :failText="translateTitle('验证失败，请重试')"
      :sliderText="translateTitle('拖动滑块完成拼图')"
    />
  </div>
</template>
<script>
import Vcode from "vue-puzzle-vcode"; //页面内引用插件
import img1 from "../../assets/img/rePhoto/2.png";
import { apiSafelyLost } from "@/model/login/index";
import { showMessage } from "@/components/dialog/message.js";
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
import { forgotPwdCheckAccount } from "@/model/user/account";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  data() {
    return {
      isShow: false, // 用来控制显示是否显示图片滑动验证框
      photoHua: 0, //图片滑动验证的错误次数
      img1,
      orderStep: 1,
      account: "",
      bindEmail: false,
      bindMobile: false,
      bindGoogle: false,
      isSubmint: false,
      email: "khreui@qq.com",
      mobile: "123klsjflku3o",
      checkList: [],
      checkRadio: "",
      contact: "",
      submitLoading: false,
    };
  },
  components: { Vcode },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
    getIsApply() {
      let isApply = false;
      if (this.checkRadio && this.contact) {
        isApply = true;
        return isApply;
      } else {
        isApply = false;
        return isApply;
      }
    },
  },
  async created() {
    let type = this.$route.query.type;
    if (type) {
      await this.getUserInfoV2();
    } else {
      await this.getUserInfo();
    }
  },
  methods: {
    routeSplicing,
    translateTitle,

    onFilterContact(val) {
      this.contact = val.replace(/[^\a-\z\A-\Z0-9\@\-\.\$]/g, "");
    },
    async getUserInfo() {
      const { authInfo } = JSON.parse(localStorage.getItem("userData"));
      if (authInfo) {
        this.bindEmail = authInfo.open_email == 0 ? false : true;
        this.bindMobile = authInfo.open_mobile == 0 ? false : true;
        this.bindGoogle = authInfo.open_google == 0 ? false : true;
        if (this.bindEmail) {
          this.checkRadio = 1;
        } else if (this.bindMobile) {
          this.checkRadio = 2;
        } else if (this.bindGoogle) {
          this.checkRadio = 3;
        }
      }
    },
    async getUserInfoV2() {
      const authInfo = JSON.parse(sessionStorage.getItem("user_info"));
      if (authInfo) {
        this.bindEmail = authInfo.validate_email == 0 ? false : true;
        this.bindMobile = authInfo.validate_mobile == 0 ? false : true;
        this.bindGoogle = authInfo.validate_google == 0 ? false : true;
        if (this.bindEmail) {
          this.checkRadio = 1;
        } else if (this.bindMobile) {
          this.checkRadio = 2;
        } else if (this.bindGoogle) {
          this.checkRadio = 3;
        }
      }
    },
    success() {
      this.orderStep = 2;
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },

    async onStartApply() {
      this.submitLoading = true;
      const req = {
        type: this.checkRadio,
        contact: this.contact,
        token: sessionStorage.getItem("topcredit_exchange_t"),
      };
      const { status, data } = await apiSafelyLost(req);
      this.submitLoading = false;
      if (status == 200) {
        showMessage({
          message: this.translateTitle(`提交安全项丢失成功`),
          type: "success",
          duration: 1200,
        });
        this.$router.push(this.routeSplicing(this.language, "login"));
      }
    },
  },
};
</script>
<style lang="scss">
.security {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  display: flex;
  // min-height: 709px;
  height: calc(100vh - 60px);
  background-image: linear-gradient(174deg, #19273c 12%, #273244 92%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  // background-size: 100% 100%;

  .el-form {
    text-align: left !important;
  }
  .cancel {
    margin-top: 20px;
    color: #3348f6;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  .security-content {
    padding: 23px 50px 40px 50px;
    width: 500px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
    background-color: #ffffff;

    .security-title {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .sub-title {
      font-size: 14px;
      color: #354052;
      font-weight: 400;
      margin-top: 15px;
      text-align: center;
    }
    .el-radio-group {
      margin: 10px 0px 25px;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      .is-checked {
        .el-radio__label {
          color: #354052 !important;
          font-size: 14px;
          font-weight: 400;
        }
        .el-radio__input {
          .el-radio__inner {
            border-color: #3348f6 !important;
            background: #3348f6 !important;
          }
        }
      }

      // .el-radio__inner {
      //   border: 1px solid #dde0e5;
      //   border-radius: 100%;
      //   width: 18px;
      //   height: 18px;
      //   background-color: #fff;
      //   cursor: pointer;
      //   box-sizing: border-box;
      // }
      // .el-radio-group .el-radio__inner {
      //   border: 1px solid #3348F6;
      //   border-radius: 100%;
      //   width: 18px;
      //   height: 18px;
      //   background-color: #fff;
      //   cursor: pointer;
      //   box-sizing: border-box;
      // }
    }
    .contact {
      margin-top: 10px;
      .el-input__inner {
        height: 54px;
        border: solid 1px #e2e2e4;
      }
    }

    .step-content {
      text-align: center;
      .step-one {
        .account {
          margin-top: 40px;
          .el-input__inner {
            width: 400px;
            height: 48px;
          }
        }
        .submit-btns {
          display: flex;
          justify-content: center;
          margin-top: 15px;
          .submit {
            width: 400px;
            height: 48px;
            border-radius: 2px;
            text-align: center;
            line-height: 48px;
            font-size: 14px;
            font-weight: 400;
            background-color: #f1f2f5;
            color: #9aa1b1;
          }
          .is-submit {
            background-image: linear-gradient(
              267deg,
              #ff486d 110%,
              #c61b3d 41%
            );
            border: 0;
            cursor: pointer;
            color: #ffffff;
          }
        }
      }
      .step-two {
        padding: 25px 0px 0px;
        .title {
          text-align: left;
          font-size: 16px;
          font-weight: 500;
          color: #354052;
        }
        .tips-content {
          margin-top: 6px;
          width: 400px;
          height: 104px;
          border-radius: 2px;
          background-color: #f3f7fc;
          padding: 14px 18px 15px 15px;
          font-size: 12px;
          color: #9aa1b1;
          font-weight: 400;
          .tips-item {
            display: flex;
            .dot {
              width: 8px;
              margin-top: -5px;
            }
            .content {
              width: 359px;
              text-align: left;
            }
          }
        }
        .start-btns {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          background-color: #f1f2f5;
          .start {
            color: #9aa1b1;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 48px;
            border-radius: 2px;
            width: 400px;
            height: 48px;
          }
          .is-apply {
            cursor: pointer;
            background-image: linear-gradient(267deg, #ff486d 10%, #c61b3d 41%);
            color: #ffffff;
          }
        }
        .check-content {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          text-align: left;
          .el-checkbox {
            height: 36px;
            line-height: 36px;
            .el-checkbox__inner {
              border-radius: 50%;
            }
            span {
              color: #354052;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
      .step-three {
        margin: 72px 0px 80px;
        text-align: center;
        .title {
          height: 36px;
          line-height: 36px;
          color: #354052;
          font-size: 14px;
          font-weight: 400;
        }
        .sub-title {
          font-size: 12px;
          color: #9aa1b1;
          font-weight: 400;
        }
        .tg-customer {
          text-align: center;
          width: 100%;
          margin: 30px 0px 20px;
          img {
            width: 98px;
            height: 98px;
            border: solid 1px #e2e2e4;
          }
          .title {
            color: #9aa1b1;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #c2c7d0 !important;
    font-weight: 400;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #c2c7d0 !important;
    font-weight: 400;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #c2c7d0 !important;
    font-weight: 400;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #c2c7d0 !important;
    font-weight: 400;
  }
}
</style>
