const state = {
    wsData: [], // WS 的响应数据
    exchangeSymbolMap: {}, // 币币
    coinItemIdMap: {},
    coinInfoListThirTeen: {},
    coinInfoMap: {},
    fxSymbolInfoMap: {},
};
const getters = {
    wsData: state => state.wsData,
    exchangeSymbolMap: state => state.exchangeSymbolMap,
    coinItemIdMap: state => state.coinItemIdMap,
    coinInfoListThirTeen: state => state.coinInfoListThirTeen,
    coinInfoMap: state => state.coinInfoMap,
    fxSymbolInfoMap: state => state.fxSymbolInfoMap,
};

const mutations = {
    setWsData(state, data) {
        state.wsData = data;
    },
    updateStaticData(state, data) {
        state.exchangeSymbolMap = data.exchangeSymbolMap;
        state.coinItemIdMap = data.coinItemIdMap;
        state.coinInfoListThirTeen = data.coinInfoListThirTeen;
        state.coinInfoMap = data.coinInfoMap;
        state.fxSymbolInfoMap = data.fxSymbolInfoMap;

    },
};
const actions = {
    setWsData: ({ commit }, res) => {
        commit("setWsData", res);
    },
    updateStaticData: ({ commit }, res) => {
        commit("updateStaticData", res);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};