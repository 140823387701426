<template>
  <div class="ucenter">
    <div class="ucenter-content">
      <slot name="sidebar" class=""></slot>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.app-main {
  height: 100% !important;
}
.ucenter {
  display: flex;
  justify-content: center;
  .el-form-item__label {
    color: #9aa1b1 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  &-content {
    // min-width: 1475px;
    width: 100%;
    height: 100%;
    // margin: 12px;

    display: flex;
    justify-content: center;
  }
  .app-uncter-content {
    // margin: 20px 20px 0px 20px;
  }
}
</style>
