<template>
  <div class="ucenter">
    <layout>
      <template v-slot:sidebar>
        <side-menu />
      </template>
      <r-content />
    </layout>
  </div>
</template>
<script>
import Layout from "./layout";
import SideMenu from "./components/SideMenu";
import RContent from "./components/Content";
import { translateTitle } from "@/assets/js/i18n";
export default {
  data() {
    return {
      voucherInterval: null,
    };
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  components: {
    Layout,
    SideMenu,
    RContent,
  },
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
