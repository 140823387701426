<template>
  <div></div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
export default {
  data() {
    return {
      isHideLever: false,
    };
  },
  created() {
    if (
      document.domain
        .split(".")
        .slice(-2)
        .join(".") === "bitda.tw"
    ) {
      // if (
      //   document.domain
      //     .split(".")
      //     .slice(-2)
      //     .join(".") === "localhost"
      // ) {
      this.isHideLever = true;
    }
  },
  methods: {
    translateTitle,
  },
};
</script>
