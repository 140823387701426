import { render, staticRenderFns } from "./trade-chart.vue?vue&type=template&id=7db1faac"
import script from "./trade-chart.vue?vue&type=script&lang=js"
export * from "./trade-chart.vue?vue&type=script&lang=js"
import style0 from "./trade-chart.vue?vue&type=style&index=0&id=7db1faac&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports