import api from "@/assets/js/api";

// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export function getCoinList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/Settings/itemList`,
    data,
    type: "POST",
  });
}

export function getAssetsList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Assets/getAssetsList`,
    data,
    type: "POST",
  });
}

export function getExchangeListV2(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Assets/getCoinExchangeListV2`,
    data,
    type: "POST",
  });
}

export function getINCoinExchangeListV2(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Assets/getCoinExchangeListV2`,
    data,
    type: "POST",
  });
}

export function getUserExchangeLimit(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/getUserExchangeLimit`,
    data,
    type: "POST",
  });
}

//获取充币地址
export function getAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/getAddress`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function apiGetFinanceAddressList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/getFinanceAddressList`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function apiCreateFinanceAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/createFinanceAddress`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function apiSaveFinanceAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/saveFinanceAddress`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function apiDelFinanceAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/delFinanceAddress`,
    data,
    type: "POST",
  });
}

//获取财务充币地址列表
export function sendEmailCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/sendEmailCode`,
    data,
    type: "POST",
  });
}

export function sendSmsCode(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Users/sendSmsCode`,
    data,
    type: "POST",
  });
}

export function getAddressList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/addressList`,
    data,
    type: "POST",
  });
}

export function addAddress(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Address/addAddress`,
    data,
    type: "POST",
  });
}
// 二次验证,提币借口
export function transfer(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Assets/transfer`,
    data,
    type: "POST",
  });
}

// 获取用户信息

export function userLoginLogList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Auth/userLoginLogList`,
    data,
    type: "POST",
  });
}

//验证子帐号昵称有无重复

export function nickNameCheck(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/UCenter/Account/nickNameCheck`,
    data,
    type: "POST",
  });
}
