/*
 * @Author: your name
 * @Date: 2021-10-21 21:25:30
 * @LastEditTime: 2021-10-22 11:45:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /topcredit-exchange/src/assets/js/global.js
 */

// global.js 文件
export default {
  ws: {
    sub: {}
  },
  setWs: function(newWs) {
    this.ws = newWs;
  },
  ws2: {
    sub: {}
  },
  setWs2: function(newWs) {
    this.ws2 = newWs;
  },
  //静态表相关
  settingList: {},
  setSl: function(data) {
    this.settingList = data;
  }
  // isGetSettinFirst: false
};
