<template>
  <div class="uc-spread-center">
    <div class="trade-container">
      <div class="header">
        <div class="content-title">
          <h2>
            {{ translateTitle("邀请好友，共同赚币") }}
          </h2>
          <span class="sub-title">{{
            translateTitle("您的好友与您共同享受返佣")
          }}</span>
        </div>
      </div>
      <div class="title-content">
        <div class="left-content">
          <div class="left-img">
            <el-avatar :size="48" :src="avatar" fit="fill">
              <img src="../../../../assets/img/st-icon-1-1.png" />
            </el-avatar>
            <p class="nick-name">{{ nick_name ? nick_name : "--" }}</p>
            <p class="uid num DINPro">
              UID: {{ userInfo.userInfo ? userInfo.userInfo.bt_user_id : "--" }}
            </p>
          </div>
          <div class="left-text">
            <p class="left-text-title">
              {{ translateTitle("邀请总览") }}
            </p>
            <div class="left-content-item">
              <p class="one-item">{{ translateTitle("邀请人数") }}</p>
              <p class="two-item">
                <span class="num DINPro">{{
                  total_stats.directly_total_user_num || "0"
                }}</span
                >{{ translateTitle("人") }}
              </p>
              <p class="three-item">
                <span class="num DINPro">
                  +
                  {{ today.group_incr_user_num || "0" }}</span
                >
              </p>
            </div>
            <div class="left-content-item">
              <p class="one-item">{{ translateTitle("已交易人数") }}</p>
              <p class="two-item">
                <span class="num DINPro">{{
                  total_stats.group_total_trade_num
                }}</span
                >{{ translateTitle("人") }}
              </p>
              <p class="three-item">
                <span class="num DINPro">
                  +
                  {{ today.group_incr_trade_num || "0" }}</span
                >
              </p>
            </div>
            <div class="left-content-item">
              <p class="one-item">{{ translateTitle("我的返利") }}</p>
              <p class="two-item">
                <span class="num DINPro">{{
                  total_stats.user_total_extract_amount_usdt
                }}</span
                >usdt
              </p>
              <p class="three-item">
                <span class="num DINPro">
                  +
                  {{ today.group_incr_extract_amount_usdt || "0" }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="right-content">
          <div class="right-text">
            <p class="right-title">
              {{ translateTitle("我的邀请") }}
            </p>
            <div
              class="right-text-item-empty"
              v-if="default_invite_code_loaded && invite_status === '0'"
            >
              <div class="closed-image"></div>
              <span class="empty-text">{{
                translateTitle(
                  "非常抱歉，当前邀请活动已关闭，感谢关注平台后续活动"
                )
              }}</span>
            </div>
            <div
              class="right-text-item"
              v-else-if="
                default_invite_code_loaded &&
                default_invite_code &&
                invite_status === '1'
              "
            >
              <div class="left">
                <p class="top-text">
                  {{ translateTitle("返利比例") }}
                  <span
                    class="blue DINPro"
                    @click="onCheckDialogOpen(default_invite_code)"
                  >
                    {{ translateTitle("查看") }}
                  </span>
                </p>
                <p class="invite">
                  {{ translateTitle("默认邀请码") }}:
                  {{ default_invite_code.invite_code }}
                  <i
                    class="iconfont iconicon-copy"
                    v-clipboard:copy="default_invite_code.invite_code"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                    @click="onCopyInviteCode(default_invite_code.invite_code)"
                  ></i>
                </p>
                <div class="invite_link_outer">
                  <p class="invite">
                    {{ translateTitle("网站邀请链接") }}:{{
                      invite_url.web_url
                    }}
                  </p>
                  <i
                    class="iconfont iconicon-copy inviteIcon"
                    v-clipboard:copy="invite_url.web_url"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                    @click="onCopyInviteChain(invite_url.web_url)"
                  ></i>
                </div>
                <div class="invite_link_outer">
                  <p class="invite">
                    {{ translateTitle("APP邀请链接") }}:
                    {{ invite_url.download_url }}
                  </p>
                  <i
                    class="iconfont iconicon-copy inviteIcon"
                    v-clipboard:copy="invite_url.download_url"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                    @click="onCopyInviteChain(invite_url.download_url)"
                  ></i>
                </div>
              </div>
              <div class="right">
                <span
                  class="btn-v2"
                  @click="onInviteDialogOpen(default_invite_code)"
                >
                  {{ translateTitle("立即邀请") }}
                </span>
              </div>
            </div>
            <div
              class="right-text-item-empty"
              v-else-if="default_invite_code_loaded && !default_invite_code"
            >
              <div class="empty-image"></div>
              <span class="empty-text">{{
                translateTitle(
                  "您还没有邀请码，开启你的专属邀请，请点击下方按钮"
                )
              }}</span>
              <div class="empty-icon"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="spread-uncter-content" v-loading="tableLoading">
        <div class="tab-content">
          <ul>
            <li
              class="classify-area"
              :class="{ active: currIndex == item.value }"
              v-for="item of tabList"
              :key="item.value"
              @click="onAreaChange(item)"
            >
              {{ translateTitle(item.label) }}
              <i v-if="currIndex == item.value" class="item-bar"></i>
            </li>
          </ul>
        </div>

        <!-- 推荐链接开始 -->
        <div v-show="currIndex == 2">
          <div class="invite-code-box">
            <div class="search-content">
              <el-input
                class="search-code"
                :clearable="true"
                v-model="searchCode"
                maxlength="20"
                :placeholder="translateTitle('邀请码')"
              ></el-input>
              <el-input
                class="search-remark"
                :clearable="true"
                v-model="searchRemark"
                maxlength="20"
                :placeholder="translateTitle('备注')"
              ></el-input>
              <span class="btn-search" @click="getInviteCodeStats">{{
                translateTitle("查询")
              }}</span>
              <div class="tenWid"></div>
              <span class="btn-reset" @click="resetInviteCodeStats">{{
                translateTitle("重置")
              }}</span>
            </div>
            <div
              class="btn-invite"
              v-if="invite_status == '1'"
              @click="onEditDialogOpen({}, true)"
            >
              {{ translateTitle("+新增邀请码") }}
            </div>
          </div>

          <el-table
            :data="inviteCodeList"
            class="g-table"
            v-loading="inviteCodeListLoading"
          >
            <el-table-column
              class="coin-cell"
              min-width="50px"
              align="left"
              prop="invite_code"
              :label="translateTitle('邀请码')"
            >
              <template slot-scope="{ row }">
                <span class="text">{{ row.invite_code }}</span>
                <i
                  class="iconfont iconicon-copy table-icon-copy"
                  v-clipboard:copy="row.invite_code"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                  @click="onCopyInviteCode(row.invite_code)"
                ></i>
                <span v-if="row.is_default == 1" class="is-default-code">{{
                  translateTitle("默认")
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              class="coin-cell"
              min-width="40px"
              align="right"
              prop="invite_user_num"
              :label="translateTitle('好友人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  row.directly_total_user_num
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="40px"
              align="right"
              prop="trade_user_num"
              :label="translateTitle('好友交易人数')"
            >
              <template slot-scope="{ row }">
                <span class="text num DINPro">{{
                  row.directly_total_trade_num
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class="coin-cell"
              min-width="80px"
              align="right"
              prop="remark"
              :label="translateTitle('备注')"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="{ row }">
                <span class="text">{{ row.note }}</span>
              </template>
            </el-table-column>
            <!-- 接口中无好友返利，展示累计返利即我的返利 -->
            <el-table-column
              class="coin-cell"
              min-width="45px"
              align="center"
              prop="rebate_config"
              :label="translateTitle('我的返利/好友返利')"
            >
              <template slot-scope="{ row }">
                <span class="operation" @click="onCheckDialogOpen(row)">{{
                  translateTitle("查看")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="365"
              :label="translateTitle('操作')"
              align="right"
            >
              <template slot-scope="{ row }">
                <span
                  v-if="row.is_default !== 1"
                  class="operation"
                  @click="setDefaultInviteCode(row)"
                  >{{ translateTitle("设为默认") }}</span
                >
                <span v-if="row.is_default === 1" class="operation">{{
                  translateTitle("默认")
                }}</span>
                <span class="operation" @click="onEditDialogOpen(row)">{{
                  translateTitle("编辑")
                }}</span>
                <span
                  class="operation"
                  @click="sensorsTrack(2, row.web_url)"
                  v-clipboard:copy="row.web_url"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                  >{{ translateTitle("网站邀请") }}</span
                >
                <span class="operation" @click="onInviteDialogOpen(row)">{{
                  translateTitle("APP邀请")
                }}</span>
                <!-- <span 
                  v-if="
                    row.directly_total_user_num == 0 && row.is_default !== 1
                  "
                  class="operation"
                  @click="onInviteRemove(row)"
                  >{{ translateTitle("删除") }}</span
                >
                <span v-else class="operation-ban">{{
                  translateTitle("删除")
                }}</span>-->
              </template>
            </el-table-column>

            <no-data
              slot="empty"
              v-show="!inviteCodeListLoading && inviteCodeList.length == 0"
            />
          </el-table>
        </div>

        <div class="common-table-pages">
          <p>
            {{ translateTitle("每页") }}
            <span>{{ pageInfo.pageSize }}</span>
            {{ translateTitle(`条，总共`) }}
            <span>{{ pageInfo.pageCount }}</span>
            {{ translateTitle("页") }}
          </p>
          <el-pagination
            class="mt20"
            background
            layout="prev, pager, next"
            :total="pageInfo.pageTotal"
            :page-size="pageInfo.pageSize"
            :current-page.sync="pageInfo.pageCurrent"
            @current-change="onPageChange"
          ></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      width="440px"
      :title="
        isAddInviteCode
          ? translateTitle('新增邀请码')
          : translateTitle('编辑邀请码')
      "
      custom-class="holder-detail-dialog"
      :close-on-click-modal="false"
      :before-close="onEditDialogClose"
      :destroy-on-close="true"
      :visible.sync="editDialogVisible"
    >
      <div class="business-area" v-if="isAddInviteCode">
        <div class="ba-item">
          <span class="bai-name header">{{ translateTitle("推广业务") }}</span>
          <span class="bai-me header">{{ translateTitle("我") }}</span>
          <span class="bai-friend header">{{ translateTitle("好友") }}</span>
        </div>
        <div class="ba-item">
          <span class="bai-name">{{ translateTitle("币币") }}</span>
          <span class="bai-me">
            {{
              new BigNumber(rebate_config_max.business_id_1)
                .times(100)
                .minus(new BigNumber(rebate_config_friend.business_id_1))
                .toString() >= 0
                ? new BigNumber(rebate_config_max.business_id_1)
                    .times(100)
                    .minus(new BigNumber(rebate_config_friend.business_id_1))
                    .toString()
                : "--"
            }}%</span
          >
          <div class="bai-friend">
            <el-input
              v-model="rebate_config_friend.business_id_1"
              style="width: 100%"
              oninput="value=value.replace(/[^0-9]/g,'')"
            >
              <template slot="append">%</template>
            </el-input>
          </div>
        </div>
        <div class="ba-item">
          <span class="bai-name">{{ translateTitle("U本位合约") }}</span>
          <span class="bai-me"
            >{{
              new BigNumber(rebate_config_max.business_id_3)
                .times(100)
                .minus(new BigNumber(rebate_config_friend.business_id_3))
                .toString() >= 0
                ? new BigNumber(rebate_config_max.business_id_3)
                    .times(100)
                    .minus(new BigNumber(rebate_config_friend.business_id_3))
                    .toString()
                : "--"
            }}%</span
          >
          <div class="bai-friend">
            <el-input
              v-model="rebate_config_friend.business_id_3"
              style="width: 100%"
              oninput="value=value.replace(/[^0-9]/g,'')"
            >
              <template slot="append">%</template>
            </el-input>
          </div>
        </div>
        <div class="ba-item">
          <span class="bai-name">{{ translateTitle("股票") }}</span>
          <span class="bai-me"
            >{{
              new BigNumber(rebate_config_max.business_id_5)
                .times(100)
                .minus(new BigNumber(rebate_config_friend.business_id_5))
                .toString() >= 0
                ? new BigNumber(rebate_config_max.business_id_5)
                    .times(100)
                    .minus(new BigNumber(rebate_config_friend.business_id_5))
                    .toString()
                : "--"
            }}%</span
          >
          <div class="bai-friend">
            <el-input
              v-model="rebate_config_friend.business_id_5"
              style="width: 100%"
              oninput="value=value.replace(/[^0-9]/g,'')"
            >
              <template slot="append">%</template>
            </el-input>
          </div>
        </div>
        <div class="ba-item">
          <span class="bai-name">{{ translateTitle("衍生品") }}</span>
          <span class="bai-me"
            >{{
              new BigNumber(rebate_config_max.business_id_6)
                .times(100)
                .minus(new BigNumber(rebate_config_friend.business_id_6))
                .toString() >= 0
                ? new BigNumber(rebate_config_max.business_id_6)
                    .times(100)
                    .minus(new BigNumber(rebate_config_friend.business_id_6))
                    .toString()
                : "--"
            }}%</span
          >
          <div class="bai-friend">
            <el-input
              v-model="rebate_config_friend.business_id_6"
              style="width: 100%"
              oninput="value=value.replace(/[^0-9]/g,'')"
            >
              <template slot="append">%</template>
            </el-input>
          </div>
        </div>
      </div>
      <span
        class="friendInviteremark"
        :style="{ marginTop: isEditCodeRemark ? '0' : '15px' }"
        >{{ translateTitle("备注") }}</span
      >
      <el-input
        maxlength="20"
        v-model="inviteCodeRemark"
        :placeholder="translateTitle('备注信息')"
      ></el-input>

      <el-checkbox
        v-model="isDefaultCode"
        label="1"
        v-if="isAddInviteCode"
        class="defaultCode"
        >{{ translateTitle("设为默认邀请码") }}</el-checkbox
      >

      <div class="button-group">
        <el-button class="btn-cancel" @click="onEditDialogClose">{{
          translateTitle("取消")
        }}</el-button>
        <el-button
          class="btn-confirm"
          :disabled="addLoading"
          :loading="addLoading"
          @click="doAddOrEdit"
          >{{ translateTitle("确认") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 修改返利设置结束 -->
    <!-- 查看返利比例开始 -->
    <el-dialog
      width="440px"
      :title="translateTitle('查看返利比例')"
      custom-class="holder-detail-dialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="checkDialogVisible"
    >
      <div class="business-area">
        <div class="ba-item">
          <span class="bai-name header">{{ translateTitle("推广业务") }}</span>
          <span class="bai-me header">{{ translateTitle("我") }}</span>
          <span class="bai-friend header">{{ translateTitle("好友") }}</span>
        </div>
        <div class="ba-item">
          <span class="bai-name">{{ translateTitle("币币") }}</span>
          <span class="bai-me"
            >{{
              new BigNumber(rebate_config_me.business_id_1)
                .times(100)
                .toString()
            }}%</span
          >
          <span class="bai-friend">
            {{
              new BigNumber(rebate_config_max.business_id_1)
                .minus(new BigNumber(rebate_config_me.business_id_1))
                .times(100)
                .toString()
            }}%</span
          >
        </div>
        <div class="ba-item">
          <span class="bai-name">{{ translateTitle("U本位合约") }}</span>
          <span class="bai-me"
            >{{
              new BigNumber(rebate_config_me.business_id_3)
                .times(100)
                .toString()
            }}%</span
          >
          <span class="bai-friend"
            >{{
              new BigNumber(rebate_config_max.business_id_3)
                .minus(new BigNumber(rebate_config_me.business_id_3))
                .times(100)
                .toString()
            }}%</span
          >
        </div>
        <div class="ba-item">
          <span class="bai-name">{{ translateTitle("股票") }}</span>
          <span class="bai-me"
            >{{
              new BigNumber(rebate_config_me.business_id_5)
                .times(100)
                .toString()
            }}%</span
          >
          <span class="bai-friend"
            >{{
              new BigNumber(rebate_config_max.business_id_5)
                .minus(new BigNumber(rebate_config_me.business_id_5))
                .times(100)
                .toString()
            }}%</span
          >
        </div>
        <div class="ba-item">
          <span class="bai-name">{{ translateTitle("衍生品") }}</span>
          <span class="bai-me"
            >{{
              new BigNumber(rebate_config_me.business_id_6)
                .times(100)
                .toString()
            }}%</span
          >
          <span class="bai-friend"
            >{{
              new BigNumber(rebate_config_max.business_id_6)
                .minus(new BigNumber(rebate_config_me.business_id_6))
                .times(100)
                .toString()
            }}%</span
          >
        </div>
      </div>
      <div class="button-group button-group-signle">
        <a
          class="btn-confirm"
          @click="
            () => {
              checkDialogVisible = false;
            }
          "
          >{{ translateTitle("确认") }}</a
        >
      </div>
    </el-dialog>
    <!-- 查看返利比例结束 -->
    <!-- 邀请对话框开始 -->
    <el-dialog
      width="600px"
      custom-class="holder-invite-dialog"
      :close-on-click-modal="false"
      :before-close="onInviteDialogClose"
      :destroy-on-close="true"
      :visible.sync="inviteDialogVisible"
    >
      <div class="swiper">
        <el-carousel
          :interval="4000"
          type="card"
          height="350px"
          :autoplay="false"
          arrow="always"
          :loop="false"
          @change="carouselChange"
        >
          <el-carousel-item v-for="(item, index) in poster_conf" :key="index">
            <img class="loop-image" :src="item.img_url" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="share-content" v-loading="shareLoading">
        <span class="shareTo">{{ translateTitle("分享到") }}</span>
        <div class="share-items">
          <ShareNetwork
            class="share-item"
            network="Telegram"
            :url="inviteObj.complieUrl"
            :title="slogan4language"
          >
            <img
              :src="tg"
              class="share-item-img"
              @click="
                onShare(poster_conf[initInviteStatus].img_url, 'Telegram')
              "
            />
            <span class="share-item-text" title="Telegram"> Telegram </span>
          </ShareNetwork>

          <ShareNetwork
            class="share-item"
            network="Twitter"
            :url="inviteObj.complieUrl"
            :title="slogan4language"
          >
            <img
              :src="tt"
              class="share-item-img"
              title="Twitter"
              @click="onShare(poster_conf[initInviteStatus].img_url, 'Twitter')"
            />
            <span class="share-item-text"> Twitter </span>
          </ShareNetwork>

          <ShareNetwork
            class="share-item"
            network="facebook"
            :url="inviteObj.complieUrl"
            :title="slogan4language"
          >
            <img
              :src="fb"
              class="share-item-img"
              title="Facebook"
              @click="
                onShare(poster_conf[initInviteStatus].img_url, 'Facebook')
              "
            />
            <span class="share-item-text"> Facebook </span>
          </ShareNetwork>

          <div class="share-item" @click="downloadPoster">
            <img :src="si" class="share-item-img" />
            <span class="share-item-text" :title="translateTitle('保存图片')">
              {{ translateTitle("保存图片") }}
            </span>
          </div>

          <div class="share-item" @click="copyPosterLink">
            <img :src="cl" class="share-item-img" />
            <span class="share-item-text" :title="translateTitle('复制链接')">
              {{ translateTitle("复制链接") }}
            </span>
          </div>

          <div class="share-item" @click="copyPoster">
            <img :src="ci" class="share-item-img" />
            <span class="share-item-text" :title="translateTitle('复制图片')">
              {{ translateTitle("复制图片") }}
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="translateTitle('操作提示')"
      custom-class="confirm-dialog"
      :visible.sync="confirmDialogVisible"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px">{{
        translateTitle("是否确认删除此邀请码？")
      }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="confirmDialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="delInviteCode">{{
          translateTitle("确认")
        }}</span>
      </span>
    </el-dialog>
    <!-- 邀请对话框结束 -->
    <vue-canvas-poster
      :widthPixels="800"
      :painting="painting"
      @success="posterSuccess"
      @fail="posterFail"
    ></vue-canvas-poster>
  </div>
</template>
<script>
import NoData from "@/components/common/NoData";
import globalFun from "@/assets/js/globalFun.js";
import BigNumber from "bignumber.js";
import * as Account from "@/model/user/account";
import {
  apiUpdateInviteCode,
  apiGetRebateCommonInfoV2,
  apiGetInviteCodeStatsV2,
  apiCreateInviteCodeV2,
  apiUpdateInviteCodeV2,
  apiDelInviteCodeV2,
} from "@/model/ucenter/spreadCenter";
import commonMixin from "@/components/common/commonMixin.vue";
import { showMessage } from "@/components/dialog/message";
import { mapGetters } from "vuex";
import { translateTitle } from "@/assets/js/i18n";
import moment from "moment";
import { VueCanvasPoster } from "vue-canvas-poster";
import ci from "@/assets/img/shareInvite/ci.png";
import cl from "@/assets/img/shareInvite/cl.png";
import fb from "@/assets/img/shareInvite/fb.png";
import si from "@/assets/img/shareInvite/si.png";
import tg from "@/assets/img/shareInvite/tg.png";
import tt from "@/assets/img/shareInvite/tt.png";
import logo8coin from "@/assets/img/siteLogo/8coin.png";
import routeSplicing from "@/assets/js/routeSplicing";

export default {
  mixins: [commonMixin],
  data() {
    return {
      moment,
      ci,
      cl,
      fb,
      si,
      tg,
      tt,
      logo8coin,
      avatar: "",
      userInfo: {},
      nick_name: "",
      painting: {},
      currIndex: 2,
      inviteCodeList: [],
      tabList: [{ label: "我的邀请码", value: 2 }],
      tableLoading: false,
      inviteCodeListLoading: false,
      shareLoading: false,
      inviteImgLoading: false,
      shareImageType: "",
      editDialogVisible: false,
      inviteDialogVisible: false,
      isDefaultCode: false,
      inviteCodeRemark: "",
      initInviteStatus: 0,
      isEditCodeRemark: true, //只编辑邀请码备注
      searchCode: "",
      searchRemark: "",
      projectFullName: "",
      slogan: "",
      projectTitle: "",
      projectLogo: "",
      inviteObj: {},
      editCode: "",
      poster_conf: [],
      default_invite_code: "",
      invite_status: "1",
      total_stats: {},
      today: {},
      invite_url: {},
      confirmDialogVisible: false,
      delPreInviteCode: "",
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      },
      globalFun,
      rebate_mode: 1,
      rebate_config_me: {
        business_id_1: "0", // 币币业务-分给自己
        business_id_3: "0", // U本位合约业务-分给自己
        business_id_5: "0", // 股票业务-分给自己
        business_id_6: "0", // 衍生品业务-分给自己
      },
      rebate_config_friend: {
        business_id_1: "0", // 币币业务-分给好友
        business_id_3: "0", // U本位合约业务-分给好友
        business_id_5: "0", // 股票业务-分给好友
        business_id_6: "0", // 衍生品业务-分给好友
      },
      rebate_config_max: {
        business_id_1: "0", // 币币业务-分给好友
        business_id_3: "0", // U本位合约业务-分给好友
        business_id_5: "0", // 股票业务-分给好友
        business_id_6: "0", // 衍生品业务-分给好友
      },
      checkDialogVisible: false,
      isAddInviteCode: false,
      default_invite_code_loaded: false,
      invite_code: "",
      addLoading: false,
    };
  },
  components: {
    NoData,
    VueCanvasPoster,
  },
  async created() {
    await this.getRebateCommonInfo();
    await this.getInviteCodeStats();
    await this.getUserInfoInit();
    this.projectFullName = "4E";
    this.sloganColor = "#3960fb";
    this.projectLogo = this.logo8coin;
    await this.getInviteCode();
    this.$sensors.track("Invite_View", {
      invite_code: this.invite_code,
    });
  },
  watch: {
    "rebate_config_friend.business_id_1"(newValue, oldValue) {
      if (newValue > 100) {
        this.rebate_config_friend.business_id_1 = 100;
      } else if (newValue < 0) {
        this.rebate_config_friend.business_id_1 = 0;
      }
    },
    "rebate_config_friend.business_id_3"(newValue, oldValue) {
      if (newValue > 100) {
        this.rebate_config_friend.business_id_3 = 100;
      } else if (newValue < 0) {
        this.rebate_config_friend.business_id_3 = 0;
      }
    },
    "rebate_config_friend.business_id_5"(newValue, oldValue) {
      if (newValue > 100) {
        this.rebate_config_friend.business_id_5 = 100;
      } else if (newValue < 0) {
        this.rebate_config_friend.business_id_5 = 0;
      }
    },
    "rebate_config_friend.business_id_6"(newValue, oldValue) {
      if (newValue > 100) {
        this.rebate_config_friend.business_id_6 = 100;
      } else if (newValue < 0) {
        this.rebate_config_friend.business_id_6 = 0;
      }
    },
  },
  computed: {
    ...mapGetters({
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    slogan4language() {
      return "高收益，上" + this.projectFullName;
    },
    myCurrRate() {
      return new BigNumber().toNumber();
    },
  },

  methods: {
    routeSplicing,
    translateTitle,
    BigNumber,
    getInviteCode() {
      this.invite_code =
        new URLSearchParams(window.location.search).get("ref") || "";
    },
    //精度优化
    digData(data) {
      return this.globalFun.handleCutZero(parseFloat(data).toFixed(10));
    },
    //幻灯片切换
    carouselChange(index) {
      this.initInviteStatus = index;
      this.$sensors.track("Poster_Click", {
        source_page: "promote_center",
        poster_content: this.poster_conf[this.initInviteStatus].img_url,
        poster_no: this.initInviteStatus + 1,
      });
    },
    //邀请图片加载完成
    inviteImgLoaded() {
      this.inviteImgLoading = false;
    },
    // 获取用户信息
    async getUserInfoInit() {
      this.userInfo = await Account.getUserInfo();
      const { userInfo } = this.userInfo;
      if (userInfo) {
        const { user_id, nick_name, avatar } = userInfo;
        this.nick_name =
          nick_name ||
          this.userInfo.accountInfo.username ||
          this.globalFun.userDecryption(
            this.userInfo.accountInfo.mobile,
            "mobile"
          ) ||
          this.globalFun.userDecryption(
            this.userInfo.accountInfo.email,
            "email"
          ) ||
          this.translateTitle(`用户`) + `${user_id}`;
        this.avatar = avatar ? avatar : "https://";
      }
    },

    //Base64 To Blob
    convertBase64ToBlob(base64, type) {
      var imgs = base64.replace(/^data:image\/\w+;base64,/, "");
      var bytes = window.atob(imgs);
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },

    //写入剪切板
    async writeClipboard(src) {
      try {
        const blob = this.convertBase64ToBlob(src, "image/png");
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
      } catch (err) {
        console.error(err.name, err.message);
      }
    },

    //图片生成成功回调函数
    posterSuccess(src) {
      this.shareLoading = false;
      if (this.shareImageType == "copy") {
        this.writeClipboard(src);
        this.copySuccess;
        this.sensorsTrack(2, src);
      } else if (this.shareImageType == "download") {
        const a = document.createElement("a");
        a.href = src;
        a.setAttribute("download", "chart-download");
        a.click();
        showMessage({
          message: this.translateTitle(`图片下载成功`),
          type: "success",
        });
      }
    },
    //图片生成失败回调函数
    posterFail(err) {
      console.log("fail", err);
    },
    onShare(url, method) {
      this.$sensors.track("Share_Click", {
        poster_name: url,
        share_method: method,
        source_page: "promote_center",
      });
    },
    //复制下载链接
    copyPosterLink() {
      let that = this;
      this.$copyText(this.inviteObj.complieUrl).then(
        function (e) {
          showMessage({
            message: that.translateTitle(`复制成功，快分享给好友吧`),
            type: "success",
          });
          that.onShare(
            that.poster_conf[that.initInviteStatus].img_url,
            "复制链接"
          );
          that.$sensors.track("Invite_Click", {
            current_page_url: window.location.href,
            current_page: "promote_center",
          });
          that.inviteDialogVisible = false;
        },
        function (e) {
          console.log(e);
        }
      );
    },
    // 下载海报
    downloadPoster() {
      this.shareImageType = "download";
      this.shareLoading = true;
      this.painting = JSON.parse(JSON.stringify(this.poster()));
      this.onShare(this.poster_conf[this.initInviteStatus].img_url, "保存图片");
    },
    // 复制海报
    copyPoster() {
      this.shareImageType = "copy";
      this.shareLoading = true;
      this.painting = JSON.parse(JSON.stringify(this.poster()));
      this.onShare(this.poster_conf[this.initInviteStatus].img_url, "复制图片");
    },
    poster() {
      return 1
        ? {
            width: "222px",
            height: "370px",
            background: "#fff",
            views: [
              {
                type: "image",
                url:
                  this.poster_conf[this.initInviteStatus].img_url +
                  "?r=" +
                  this.moment().unix(),
                css: {
                  top: "0px",
                  left: "0px",
                  width: "222px",
                  height: "310px",
                },
              },
              {
                type: "image",
                url: this.projectLogo,
                css: {
                  top: "320px",
                  left: "5px",
                  width: "35px",
                  height: "35px",
                  borderRadius: "100%",
                },
              },
              {
                type: "text",
                text: this.projectFullName,
                css: {
                  top: "329px",
                  left: "45px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "13px",
                  fontWeight: "500",
                },
              },
              {
                type: "text",
                text:
                  this.translateTitle(`邀请码`) +
                  ": " +
                  this.inviteObj.invite_code,
                css: {
                  top: "348px",
                  left: "45px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "9px",
                  fontWeight: "400",
                  color: "#354052",
                },
              },
              {
                type: "qrcode",
                content: this.inviteObj.complieUrl,
                css: {
                  top: "327px",
                  right: "10px",
                  color: "#000",
                  width: "31px",
                  height: "31px",
                },
              },
            ],
          }
        : {
            width: "222px",
            height: "370px",
            background: "#fff",
            views: [
              {
                type: "image",
                url:
                  this.poster_conf[this.initInviteStatus].img_url +
                  "?r=" +
                  this.moment().unix(),
                css: {
                  top: "0px",
                  left: "0px",
                  width: "222px",
                  height: "310px",
                },
              },
              {
                type: "image",
                url: this.projectLogo,
                css: {
                  top: "320px",
                  left: "5px",
                  width: "35px",
                  height: "35px",
                  borderRadius: "100%",
                },
              },
              {
                type: "text",
                text: this.projectFullName,
                css: {
                  top: "329px",
                  left: "45px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "13px",
                  fontWeight: "500",
                },
              },
              {
                type: "text",
                text:
                  this.translateTitle(`邀请码`) +
                  ": " +
                  this.inviteObj.invite_code,
                css: {
                  top: "348px",
                  left: "45px",
                  width: "150px",
                  maxLines: 1,
                  fontSize: "9px",
                  fontWeight: "400",
                  color: "#354052",
                },
              },
              {
                type: "qrcode",
                content: this.inviteObj.complieUrl,
                css: {
                  top: "327px",
                  right: "10px",
                  color: "#000",
                  width: "31px",
                  height: "31px",
                },
              },
            ],
          };
    },

    onAreaChange(item) {
      this.currIndex = item.value;
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      };
      if (this.currIndex == 2) {
        this.resetInviteCodeStats();
      }
    },

    //关闭编辑邀请码弹窗
    onEditDialogClose() {
      this.editDialogVisible = false;
    },

    //查看返利比例
    onCheckDialogOpen(obj) {
      this.checkDialogVisible = true;
      this.doConfigRebate(obj.rebate_config);
    },
    //开启编辑邀请码弹窗
    onEditDialogOpen(obj, isAddInviteCode = false) {
      this.addLoading = false;
      this.isAddInviteCode = isAddInviteCode;
      if (isAddInviteCode) {
        // 新增邀请码
        this.inviteCodeRemark = "";
        this.rebate_config_me = {
          business_id_1: this.rebate_config_max.business_id_1,
          business_id_3: this.rebate_config_max.business_id_3,
          business_id_5: this.rebate_config_max.business_id_5,
          business_id_6: this.rebate_config_max.business_id_6,
        };
        this.doConfigRebate(this.rebate_config_me);
      } else {
        // 编辑邀请码
        this.editCode = obj.invite_code;
        this.inviteCodeRemark = obj.note;
        this.doConfigRebate(obj.rebate_config);
      }

      this.editDialogVisible = true;
    },
    //关闭邀请分享弹窗
    onInviteDialogClose() {
      this.shareImageType = "";
      this.inviteDialogVisible = false;
      this.initInviteStatus = 0;
    },
    //开启邀请分享弹窗
    onInviteDialogOpen(obj) {
      this.inviteObj = obj;
      this.inviteObj.complieUrl = obj.download_url;
      this.inviteDialogVisible = true;
      this.$sensors.track("Invite_Click", {
        current_page_url: window.location.href,
        current_page: "promote_center",
      });
      this.sensorsTrack(1, obj.complieUrl);
    },
    //确认删除邀请码
    onInviteRemove(row) {
      this.confirmDialogVisible = true;
      this.delPreInviteCode = row.invite_code;
    },
    //删除邀请码 apiDelInviteCode
    async delInviteCode() {
      this.confirmDialogVisible = false;
      let params = {
        invite_code: this.delPreInviteCode,
      };
      const { status, data } = await apiDelInviteCodeV2(params);
      if (status == 200) {
        this.$message({
          message: this.translateTitle(`删除成功`),
          type: "success",
        });
        this.getInviteCodeStats();
      } else {
        this.$message({
          message: this.translateTitle(`删除失败`),
          type: "error",
        });
      }
    },
    //判断是新增邀请码或修改邀请码备注
    async doAddOrEdit() {
      this.addLoading = true;
      if (this.isAddInviteCode) {
        // 新增邀请码

        if (
          this.rebate_config_max.business_id_6 * 100 -
            this.rebate_config_friend.business_id_6 >=
            0 &&
          this.rebate_config_max.business_id_5 * 100 -
            this.rebate_config_friend.business_id_5 >=
            0 &&
          this.rebate_config_max.business_id_3 * 100 -
            this.rebate_config_friend.business_id_3 >=
            0 &&
          this.rebate_config_max.business_id_1 * 100 -
            this.rebate_config_friend.business_id_1 >=
            0
        ) {
        } else {
          this.$message({
            message: this.translateTitle("请输入范围内的正整数"),
            type: "error",
          });
          this.addLoading = false;
          return;
        }
        if (this.inviteCodeRemark == "") {
          showMessage({
            message: this.translateTitle(`请填写备注信息`),
            type: "error",
          });
          this.addLoading = false;
          return;
        }
        this.rebate_config_me.business_id_1 = new BigNumber(
          this.rebate_config_max.business_id_1
        )
          .times(100)
          .minus(new BigNumber(this.rebate_config_friend.business_id_1))
          .div(100)
          .toString();
        this.rebate_config_me.business_id_3 = new BigNumber(
          this.rebate_config_max.business_id_3
        )
          .times(100)
          .minus(new BigNumber(this.rebate_config_friend.business_id_3))
          .div(100)
          .toString();
        this.rebate_config_me.business_id_5 = new BigNumber(
          this.rebate_config_max.business_id_5
        )
          .times(100)
          .minus(new BigNumber(this.rebate_config_friend.business_id_5))
          .div(100)
          .toString();
        this.rebate_config_me.business_id_6 = new BigNumber(
          this.rebate_config_max.business_id_6
        )
          .times(100)
          .minus(new BigNumber(this.rebate_config_friend.business_id_6))
          .div(100)
          .toString();
        let params = {
          is_default: this.isDefaultCode ? 1 : 0,
          note: this.inviteCodeRemark,
          ...this.rebate_config_me,
        };
        const { status, data } = await apiCreateInviteCodeV2(params);
        this.onEditDialogClose();

        if (status == 200 && data) {
          showMessage({
            message: this.translateTitle(`新增成功`),
            type: "success",
          });
          this.getInviteCodeStats();
          this.getRebateCommonInfo();
          // this.addLoading = false;
        } else {
          showMessage({
            message: this.translateTitle(`新增失败`),
          });
          // this.addLoading = false;
        }
        this.$sensors.track("Generate_Invitation_Code", {
          channel_type: "WEB",
          business_commission: [
            {
              币币: Number(this.rebate_config_me.business_id_1) * 100 + "%",
            },
            {
              U本位合约:
                Number(this.rebate_config_me.business_id_3) * 100 + "%",
            },
            {
              股票: Number(this.rebate_config_me.business_id_5) * 100 + "%",
            },
            {
              衍生品: Number(this.rebate_config_me.business_id_6) * 100 + "%",
            },
          ],
        });
      } else {
        if (this.inviteCodeRemark == "") {
          showMessage({
            message: this.translateTitle(`请填写备注信息`),
            type: "error",
          });
          this.addLoading = false;
          return;
        }
        let params = {
          invite_code: this.editCode,
          note: this.inviteCodeRemark,
          ...this.rebate_config_me,
        };
        const { status, data } = await apiUpdateInviteCodeV2(params);
        this.onEditDialogClose();
        // this.addLoading = false;
        if (status == 200 && data) {
          showMessage({
            message: this.translateTitle(`编辑成功`),
            type: "success",
          });
          this.getInviteCodeStats();
          this.getRebateCommonInfo();
        } else {
          showMessage({
            message: this.translateTitle(`编辑失败`),
          });
        }
      }
    },
    sensorsTrack(type, url) {
      if (type == 1) {
        this.$sensors.track("Invitecode_Copy", {
          current_page: "promote_center",
          current_page_url: window.location.href,
          invite_code: url,
        });
      } else if (type == 2) {
        this.$sensors.track("Invitechain_Copy", {
          current_page: "promote_center",
          current_page_url: window.location.href,
          invite_chain: url,
        });
      }
    },
    onCopyInviteCode(code) {
      this.$sensors.track("Invitecode_Copy", {
        current_page: "promote_center",
        current_page_url: window.location.href,
        invite_code: code,
      });
    },
    onCopyInviteChain(url) {
      this.$sensors.track("Invitechain_Copy", {
        current_page: "promote_center",
        current_page_url: window.location.href,
        invite_chain: url,
      });
    },
    // 复制成功
    copySuccess() {
      showMessage({
        message: this.translateTitle(`复制成功，快分享给好友吧`),
        type: "success",
      });
    },
    // 复制失败
    copyError() {
      showMessage({ message: this.translateTitle(`内容复制失败`) });
    },

    // 重置我的邀请码搜索条件
    resetInviteCodeStats() {
      this.searchCode = "";
      this.searchRemark = "";
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1,
      };
      this.getInviteCodeStats();
    },

    //获取推广链接返佣统计
    async getInviteCodeStats(pageNum = 1) {
      let params = {
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
        invite_code: this.searchCode ? this.searchCode : "",
        note: this.searchRemark ? this.searchRemark : "",
      };
      this.inviteCodeListLoading = true;
      const { status, data } = await apiGetInviteCodeStatsV2(params);
      this.inviteCodeListLoading = false;
      if (status == 200) {
        this.inviteCodeList = JSON.parse(JSON.stringify(data.list));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
    },

    //设置默认邀请码
    async setDefaultInviteCode(row) {
      let params = {
        invite_code: row.invite_code,
        is_default: 1,
      };
      const { status, data } = await apiUpdateInviteCodeV2(params);
      if (status == 200 && data) {
        showMessage({
          message: this.translateTitle(`设为默认成功`),
          type: "success",
        });
        this.getInviteCodeStats();
        this.getRebateCommonInfo();
      } else {
        showMessage({
          message: this.translateTitle(`设为默认失败`),
        });
      }
    },
    getRebateCommonInfo() {
      apiGetRebateCommonInfoV2().then(res => {
        if (res.status == 200) {
          let data = res.data;
          this.inviteImgLoading = true;
          if (data.default_invite_code) {
            this.default_invite_code = data.default_invite_code;
            this.default_invite_code["download_url"] = data.download_url;
            this.default_invite_code["web_url"] = data.web_url;
            this.doConfigRebate(data.default_invite_code.rebate_config);
          }
          this.invite_status = data.invite_status + "";
          this.poster_conf = data.poster_conf;
          this.total_stats = data.total_stats;
          this.today = data.today;
          this.invite_url = {
            download_url: data.download_url,
            web_url: data.web_url,
          };
          this.rebate_config_max = data.list_extract_rate_max;
        }
        this.default_invite_code_loaded = true;
      });
    },
    //页数变更
    onPageChange(val) {
      if (this.currIndex == 2) {
        this.getInviteCodeStats(val);
      }
    },
    doConfigRebate(rebate_config) {
      this.rebate_config_me = rebate_config;
      this.rebate_config_friend = {
        business_id_1: new BigNumber(this.rebate_config_max.business_id_1)
          .minus(this.rebate_config_me.business_id_1)
          .toString(),
        business_id_3: new BigNumber(this.rebate_config_max.business_id_3)
          .minus(this.rebate_config_me.business_id_3)
          .toString(),
        business_id_5: new BigNumber(this.rebate_config_max.business_id_5)
          .minus(this.rebate_config_me.business_id_5)
          .toString(),
        business_id_6: new BigNumber(this.rebate_config_max.business_id_6)
          .minus(this.rebate_config_me.business_id_6)
          .toString(),
        // business_id_1:
        //   Number(this.rebate_config_max.business_id_1) -
        //   Number(this.rebate_config_me.business_id_1),
        // business_id_3:
        //   Number(this.rebate_config_max.business_id_3) -
        //   Number(this.rebate_config_me.business_id_3),
        // business_id_5:
        //   Number(this.rebate_config_max.business_id_5) -
        //   Number(this.rebate_config_me.business_id_5),
        // business_id_6:
        //   Number(this.rebate_config_max.business_id_6) -
        //   Number(this.rebate_config_me.business_id_6),
      };
    },
  },
};
</script>
<style lang="scss">
.uc-spread-center {
  .el-carousel {
    width: 100%;
    height: 100%;

    .el-carousel__item {
      text-align: center;

      .loop-image {
        width: 250px;
        height: 350px;
        border-radius: 7.4px;
      }
    }

    .el-carousel__item:nth-child(2n) {
      background-color: transparent;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: transparent;
    }

    .el-carousel__indicators--outside {
      display: none;
    }
  }

  .holder-invite-dialog {
    background-color: #f3f7fc;

    .share-content {
      background-color: #fff;
      width: 600px;
      height: 150px;
      margin: 20px -20px -30px -20px;
      padding: 15px 20px 25px 20px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      .shareTo {
        font-size: 14px;
        text-align: center;
        color: #354052;
      }

      .share-items {
        width: 100%;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .share-item {
          height: 77px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: 13px;
          cursor: pointer;

          .share-item-img {
            height: 50px;
            width: 50px;
            margin-bottom: 10px;
          }

          .share-item-text {
            font-size: 12px;
            text-align: center;
            color: #354052;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 80px;
          }
        }
      }
    }

    .swiper {
      width: 600px;
      height: 380px;
      background-color: #f3f7fc;
      margin: 0 -20px 0 -20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      position: relative;

      .placeholderDiv {
        height: 280px;
        width: 120px;
        margin: 0 10px;
        border-radius: 7.4px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .grey-image {
        height: 280px;
        width: 200px;
        margin: 0 10px;
        border-radius: 7.4px;
        flex-grow: 0;
        flex-shrink: 0;
        filter: opacity(0.45);
      }

      .activeImage {
        height: 310px;
        width: 222px;
        border-radius: 9px;
        flex-grow: 0;
        flex-shrink: 0;
        filter: opacity(1);
      }

      .pointerLeft {
        position: fixed;
        margin-left: 430px;
        cursor: pointer;
      }

      .pointerRight {
        position: fixed;
        margin-left: 30px;
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
  }

  .holder-detail-dialog {
    border-radius: 8px;

    .el-dialog__header {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 30px 0;
    }

    .el-dialog__body {
      padding: 20px 30px 30px;
    }

    .el-checkbox__inner {
      border-radius: 50%;
    }

    .button-group-signle {
      display: flex;
      justify-content: center;
    }

    .button-group {
      margin: 25px 25px 0 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-cancel {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #c2c7d0;
        font-size: 14px;
        text-align: center;
        color: #354052;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .btn-confirm {
        width: 160px;
        height: 40px;
        border-radius: 3px;
        background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
        font-size: 14px;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .button-group-signle {
      margin: 25px 0 0;
      display: flex;
      justify-content: center;

      .btn-confirm {
        width: 100%;
      }
    }

    .defaultCode {
      margin-top: 14px;
    }

    .friendInviteremark {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #354052;
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .friendInvite {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #354052;

      .friendInviteNum {
        color: #c61b3d;
      }
    }

    .tips-cust {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.93;
      color: #c2c7d0;
    }

    .rabate-rate-content {
      width: 100%;
      margin-top: 16px;

      ul {
        display: flex;
        flex-wrap: wrap;

        .rabate-rate-item {
          cursor: pointer;
          width: 80px;
          height: 30px;
          background-color: #f1f5f9;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: 500;
          color: #354052;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 4px;
          position: relative;

          &.active {
            border: solid 1px #c61b3d;
            background-color: #fff;
            color: #c61b3d;
            box-sizing: border-box;
          }
        }

        .rabate-rate-item-cust::-webkit-inner-spin-button,
        .rabate-rate-item-cust::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .rabate-rate-item-cust {
          -moz-appearance: textfield;
        }

        .rabate-rate-item-cust {
          width: 80px;
          height: 30px;
          text-align: center;
          border-radius: 4px;
          border: solid 1px #c2c7d0;
          font-size: 15px;
          font-weight: 500;

          &:focus {
            border: solid 1px;
            background-color: #fff;
            color: #c61b3d;
            box-sizing: border-box;
            border-color: #c61b3d !important;
          }

          &.active {
            border: solid 1px;
            background-color: #fff;
            color: #c61b3d;
            box-sizing: border-box;
            border-color: #c61b3d !important;
          }

          &::placeholder {
            color: #c2c7d0;
            font-weight: normal;
          }
        }
      }
    }

    .business-area {
      display: flex;
      flex-direction: column;

      .ba-item {
        display: flex;
        font-size: 16px;
        margin: 5px 0;

        .bai-name {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          width: 40%;
          color: #9aa1b1;
        }

        .bai-me {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 30%;
          color: #f04a5a;
        }

        .bai-friend {
          display: inline-flex;
          justify-content: flex-end;
          align-items: center;
          width: 30%;
          height: 35px;

          .el-input {
            width: 85%;
            height: 35px;

            .el-input__inner {
              height: 35px;
              line-height: 35px;
            }

            .el-input__icon {
              line-height: 35px;
            }
          }
        }

        .header {
          font-weight: 500;
          color: #354052;
        }
      }
    }
  }

  .trade-container {
    .title-content {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0;

      .left-content {
        width: 60%;
        margin-right: 20px;
        height: 165px;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        background: #fff;
        display: flex;
        justify-content: space-between;
        min-width: 731px;

        .left-img {
          // width: 218px;
          min-width: 230px;
          padding: 20px 40px 24px 40px;
          border-right: 1px solid #ecedf1;

          .nick-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 144px;
            color: #354052;
            padding-bottom: 7px;
            font-size: 18px;
            margin-top: 10px;
          }

          img {
            width: 48px;
            height: 48px;
            margin-bottom: 15px;
          }

          p {
            margin-bottom: 0px;
            color: #354052;
            font-size: 18px;
            font-weight: 500;
          }

          .uid {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
          }

          .el-avatar {
            user-select: none;

            img {
              width: 48px;
              height: 48px;
            }
          }
        }

        .left-text {
          width: 100%;
          color: #354052;
          padding: 20px 40px 24px 40px;
          font-weight: 500;

          .left-text-title {
            font-size: 20px;
            margin-bottom: 15px;
            user-select: none;
          }

          .left-content-item {
            width: 33%;
            display: inline-block;

            .one-item {
              font-size: 14px;
              user-select: none;
            }

            .two-item {
              font-size: 12px;

              span {
                font-size: 24px;
                margin-right: 5px;
              }
            }

            .three-item {
              span.num {
                display: block;
                width: fit-content;
                height: 20px;
                margin: 4px 19px 0 0;
                padding: 3px 10px 3px 9px;
                border-radius: 12.5px;
                background-color: rgba(240, 74, 90, 0.07);
                font-size: 12px;
                font-weight: 500;
                color: #f04a5a;
              }
            }
          }
        }
      }

      .right-content {
        width: 40%;
        height: 165px;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        background: #fff;
        display: flex;
        justify-content: space-between;

        .right-text {
          width: 100%;
          padding: 20px;

          .right-title {
            font-size: 20px;
            font-weight: 500;
            color: #354052;
            margin-bottom: 5px;
            user-select: none;

            .real-rebate {
              font-size: 12px;
              color: #354052;
              margin-left: 10px;

              .rebate-value {
                color: #f04a5a;
              }
            }
          }

          .right-text-item-empty {
            width: 100%;
            height: 100px;
            padding: 13px 16px 12px 15px;
            border-radius: 4px;
            background-color: #f9f9fa;
            display: flex;
            align-items: center;
            justify-content: center;

            .empty-image {
              flex-shrink: 0;
              width: 60px;
              height: 60px;
              background-image: url("~@/assets/img/promotev3/empty.png");
              background-size: cover;
              background-repeat: no-repeat;
            }

            .closed-image {
              flex-shrink: 0;
              width: 60px;
              height: 60px;
              background-image: url("~@/assets/img/promotev3/closed.png");
              background-size: cover;
              background-repeat: no-repeat;
            }

            .empty-text {
              flex-wrap: wrap;
              margin: 0 5px 0 20px;
              color: #5c5f69;
              font-family: PingFang SC;
              font-size: 14px;
              font-weight: 500;
            }

            .empty-icon {
              flex-shrink: 0;
              width: 20px;
              height: 20px;
              background-image: url("~@/assets/img/promotev3/frame.png");
              background-size: cover;
              background-repeat: no-repeat;
            }
          }

          .right-text-item {
            width: 100%;
            height: 100px;
            padding: 13px 16px 12px 15px;
            border-radius: 4px;
            background-color: #f9f9fa;
            display: flex;
            justify-content: space-between;

            .invite {
              margin-top: 2px;
              font-size: 12px;
              color: #354052;

              .iconfont {
                color: #9aa1b1;
                margin-left: 10px;
                cursor: pointer;
              }
            }

            .invite_link_outer {
              font-size: 12px;
              color: #354052;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 2px;

              .inviteIcon {
                // margin-top: 6px;
                color: #9aa1b1;
                margin-left: 10px;
                cursor: pointer;
              }

              .invite {
                overflow: hidden;
                width: 290px;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .left {
              .top-text {
                color: #354052;
                font-size: 14px;
                font-weight: 500;

                .red {
                  font-size: 16px;
                  font-weight: bold;
                  color: #f04a5a;
                }

                .blue {
                  margin-left: 10px;
                  font-weight: bold;
                  color: #3248f6;
                  cursor: pointer;
                }

                .ask {
                  color: #9aa1b1;
                  margin-left: 10px;
                  cursor: pointer;
                  font-size: 18px;
                }
              }
            }

            .right {
              text-align: right;
              user-select: none;

              p {
                cursor: pointer;
                height: 20px;
                margin-bottom: 20px;
                font-size: 12px;
                color: #354052;

                .editcolor {
                  color: #9aa1b1;
                }
              }
            }
          }
        }

        .btn-v2 {
          user-select: none;
          cursor: pointer;
          width: 120px;
          height: 32px;
          padding: 6px 20px;
          border-radius: 3px;
          background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        }
      }
    }

    .header {
      border-radius: 4px;

      .content-title {
        background-color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;
        user-select: none;

        h2 {
          font-size: 26px;
          font-weight: 600;
          color: #354052;
          text-align: left;
          height: 80px;
          line-height: 80px;
          padding-left: 24px;
        }

        .sub-title {
          width: fit-content;
          height: 24px;
          line-height: 24px;
          margin: 7px 0 6.5px 6px;
          padding: 0 10px;
          border-radius: 12px;
          background-image: linear-gradient(240deg, #ff486d 7%, #c61b3d 94%);
          color: #ffffff;
          font-size: 12px;
        }
      }

      .nav {
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-size: 14px;

        .profitmessage {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;

          .exchange-rate {
            font-size: 14px;
            line-height: 14px;
            color: #354052;
          }

          .profit-loss {
            font-size: 14px;
            line-height: 14px;
            color: #354052;

            .profit {
              color: #03ad79;
            }

            .loss {
              color: red;
            }
          }
        }

        .list-type {
          display: flex;

          .tab {
            position: relative;
            padding: 0px 0px 10px 0px;
            margin-right: 22px;
            color: #959ba7;
            line-height: 22px;
            cursor: pointer;

            span {
              font-size: 16px;
              font-weight: 400;
            }

            &:hover {
              color: #354052;

              &:after {
                content: "";
                height: 2px;
                width: 80%;
                background-color: #c61b3d;
              }
            }

            &.active {
              color: #354052;
              font-weight: 400;
              font-size: 16px;

              &:after {
                content: "";
                height: 3px;
                width: 20px;
                background-color: #c61b3d;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }

      .assets {
        margin-top: 16px;
        background-color: #fff;
        min-height: 854px;
      }
    }

    .spread-uncter-content {
      min-height: 757px;
      padding: 0px 20px;
      background: #ffffff;
      margin: 20px 20px 20px 20px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);

      .tab-content {
        user-select: none;
        background-color: #fff;
        border-bottom: 1px solid #ecedf1;

        ul {
          display: flex;
        }

        .classify-area {
          padding: 10px 15px;
          cursor: pointer;
          font-size: 16px;
          // height: 32px;
          line-height: 32px;
          color: #354052;
          position: relative;

          &:first-child {
            margin-left: -15px;
          }

          &.active {
            font-weight: 500;
            background-color: #ffffff;
            color: #354052;
          }

          .item-bar {
            position: absolute;
            width: 70%;
            height: 3px;
            background-color: #e81b40;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }

      .el-table {
        min-height: 570px;

        th > .cell {
          padding-right: 30px;
        }

        .cell {
          padding-right: 30px;
        }
      }

      .no-data-box {
        min-height: 520px;
      }

      .el-table__row {
        height: 50px !important;

        .cell {
          color: #354052;
          font-size: 14px;
          align-items: center;
        }
      }

      .coin-ing {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        vertical-align: middle;
      }

      .text {
        vertical-align: middle;
      }

      .persent {
        color: #f04a5a;
      }

      .total-msg-box {
        border-radius: 4px;
        background-color: #f9f9fa;
        height: 48px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #354052;
        padding-left: 15px;

        .msg-num {
          display: inline-block;
          padding: 0 20px 0 4px;
          font-size: 16px;
          font-weight: bold;
          color: #f04a5a;
        }
      }

      .invite-code-box {
        height: 40px;
        margin-top: 15px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .search-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .title-box {
            height: 100%;
            font-size: 20px;
            font-weight: 500;
            color: #354052;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
          }

          .search-code {
            width: 150px;
            margin-right: 20px;
          }

          .search-remark {
            width: 150px;
            margin-right: 20px;
          }

          .tenWid {
            width: 10px;
            height: 1px;
            display: inline-block;
            position: relative;
          }

          .twentyWid {
            width: 20px;
            height: 1px;
            display: inline-block;
            position: relative;
          }

          .btn-search {
            user-select: none;
            cursor: pointer;
            padding: 10px 21px;
            border-radius: 3px;
            background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
            color: #fff;
            font-size: 14px;
          }

          .btn-reset {
            user-select: none;
            cursor: pointer;
            padding: 9px 20px;
            border-radius: 3px;
            color: #354052;
            font-size: 14px;
            border: solid 1px #c2c7d0;
            box-sizing: border-box;
          }
        }

        .btn-invite {
          user-select: none;
          cursor: pointer;
          height: 32px;
          padding: 6px 20px;
          border-radius: 3px;
          background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
          color: #fff;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .friend-rebate-box {
        height: 40px;
        margin-top: 15px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .search-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .title-box {
            height: 100%;
            font-size: 20px;
            font-weight: 500;
            color: #354052;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .search-code {
            width: 150px;
          }

          .search-remark {
            width: 150px;
          }

          .tenWid {
            width: 10px;
            height: 1px;
            display: inline-block;
            position: relative;
          }

          .twentyWid {
            width: 20px;
            height: 1px;
            display: inline-block;
            position: relative;
          }

          .btn-search {
            user-select: none;
            cursor: pointer;
            padding: 10px 21px;
            border-radius: 3px;
            background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
            color: #fff;
            font-size: 14px;
          }

          .btn-reset {
            user-select: none;
            cursor: pointer;
            padding: 9px 20px;
            border-radius: 3px;
            color: #354052;
            font-size: 14px;
            border: solid 1px #c2c7d0;
            box-sizing: border-box;
          }
        }
      }

      .table-icon-copy {
        vertical-align: -4%;
        margin-left: 10px;
        color: #9aa1b1;
        cursor: pointer;
      }

      .is-default-code {
        color: #f04a5a;
        margin-left: 10px;
        font-weight: 500;
        border-radius: 12.5px;
        border: solid 1px #f04a5a;
        padding: 0px 10px;
        font-size: 12px;
      }

      .operation {
        font-size: 14px;
        color: #3348f6;
        font-weight: 400;
        cursor: pointer;
        padding: 0px 0px 0px 20px;
      }

      .operation-ban {
        font-size: 14px;
        color: #9aa1b1;
        font-weight: 400;
        padding: 0px 0px 0px 20px;
      }

      .filter-box {
        margin: 15px 0px 10px 0px;

        .tenWid {
          width: 10px;
          height: 1px;
          display: inline-block;
          position: relative;
        }

        .twentyWid {
          width: 20px;
          height: 1px;
          display: inline-block;
          position: relative;
        }

        .btn-search {
          user-select: none;
          cursor: pointer;
          width: 120px;
          height: 16px;
          padding: 10px 21px;
          border-radius: 3px;
          background-image: linear-gradient(73deg, #c61b4e 21%, #ee244c 82%);
          color: #fff;
          font-size: 14px;
        }

        .btn-reset {
          user-select: none;
          cursor: pointer;
          width: 120px;
          height: 16px;
          padding: 9px 20px;
          border-radius: 3px;
          color: #354052;
          font-size: 14px;
          border: solid 1px #c2c7d0;
          box-sizing: border-box;
        }

        .btn-export {
          user-select: none;
          cursor: pointer;
          padding: 10px 12px;
          border-radius: 3px;
          color: #354052;
          font-size: 14px;
          border: solid 1px #c2c7d0;
          box-sizing: border-box;
          float: right;

          .export-icon {
            color: #9aa1b1;
            margin-right: 4px;
          }
        }

        .el-input {
          width: 200px;

          .el-input__inner {
            border: solid 1px #e2e2e4;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }

        .el-checkbox {
          margin-left: 20px;

          .el-checkbox__label {
            font-size: 14px;
            color: #354052;
            font-weight: 400;
          }
        }
      }
    }
  }

  .confirm-dialog {
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }

    .el-dialog__body {
      text-align: center;
      color: #354052;
      padding: 20px 20px 30px;
      font-size: 16px;
      font-weight: normal;
    }
  }
}
</style>
