<template>
  <el-scrollbar id="app" :class="getLanguageClass">
    <header-eight-coin @onApiClick="onApiClick"></header-eight-coin>
    <div class="app-main">
      <router-view v-if="$route.meta.title == '首页'"></router-view>
      <keep-alive v-else-if="keepAlivePath.includes($route.name)">
        <router-view></router-view>
      </keep-alive>
      <router-view
        v-else
        v-wechat-title="
          translateTitle($route.meta.title) +
          ' | ' +
          ' 4E ' +
          translateTitle(title)
        "
      ></router-view>
    </div>
    <Footer
      v-if="!hideFooter && !$route.meta.hideFooter"
      @onApiClick="onApiClick"
    ></Footer>
    <!-- visibility ="hidden" -->

    <div v-if="customer_type == 1">
      <LiveChatWidget
        v-if="!hideCustomer"
        :visibility="hiddenCustomer"
        license="15392850"
        :group="groupId"
        :chatBetweenGroups="false"
        :sessionVariables="sessionVariables"
        v-on:new-event="handleNewEvent"
      />
    </div>
    <ChatWedget ref="ChatWedget" v-if="customer_type == 0" />
  </el-scrollbar>
</template>
<script>
import HeaderEightCoin from "@/components/common/header-8coin.vue";
import Footer from "@/components/common/footer.vue";
import { translateTitle } from "@/assets/js/i18n";
import { mapActions, mapGetters } from "vuex";
import { LiveChatWidget } from "@livechat/widget-vue/v2";
import ChatWedget from "./pages/customer-service/ChatWedget.vue";
import { getLangPackage } from "@/assets/js/lang";
import seoMixin from "@/pages/seo/seoMixin.vue";
import { DEVICE_ID } from "@/assets/js/stationSetting";
import { apiAppInitialDataV2 } from "@/model/ucenter/billCenter";
import api from "@/assets/js/api";
import { apiWsUrl } from "@/model/exchange/index";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import Socket from "@/assets/js/socket.js";
import { apiGetStaticTableInfo } from "@/model/settings";

// import Cookies from "js-cookie";
export default {
  name: "App",
  mixins: [seoMixin],
  components: {
    HeaderEightCoin,
    Footer,
    LiveChatWidget,
    ChatWedget,
  },
  data() {
    return {
      wsUrl: "",
      wsContentTime: 0,
      lockReconnect: false,
      tt: {},

      customer_type: -1,
      reportGeeGuardDataUrl: "",
      req: {},
      title: "全球金融衍生品平台",
      groupId: "2",
      hiddenCustomer: "",
      hideFooter: false,
      gotop: false,

      is8Coin: false,
      isRouterAlive: true,
      hideCustomer: true,
      sessionVariables: {
        uid: "",
        email: "",
        google: "",
        mobile: "",
        page: "",
        kyc_level: "",
      },
      countryList: [
        {
          name: "中文简体",
          value: "zh_CN",
          valueFull: "zh_CN",
          id: "2",
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          valueFull: "zh_HK",
          id: "3",
        },
        {
          id: "English",
          value: "en",
          valueFull: "en_US",
          id: "4",
        },
        {
          name: "한국인",
          value: "ko",
          valueFull: "ko_KR",
          id: "4",
        },
        {
          name: "日本語",
          value: "ja",
          valueFull: "ja_JP",
          id: "4",
        },
        {
          name: "عربي",
          value: "ar",
          valueFull: "ar_AR",
          id: "4",
        },
        {
          name: "Français",
          value: "fr",
          valueFull: "fr_FR",
          id: "4",
        },
        {
          name: "Español",
          value: "es",
          valueFull: "es_ES",
          id: "4",
        },
        {
          name: "Pусский",
          value: "ru",
          valueFull: "ru_RU",
          id: "4",
        },
        {
          name: "Deutsch",
          value: "de",
          valueFull: "de_DE",
          id: "4",
        },
        {
          name: "Português",
          value: "pt",
          valueFull: "pt_BR",
          id: "21",
        },
        {
          name: "हिंदी",
          value: "hi",
          valueFull: "hi_IN",
          id: "4",
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          valueFull: "vi_VN",
          id: "20",
        },
        {
          name: "Pilipino",
          value: "tl",
          valueFull: "tl_PH",
          id: "15",
        },
        {
          name: "ภาษาไทย",
          value: "th",
          valueFull: "th_TH",
          id: "4",
        },
        {
          name: "Türk",
          value: "tr",
          valueFull: "tr_TR",
          id: "4",
        },
        {
          name: "Italiano",
          value: "it",
          valueFull: "it_IT",
          id: "4",
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          valueFull: "id_ID",
        },
      ],
      keepAlivePath: [],
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        this.sessionVariables.uid = this.userInfo.accountInfo.account_id;
        this.sessionVariables.email = this.userInfo.authInfo.open_email
          ? "yes"
          : "no";
        this.sessionVariables.google =
          this.userInfo.authInfo.open_google == 1 ? "yes" : "no";
        this.sessionVariables.mobile =
          this.userInfo.authInfo.open_mobile == 1 ? "yes" : "no";
        let DOMAIL = document.location.pathname;
        if (DOMAIL.indexOf("/login") >= 0) {
          this.sessionVariables.page = "log";
        } else if (DOMAIL.indexOf("/register") >= 0) {
          this.sessionVariables.page = "reg";
        } else if (DOMAIL.indexOf("/quicklycoins") >= 0) {
          this.sessionVariables.page = "qui";
        } else if (DOMAIL.indexOf("/markets") >= 0) {
          this.sessionVariables.page = "mar";
        } else if (DOMAIL.indexOf("/contract-exchange") >= 0) {
          this.sessionVariables.page = "fut";
        } else if (DOMAIL.indexOf("/exchange") >= 0) {
          this.sessionVariables.page = "spo";
        } else if (DOMAIL.indexOf("/delivery") >= 0) {
          this.sessionVariables.page = "fcm";
        } else if (DOMAIL.indexOf("/finances") >= 0) {
          this.sessionVariables.page = "fim";
        } else if (DOMAIL.indexOf("/aboutUs") >= 0) {
          this.sessionVariables.page = "abo";
        } else if (DOMAIL.indexOf("/ucenter") >= 0) {
          this.sessionVariables.page = "uct";
        }
        this.sessionVariables.kyc_level =
          this.userInfo &&
          this.userInfo.kycInfo &&
          this.userInfo.kycInfo.kyc_level;
      }
    },
  },
  async created() {
    this.customer_type = sessionStorage.getItem("customer_type");
    await this.getAppIntialDataV2();

    this.$sensors.identify(DEVICE_ID, true);
    this.setTheme();
    const DOMAIL = document.location.pathname;
    if (this.userInfo) {
      this.sessionVariables.uid = this.userInfo.accountInfo.account_id;
      this.sessionVariables.email = this.userInfo.authInfo.open_email
        ? "yes"
        : "no";
      this.sessionVariables.google =
        this.userInfo.authInfo.open_google == 1 ? "yes" : "no";
      this.sessionVariables.mobile =
        this.userInfo.authInfo.open_mobile == 1 ? "yes" : "no";
    }

    if (DOMAIL.indexOf("/login") >= 0) {
      this.sessionVariables.page = "log";
    } else if (DOMAIL.indexOf("/register") >= 0) {
      this.sessionVariables.page = "reg";
    } else if (DOMAIL.indexOf("/quicklycoins") >= 0) {
      this.sessionVariables.page = "qui";
    } else if (DOMAIL.indexOf("/markets") >= 0) {
      this.sessionVariables.page = "mar";
    } else if (DOMAIL.indexOf("/contract-exchange") >= 0) {
      this.sessionVariables.page = "fut";
    } else if (DOMAIL.indexOf("/exchange") >= 0) {
      this.sessionVariables.page = "spo";
    } else if (DOMAIL.indexOf("/delivery") >= 0) {
      this.sessionVariables.page = "fcm";
    } else if (DOMAIL.indexOf("/finances") >= 0) {
      this.sessionVariables.page = "fim";
    } else if (DOMAIL.indexOf("/aboutUs") >= 0) {
      this.sessionVariables.page = "abo";
    } else if (DOMAIL.indexOf("/ucenter") >= 0) {
      this.sessionVariables.page = "uct";
    }
    this.sessionVariables.kyc_level =
      this.userInfo && this.userInfo.kycInfo && this.userInfo.kycInfo.kyc_level;
    this.$router.afterEach(() => {
      this.isHasFooter();
    });
    this.$router.beforeEach((to, from, next) => {
      this.isHasFooter();
      next();
    });
    this.isHasFooter();
    setInterval(() => {
      window.location.reload();
    }, 6 * 60 * 60 * 1000);
    this.countryList.forEach((ele) => {
      if (ele.value == this.language) {
        this.groupId = ele.id;
      }
    });
    this.chooseLanguage();
    //初始化行情相关信息
    await this.initMarkDataInfo();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.initGeeGuard();
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      v8show: "user/v8show",
      language: "user/language",
      darkMode: "user/darkMode",
    }),

    getLanguageClass() {
      let tempClass = "";
      tempClass = this.language;
      if (tempClass == "" || tempClass == null || tempClass == undefined) {
        tempClass = "zh_CN";
      }
      // window.location.search = `/lang=${tempClass}`;
      return tempClass;
    },
  },
  methods: {
    translateTitle,
    async initMarkDataInfo() {
      await this.initStaticData();
      await this.initURL();
      this.initWS();
    },
    async initStaticData() {
      const { data: coinInfoListThirTeen } = await apiGetStaticTableInfo(13);
      const { data: coinInfoMap } = await apiGetStaticTableInfo(49);
      const { data: exchangeSymbolMap } = await apiGetStaticTableInfo(55);
      const { data: coinItemIdMap } = await apiGetStaticTableInfo(1);
      const { data: fxSymbolInfoMap } = await apiGetStaticTableInfo(85);
      this.updateStaticData({
        coinInfoListThirTeen,
        coinInfoMap,
        exchangeSymbolMap,
        coinItemIdMap,
        fxSymbolInfoMap,
      });
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWS() {
      let me = this;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "assets",
            app_id: VUE_APP_ID,
          },
        },
        callback: this.onResponse,
        mounted: this.mounted,
        onConnect,
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();

      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWS();
          me.lockReconnect = false;
        }, 3000);
      }
    },
    async mounted() {
      let me = this;
      me.ws.sub({
        action: "Topic.sub",
        data: {
          type: "marketM", // 数字资产
          app_id: VUE_APP_ID,
        },
      });
      me.ws.sub({
        action: "Topic.sub",
        data: {
          type: "derivativesM", // 外汇
          app_id: VUE_APP_ID,
        },
      });
    },
    onResponse(res) {
      this.setWsData(res);
    },
    handleNewEvent(event) {
      // console.log("LiveChatWidget.onNewEvent", event);
    },
    async getAppIntialDataV2() {
      const { status, data } = await apiAppInitialDataV2();
      if (status == 200) {
        if (data.service_config && data.service_config.collectlog) {
          this.reportGeeGuardDataUrl = data.service_config.collectlog; //线上
          // this.reportGeeGuardDataUrl = "https://collectlog.ee2baselog.com"; //本地测试
        }
      }
    },
    async initGeeGuard() {
      if (window.geetestScriptLoaded) {
        this.req = {
          token:
            this.userInfo && this.userInfo.tokenInfo
              ? this.userInfo.tokenInfo.token
              : "",
          gee_token: encodeURIComponent(this.$gee_data.gee_token),
          data: JSON.stringify({}),
          business_type: "homepage",
        };
        const { status, msg } = await this.apiGeetestReport();
        if (status == 200) {
          // console.log("上报成功");
        } else {
          // console.error(msg);
        }
      } else {
        setTimeout(this.initGeeGuard, 1000);
      }
    },
    apiGeetestReport() {
      let that = this;
      return api({
        url: that.reportGeeGuardDataUrl + "/api/log/gee_token",
        data: that.req,
        type: "POST",
      });
    },
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
      setWsData: "market/setWsData",
      updateStaticData: "market/updateStaticData",
    }),
    tipsDownload() {
      this.$refs.header8v.onDownloadTipsDialog();
    },
    onApiClick() {
      this.$refs.ChatWedget.onChatClick();
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollTop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    onTopClick() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    setTheme() {
      this.title = "全球金融衍生品平台";
      this.is8Coin = true;
      document.getElementsByTagName("body")[0].className = `vab-theme-8coin`;
    },

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    showMaskexCustomerService() {
      const index1 = location.pathname.indexOf("/landingpage/register");
      if (index1 == -1) {
        this.showMaskexCustomer = true;
      } else {
        this.showMaskexCustomer = false;
      }
    },
    isHasFooter() {
      const index = location.pathname.indexOf("/login");
      const index1 = location.pathname.indexOf("/register");
      const index2 = location.pathname.indexOf("/assets");
      const index3 = location.pathname.indexOf("/forgetPwd");
      const index4 = location.pathname.indexOf("/LoginSecurity");
      const index5 = location.pathname.indexOf("/landingpage/register");
      if (
        index != -1 ||
        index1 != -1 ||
        index3 != -1 ||
        index4 != -1 ||
        index5 != -1
      ) {
        this.hideFooter = true;
      } else {
        this.hideFooter = false;
      }
      if (index5 != -1) {
        this.hideCustomer = true;
        this.hiddenCustomer = "hidden";
      } else {
        this.hideCustomer = false;
        this.hiddenCustomer = "";
      }
    },

    chooseLanguage() {
      let lastIsLang = false;
      let lastpath = window.location.href.split("/");
      this.countryList.forEach((item) => {
        if (item.value == lastpath[lastpath.length - 1]) {
          lastIsLang = true;
        }
      });

      if (!(this.$route.params && this.$route.params.lang)) return;
      const langPackage = getLangPackage([this.$route.params.lang]);
      this.$i18n.setLocaleMessage(
        this.$route.params.lang,
        langPackage[this.$route.params.lang]
      );
      this.changeLanguage(this.$route.params.lang);
      this.$i18n.locale = this.$route.params.lang;
    },
  },
};
</script>

<style lang="scss">
.app-main {
  margin-top: 64px;
}
#app {
  &.fr {
    .app-main .side-menu {
      width: 266px;
    }
  }
  &.es {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.ru {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.de {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.vi {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.it {
    .app-main .side-menu {
      width: 260px;
    }
  }
  &.pt {
    .app-main .side-menu {
      width: 270px;
    }
  }
  &.tl {
    .app-main .side-menu {
      width: 280px;
    }
  }
  &.ar {
    .app-main {
      .home-page {
        // direction: rtl;
      }
    }
  }
}
#app {
  display: flex;
  font-family: PingFang SC, robotoregular, Hiragino Sans GB, Heiti SC,
    Microsoft YaHei, WenQuanYi Micro Hei, Helvetica, Arial, monospace, serif !important;
  flex-direction: column;
  font-style: normal;
  // min-width: 1440px;
  height: 100%;
  min-width: 1180px;
  /* background: #ecedf1; */
  &.ar {
    .el-scrollbar__wrap {
      margin-right: 0;
      margin-left: -15px;
    }
  }
  .el-scrollbar__wrap {
    height: 102%;
  }
  // .el-scrollbar__view {
  //   height: 100%;
  // }
  .app-main {
    flex: 1;
    min-height: calc(100% - 281px);
    .side-menu {
      padding: 0px 0;
      background: rgba(255, 255, 255, 1);
      // border-radius: 4px;
      overflow: hidden;
      width: 206px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      z-index: 999;
      height: auto;
      float: left;

      .nav {
        li {
          text-align: left;
          justify-content: left;
          padding-left: 30px;
          box-sizing: border-box;
          height: 40px; // jason要求改为40

          i {
            margin-right: 15px;
          }
        }
        li.active {
          color: #fff;
          background: linear-gradient(
            315deg,
            rgba(39, 198, 131, 1) 0%,
            rgba(39, 198, 131, 1) 100%
          );
        }
      }
    }
    .el-form-item {
      text-align: left !important;
    }
  }
  .top-icon {
    position: fixed;
    right: 40px;
    bottom: 14%;
    cursor: pointer;
    z-index: 99999;
  }
  .el-checkbox {
    &:hover {
      .el-checkbox__input {
        .el-checkbox__inner {
          border-color: #409eff;
        }
      }
    }
  }
}
</style>
