import * as echarts from "echarts";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import BigNumber from "bignumber.js";
const chartColor = {
    green: {
        lineStyle: {
            color: "#31BD50", // 线条颜色
        },
        areaStyle: {
            color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                    {
                        offset: 0,
                        color: "rgba(49, 189, 80, .5)",
                    },
                    {
                        offset: 1,
                        color: "rgba(49, 189, 80, 0)",
                    },
                ],
                false
            ),
        },
    },
    red: {
        lineStyle: {
            color: "#F0551C", // 线条颜色
        },
        areaStyle: {
            color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                    {
                        offset: 0,
                        color: "rgba(240, 85, 28, .5)",
                    },
                    {
                        offset: 1,
                        color: "rgba(240, 85, 28, 0)",
                    },
                ],
                false
            ),
        },
    },
};
const chartOptions = {
    backgroundColor: "#FBFCFF",
    animation: false, // 关闭动画
    grid: {
        top: "15%",
        left: "10%",
        right: "10%",
        bottom: "15%",
        // containLabel: true
    },
    xAxis: [
        {
            type: "category",
            boundaryGap: false,
            axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                    color: "#233653",
                },
            },
            axisLabel: {
                //坐标轴刻度标签的相关设置
                show: false,
                textStyle: {
                    color: "#7ec7ff",
                    padding: 16,
                    fontSize: 14,
                },
                formatter: function (data) {
                    return data;
                },
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#192a44",
                },
            },
            axisTick: {
                show: false,
            },
            // data: xLabel,
        },
    ],
    yAxis: [
        {
            name: "",
            nameTextStyle: {
                color: "#7ec7ff",
                fontSize: 16,
                padding: 10,
            },
            min: 0,
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#192a44",
                },
            },
            axisLine: {
                show: false,
                lineStyle: {
                    color: "#233653",
                },
            },
            axisLabel: {
                show: false,
                textStyle: {
                    color: "#7ec7ff",
                    padding: 16,
                },
                formatter: function (value) {
                    if (value === 0) {
                        return value;
                    }
                    return value;
                },
            },
            axisTick: {
                show: false,
            },
        },
    ],
    series: [
        {
            name: "",
            type: "line",
            symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
                normal: {
                    width: 1,
                    color: "#31BD50", // 线条颜色
                },
                borderColor: "rgba(0,0,0,.4)",
            },
            itemStyle: {
                color: "rgba(25,163,223,1)",
                borderColor: "#646ace",
                borderWidth: 2,
            },
            tooltip: {
                show: true,
            },
            areaStyle: {
                //区域填充样式
                normal: {
                    //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                    color: new echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(49, 189, 80, .5)",
                            },
                            {
                                offset: 1,
                                color: "rgba(49, 189, 80, 0)",
                            },
                        ],
                        false
                    ),
                    shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                    shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                },
            },
            // data: goToSchool,
        },
    ],
};
export default {
    data() {
        return {
            activeNav: "0",
            // 图表数据
            spotsTableData: [], // 加密货币-现货   -> 数字资产
            derivativesTableData: [], // 外汇
            // stockTableData: [], // 股票 TODO:无接口
            indexTableData: [], //股指
            bulkTableData: [], //大宗商品
            mockDataMap: {}, // 图表模拟数据
            chartColor,
            charts: [

            ],
            navs: [
                {
                    title: "数字资产",
                    subTitle: "主流数字资产，如BTC、ETH、USDT等",
                    id: "0",
                    key: 'spotsTableData'
                },
                {
                    title: "外汇",
                    subTitle: "逾50中交易币种，包括美元、欧元、英镑等",
                    id: "1",
                    key: 'derivativesTableData'
                },
                {
                    title: "股票",
                    subTitle: "覆盖港美股热门标的",
                    id: "4",
                    key: 'stockTableData'
                },
                {
                    title: "股指",
                    subTitle: "全球十大股指",
                    id: "3",
                    key: "indexTableData"
                },
                {
                    title: "大宗商品",
                    subTitle: "包括能源、贵金属、谷物等在内的多种大宗商品",
                    id: "2",
                    key: 'bulkTableData'
                },
            ],
            stockTableData: [
                {
                    title: "AAPL",
                    icon: require("../../../assets/img/stocks/AAPL.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "AMZN",
                    icon: require("../../../assets/img/stocks/AMZN.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "GOOG",
                    icon: require("../../../assets/img/stocks/GOOG.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "INTC",
                    icon: require("../../../assets/img/stocks/INTC.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "KO",
                    icon: require("../../../assets/img/stocks/KO.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "meta",
                    icon: require("../../../assets/img/stocks/meta.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "MSFT",
                    icon: require("../../../assets/img/stocks/MSFT.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "NVDA",
                    icon: require("../../../assets/img/stocks/NVDA.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "TSLA",
                    icon: require("../../../assets/img/stocks/TSLA.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
                {
                    title: "TSM",
                    icon: require("../../../assets/img/stocks/TSM.png"),
                    isGrowth: Math.floor(Math.random() * 100) > 50,
                },
            ],
            chartOptions: chartOptions,
            // 静态数据
        }
    },
    destroyed() {
    },
    async created() {
        this.initCharts();
    },
    computed: {
        ...mapGetters({
            wsData: "market/wsData",
            coinInfoListThirTeen: "market/coinInfoListThirTeen",
            coinInfoMap: "market/coinInfoMap",
            exchangeSymbolMap: "market/exchangeSymbolMap",
            coinItemIdMap: "market/coinItemIdMap",
            fxSymbolInfoMap: "market/fxSymbolInfoMap",
        }),
    },
    watch: {
        wsData: {
            handler(val) {
                this.onResponse(val);
            },
            immediate: true,
        },
    },
    methods: {
        routeSplicing,
        gotoExchange(item) {
            const { name } = item;
            if (this.activeNav === '0') {
                // 数字资产
                let tempUrl = this.routeSplicing(
                    this.language,
                    `exchange/${name.toLowerCase()}`
                );
                console.log(tempUrl)
                window.open(tempUrl);
            } else {
                // 下载提示框
                this.$emit("trigger-download");
            }
        },
        getClassName(index) {
            const map = {
                0: "left695",
                1: "left278",
                2: "left855",
                3: "left120",
                4: "left1005",
            };
            return "trade-chart__container-content-charts-rows" + " " + map[index];
        },

        handleRowLastValue(value) {
            return new BigNumber(value).toFixed();
        },
        randomLineData() {
            const res = [];
            for (let i = 0; i < 6; i++) {
                res.push(Math.floor(Math.random() * 10));
            }
            return res;
        },
        updateCharts(items, type) {
            let mockData = this.mockDataMap[type];
            const charts = items.reduce((init, item, index) => {
                if (!mockData) {
                    mockData = [];
                    mockData.push(this.randomLineData());
                }
                if (mockData[index] === undefined) {
                    mockData[index] = this.randomLineData();
                }
                let data = {};
                if (type === 'stockTableData') {
                    // 股票数据静态处理
                    data = {
                        ...item,
                        id: item.title,
                        data: mockData[index],
                    };
                } else {
                    data = {
                        title: item.whole_name,
                        value: this.handleRowLastValue(item.last),
                        growth: item.price_change_percent,
                        name: item.name,
                        isGrowth: Number(item.price_change_percent) > 0,
                        icon:
                            item.contract_item_icon ||
                            item.base_currency_icon ||
                            item.margin_currency_icon,
                        data: mockData[index],
                        id: item.symbol_id,
                    };
                }

                if (this.isBarnd) {
                    // 数字资产是五个一行
                    if (index % 6 === 0) {
                        init.push([]);
                    }
                } else {
                    if (index % 2 === 0) {
                        init.push([]);
                    }
                }

                init[init.length - 1].push(data);

                return init;
            }, []);
            this.mockDataMap[type] = mockData;
            this.charts = charts;
            this.$nextTick(() => {
                this.initCharts();
            });
        },
        compareOrderBy(property) {
            return function (a, b) {
                if (!a[property] || !b[property]) return 0;
                if (a[property] < b[property]) return -1;
                if (a[property] == b[property]) return 0;
                return 1;
            };
        },
        getDerivativeArray(res) {
            let derivativeArray = [];
            if (res.data) {
                let fieldDerivativeArray = res.data.fieldDerivative.split(",");

                res.data.data.forEach((item, index) => {
                    let derivativeObj = {};
                    fieldDerivativeArray.forEach((ele, index) => {
                        derivativeObj[ele] = item.split(",")[index];
                    });
                    derivativeArray.push(derivativeObj);
                });
            }
            return derivativeArray;
        },
        onResponse(res) {
            const { action, data } = res;
            if (this.isBarnd && action !== "Pushdata.marketM") {
                // 品牌只需要数字资产的数据，其他的数据不用关注
                return;
            }
            if (action == "Pushdata.marketM" && this.activeNav === "0") {

                // 数字资产 加密货币-现货   -> 数字资产
                const tempData = this.getMarket(data);
                const sortData = tempData
                    .filter((ele) => {
                        return ele.to_coin_name == "USDT";
                    })
                    .slice(0, this.isBarnd ? 12 : 10);

                sortData.sort(this.compareOrderBy("order_by"));
                this.spotsTableData = sortData;
                // console.log(this.spotsTableData)
                this.updateCharts(this.spotsTableData, "marketM");
            }
            if (action == "Pushdata.derivativesM" && this.activeNav === "1") {
                // ele.symbol_type === 1 // 外汇
                const tempData = this.getDerivativesM(this.getDerivativeArray(res));
                const sortData = tempData
                    .filter((ele) => {
                        return ele.symbol_type == 1;
                    })
                    .slice(0, 10);
                sortData.sort(this.compareOrderBy("order_by"));
                this.derivativesTableData = sortData;
                this.updateCharts(this.derivativesTableData, "derivativesTableData");
            }
            if (action == "Pushdata.derivativesM" && this.activeNav === "4") {
                // 股票TODOS：
            }
            if (action == "Pushdata.derivativesM" && this.activeNav === "3") {
                // ele.symbol_type === 3  // 指数 ->股指
                const tempData = this.getDerivativesM(this.getDerivativeArray(res));
                const sortData = tempData
                    .filter((ele) => {
                        return ele.symbol_type == 3;
                    })
                    .slice(0, 10);
                sortData.sort(this.compareOrderBy("order_by"));
                this.indexTableData = sortData;
                this.updateCharts(this.indexTableData, "indexTableData");
            }
            if (action == "Pushdata.derivativesM" && this.activeNav === "2") {
                // ele.symbol_type === 2 //  大宗 -> 大宗商品
                const tempData = this.getDerivativesM(this.getDerivativeArray(res));
                const sortData = tempData
                    .filter((ele) => {
                        return ele.symbol_type == 2;
                    })
                    .slice(0, 10);
                sortData.sort(this.compareOrderBy("order_by"));
                this.bulkTableData = sortData;
                this.updateCharts(this.bulkTableData, "bulkTableData");
            }
        },
        initCharts(isTriggerByTheme) {
            this.chartOptions.backgroundColor = this.isDarkMode
                ? "#000"
                : "#FBFCFF";

            this.charts.forEach((row) => {
                row &&
                    row.forEach?.((chart) => {
                        // :ref="'echarts' + item.id + '_double'"
                        const $el = this.$refs["echarts" + chart.id]?.[0];
                        const $el1 = this.$refs["echarts" + chart.id + '_double']?.[0];
                        if ($el) {
                            var myChart = echarts.getInstanceByDom($el);
                            if (!myChart) {
                                myChart = echarts.init($el);
                                let type = chart.isGrowth ? "green" : "red";
                                this.chartOptions.series[0].data = chart.data;
                                this.chartOptions.series[0].lineStyle.normal.color =
                                    this.chartColor[type].lineStyle.color;
                                this.chartOptions.series[0].areaStyle.normal.color =
                                    this.chartColor[type].areaStyle.color;
                                myChart.setOption(this.chartOptions);
                            } else {
                                if (isTriggerByTheme) {
                                    let type = chart.isGrowth ? "green" : "red";
                                    this.chartOptions.series[0].data = chart.data;
                                    this.chartOptions.series[0].lineStyle.normal.color =
                                        this.chartColor[type].lineStyle.color;
                                    this.chartOptions.series[0].areaStyle.normal.color =
                                        this.chartColor[type].areaStyle.color;
                                    myChart.setOption(this.chartOptions);
                                }
                            }


                        }
                        if ($el1) {
                            var myChart = echarts.getInstanceByDom($el1);
                            if (!myChart) {
                                myChart = echarts.init($el1);
                                let type = chart.isGrowth ? "green" : "red";
                                this.chartOptions.series[0].data = chart.data;
                                this.chartOptions.series[0].lineStyle.normal.color =
                                    this.chartColor[type].lineStyle.color;
                                this.chartOptions.series[0].areaStyle.normal.color =
                                    this.chartColor[type].areaStyle.color;
                                myChart.setOption(this.chartOptions);
                            } else {
                                if (isTriggerByTheme) {
                                    let type = chart.isGrowth ? "green" : "red";
                                    this.chartOptions.series[0].data = chart.data;
                                    this.chartOptions.series[0].lineStyle.normal.color =
                                        this.chartColor[type].lineStyle.color;
                                    this.chartOptions.series[0].areaStyle.normal.color =
                                        this.chartColor[type].areaStyle.color;
                                    myChart.setOption(this.chartOptions);
                                }
                            }

                        }
                    });
            });
        },
        getDerivativesM(derivativesM) {
            let tempArr = derivativesM || []
            tempArr.forEach(ele => {
                ele['trade_type'] = 6
                ele['currency'] = JSON.parse(JSON.stringify(ele))
            })
            const tempDataV2 = tempArr
            let tempMarket = []
            tempDataV2.forEach(item => {
                for (const key in this.fxSymbolInfoMap) {
                    if (item.symbol_id == key) {
                        tempMarket.push({
                            ...item,
                            ...this.fxSymbolInfoMap[key],
                        })
                    }
                }
            })
            tempMarket.forEach(item => {
                item['whole_name'] = item.symbol.replace('.', '/')
                item['coin_name_tag'] = {}
                item['coin_name_tag']['display_' + this.language] = item.language['symbol_name_' + this.language]
            })
            return tempMarket
        },
        getMarket(market) {
            let tempArr = market;// this.$store.state.market.market
            tempArr.forEach(ele => {
                ele['trade_type'] = 3
                ele.type = 'currency'
                ele.lastV2 = Number(ele.last)
                ele.currency.usdV2 = Number(ele.usd)
                ele.price_change_percentV2 = Number(ele.price_change_percent)
            })
            let tempDataV2 = tempArr
            let tempMarket = []
            tempDataV2.forEach(item => {
                for (const key in this.exchangeSymbolMap) {
                    if (item.symbol_id == key) {
                        tempMarket.push({
                            ...item,
                            ...this.exchangeSymbolMap[key],
                        })
                    }
                }
            })
            tempMarket.forEach(item => {
                for (const key in this.coinInfoListThirTeen) {
                    if (item.coin_from == key) {
                        item.change_coin_id = Number(this.coinInfoListThirTeen[key].item_tag)
                        item.contract_item_icon = this.coinInfoListThirTeen[key].itemDetail.coin_url
                    }
                    if (item.coin_to == key) {
                        item.base_coin_id = Number(this.coinInfoListThirTeen[key].item_tag)
                        // item.contract_item_icon = this.coinInfoListThirTeen[key].itemDetail.coin_url
                    }
                }
            })
            tempMarket.forEach(item => {
                for (const key in this.coinItemIdMap) {
                    if (item.change_coin_id == key) {
                        item.from_coin_name = this.coinItemIdMap[item.change_coin_id].name.toUpperCase()
                        item.coin_name_tag = this.coinItemIdMap[item.change_coin_id].language
                    }
                    if (item.base_coin_id == key) {
                        item.to_coin_name = this.coinItemIdMap[item.base_coin_id].name.toUpperCase()
                        item.to_coin_name_tag = this.coinItemIdMap[item.base_coin_id].language
                    }
                }
                item.whole_name = item.from_coin_name ? item.from_coin_name + '/' + item.to_coin_name : item.to_coin_name
            })
            return tempMarket
        },
    },
}
