<template>
  <div class="setting-account">
    <el-dialog
      class="g-dialog"
      :title="translateTitle('修改账号')"
      :visible.sync="currentIsShow"
      :destroy-on-close="true"
      width="500px"
      :close-on-click-modal="false"
    >
      <template v-if="currentIsShow">
        <div class="changePwd">
          <el-form
            label-position="top"
            class="g-form"
            ref="changePwdForm"
            :rules="formRules"
            :model="formData"
          >
            <el-form-item prop="avatar" class="avatar-uploader">
              <el-upload
                class="avatar-uploader"
                action="#"
                ref="uploadFile"
                list-type="picture"
                accept=".jpg, .jpeg, .png"
                :show-file-list="false"
                :http-request="picUpload"
                v-loading="uploadImgLoading"
              >
                <div v-if="formData.avatar" class="avatar-item">
                  <img :src="formData.avatar" class="avatar" />
                  <div class="edit-btn">
                    <i class="iconfont iconedit"></i>
                  </div>
                </div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <!-- <div class="edit">{{ translateTitle("修改头像") }}</div> -->
              </el-upload>

              <!-- <div class="avatar-title">头像</div> -->
            </el-form-item>

            <el-form-item :label="translateTitle('用户名')">
              <el-input
                disabled
                v-model.trim="username"
                autocomplete="off"
                type="input"
                maxlength="20"
                :placeholder="translateTitle('请输入用户名')"
              ></el-input>
            </el-form-item>

            <el-form-item :label="translateTitle('昵称')" prop="nick_name">
              <el-input
                v-model.trim="formData.nick_name"
                autocomplete="off"
                type="input"
                maxlength="10"
                minlength="2"
                :placeholder="translateTitle('请输入账号昵称')"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="用户名" prop="username">
              <el-input
                v-model.trim="formData.username"
                autocomplete="off"
                type="input"
                maxlength="20"
                placeholder="请输入账号用户名"
                :disabled="true"
              ></el-input>
            </el-form-item> -->
            <el-form-item :label="translateTitle('个人签名')" prop="intro">
              <el-input
                v-model.trim="formData.intro"
                autocomplete="off"
                type="input"
                maxlength="30"
                minlength="2"
                :placeholder="translateTitle('请输入个人签名')"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot:footer class="footer">
          <el-button class="common-cancel" @click="onCancel">{{
            translateTitle("取消")
          }}</el-button>
          <el-button
            class="common-save update-btn"
            @click="onSubmit"
            :loading="confirmLoading"
            >{{ translateTitle("保存") }}</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import * as Common from "@/model/common";
import { editUserInfo } from "@/model/ucenter/ucenter";
import { nickNameCheck } from "@/model/ucenter/assets";
import { showMessage } from "@/components/dialog/message.js";
import commonMixin from "@/components/common/commonMixin.vue";
const IMAGE_DEFAULT = "../../../assets/img/avatar.png";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
export default {
  mixins: [commonMixin],
  name: "SettingAccount",
  props: {
    getUserInfoInit: Function,
    isShowDialog: Boolean,
    updateShowDialog: Function,
    userInfo: Object,
    username: [String],
  },
  data() {
    return {
      //上传图片请求参数
      filePostParams: {
        file: "",
        type: 2,
      },
      currentDefPermiss: null,
      defPermissList: [],
      currentIsShow: false,
      formData: {
        username: this.userInfo.realname ? this.userInfo.realname : "",
        intro: this.userInfo.intro,
        nick_name: this.userInfo.nick_name
          ? this.userInfo.nick_name
          : this.translateTitle(`用户`) + `${this.userInfo.user_id}`,
        avatar: this.userInfo.avatar ? this.userInfo.avatar : IMAGE_DEFAULT,
      },
      confirmLoading: false,
      uploadImgLoading: false,
      formRules: {
        nick_name: [
          {
            required: true,
            message: this.translateTitle("请输入昵称"),
            trigger: "blur",
          },
          {
            min: 2,
            max: 10,
            // pattern: /^[\u4e00-\u9fa5_\u0800-\u4e00_a-zA-Z0-9]{2,20}$/,
            // trigger: "change",
            // message: this.translateTitle("请输入昵称"),
            trigger: "blur",
            message: this.translateTitle("请输入昵称,昵称需2-10位字符"),
          },
        ],
        intro: [
          {
            min: 2,
            max: 30,
            required: true,
            message: this.translateTitle("请输入个人签名，签名需2-30字符"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    currentIsShow(val) {
      this.updateShowDialog(val);
    },
  },
  created() {
    if (this.userInfo) {
      this.imageUrl = this.userInfo.avatar;
    }
    this.currentIsShow = this.isShowDialog;
  },
  methods: {
    onCancel() {
      this.currentIsShow = false;
    },
    // 提交表单方法
    async onSubmit() {
      let statusData;
      let { formData } = this;
      this.$refs.changePwdForm.validate(valid => {
        if (valid) {
          statusData = formData;
        } else {
          statusData = false;
        }
      });

      if (!statusData) {
        return false;
      }
      // 检测昵称是否存在
      let { status, msg } = await nickNameCheck({
        subaccount_id: this.userInfo.account_id,
        nick_name: statusData.nick_name,
      });
      if (status !== 200) {
        showMessage({ message: msg });
        return;
      }

      //提交
      this.onCancel();
      this.postApi(this.formData, () => {
        this.getUserInfoInit();
      });
    },
    customerModifiedInfoSuccessfully(data) {
      const customerInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      Object.keys(customerInfo.userInfo).forEach(key => {
        data[key] ? (customerInfo.userInfo[key] = data[key]) : null;
      });
      sessionStorage.setItem("userInfo", JSON.stringify(customerInfo));
    },
    //设置POST回调
    postApi(params, callback) {
      let data = Object.assign({}, this.formData);
      if (params) {
        data = Object.assign(params, this.formData);
      }
      editUserInfo(data).then(({ status, data, msg }) => {
        if (status === 200) {
          showMessage({
            message: this.translateTitle("修改账号成功"),
            type: "success",
          });
          this.customerModifiedInfoSuccessfully(this.formData);
          this.$emit("onConfirm");
          callback && callback();
        } else {
          showMessage({ message: msg ? msg : status });
          if (!params) {
            callback && callback();
          }
        }
      });
    },
    //图片上传
    async picUpload({ file }) {
      // this.$refs.uploadFile.type = "text";
      // this.$refs.uploadFile.type = "file";
      this.uploadImgLoading = true;
      if (file.size > 2 * 1024 * 1024) {
        this.uploadImgLoading = false;
        showMessage({ message: "上传图片不能大于 2M" });
        return;
      }
      if (["image/jpg", "image/jpeg", "image/png"].indexOf(file.type) < 0) {
        this.uploadImgLoading = false;
        showMessage({
          message: "只支持上传 jpg / jpeg / png 类型图片",
          duration: 2000,
        });
        return false;
      }
      const token = window.$.cookie("topcredit_exchange_t") || "";
      let formData = new window.FormData();
      formData.append("type", 1);
      formData.append("app_id", VUE_APP_ID);
      formData.append("file", file);
      formData.append("token", token);

      // this.filePostParams.type = 1;
      // this.filePostParams.file = file;
      // this.filePostParams.app_id = VUE_APP_ID
      //请求参数
      const { status, data, msg } = await Common.fileUpload(formData);

      if (data.status == 200) {
        const url = data.data.url;
        this.formData.avatar = data.data.url;
        showMessage({
          message: this.translateTitle(`图片上传成功`),
          type: "success",
        });
        this.$emit("setPaymentUrl", url);
        this.uploadImgLoading = false;

        // 貼上圖片url
      } else {
        showMessage({ message: data.msg });
        this.uploadImgLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.setting-account {
  .avatar-uploader {
    text-align: center;
    .el-form-item__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .avatar-title {
      font-size: 14px;
      color: #9aa1b1;
      font-weight: 400;
    }
  }
  .avatar-item {
    position: relative;
    .edit-btn {
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      bottom: 5px;
      right: 0;

      background-image: linear-gradient(49deg, #ff486d 23%, #c61b4d 87%);
      .iconedit {
        color: #ffffff;
        line-height: 24px;
        text-align: center;
      }
    }
  }
  .update-btn {
    padding: 0 !important;
  }

  .el-form-item {
    // margin-bottom: 18px !important;

    .el-form-item__label {
      float: unset;
      flex: 1;
    }
    .el-form-item__content {
      line-height: unset;
      flex-basis: 276px;
    }
    .el-select {
      width: 100%;
    }
  }

  .g-dialog {
    .el-dialog__header {
      user-select: none;
      height: 68px;
      padding: 19px 0;
      padding-left: 31px;
      box-sizing: border-box;
      text-align: left;
      .el-dialog__title {
        font-size: 20px;
        font-weight: 500;
      }
    }
    .el-dialog__body {
      padding: 0px 30px 30px 30px;
      .footer {
        margin-top: 25px;
        display: flex;
        justify-content: center;
      }
      .el-form-item__label {
        user-select: none;
      }

      .avatar-uploader {
        user-select: none;
        .edit {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #6969698f;
          font-size: 12px;
          color: #ffffff;
          width: 80px;
          height: 80px;
          line-height: 80px;
          border-radius: 50%;
          :hover {
            color: #ffffff;
          }
        }

        .el-form-item__content {
          display: flex;
          align-items: center;
          .el-form-item__label {
            user-select: none;
          }
        }
        .upload-tips {
          color: #b4bac6;
          font-size: 14px;
          margin: 19px;
        }
      }

      .avatar-uploader .el-upload {
        border: 0;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
        .edit {
          display: inline;
        }
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
      }
      .avatar {
        width: 80px;
        height: 80px;
        display: block;
        border-radius: 50px;
      }
    }
  }
}
</style>
