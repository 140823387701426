<template>
  <div>
    <div class="ad-panel-feature__container">
      <div class="ad-panel-feature__container-title">
        {{ translateTitle("更多服务") }}
      </div>
      <div class="ad-panel-feature__container-items">
        <div
          class="ad-panel-feature__container-items-item"
          v-for="(item, index) in items"
          :key="index"
          :class="{ active: index === activeIndex }"
          @mouseover="overItem(index)"
        >
          <div>
            <div
              class="ad-panel-feature__container-items-item-title"
              v-for="(title, tIndex) in item.title"
              :key="tIndex"
            >
              {{ translateTitle(title) }}
            </div>
            <div
              class="ad-panel-feature__container-items-item-subTitle"
              v-if="index === activeIndex"
            >
              {{ translateTitle(item.subTitle) }}
            </div>
          </div>
          <img
            v-show="index === activeIndex"
            :src="item.icon[isDarkMode ? 'dark' : 'light']['active']"
            alt=""
            class="icon"
            :class="{
              active: index === activeIndex,
            }"
          />
          <img
            v-show="index !== activeIndex"
            :src="item.icon[isDarkMode ? 'dark' : 'light']['inActive']"
            alt=""
            class="icon"
            :class="{
              active: index === activeIndex,
            }"
          />
          <img
            src="../../assets/img/token2049/box3_arrow_active.png"
            alt=""
            class="arrowIcon"
            v-if="index === activeIndex"
          />
          <img
            src="../../assets/img/token2049/box3_arrow.png"
            alt=""
            class="arrowIcon"
            v-else
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import ad1 from "@/assets/img/token2049/box3_img1.png";
import ad1Active from "@/assets/img/token2049/box3_img1_active.png";

import ad1Dark from "@/assets/img/token2049/box3_img1_dark.png";
import ad1DarkActive from "@/assets/img/token2049/box3_img1_active_dark.png";

import ad2 from "@/assets/img/token2049/box3_img2.png";
import ad2Active from "@/assets/img/token2049/box3_img2_active.png";

import ad2Dark from "@/assets/img/token2049/box3_img2_dark.png";
import ad2DarkActive from "@/assets/img/token2049/box3_img2_active_dark.png";

import ad3 from "@/assets/img/token2049/box3_img3.png";
import ad3Active from "@/assets/img/token2049/box3_img3_active.png";
import ad3Dark from "@/assets/img/token2049/box3_img3_dark.png";
import ad3DarkActive from "@/assets/img/token2049/box3_img3_active_dark.png";

import ad4 from "@/assets/img/token2049/box3_img4.png";
import ad4Active from "@/assets/img/token2049/box3_img4_active.png";
import ad4Dark from "@/assets/img/token2049/box3_img4_dark.png";
import ad4DarkActive from "@/assets/img/token2049/box3_img4_active_dark.png";

export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      activeIndex: 0,
      items: [
        {
          title: ["一键跟单"],
          subTitle: "财富不落单",
          icon: {
            dark: {
              inActive: ad1Dark,
              active: ad1DarkActive,
            },
            light: {
              inActive: ad1,
              active: ad1Active,
            },
          },
        },
        {
          title: ["投资赚币"],
          subTitle: "持币生币 理财有道",
          icon: {
            dark: {
              inActive: ad2Dark,
              active: ad2DarkActive,
            },
            light: {
              inActive: ad2,
              active: ad2Active,
            },
          },
        },
        {
          title: ["借贷质押", "灵活满足"],
          subTitle: "小资金撬动大收益",
          icon: {
            dark: {
              inActive: ad3Dark,
              active: ad3DarkActive,
            },
            light: {
              inActive: ad3,
              active: ad3Active,
            },
          },
        },
        {
          title: ["4E金融卡"],
          subTitle: "前途无界 触手可及",
          icon: {
            dark: {
              inActive: ad4Dark,
              active: ad4DarkActive,
            },
            light: {
              inActive: ad4,
              active: ad4Active,
            },
          },
        },
      ],
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    overItem(index) {
      this.activeIndex = index;
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  .ad-panel-feature__container {
    .ad-panel-feature__container-items-item {
      background: #f9faff;

      &.active {
        width: 490px;
        height: 320px;
        background: url(/img/box3_active_bg.67ead5ad.png) no-repeat;
        background-size: 100%;
        .ad-panel-feature__container-items-item-title {
          color: #fff;
        }
        .ad-panel-feature__container-items-item-subTitle {
          color: hsla(0, 0%, 100%, 0.65);
        }
      }
      .ad-panel-feature__container-items-item-title {
        color: #000;
      }
    }
  }
}
.ad-panel-feature__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 498px; */
  background-color: var(--homeBgColor);
  &.banner {
    background-image: url(../../assets/img/home/banners/top-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  color: var(--homeFontColor);
  &-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 1200px;
    height: 500px;
    /* background-image: url("../../../assets/images/home/ad-banner.png");
    background-size: cover;
    background-position: center; */
    &-title {
      font-size: 48px;
      font-weight: 500;
      line-height: 60px;
    }
    &-tips {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 10px;
    }
  }
  &-items {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 1200px;
    min-height: 488px;
    /* flex-flow: row wrap; */
    padding: 50px 0;

    &-item {
      position: relative;
      width: 220px;
      height: 320px;
      padding: 44px 5px 0 36px;
      border-radius: 16px;
      background: #161a27;
      margin-right: 16px;
      box-sizing: border-box;
      transition: all 0.5s;
      transform-origin: left center;

      &.active {
        width: 490px;
        height: 320px;
        background: url("../../assets/img/token2049/box3_active_bg.png")
          no-repeat;
        background-size: 100%;
      }
      &-title {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
      &-subTitle {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.65);
      }
      .arrowIcon {
        position: absolute;
        left: 22px;
        bottom: 17px;
        width: 35px;
        height: 35px;
      }
      .icon {
        position: absolute;
        bottom: 12px;
        height: 150px;
        right: 0px;

        &.active {
          height: 224px;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  &-title {
    font-size: 36px;
    font-weight: 600;
    margin-top: 50px;
  }
}
</style>