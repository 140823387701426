const encryption = (val) => {
  val = val.toString();
  if (val.includes('@')) {
    var arr = val.split("@");
      var star = "";
      if (arr[0].length <= 3) {
        star = "***";
        arr[0] = arr[0].substr(0, arr[0].length - 1) + star;
      } else if (arr[0].length <= 6) {
        star = "***";
        arr[0] = arr[0].substr(0, arr[0].length - 3) + star;
      } else {
        star = "***";
        arr[0] = arr[0].substr(0, arr[0].length - 5) + star;
      }
      return val = arr[0] + "@" + arr[1];
  } else {
    var reg=/(\d{3})\d*(\d{3})/;
    return val.replace(reg,'$1****$2');
  }
}

export default encryption;