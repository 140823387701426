<template>
  <div id="charge-history">
    <el-table
      :data="dataList"
      style="width: 100%"
      ref="refTable"
      v-loading="historyLoading"
    >
      <el-table-column
        align="left"
        header-align="left"
        width="20"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        align="left"
        :label="translateTitle('时间')"
        header-align="left"
        width="200"
      ></el-table-column>
      <el-table-column
        :prop="!multiChainStatus ? '' : 'chain_tag'"
        align="center"
        :label="!multiChainStatus ? '' : translateTitle('链名称')"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="address"
        align="left"
        :label="translateTitle('地址')"
        min-width="180"
      ></el-table-column>
      <el-table-column
        prop="num"
        align="left"
        min-width="65"
        :label="translateTitle('数量')"
      >
        <template slot-scope="scope">
          <span>{{ Number(scope.row.num) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="confirm"
        align="center"
        min-width="75"
        :label="translateTitle('确认数')"
      >
        <template slot-scope="scope">
          <span
            v-if="
              (scope.row.status == 'success' ||
                scope.row.status == 'cantrade' ||
                scope.row.status == 'confirming') &&
              scope.row.exchange_type != 1
            "
          >
            <span :class="`confirm-span`">{{ scope.row.confirm }}</span>
            /{{ data.itemDetail.confirm_num }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="translateTitle('状态')"
        min-width="65"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.coin_out_status === 0 && !isRecharge"
            :class="`common-progress`"
          >
            {{ translateTitle("审核中") }}</span
          >
          <span v-else :class="getClassName(scope.row.status)">{{
            translateTitle(chargeWithdrawStatus(scope.row.status))
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        width="100"
        :label="translateTitle('操作')"
      >
        <template slot-scope="scope">
          <span
            style="color: 0da8ff"
            v-if="scope.row.txid"
            @click="seeTxid(scope.row)"
            class="seeTxid"
          >
            <el-tooltip popper-class="atooltip" placement="left-start">
              <div slot="content" class="top-tip">
                <div class="title">{{ translateTitle("转账Txid:") }}</div>
                <div @click="txidJump(scope.row)" class="title txid">
                  {{ scope.row.txid }}
                </div>
              </div>
              <div disabled class="maintenance">
                {{ translateTitle("查看Txid") }}
              </div>
            </el-tooltip>
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <no-data slot="empty" v-show="!historyLoading" />
    </el-table>
  </div>
</template>
<script>
import NoData from "@/components/common/NoData";
import { accuracy } from "@/assets/js/accuracy";
import { reformatting } from "@/assets/js/getTime";
import BigNumber from "bignumber.js";
import { apiGetStaticTableInfo } from "@/model/settings";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  props: [
    "data",
    "exchangeList",
    "isRecharge",
    "historyLoading",
    "multiChainStatus",
  ],
  data() {
    return {
      fail_reason: "",
    };
  },

  computed: {
    dataList() {
      if (JSON.stringify(this.data) !== "{}") {
        let { confirm_num } = this.data.itemDetail;
        return this.exchangeList.filter((item) => {
          if (typeof item.create_time === "number") {
            item.create_time = reformatting(+item.create_time * 1000);
          }
          item.chain_tag = item.chain_tag.toUpperCase() || "--";
          // 不截取接口值小数位
          // item.num = accuracy(item.num, 2);
          item.address = this.isRecharge ? item.from_address : item.to_address;
          item.confirm =
            item.confirm > confirm_num ? confirm_num : item.confirm;
          return true;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    chargeWithdrawStatus(val) {
      if (["waiting", "pending"].indexOf(val) !== -1) {
        return "等待中";
      } else if (["confirming", "cantrade"].indexOf(val) !== -1) {
        return "确认中";
      } else if (val === "success") {
        return "完成";
      } else if (val === "canceled") {
        return "取消";
      } else if (val === "faild") {
        return "失败";
      }
    },

    getClassName(status) {
      if (status == "inAudit") {
        return "common-progress";
      } else if (status == "waiting" || status == "pending") {
        return "common-progress";
      } else if (status == "confirming" || status == "cantrade") {
        return "common-progress";
      } else if (status == "success") {
        return "common-success";
      } else if (status == "faild") {
        return "common-error";
      } else if (status == "canceled") {
        return "common-cancel";
      }
    },
    seeTxid(row) {
      this.txidJump(row);
    },
    async txidJump(row) {
      let txid_url = "";
      const { status, data, msg } = await apiGetStaticTableInfo(56);
      this.fail_reason = msg;
      if (status == 200) {
        this.$sensors.track("Deposit_Recharge_Click", {
          source_page_url: window.location.href,
          source_page: "assets management",
          source_tab:
            Math.floor(Math.log10(Math.abs(row.item_id))) + 1 === 5
              ? "cryptoCurrency"
              : "fiatCurrency",
          source_button: row.type === 1 ? "charge" : "withdraw",
          first_level_tab: row.type === 1 ? "charge" : "withdraw",
          second_level_tab: row.type === 1 ? "" : row.chain_tag,
          currency_name: this.data.item_name,
          button_name: "check_txid",
          is_success: true,
          fail_reason: "",
        });
        for (const key in data) {
          if (row.chain_tag.toLowerCase() == data[key].chain_tag) {
            txid_url = data[key].txid_url;
            break;
          } else {
            txid_url = this.data.itemDetail.txid_url;
          }
        }
      } else {
        this.$sensors.track("Deposit_Recharge_Click", {
          source_page_url: window.location.href,
          source_page: "assets management",
          source_tab:
            Math.floor(Math.log10(Math.abs(row.item_id))) + 1 === 5
              ? "cryptoCurrency"
              : "fiatCurrency",
          source_button: row.type === 1 ? "charge" : "withdraw",
          first_level_tab: row.type === 1 ? "charge" : "withdraw",
          second_level_tab: row.type === 1 ? "" : row.chain_tag,
          currency_name: this.data.item_name,
          button_name: "check_txid",
          is_success: false,
          fail_reason: this.fail_reason,
        });
      }
      let jumpUrl = txid_url + row.txid;
      window.open(jumpUrl);
    },
  },
  filters: {
    formatNum(val) {
      return BigNumber(val).toFixed(8);
    },
  },
  components: { NoData },
};
</script>
<style lang="scss">
#charge-history {
  .no-data-box {
    min-height: 520px;
  }

  thead tr {
    border-radius: 2px;
    background-color: #f5f5f7 !important;
    height: 44px;
  }

  .el-table th {
    background-color: #f5f5f7 !important;
    font-size: 14px;
    color: #9aa1b1;
    font-weight: 400;
    height: 44px !important;
    padding: 0 !important;
  }

  .el-table tr {
    border-bottom: 1px solid #ecedf1 !important;
    box-sizing: border-box;
  }

  .el-table {
    /* min-height: 470px; */
    min-height: 570px;

    .el-table__body-wrapper {
      .el-table__expand-column {
        width: 0;
        height: 0;
        font-size: 0;

        .cell {
          padding: 0;
        }
      }

      .el-table__body {
        tr {
          .el-table__expanded-cell {
            color: #717985;
            // text-align: center;
            /* line-height: 36px; */
            line-height: 23px;
            background-color: rgba(245, 247, 250, 1);
            padding: 12px 0;

            p {
              span {
                cursor: pointer;
                color: #0199f1;
              }
            }

            &:hover {
              background-color: rgba(245, 247, 250, 0.7) !important;
            }
          }

          // td {
          //   border: none;
          // }
        }

        .cell {
          font-size: 14px;
          font-weight: 500;
          color: #354052;
          /* line-height: 36px; */
          line-height: 23px;

          /* 确认数量 */
          .confirm-span {
            color: #3348f6;
            font-weight: 500;
          }
        }

        .seeTxid {
          color: #3348f6;
          cursor: pointer;
        }
      }
    }
  }

  .no-data-box {
    /* margin-top: 180px; */
  }
}

.top-tip {
  .title {
    //  color: #fff;
    //  font-size: 14px;
    //  font-weight: 500;
    height: 20px;
    line-height: 20px;
  }

  .txid {
    cursor: pointer;

    &:hover {
      color: #3348f6;
    }
  }
}
</style>
