<template>
  <el-switch
    :value="isLight"
    class="theme-switch"
    active-color="#3348F6"
    inactive-color="#3348F6"
    activeIconClass="dark-mode"
    inactiveIconClass="light-mode"
    @change="themeModeChange"
  >
  </el-switch>
</template>


<script>
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  data() {
    return {
      //   value: true,
    };
  },
  computed: {
    isLight() {
      return !this.isDarkMode;
    },
  },
};
</script>
<style lang="scss">
.theme-switch {
  .el-switch__core {
    background-color: var(--homeBtnBgColor) !important;
    border-color: var(--homeBtnBgColor) !important;
    &:after {
      background-color: transparent;
      background-image: url("../../assets/img/home/theme/light.png");

      background-size: 100% 100%;
    }
  }
  &.is-checked {
    .el-switch__core {
      &:after {
        background-color: transparent;
        background-image: url("../../assets/img/home/theme/dark.png");
        background-size: 100% 100%;
      }
    }
  }
}
</style>