<template>
  <div class="withdrawalAddress">
    <div class="address">
      <div class="multi_chain_status">
        <div
          class="multi-chain-tabs"
          :class="{
            active:
              currChainValue.toLowerCase() == item.label.toLowerCase() &&
              item.status == 1 &&
              item.chain_status == 1,
          }"
          @click="onChainChange(item)"
          v-for="(item, index) in coinInfoComputed"
          :key="index"
        >
          <div
            class="select-chain"
            v-if="item.chain_status == 1 && item.status == 1"
          >
            <span>{{ item.label }}</span>
            <i
              v-if="currChainValue.toLowerCase() == item.label.toLowerCase()"
              class="iconfont iconicon-jb"
            ></i>
          </div>
          <div class="maintenance-ing" v-else>
            <el-tooltip popper-class="atooltip" placement="top">
              <div class="title" slot="content">
                {{ translateTitle("当前维护中，请选择其他链") }}
              </div>
              <div disabled class="maintenance">{{ item.label }}</div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <el-form
        label-position="top"
        class="address-form"
        ref="changePwdForm"
        :rules="formRules"
        :model="formData"
        @submit.native.prevent
      >
        <el-form-item prop="address" class="address">
          <el-select
            :value="formData.address"
            filterable
            allow-create
            @change="selectChange"
            @blur="selectBlur($event, 'address')"
            class="address-select"
            :popper-append-to-body="false"
            :placeholder="translateTitle('请选择提币地址')"
          >
            <el-option
              :value="item.address"
              :label="item.address"
              v-for="(item, index) in addressList"
              :key="index"
              :style="{
                position: 'relative',
                marginBottom: index + 1 == addressList.length ? '40px' : '0',
              }"
            >
              <p class="coin-select">
                <img
                  class="slect-coin-url"
                  :src="data.itemDetail.coin_url"
                  alt=""
                />
                <span class="item-name">{{
                  data.itemDetail.capital_name
                }}</span>
                <span>{{ item.remark }} </span>
              </p>
              <p class="item-address">{{ item.address }}</p>
              <i class="iconfont iconicon-close" @click.stop="onDel(item)"></i>
            </el-option>
            <el-option :value="true" class="add-address">
              <div style="display: flex">
                <i class="iconfont icontianjia"></i>
                <span style="margin-left: 5px">{{
                  translateTitle("添加地址")
                }}</span>
              </div>
            </el-option>
          </el-select>
          <div class="paste" @click.stop="handlePaste">
            <span>{{ translateTitle("粘贴") }}</span>
          </div>
        </el-form-item>
        <!-- 输入提币数量 -->
        <el-form-item prop="withdrawNum" class="withdrawNum">
          <InputNumber
            v-model="formData.withdrawNum"
            :placeholder="translateTitle('请输入提币数量')"
            :decimals="data.itemDetail.num_precision"
            maxlength="20"
          ></InputNumber>
          <span class="available" @click="availableClick">
            {{ translateTitle("可用：") }}
            <!-- <span class="assetsNum">
              {{
                (assetsList.list
                  ? Number(assetsList.list[data.itemDetail.name].over)
                  : "") +
                " " +
                data.itemDetail.capital_name
              }}</span
            > -->
            <span class="assetsNum">
              {{ lockAssets.over_num }}
            </span>
          </span>
        </el-form-item>
        <div
          v-if="lockAssets.lock_total > 0"
          class="arrivalAccount"
          style="margin-bottom: 5px"
        >
          <span>
            {{ lockAssets.lock_total }} {{ data.itemDetail.capital_name }}
            {{ translateTitle("不可转出") }}</span
          >
          <span class="common-operate" @click="onCheckAnswerShow">
            {{ translateTitle("查看原因？") }}
          </span>
        </div>
        <div class="arrivalAccount">
          <span v-if="fee_type == 1">
            {{ translateTitle("到账数量：") }} {{ arrivalAccount }}</span
          >
          <span>
            {{ translateTitle("手续费：") }}
            <span class="assetsNum" v-if="fee_type == 1">{{
              Number(rate_out) + " " + data.itemDetail.capital_name
            }}</span>
            <span class="assetsNum" v-else>{{ fee_rate * 100 }}%</span>
          </span>
        </div>
        <el-button
          style="width: 200px"
          class="common-save rechangr-coin"
          @click="openVerification"
        >
          {{ translateTitle("提币") }}</el-button
        >
      </el-form>
    </div>
    <!-- 添加提币地址-->
    <el-dialog
      class="address-item-dialog"
      :visible.sync="isAddAddress"
      width="500px"
      :close-on-click-modal="false"
    >
      <p class="title">{{ translateTitle("添加地址") }}</p>
      <div class="address-remark">
        <div class="multi_chain_status">
          <div>
            <div class="chail-title">{{ translateTitle("链名称") }}</div>
            <div
              class="multi-chain-tabs"
              :class="{
                active:
                  currChainValueV2 == item.label &&
                  item.status == 1 &&
                  item.chain_status == 1,
              }"
              @click="onChainChangeV2(item)"
              v-for="(item, index) in coinInfoComputed"
              :key="index"
            >
              <div
                class="select-chain"
                v-if="item.chain_status == 1 && item.status == 1"
              >
                {{ item.label }}
                <i
                  v-if="
                    currChainValueV2.toLowerCase() == item.label.toLowerCase()
                  "
                  class="iconfont iconicon-jb"
                ></i>
              </div>
              <div class="maintenance-ing" v-else>
                <el-tooltip popper-class="atooltip" placement="top">
                  <div class="title" slot="content">
                    {{ translateTitle("当前维护中，请选择其他链") }}
                  </div>
                  <div disabled class="maintenance">{{ item.label }}</div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <!-- <p class="address-item" style="margin-bottom: 10px;margin-top:30px"> -->
        <p class="address-item" style="margin-bottom: 10px">
          <span>{{ translateTitle("提币地址") }}</span>
          <el-input
            @input="onFilterAddress"
            v-model.trim="withdrawAddress"
            :placeholder="translateTitle('请输入提币地址')"
          ></el-input>
        </p>
        <p>
          <span>{{ translateTitle("地址备注") }}</span>
          <el-input
            v-model="remark"
            :placeholder="translateTitle('备注不能超过20字')"
            maxlength="20"
          ></el-input>
        </p>
        <div slot:footer class="footer">
          <el-button
            class="common-cancel"
            style="width: 160px"
            @click="onCancel"
            >{{ translateTitle("取消") }}</el-button
          >
          <el-button class="common-save" @click="onConfirmAddress">{{
            translateTitle("确定")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 二次验证 -->
    <el-dialog
      class="verification"
      :visible.sync="verification"
      width="450px"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <p class="title">{{ translateTitle("二次验证") }}</p>
      <div class="content">
        <p>
          <span>{{ translateTitle("提币地址：") }}</span>
          <span>{{ formData.address }}</span>
        </p>
        <p>
          <span>{{ translateTitle("链名称：") }}</span>
          <span>{{ currChainValue }}</span>
        </p>
        <p>
          <span>{{ translateTitle("提币数量：") }}</span>
          <span
            >{{ formData.withdrawNum }} {{ data.itemDetail.capital_name }}</span
          >
        </p>
        <d-bind
          :accountInfo="accountInfo"
          v-if="mobileStatus"
          type="mobile"
          type_num="25"
          :disabled="true"
          :verificate="true"
          ref="mobile_ref"
          :bgColor="true"
        ></d-bind>
        <d-bind
          :accountInfo="accountInfo"
          v-if="emailStatus"
          type="email"
          type_num="26"
          :disabled="true"
          :verificate="true"
          ref="email_ref"
          :bgColor="true"
        ></d-bind>
        <d-google
          v-if="googleStatus"
          :label="translateTitle('谷歌验证码')"
          ref="google_ref"
        ></d-google>
        <div slot:footer class="button">
          <el-button class="common-cancel" @click="onCancel">{{
            translateTitle("取消")
          }}</el-button>
          <!-- <g-button plain text="确定" @click="onConfirm"></g-button> -->
          <el-button
            class="common-save"
            @click="onConfirm"
            :loading="confirmLoading"
            >{{ translateTitle("确定") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="translateTitle('安全验证')"
      custom-class="confirm-dialog"
      :visible.sync="safetyVerification"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px">{{
        translateTitle(
          "为保障您的资金安全，请先在个人中心—>安全设置中绑定谷歌、手机或者邮箱"
        )
      }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="safetyVerification = false">{{
          translateTitle("取消")
        }}</span>
        <span class="dialog-confirm-btn" @click="onConfirmV2">{{
          translateTitle("确定")
        }}</span>
      </span>
    </el-dialog>
    <el-dialog
      :title="translateTitle('操作提示')"
      custom-class="del-address-dialog"
      :visible.sync="delAddressDialog"
      width="440px"
      :lock-scroll="false"
    >
      <span style="font-size: 16px">{{
        translateTitle("是否确认删除该地址？")
      }}</span>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="delAddressDialog = false">{{
          translateTitle("取消")
        }}</span>
        <el-button
          class="dialog-confirm-btn"
          :loading="delAddressLoading"
          @click="onDelAddress"
          >{{ translateTitle("确认") }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      custom-class="check-lockAssets-dialog"
      :visible.sync="checkNoTransDialog"
      width="440px"
      :lock-scroll="false"
    >
      <div class="header">
        <p>
          {{ translateTitle("不可转出资金") }}
        </p>
        <p>{{ lockAssets.lock_total }} {{ data.itemDetail.capital_name }}</p>
      </div>
      <div class="content">
        <p class="title">{{ translateTitle("三方入金限制资金") }}</p>
        <p class="item">
          <span>{{ translateTitle("法币同卡进出限制") }}</span>
          <span
            >{{ lockAssets.restricted_num }}
            {{ data.itemDetail.capital_name }}</span
          >
        </p>
        <p class="title" v-if="lockAssets.lock_assets_list.length > 0">
          {{ translateTitle("冻结资产") }}
        </p>
        <div v-if="lockAssets.lock_assets_list.length > 0">
          <p
            v-for="(item, index) in lockAssets.lock_assets_list"
            class="item"
            :key="index"
          >
            <span>{{ translateTitle(item.name) }}</span>
            <span>{{ item.number }} {{ data.itemDetail.capital_name }}</span>
          </p>
        </div>
      </div>
      <span slot="footer" class="common-dialog-footer">
        <span class="dialog-cancel-btn" @click="goTohelpCenter">
          {{ translateTitle("查看解决方案") }}
        </span>
        <el-button
          class="dialog-confirm-btn"
          @click="checkNoTransDialog = false"
          >{{ translateTitle("我知道了") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import GButton from "../Button";
import DBind from "../DBind";
import DGoogle from "../DGoogle";
import InputNumber from "@/components/common/InputNumber";
import { showMessage } from "@/components/dialog/message";
import { getUserInfo } from "@/model/user/account";
import {
  getAddressList,
  addAddress,
  transfer,
  apiGetUserInfoByAddress,
  apiDelAddress,
  apiGetlockAssets,
} from "@/model/assets/index";
import { getAssetsData } from "../../common/getAssetsCoin";
import { accuracy } from "@/assets/js/accuracy";
import BigNumber from "bignumber.js";
import { apiGetStaticTableInfo } from "@/model/settings";
import SocketDispatcher from "@/assets/js/SocketDispatcher";
import { translateTitle } from "@/assets/js/i18n";
import routeSplicing from "@/assets/js/routeSplicing";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
export default {
  components: { DBind, DGoogle, InputNumber },
  props: ["data", "assetsList", "exchangeLimit"],
  data() {
    return {
      isAddAddress: false, // 是否打开提币地址
      safetyVerification: false,
      delAddressLoading: false,
      delAddressDialog: false,
      addressList: {}, // 地址列表
      withdrawAddress: "", // 提币地址添加时
      remark: "", // 备注
      ua_id: "", // 备注
      formData: {
        address: "", // 提币地址
        withdrawNum: "", // 提币数量
      },
      formRules: {
        // 表单规则
        address: [
          {
            required: true,
            message: this.translateTitle("请选择提币地址"),
            trigger: ["blur", "change"],
          },
        ],
        withdrawNum: [
          { validator: this.withdrawNumBlur, trigger: "blur" },
          { validator: this.withdrawNumBlur, trigger: "change" },
          {
            max: 20,
            message: this.translateTitle("提币数量长度最大20位"),
            trigger: "blur",
          },
        ],
      },
      chainDataMap: {},
      coinInfo: [],
      chainMap: {},
      currChainValue: "ERC20",
      currChainValueV2: "ERC20",
      multi_chain_status: 1,
      verification: false, // 验证弹窗,
      mobileStatus: false, // 是否手机开启验证
      emailStatus: false, // 是否邮箱开启验证
      googleStatus: false, // 是否谷歌开启验证
      checkNoTransDialog: false,
      confirmLoading: false,
      fee_type: 1, // 提币手续费 类型  1:固定金额 2:按提币数量百分比收取
      fee_rate: 0, // 提币手续费百分比
      rate_out: "", // 提币手续费
      rate_out_log: "", // 提币手续费记录
      lockAssets: {
        lock_total: "--",
      },
    };
  },
  async created() {
    await this.getUserInfo();
    await this.getStaticChainInfo();
    await this.getlockAssets();
    await this.getAddressList();
    this.currChainValue =
      this.data.itemDetail && this.data.itemDetail.default_chain
        ? this.data.itemDetail.default_chain.toUpperCase()
        : "maintain";
  },

  methods: {
    routeSplicing,
    translateTitle,
    onCheckAnswerShow() {
      this.checkNoTransDialog = true;
    },
    onDel(item) {
      this.ua_id = item.ua_id;
      this.delAddressDialog = true;
    },
    onDelAddress() {
      this.delAddressLoading = true;
      const req = {
        ua_id: this.ua_id,
      };
      apiDelAddress(req).then(res => {
        if (res.status == 200) {
          showMessage({
            message: this.translateTitle(`删除成功`),
            type: "success",
          });
          this.delAddressLoading = false;
          this.delAddressDialog = false;
          this.getAddressList();
        }
      });
    },
    async getStaticChainInfo() {
      this.coinInfo = [];
      await apiGetStaticTableInfo(56).then(({ data }) => {
        this.chainMap = data;
      });
      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      await apiGetStaticTableInfo(57).then(({ data }) => {
        for (const key in data) {
          for (let item in this.chainMap) {
            if (
              data[key].chain.toUpperCase() ==
              this.chainMap[item].chain_tag.toUpperCase()
            ) {
              this.coinInfo.push({
                label: data[key].chain.toUpperCase(),
                value: data[key].coin_id,
                fee_type: data[key].fee_type,
                fee_rate: data[key].fee_rate,
                rate_out: data[key].fee,
                is_default: data[key].is_default,
                chain_status: this.chainMap[item].status,
                status: data[key].status,
                minout: data[key].minout,
                maxout: data[key].maxout,
              });
            }
          }

          if (
            this.currChainValue.toLowerCase() == data[key].chain &&
            this.data.itemDetail.coin_id == data[key].coin_id
          ) {
            this.fee_type = data[key].fee_type;
            this.fee_rate = data[key].fee_rate;
            this.rate_out = data[key].fee;
            this.rate_out_log = data[key].fee;
          }
        }
      });
    },

    onChainChange(item) {
      if (item.status == 1 && item.chain_status == 1) {
        this.chainDataMap = item;
        this.fee_type = item.fee_type;
        this.fee_rate = item.fee_rate;
        this.rate_out = item.rate_out;
        this.rate_out_log = item.rate_out;
        this.currChainValue = item.label;
        this.$emit("getChainData", item);
        this.formData.address = "";
        this.addressList = {};
        SocketDispatcher.dispatchEvent("onChainChangeDispatch", item.label);
        this.$refs.changePwdForm.resetFields();
        this.getAddressList();
      } else {
        this.currChainValue = item.label + "-maintain"; //神策上报
        return;
      }
    },
    onChainChangeV2(item) {
      if (item.status == 1 && item.chain_status == 1) {
        this.currChainValueV2 = item.label;
        this.withdrawAddress = "";
        this.remark = "";
      } else {
        return;
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let { accountInfo } = await getUserInfo();
      this.accountInfo = accountInfo;
    },
    onFilterAddress(val) {
      this.withdrawAddress = val.replace(/[^a-zA-Z\d:]/g, "");
    },
    // 获取地址列表
    async getAddressList() {
      const { item_id } = this.data;
      // 获取当前选中币种下的地址列表
      if (item_id) {
        let params = {
          item_id: item_id,
        };
        if (this.data.itemDetail.multi_chain_status) {
          params.chain_tag = this.currChainValue.toLowerCase();
        }
        const { status, msg, data } = await getAddressList(params);
        if (status === 200) {
          this.addressList = data;
        } else {
          showMessage({ message: msg ? msg : status });
        }
      }
    },
    goTohelpCenter() {
      let helpLang = ["zh_CN", "zh_HK", "vi", "th"].includes(this.language)
        ? this.language
        : "en";
      let protocol = location.protocol;
      let host = location.host;
      let jumpUrl =
        protocol +
        "//" +
        host +
        "/help-center/" +
        this.language +
        "?path=category&lang=" +
        helpLang +
        "&code=PROBLEM&showContent=true&articleId=beb867be-5423-40f7-bf8c-335850f6fc7a";

      window.open(jumpUrl);
    },
    async getlockAssets() {
      const { item_id } = this.data;
      // 获取当前选中币种下的地址列表
      if (item_id) {
        let params = {
          item_id: item_id,
        };

        const { status, msg, data } = await apiGetlockAssets(params);
        if (status === 200) {
          this.lockAssets = data;
        } else {
          showMessage({ message: msg ? msg : status });
        }
      }
    },
    // withdrawNumBlur
    withdrawNumBlur(rule, value, callback) {
      let {
        itemDetail: { name },
      } = this.data;
      let { minout, maxout } = this.chainDataMap;
      let { list } = this.assetsList;
      let { coinOutSingleNum } = this.exchangeLimit;

      value = Number(value);
      minout = Number(minout);
      coinOutSingleNum = Number(coinOutSingleNum);
      if ((value === "") | !value) {
        callback(new Error(this.translateTitle("请输入提币数量")));
        // 输入值 小于 可用数量
      } else if (value < minout) {
        callback(
          new Error(
            this.translateTitle("单笔最少提币数量为") +
              `${minout} ${name.toUpperCase()}`
          )
        );
      } else if (value > coinOutSingleNum) {
        callback(
          new Error(
            this.translateTitle("单笔最大提币数量为") +
              `${coinOutSingleNum} ${name.toUpperCase()}`
          )
        );
      } else if (value < coinOutSingleNum && value > list[name].over) {
        callback(
          new Error(
            this.translateTitle("您当前可用提币数量为") +
              `${list[name].over} ${name.toUpperCase()}`
          )
        );
      } else {
        callback();
      }
    },
    // 点击可用
    availableClick() {
      // let {
      //   itemDetail: { name },
      // } = this.data;
      // let { list } = this.assetsList;

      this.formData.withdrawNum = this.lockAssets.over_num;
    },
    onConfirmV2() {
      this.$router.push(
        this.routeSplicing(this.language, "ucenter/safe-setting")
      );
    },
    // 打开二次验证
    openVerification() {
      let that = this;
      this.$refs.changePwdForm.validate(valid => {
        if (valid) {
          this.$store.dispatch("user/getUserInfo").then(res => {
            if (
              res.authInfo.is_bind_security == 1 &&
              res.authInfo.open_google == 0
            ) {
              this.safetyVerification = true;
            } else {
              this.mobileStatus = res.authInfo.open_mobile === 0 ? false : true;
              this.emailStatus = res.authInfo.open_email === 0 ? false : true;
              this.googleStatus = res.authInfo.open_google === 0 ? false : true;
              this.verification = true;
            }
          });
          this.$sensors.track("Deposit_Recharge_Click", {
            source_page_url: window.location.href,
            source_page: "assets management",
            source_tab:
              Math.floor(Math.log10(Math.abs(that.data.client_show_id))) + 1 ===
              5
                ? "cryptoCurrency"
                : "fiatCurrency",
            source_button: "withdraw",
            first_level_tab: "withdraw",
            second_level_tab: that.currChainValue,
            currency_name: that.data.item_name,
            button_name: "withdraw_token",
            is_success: true,
            fail_reason: "",
          });
        }
      });
    },
    selectChange(val) {
      if (typeof val === "boolean") {
        this.isAddAddress = true;
        this.currChainValueV2 = JSON.parse(JSON.stringify(this.currChainValue));
      } else {
        this.formData.address = val.replace(/[^a-zA-Z\d:]/g, "");
        this.getUserInfoByAddress(val.replace(/[^a-zA-Z\d:]/g, ""));
      }
    },
    selectBlur(e) {
      let that = this;
      let addressValue = e.target.value.replace(/[^a-zA-Z\d:]/g, "");
      if (addressValue.length > 100) {
        showMessage({ message: this.translateTitle("提币地址最大长度为100") });
        return;
      }
      // if (addressValue.trim()) {
      //   this.addressList.unshift({ address: addressValue });
      // }
      that.formData.address = addressValue;
      debounce(function () {
        if (addressValue) {
          that.getUserInfoByAddress(addressValue);
        }
      }, 200).bind(addressValue, that)();
    },

    handlePaste() {
      let that = this;
      navigator.clipboard
        .readText()
        .then(text => {
          if (text.trim().length > 100) {
            showMessage({
              message: that.translateTitle("提币地址最大长度为100"),
            });
            return;
          }
          if (text.trim()) {
            that.formData.address = text.trim().replace(/[^a-zA-Z\d:]/g, "");
            that.getUserInfoByAddress(text.trim().replace(/[^a-zA-Z\d:]/g, ""));
          }
        })
        .catch(error => {});
    },

    async getUserInfoByAddress(address) {
      const params = {
        item_id: this.data.item_id,
        address,
      };
      const { status, data } = await apiGetUserInfoByAddress(params);
      if (status == 200) {
        if (data.type != "0") {
          //内部地址
          this.fee_type = 1;
          this.fee_rate = 0;
          this.rate_out = 0;
        } else {
          this.rate_out = this.rate_out_log;
        }
        if (data.chain_tag) {
          this.coinInfoComputed.forEach(item => {
            if (item.label.toUpperCase() === data.chain_tag.toUpperCase()) {
              if (item.status == 1 && item.chain_status == 1) {
                this.chainDataMap = item;
                this.fee_type = item.fee_type;
                this.fee_rate = item.fee_rate;
                this.rate_out = item.rate_out;
                this.rate_out_log = item.rate_out;
                this.currChainValue = item.label;
                this.$emit("getChainData", item);
                this.addressList = {};
                SocketDispatcher.dispatchEvent(
                  "onChainChangeDispatch",
                  item.label
                );
                this.getAddressList();
              } else {
                this.currChainValue = item.label + "-maintain"; // 神策上报
              }
            }
          });
        }
      }
    },
    onCancel() {
      this.isAddAddress = false;
      this.verification = false;
    },
    onConfirmAddress() {
      let { item_id } = this.data;
      let { withdrawAddress, remark } = this;
      if (/^\s*$/.test(withdrawAddress)) {
        showMessage({ message: this.translateTitle("地址不能为空") });
        return;
      }
      if (/^\s*$/.test(remark)) {
        showMessage({ message: this.translateTitle("备注不能为空") });
        return;
      }
      let params = { item_id, address: withdrawAddress, remark };
      // if (this.data.itemDetail.multi_chain_status) {
      // }
      params.chain_tag = this.currChainValueV2.toLowerCase();
      addAddress(params).then(({ status, data, msg }) => {
        if (status === 200) {
          this.getAddressList();
          showMessage({
            message: this.translateTitle(`提币地址添加成功`),
            type: "success",
          });
          this.isAddAddress = false;
          this.withdrawAddress = "";
          this.remark = "";
        } else {
          showMessage({ message: msg ? msg : status });
        }
      });
    },
    // 确认
    async onConfirm() {
      let param = {};
      let emailData, mobileData, googleData;
      let {
        formData: { withdrawNum, address },
        data: { item_id },
      } = this;
      if (this.$refs.email_ref) {
        emailData = this.$refs.email_ref.submitForm();
        if (emailData) {
          param = Object.assign(param, { ...emailData });
          delete param.email;
        }
      } else {
        emailData = true;
      }
      if (this.$refs.mobile_ref) {
        mobileData = this.$refs.mobile_ref.submitForm();
        if (mobileData) {
          param = Object.assign(param, { ...mobileData });
          delete param.mobile;
        }
      } else {
        mobileData = true;
      }
      if (this.$refs.google_ref) {
        googleData = this.$refs.google_ref.submitForm();
        if (googleData) {
          param = Object.assign(param, { ...googleData });
        }
      } else {
        googleData = true;
      }
      // 发送请求，提币开始
      if (emailData && mobileData && googleData) {
        param.address = address;
        param.number = withdrawNum;
        param.item_id = item_id;
        param.chain_tag = this.currChainValue.toLowerCase(); // chain_tag改为必传 lars
        this.confirmLoading = true;
        let { msg, status } = await transfer(param);
        if (status == 200) {
          this.confirmLoading = false;
          showMessage({
            message: this.translateTitle(`提币成功`),
            type: "success",
          });
          this.$emit("withdrawSuccess", await getAssetsData());
          this.formData.withdrawNum = "";
          this.verification = false;
        } else {
          this.confirmLoading = false;
          showMessage({ message: msg ? msg : status });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
    coinInfoComputed() {
      let tempObj = [];
      for (const key in this.coinInfo) {
        if (this.data.itemDetail.coin_id == this.coinInfo[key].value) {
          tempObj.push({
            value: this.coinInfo[key].value,
            label: this.coinInfo[key].label,
            fee_type: this.coinInfo[key].fee_type,
            fee_rate: this.coinInfo[key].fee_rate,
            rate_out: this.coinInfo[key].rate_out,
            status: this.coinInfo[key].status,
            chain_status: this.coinInfo[key].chain_status,
            minout: this.coinInfo[key].minout,
            maxout: this.coinInfo[key].maxout,
          });
        }
      }
      tempObj.forEach(ele => {
        if (ele.label == this.currChainValue.toUpperCase()) {
          this.$emit("getChainData", ele);
          this.chainDataMap = ele;
        }
      });
      let erc20 = {};
      let trc20 = {};
      tempObj.forEach(ele => {
        if (ele.label === "ERC20") erc20 = ele;
        if (ele.label === "TRC20") trc20 = ele;
      });
      let newtempObj = tempObj.filter(ele => {
        return ele.label != "ERC20" && ele.label != "TRC20";
      });
      if (JSON.stringify(erc20) != "{}") {
        newtempObj.push(erc20);
      }
      if (JSON.stringify(trc20) != "{}") {
        newtempObj.unshift(trc20);
      }
      return newtempObj;
    },
    coinOutNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let { coinOutNum } = this.exchangeLimit;
        return coinOutNum;
        // return accuracy(coinOutNum, 2);
      } else {
        return "--";
      }
    },
    coinOutSingleNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let { coinOutSingleNum } = this.exchangeLimit;
        return coinOutSingleNum;
      } else {
        return "--";
      }
    },
    limitNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let { limitNum } = this.exchangeLimit;
        // return accuracy(limitNum, 2);
        return limitNum;
      } else {
        return "--";
      }
    },
    arrivalAccount() {
      let {
        formData: { withdrawNum },
        data: { itemDetail },
        rate_out,
      } = this;
      let Num = new BigNumber(withdrawNum);
      if (Number(rate_out)) {
        return Number(withdrawNum) - Number(rate_out) > 0
          ? accuracy(Num.minus(rate_out), itemDetail.num_precision)
          : "0" + " " + itemDetail.capital_name;
      } else {
        return Number(withdrawNum) - Number(rate_out) > 0
          ? accuracy(Num, itemDetail.num_precision)
          : "0" + " " + itemDetail.capital_name;
      }
    },
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        this.getStaticChainInfo();
        this.currChainValueV2 =
          this.data.itemDetail.default_chain.toUpperCase();
        this.getlockAssets();
        this.getAddressList();
        this.formData.address = "";
        this.currChainValue = this.data.itemDetail.default_chain.toUpperCase();
      },
      deep: true,
      immediate: false,
    },
    isAddAddress(val) {
      if (!val) {
        this.withdrawAddress = "";
        this.remark = "";
      }
    },
  },
};
</script>

<style lang="scss">
.atooltip {
  background: #ffffff !important;
  box-shadow: 0 2px 10px 0 rgba(53, 64, 82, 0.17);
  font-size: 14px;
  color: #354052 !important;
  // max-width:	400px;
}

// 提币地址
.withdrawalAddress {
  width: 440px;
  text-align: left;

  .maintenance-ing {
    .maintenance {
      width: 100px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      border-radius: 3px;
      background-color: #c2c7d0;
      outline: none;
      border: 0;
      color: #ffffff;
      border: 1px solid #c2c7d0;
      font-weight: 400;
    }
  }

  .rechangr-coin {
    padding: 0px !important;
  }

  .common-save {
    line-height: 0px;
  }

  .coin-select {
    display: flex;
    align-items: center;
    font-size: 14px;

    .item-name {
      font-weight: bold;
      margin-right: 5px;
    }

    .slect-coin-url {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  .item-address {
    font-weight: 500;
    font-size: 12px !important;
    color: #354052;
    margin-left: 25px;
  }

  .Withdrawal-btn {
    border-radius: 3px;
    width: 120px !important;
    height: 32px;
    padding: 9px 46px 10px;
    outline: none;
    border: 0px;
  }

  .multi_chain_status {
    /* height: 40px; */
    margin-bottom: 15px;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-start;
    align-items: center;

    .title {
      color: #9aa1b1;
      margin-bottom: 10px;
      font-size: 14px;
    }

    .multi-chain-tabs {
      box-sizing: border-box;
      font-size: 14px;
      display: inline-block;
      margin-right: 10px;
      // margin-top: 10px;
      width: 100px;
      height: 32px;
      border-radius: 4px;
      color: #354052;
      line-height: 32px;
      text-align: center;
      border: solid 1px #e2e2e4;

      .select-chain {
        cursor: pointer;
        position: relative;
        box-sizing: border-box;

        i {
          position: absolute;
          right: 0px;
          top: 6px;
        }
      }

      &.active {
        color: #d60c30;
        background-color: #ffffff;
        border: solid 1px #d60c30;
      }
    }
  }

  .el-select-dropdown {
    left: 0px !important;

    .el-scrollbar {
      width: 440px;
    }
  }

  .address-form {
    /* 每一项表单 */
    .el-form-item {
      width: 440px;
      margin-bottom: 20px;
    }

    /* 选择地址框 */
    .address-select {
      width: 440px;

      // min-height: 50px;
      .iconicon-close {
        position: absolute;
        bottom: 10px;
        right: 20px;
      }

      .el-select-dropdown {
        .el-scrollbar {
          min-height: 40px;

          .el-select-dropdown__item {
            min-height: 53px;
            display: flex;
            // align-items: center;
            flex-direction: column;
            justify-content: center;

            p {
              font-size: 14px;
              line-height: 16px;
              white-space: nowrap;
              word-break: break-all;

              &:first-child {
                margin-bottom: 6px;
              }
            }

            &.add-address {
              width: 100%;
              height: 40px;
              min-height: 40px;
              color: #3348f6;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;
              position: absolute;
              left: 0;
              bottom: 0;

              &:hover {
                color: #3348f6;
                background-color: #f3f7fc;
              }
            }
          }
        }
      }
    }

    /* 提币额度 */
    .quota {
      width: 440px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 7px;

      .title {
        font-size: 14px;
        color: #9aa1b1 !important;
        font-weight: 400;
      }

      .num {
        color: #e81b40 !important;
        font-size: 14px;
        font-weight: 400;
      }
    }

    /* 提币数量 */
    .withdrawNum {
      input {
        padding-right: 165px;
      }

      .available {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 14px;
        color: #354052;
        font-weight: 4000;

        .assetsNum {
          user-select: text;
        }
      }
    }

    /* 到账数量 */
    .arrivalAccount {
      width: 440px;
      min-height: 40px;
      color: #9aa1b1;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f3f7fc;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 0 15px;
      border: 0;
      // margin-top: -10px;
      margin-top: 5px;
      margin-bottom: 20px;
      cursor: not-allowed;

      span {
        font-size: 14px;
      }

      .assetsNum {
        user-select: text;
      }
    }
  }

  /* 添加地址弹出框 */
  .address-item-dialog {
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }

    .chail-title {
      padding-bottom: 10px;
      color: #9aa1b1;
      font-size: 14px;
    }

    .el-dialog__body {
      .title {
        height: 28px;
        color: #354052;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        padding: 19px 31px 21px;
      }

      .address-remark {
        // padding: 0 50px 38px 30px;
        padding: 20px 30px 38px;

        p {
          &:nth-child(1) {
            margin-bottom: 15px;
          }

          span {
            color: #9aa1b1;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 5px;
            display: inline-block;
            font-weight: normal;
          }
        }

        .footer {
          margin-top: 25px;
          display: flex;
          justify-content: center;

          button {
            width: 160px !important;
          }
        }
      }
    }
  }

  .confirm-dialog {
    // height: 200px;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }

    .el-dialog__body {
      text-align: left;
      color: #354052;
      padding: 20px 30px 30px;
      font-size: 16px;
      font-weight: normal;
      line-height: 2;
    }
  }

  // 谷歌验证
  .safety-verification {
    text-align: left;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
    }

    .title {
      color: #333;
      height: 66px;
      line-height: 66px;
      font-size: 20px;
      padding-left: 30px;
    }

    .content {
      // padding: 0px 50px 40px 30px;
      padding: 0px 40px 40px;

      p {
        color: #354052;
        font-size: 16px;
        // height: 12px;
        margin-bottom: 15px;

        &:first-child {
          margin-bottom: 6px;
        }
      }

      .button {
        margin-top: 25px;
        display: flex;
        // justify-content: center;
        justify-content: space-between;

        button {
          width: 170px;
          height: 40px;

          &:first-child {
            margin-right: 10px;
          }
        }

        .confirmBtn {
          border: none;
          color: #fff;
          background-color: #27c683;
          border-radius: 2px;

          &:hover {
            background-color: #27c683;
          }
        }
      }
    }
  }

  // 二次验证
  .verification {
    text-align: left;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
    }

    .title {
      color: #333;
      height: 66px;
      line-height: 66px;
      font-size: 20px;
      padding-left: 30px;
    }

    .content {
      // padding: 0px 50px 40px 30px;
      padding: 0px 40px 40px;

      p {
        color: #354052;
        font-size: 12px;
        // height: 12px;
        margin-bottom: 15px;

        &:first-child {
          margin-bottom: 6px;
        }

        &:nth-child(2) {
          margin-bottom: 6px;
        }
      }

      .button {
        margin-top: 25px;
        display: flex;
        // justify-content: center;
        justify-content: space-between;

        button {
          width: 170px;
          height: 40px;

          &:first-child {
            margin-right: 10px;
          }
        }

        .confirmBtn {
          border: none;
          color: #fff;
          background-color: #27c683;
          border-radius: 2px;

          &:hover {
            background-color: #27c683;
          }
        }
      }
    }
  }

  .address {
    position: relative;

    .el-input--suffix {
      .el-input__inner {
        padding-right: 70px;
      }
    }

    i.el-select__caret {
      /*很关键：将默认的select选择框样式清除*/
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /*为下拉小箭头留出一点位置，避免被文字覆盖*/

      padding-right: 14px;
      transform: none !important;
      /*自定义图片*/
      background: url("../../../../assets/img/address.png") no-repeat scroll
        right center transparent;
      /*自定义图片的大小*/
      background-size: 17px 17px;
    }

    /*将小箭头的样式去去掉*/
    .el-icon-arrow-up:before {
      content: "";
    }

    .paste {
      cursor: pointer;
      position: absolute;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translate(0, -50%);
    }
  }

  .del-address-dialog {
    // height: 200px;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }

    .el-dialog__body {
      text-align: center;
      color: #354052;
      padding: 20px 20px 30px;
      font-size: 16px;
      font-weight: normal;
    }
  }
  .check-lockAssets-dialog {
    text-align: center;
    color: #354052;
    padding: 0px 20px 30px;
    font-size: 16px;
    font-weight: normal;
    .el-dialog__body {
      padding-top: 0;
      .header {
        text-align: center;
        font-family: "HarmonyOS Sans SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #000;
        p {
          margin: 10px;
        }
      }
      .content {
        .title {
          margin-top: 15px;
          text-align: left;
          font-family: "HarmonyOS Sans SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          color: #000;
          margin-bottom: 10px;
        }
        .item {
          display: flex;
          padding: 0 15px;
          align-content: center;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
