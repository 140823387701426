<template>
  <div class="project-help-center-page" v-loading="loading">
    <!-- <iframe
    :src="iframeSrc"
    v-resize="{ log: true }"
    width="100%"
    scrolling="no"
    id="iframeHelpCenter"
    ref="iframe"
    @load="loaderHandel"
  ></iframe> -->
    <!-- </div> -->
    <iframe
      :src="iframeSrc"
      v-resize="{
        log: false,
        heightCalculationMethod: 'documentElementScroll',
      }"
      width="100%"
      frameborder="0"
      @load="loaderHandel"
    ></iframe>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
export default {
  mixins: [],

  data() {
    return {
      iframeSrc: "",
      loading: true,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  created() {
    // let obj = this.parseQueryString(
    //   decodeURIComponent(this.$route.query.iframeSrc)
    // );
    let helpLang =
      // this.language == "zh_CN" || this.language == "zh_HK" ? "zh-CN" : (this.language == "vi" ? "vi" : "en");
      ["zh_CN", "zh_HK", "vi", "th"].includes(this.language)
        ? this.language
        : "en";
    let protocol = location.protocol;
    let host = location.host;
    let shareUrl =
      protocol +
      "//" +
      host +
      "/help-center/" +
      this.language +
      "?path=category&lang=" +
      helpLang +
      "&code=^^cate^^&showContent=true&articleId=^^aid^^";
    let topDomain = document.domain.split(".").slice(-2).join(".");
    if (topDomain.includes("localhost")) {
      topDomain = "4ezh8.com";
      // topDomain = "justonlytest.com";
    }
    let obj = this.parseQueryString(window.location.href);
    obj["lang"] = helpLang;
    // this.language == "zh_CN" || this.language == "zh_HK" ? "zh-CN" : "en";
    let hostName = "https://help-center." + topDomain + "/#/";
    if (
      // window.location.host.includes("hxexchge") ||
      // window.location.host.includes("justonlytest") ||
      window.location.host.includes("localhost")
    ) {
      hostName = "https://help-center.4ezh8.com/#/";
    }
    // let hostName = "https://help-center.justonlytest.com/#/"
    let paramsStr = this.stringifyQueryObj(obj);
    // this.iframeSrc =
    //   this.$route.query.iframeSrc.split("?")[0] + "?" + paramsStr;
    this.iframeSrc =
      hostName +
      (obj["path"] ? obj["path"] : "") +
      "?" +
      paramsStr +
      "&shareurl=" +
      encodeURIComponent(shareUrl);
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {
    // $("#iframeHelpCenter").iFrameResize([{ log: true }]);
    //接收跨域信息
    window.addEventListener(
      "message",
      function (e) {
        if (e.data == "beginChat") {
          window.document.getElementById("chat-widget-minimized")
            .contentWindow &&
            window.document
              .getElementById("chat-widget-minimized")
              .contentWindow.document.querySelector(
                "button[aria-label='Open LiveChat chat widget']"
              )
              .click();
        }
      },
      false
    );
  },
  methods: {
    translateTitle,
    loaderHandel() {
      this.loading = false;
    },
    parseQueryString(url) {
      if (url.indexOf("?") == -1) {
        return {};
      }
      var obj = {};
      var keyvalue = [];
      var key = "",
        value = "";
      var paraString = url
        .substring(url.indexOf("?") + 1, url.length)
        .split("&");
      for (var i in paraString) {
        keyvalue = paraString[i].split("=");
        key = keyvalue[0];
        value = keyvalue[1];
        obj[key] = value;
      }
      return obj;
    },
    stringifyQueryObj(obj) {
      let queryString = "";
      Object.keys(obj).forEach((key) => {
        queryString = queryString + key + "=" + obj[key] + "&";
      });
      queryString = queryString.slice(0, -1);
      return queryString;
    },
    // setHeight() {
    //   const iframe = this.$refs.iframe;
    //   const contentHeight = iframe.contentWindow.document.body.scrollHeight;
    //   iframe.height = contentHeight;
    // },
    // adjustIframe() {
    //   var ifm = document.getElementById("iframeHelpCenter");
    //   ifm.height = document.documentElement.clientHeight;
    //   ifm.width = document.documentElement.clientWidth;
    //   consoe
    // },
  },
};
</script>
<style lang="scss">
.project-help-center-page {
  width: 100%;
  // height: auto;
  min-height: calc(100vh - 64px);
  // background-color: #fff;
  background-color: rgb(249, 249, 249);

  .help-center-page-iframe {
    // width: 100%;
    // height: auto;
    width: 100%;
    // height: 100%;
  }
}
</style>
