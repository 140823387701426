<template>
  <div class="top-banner__container brand">
    <div class="top-banner__container-title">
      {{ translateTitle("4E交易所 赢在未来") }}
    </div>
    <div class="top-banner__container-btns brand">
      <input
        v-if="!userInfoShow"
        class="top-banner__container-btns-input"
        v-model="registerInput"
        :placeholder="translateTitle('邮箱/手机号')"
        placeholder-class="input-placeholder"
      />
      <span
        v-if="!userInfoShow"
        @click="onResiter(2)"
        class="top-banner__container-btns-register"
        >{{ translateTitle("立即注册") }}</span
      >
      <span
        v-if="userInfoShow"
        @click="onTrade"
        class="top-banner__container-btns-register"
        >{{ translateTitle("立即交易") }}</span
      >
    </div>

    <download-popup
      :downloadTipsVisible="downloadTipsVisible"
      :closeDownloadTipsDialog="closeDownloadTipsDialog"
    ></download-popup>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import Cookies from "js-cookie";
import DownloadPopup from "@/components/common/download-popup.vue";
export default {
  props: {},
  components: { DownloadPopup },
  mixins: [commonMixin],
  data() {
    return {
      downloadTipsVisible: false,
      registerInput: "",
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },
  methods: {
    routeSplicing,
    closeDownloadTipsDialog() {
      this.downloadTipsVisible = false;
    },
    reportHomepageClick(module, type) {
      this.$sensors.track("Homepage_Click", {
        of_module: module,
        button_name: type ? type : "--",
      });
    },
    mockTrade() {
      // let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      // window.open(tempUrl);
      this.downloadTipsVisible = true;
      this.reportHomepageClick("title", "mock_trade");
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
      this.reportHomepageClick("title", "trade_now");
    },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.registerInput;
      } else {
        inputVal = this.registerInput;
      }
      var emailReg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      );
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      // 埋点
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal,
        },
      });

      this.$sensors.track("Register_Click", {
        invite_code: "",
        channel_type: "自然流量",
        second_channel_type: "WEB",
      });
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  .top-banner__container.brand {
    background-image: url(../../assets/img/brand/top-banner.png);
  }
}
:root[theme="dark"] {
  .top-banner__container.brand {
    background-image: url(../../assets/img/brand/top-banner-dark.png);
  }
}
.top-banner__container-btns.brand {
  .top-banner__container-btns-register {
    margin-right: 0px;
  }
}
.top-banner__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  background-color: var(--homeBgColor);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--homeFontColor);
  &-title {
    line-height: 60px;
    font-size: 48px;
    font-weight: 600;
    text-align: center;
  }
  &-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    &-register,
    &-login {
      cursor: pointer;
      border-radius: 26px;
      padding: 0px 16px;
      height: 52px;
      line-height: 52px;
      min-width: 150px;
      text-align: center;
    }
    &-input {
      width: 300px;
      height: 52px;
      border: 1px solid var(--homeFontColor);
      border-radius: 30px;
      padding: 0 20px;
      font-size: 24px;
      border-radius: 66px;
      border: 1px solid var();
      margin-right: 16px;
      border: var(--homeGrayBorder);
      color: var(--homeFontColor);
      padding-bottom: 6px;
      &:focus,
      &:hover {
        border: 2px solid var(--homeLightBtnBorder);
      }
      &::placeholder {
        color: var(--Light04-text-c_t03, #8b909c);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 131.25% */
      }
    }
    &-register {
      /* padding: 10px 30px;
      border-radius: 5px; */
      /* border: 1px solid var(--homeFontColor); */
      margin-right: 50px;
      background: var(--homeColorBtnBgColor);
      color: var(--homeColorBtnColor);
      &:hover {
        background: var(--blueBtnHover);
      }
    }
    &-login {
      /* padding: 10px 30px;
      border-radius: 5px; */
      border: 1px solid var(--homeLightBtnBorder);
      background-color: var(--homeLightBtnBgColor);
      color: var(--homeLightBtnColor);
    }
  }
}
</style>
