<template>
  <div class="explain">
    <template v-if="data.itemDetail">
      <div class="explain-item">
        <span
          >1. {{ translateTitle("24h转账额度：") }}
          <span class="item-num">{{ usdtCoinOutNum }}/{{ usdtLimitNum }} </span>
          USDT
          <!-- {{ data.itemDetail.capital_name }} -->
        </span>
        <span
          >（ {{ translateTitle("累计折合超过") }} {{ usdtAuditNum }} USDT,
          {{ translateTitle("转账需等待系统审核") }}）</span
        >
      </div>
      <!-- <div class="explain-item">
        <span>
          <span>{{ translateTitle("最大提币数量为：") }}</span
          ><span class="item-num">{{
            exchangeLimit.coinOutSingleNum || "--"
          }}</span>
          {{ data.itemDetail.capital_name }}
        </span>
      </div> -->
      <div class="explain-item">
        <span
          >2. {{ translateTitle("当前选择链为")
          }}<span class="item-num"> {{ currChainValue }}, </span>
          <span>{{ translateTitle("收款地址必须为此链上地址。") }}</span>
          <!-- {{translateTitle('如果提币的地址为平台内部地址，则免除本次提币手续费，提币成功后可在资产管理中进行确认')}} -->
        </span>
      </div>
      <div class="explain-item">
        <span
          >3. {{ translateTitle("单笔转账手续费") }}
          <span>
            <span v-if="chainMap.fee_type == 2">
              {{ chainMap.fee_rate * 100 }}% ,</span
            >
            <span class="item-num" v-else>
              {{ Number(chainMap.rate_out) }}
              {{ data.itemDetail.capital_name }},
            </span>
          </span>
          <span>{{
            translateTitle("若收款地址为平台内部地址，转账免手续费。")
          }}</span>
        </span>
      </div>
      <div class="explain-item">
        <span
          >4. {{ translateTitle("请勿向非") }}
          {{ data.itemDetail.capital_name }}
          <span>{{
            translateTitle("地址转账，否则无法到账，且不可找回。")
          }}</span>
        </span>
      </div>
      <!-- <p>
        <span>3.</span>
        <span>所有币种24小时内提币总额达到等值 {{auditNum}} USDT，需等待系统审核；</span>
      </p> -->
      <!-- <p>
        <span>4.</span>
        <span>若您修改过安全相关设置（登录密码、手机验证以及邮箱验证等），在修改后24小时内将无法提币，24小时后将自动恢复；</span>
      </p> -->
    </template>
  </div>
</template>
<script>
import { accuracy } from "@/assets/js/accuracy";
import { apiGetStaticTableInfo } from "@/model/settings";
import SocketDispatcher from "@/assets/js/SocketDispatcher";
import { translateTitle } from "@/assets/js/i18n";
export default {
  props: ["data", "assetsList", "exchangeLimit", "chainMap"],
  data() {
    return {
      currChainValue: "ERC20",
      rate_out: "--", // 提币手续费
    };
  },
  created() {
    this.currChainValue = this.data.itemDetail.default_chain.toUpperCase();
    SocketDispatcher.addEventListener(
      "onChainChangeDispatch",
      this.getStaticChainInfo,
      this
    );
    this.getStaticChainInfo(this.currChainValue);
  },

  methods: {
    translateTitle,
    getStaticChainInfo(val) {
      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      this.currChainValue = val.toUpperCase();
      apiGetStaticTableInfo(29).then(({ data }) => {
        for (const key in data) {
          if (
            this.currChainValue.toLowerCase() == data[key].chain_tag &&
            this.data.item_id == data[key].item_id
          ) {
            this.rate_out = data[key].rate_out || "--";
          }
        }
      });
    },
  },
  computed: {
    hasParam() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        return true;
      } else {
        return false;
      }
    },
    auditNum() {
      if (this.hasParam) {
        let { auditNum } = this.exchangeLimit;
        return auditNum;
      } else {
        return "--";
      }
    },
    limitNum() {
      if (this.hasParam) {
        let { limitNum } = this.exchangeLimit;
        let { coinOutNum } = this.exchangeLimit;
        let remaining = limitNum - coinOutNum;
        return remaining;
      } else {
        return "--";
      }
    },
    coinOutNum() {
      if (this.hasParam) {
        let { coinOutNum } = this.exchangeLimit;
        return coinOutNum;
      } else {
        return "--";
      }
    },
    usdtAuditNum() {
      if (this.hasParam) {
        let { usdtAuditNum } = this.exchangeLimit;
        return usdtAuditNum;
      } else {
        return "--";
      }
    },
    usdtLimitNum() {
      if (this.hasParam) {
        let { usdtLimitNum } = this.exchangeLimit;
        return usdtLimitNum;
      } else {
        return "--";
      }
    },
    usdtCoinOutNum() {
      if (this.hasParam) {
        let { usdtCoinOutNum } = this.exchangeLimit;
        return usdtCoinOutNum;
      } else {
        return "--";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.explain {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  color: #9aa1b1;
  padding-bottom: 15px;
  .explain-item {
    // height: 25px;
    line-height: 25px;
    span {
      color: #9aa1b1;
      font-size: 14px;
      font-weight: 400;
      .item-num {
        color: #354052;
        font-weight: 500;
      }
    }
  }

  // p {
  //   padding-left: 16px;
  //   position: relative;

  //   span:first-child {
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //   }
  // }

  // p + p {
  //   margin-top: 22px;
  // }
}
</style>
