<template>
  <div>
    <div class="ad-panel__container banner win">
      <div class="ad-panel__container-banner">
        <div class="ad-panel__container-banner-title">
          {{ translateTitle("4E携手蓝白军团 赢在未来") }}
        </div>
        <div class="ad-panel__container-banner-tips">
          <img v-if="isDarkMode" src="../../assets/img/home/afa-logo.png" />
          <img v-else src="../../assets/img/home/afa-log-light.png" />
          {{ translateTitle("4E交易所是阿根廷国家足球队全球合作伙伴") }}
        </div>
        <a class="ad-panel__container-banner-more" :href="getUrl()">
          {{ translateTitle("查看更多") }}
          <i class="iconfont iconright act"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import routeSplicing from "@/assets/js/routeSplicing";
import { mapGetters } from "vuex";
export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {};
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  methods: {
    routeSplicing,
    getUrl() {
      return `/idol/${this.language}#ido-page`;
    },
    gotoIdolPage() {
      this.$router.push({
        path: "/idol#idol-page",
      });
    },
  },
};
</script>
<style lang="scss">
#app:not(.zh_CN, .zh_HK) {
  .ad-panel__container-banner-title {
    font-size: 45px;
    max-width: 600px;
  }
  .ad-panel__container-banner-tips {
    max-width: 600px;
  }
}
:root[theme="light"] {
  .ad-panel__container-banner-tips {
    color: #5c5f69;
  }
  .ad-panel__container-banner-title {
    color: #3348f6;
  }
  .ad-panel__container-banner-more {
    color: #3348f6;
  }
  .ad-panel__container.banner.win {
    background-image: url(../../assets/img/home/banners/xieshou-banner-light.png);
  }
}
.ad-panel__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 498px; */
  background-color: var(--homeBgColor);
  /* cursor: pointer; */
  &.banner.win {
    background-image: url(../../assets/img/home/banners/xieshou-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  color: #fff; //var(--homeFontColor);
  &-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 1200px;
    height: 500px;
    /* background-image: url("../../../assets/images/home/ad-banner.png");
    background-size: cover;
    background-position: center; */
    &-title {
      font-size: 40px;
      font-weight: 500;
      line-height: 60px;
    }
    &-tips {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 10px;
      color: #fff;
      img {
        width: 23.091px;
        height: 21.234px;
        flex-shrink: 0;
        position: relative;
        top: 2px;
      }
      /* color: var(--homeLightGary); */
    }
    &-more {
      color: var(--Dark04-text-c_t01, #fff);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
      cursor: pointer;
    }
  }
  &-title {
    font-size: 36px;
    font-weight: 600;
    margin-top: 50px;
  }
}
</style>