<template>
  <div class="currency-based-contract-oreder-book-component">
    <div class="header">
      <div class="header-content">
        <span class="type common-operate" @click="goback()">{{
          translateTitle("币本位合约")
        }}</span>
        <span class="signal">></span>
        <span class="name">{{ translateTitle("买卖盘") }}</span>
      </div>
    </div>
    <div class="order-book-content">
      <div class="content-header">
        <span class="title">{{ translateTitle("买卖盘") }}</span>
        <span class="symbol"
          >{{ from_symbol.toUpperCase() }} {{ translateTitle("永续") }}/{{
            to_symbol.toUpperCase()
          }}</span
        >
      </div>
      <div class="content">
        <div class="left-item">
          <ul class="depth-sub-title">
            <li class="number">{{ translateTitle("买盘") }}</li>
            <li class="time">
              {{ translateTitle("买入价") }}({{ to_symbol_show }})
            </li>
            <li class="price">
              {{ translateTitle("买入量") }}({{ from_symbol_show }})
            </li>
            <li class="num">
              {{ translateTitle("累计") }}({{ to_symbol_show }})
            </li>
          </ul>
          <ul class="depth-sub-content-buy">
            <li
              v-for="(item, index) in showListBuy"
              @click="onRowClick(item)"
              v-bind:key="index"
            >
              <div class="depth-item">
                <div class="number">
                  {{ translateTitle("买") }}{{ index + 1 }}
                </div>
                <div class="time DINPro">{{ item[0] }}</div>
                <div class="price DINPro">{{ item[1] }}</div>
                <div class="num DINPro" v-if="item[1] == '--'">--</div>
                <div class="num DINPro" v-else>
                  {{ (item[0] * item[1]).toFixed(2) }}
                </div>
                <i
                  v-if="Number(bigSell) == Number(item[1])"
                  class="bg-num"
                  :style="{ width: 100 + '%' }"
                ></i>
                <i
                  v-else
                  class="bg-num"
                  :style="{ width: (item[1] / totleSell) * 100 + '%' }"
                ></i>
              </div>
            </li>
          </ul>
        </div>
        <div class="right-item">
          <ul class="depth-sub-title">
            <li class="number V2">{{ translateTitle("卖盘") }}</li>
            <li class="time V2">
              {{ translateTitle("卖出价") }}({{ to_symbol_show }})
            </li>
            <li class="price">
              {{ translateTitle("卖出量") }}({{ from_symbol_show }})
            </li>
            <li class="num">
              {{ translateTitle("累计") }}({{ to_symbol_show }})
            </li>
          </ul>
          <ul class="depth-sub-content">
            <li
              v-for="(item, index) in showListSell"
              v-bind:key="index"
              @click="onRowClick(item)"
            >
              <div class="depth-item">
                <div class="number">
                  {{ translateTitle("卖") }} {{ index + 1 }}
                </div>
                <div class="time sell DINPro">{{ item[0] }}</div>
                <div class="price DINPro">{{ item[1] }}</div>
                <div class="num DINPro" v-if="item[1] == '--'">--</div>
                <div class="num DINPro" v-else>
                  {{ (item[0] * item[1]).toFixed(2) }}
                </div>
                <i
                  v-if="Number(bigBuy) == Number(item[1])"
                  class="bg-num"
                  :style="{ width: 100 + '%' }"
                ></i>
                <i
                  v-else
                  class="bg-num"
                  :style="{ width: (item[1] / totleBuy) * 100 + '%' }"
                ></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiWsUrl } from "@/model/exchange/index";
import Socket from "@/assets/js/socket.js";
import { mapGetters } from "vuex";
import { apiGetStaticTableInfo } from "@/model/settings";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],

  props: {},
  data() {
    return {
      tt: {},
      lockReconnect: false,

      coinFrom: "--",
      coinTo: "--",
      colorClass: "",
      swap_symbol_id: "",
      selfOptionsList: [],
      symbolList: [],
      depthList: {
        a: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
        b: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
      },
      from_symbol: "",
      to_symbol: "",
      tableLoading: false,
      wsUrl: null,
    };
  },
  async created() {
    await this.getTradePairList();
    let tempSymbol = this.$route.query.symbol;
    this.from_symbol = tempSymbol;

    for (const key in this.symbolList) {
      if (this.symbolList[key].symbol == this.from_symbol) {
        const [coinFrom, coinTo] = this.symbolList[key].name.split("_");
        this.from_symbol = coinFrom;
        this.to_symbol = coinTo;
        this.swap_symbol_id = this.symbolList[key].symbol_id;
      }
    }
    await this.initURL();
    this.initWS();
  },
  methods: {
    goback() {
      window.history.back();
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWS() {
      let me = this;
      this.tableLoading = true;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id,
          },
        },
        callback: me.onResponse,
        onConnect,
        mounted,
      };
      function mounted() {
        me.ws.sub({
          action: "Topic.sub",
          data: {
            type: "market",
            symbol: me.swap_symbol_id,
          },
        });
        me.ws.sub({
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id,
          },
        });
      }
      window.USDT_WS = this.ws = new Socket(option).getInstance();
      // 重新连接
      function onConnect() {
        if (me.lockReconnect) return;
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
    },
    onResponse(data) {
      let me = this;
      if (data.action == "Pushdata.depth") {
        if (data.data) {
          if (!data.params.p) {
            return;
          }
          let tempObj = data.data;
          if (tempObj.a.length > 0) {
            tempObj.c = 0;
            tempObj.d = 0;
            tempObj.a.forEach((ele) => {
              tempObj.c += Number(ele[1]);
            });
            tempObj.b.forEach((ele) => {
              tempObj.d += Number(ele[1]);
            });
          }
          me.depthList = tempObj;
        }
      }
    },
    async getTradePairList() {
      const { status, data } = await apiGetStaticTableInfo(58);
      if (status == 200) {
        this.symbolList = data;
      }
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
    }),
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    totleBuy() {
      return this.depthList.c;
    },
    bigBuy() {
      let temp = Number(this.depthList.a[0][1]);
      for (let i = 0; i < this.depthList.a.length - 1; i++) {
        temp =
          temp < Number(this.depthList.a[i + 1][1])
            ? Number(this.depthList.a[i + 1][1])
            : temp;
      }
      return temp;
    },
    bigSell() {
      let temp = Number(this.depthList.b[0][1]);
      for (let i = 0; i < this.depthList.b.length - 1; i++) {
        temp =
          temp < Number(this.depthList.b[i + 1][1])
            ? Number(this.depthList.b[i + 1][1])
            : temp;
      }
      return temp;
    },
    totleSell() {
      return this.depthList.d;
    },
    // a卖  b买
    showListSell() {
      return this.depthList.a.slice(0, 50);
    },
    showListBuy() {
      return this.depthList.b.slice(0, 50);
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.info.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.info.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.info.price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
  },
};
</script>
<style lang="scss">
.currency-based-contract-oreder-book-component {
  background-color: #f5f5f7;
  align-items: center;
  user-select: none;
  .header {
    height: 60px;
    padding: 20px 0 20px 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    color: #354052;
    .header-content {
      width: 1200px;
      margin: 0 auto;
      .signal {
        color: #9aa1b1;
        margin: 0 16px;
      }
    }
  }
  .order-book-content {
    width: 1200px;
    padding: 20px 30px 29px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    margin: 20px auto 30px;

    .content-header {
      .title {
        height: 20px;
        margin: 0 10px 1px 0;
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
      .symbol {
        color: #9aa1b1;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      min-height: 1340px;
      .left-item,
      .right-item {
        padding: 15px 0px 23px 0px;
        border-radius: 2px;
        border: solid 1px #e2e2e4;
        background-color: #ffffff;
      }
      .depth-sub-title {
        display: flex;
        color: #7f8490;
        font-size: 12px;
        font-weight: 500;
        height: 36px;
        line-height: 36px;
        .number {
          width: 120px;
          color: #03ad79;
          text-align: center;
          &.V2 {
            color: #c61b3d;
          }
        }
        .time {
          width: 155px;
          &.V2 {
            width: 155px;
          }
        }
        .price {
          width: 102px;
        }
        .num {
          width: 126px;
          text-align: right;
        }
      }
    }
    .depth-sub-content {
      overflow: hidden;
      cursor: pointer;
      &.all {
        height: 622px;
      }
      &.none {
        display: none;
      }
      .depth-item {
        width: 100%;
        position: relative;
        display: flex;
        .bg-num {
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          height: 100%;
          border-radius: 0.1rem;
          background-image: linear-gradient(to left, #c61b3d, #c61b3d 0%);
          opacity: 0.1;
        }
      }
      li {
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        .number {
          width: 120px;
          color: #c61b3d;
          text-align: center;
        }
        .time {
          width: 158px;
          color: #03ad79;
          &.sell {
            color: #c61b3d;
          }
        }
        .price {
          width: 118px;
          color: #354052;
        }
        .num {
          width: 159px;
          padding-right: 60px;
          color: #354052;
          text-align: right;
        }
      }
    }
    .depth-sub-content-buy {
      // margin-top: 10px;
      overflow: hidden;
      cursor: pointer;
      &.all {
        height: 648px;
      }
      &.none {
        height: 30px;
      }
      .depth-item {
        width: 100%;
        position: relative;
        display: flex;
        .bg-num {
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          height: 100%;
          border-radius: 0.1rem;
          background-image: linear-gradient(to left, #14dacb, #02cc87 0%);
          opacity: 0.1;
        }
      }
      .buy-header {
        height: 30px;
        border-top: 1px solid #f1f2f5;
        border-bottom: 1px solid #f1f2f5;
        font-size: 16px;
        font-weight: bold;
        cursor: auto;
        user-select: none;

        .current-price {
          color: #354052;
          line-height: 28px;
          .currencyPrice {
            line-height: 28px;
            display: inline-block;
            color: #7f8490;
            font-weight: normal;
            font-size: 12px;
            margin-left: 5px;
          }
          &.green {
            color: #03ad79;
          }
          &.red {
            color: #c61b3d;
          }
        }
      }
      li {
        display: flex;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        justify-content: space-between;
        .number {
          width: 120px;
          color: #03ad79;
          text-align: center;
        }
        .time {
          width: 155px;
          color: #03ad79;
          line-height: 31px;
        }
        .price {
          width: 118px;
          line-height: 31px;
          color: #354052;
        }
        .num {
          width: 159px;
          padding-right: 60px;
          color: #354052;
          text-align: right;
        }
      }
    }
  }
}
</style>
