<template>
  <div class="explain">
    <p>
      <!-- <span>1.</span> -->
      <span>
        1.{{ translateTitle("禁止向") }} {{ capital_name }}
        {{ translateTitle("充币地址充值除") }} {{ capital_name }}
        {{ translateTitle("之外的资产，任何充入") }} {{ capital_name }}
        {{ translateTitle("地址的非") }} {{ capital_name }}
        {{ translateTitle("资产将不可找回；") }}
      </span>
    </p>
    <p>
      <!-- <span>2.</span> -->
      <span
        >2.{{ capital_name }}{{ translateTitle("的区块确认数达到") }}
        {{ confirmNum }} {{ translateTitle("个后，您的") }} {{ capital_name }}
        {{ translateTitle("会自动转入到您的账户；") }}
      </span>
    </p>
    <p>
      <!-- <span>3.</span> -->
      <span>
        3.{{ translateTitle("充值完成后，您可以进入充币记录页面跟踪进度。") }}
      </span>
    </p>
    <!-- <p>
      <span>4.</span>
      <span>为充分保障您的隐私，充币地址每收款 {{exchangeLimit.addressMaxUsed || '-'}} 笔更换一次。向老地址充币仍然可以到账，不过为了您的隐私，请向页面显示的最新充币地址充币。</span>
    </p> -->
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  props: ["data", "capital_name", "exchangeLimit"],
  computed: {
    confirmNum() {
      if (JSON.stringify(this.data) !== "{}") {
        let { confirm_num } = this.data.itemDetail;
        return confirm_num;
      } else {
        return "--";
      }
    },
  },
};
</script>
<style lang="scss">
.explain {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  color: #9aa1b1;
  margin-top: 10px;
  font-weight: 400;

  p {
    /* padding-left: 16px; */
    position: relative;
    // height: 26px;
    line-height: 26px;

    /* span:first-child {
      position: absolute;
      left: 0;
      top: 0;
    } */
  }

  // p + p {
  //   margin-top: 22px;
  // }
}
</style>
