import api from "@/assets/js/api";
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export function apiGetOrderList(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/getOrderListV2`,
    data,
    type: "POST",
  });
}

export function apiCancelOrde(data = {}) {
  return api({
    url: VUE_APP_UCENTER + `/App/TradeCoin/cancelLimitOrder`,
    data,
    type: "POST",
  });
}
// 撤销订单新接口
export function apiTrustCancel(data = {}) {
  return api({
    url: VUE_APP_APP + `/Spot/Trade/trustCancel`,
    data,
    type: "POST",
  });
}
//用户委托列表
export function apiTrustIndex(data = {}) {
  return api({
    url: VUE_APP_APP + `/Spot/Trade/trustIndex`,
    data,
    type: "POST",
  });
}
// 用户成交列表
export function apiTrustDealIndex(data = {}) {
  return api({
    url: VUE_APP_APP + `/Spot/Trade/trustDealIndex`,
    data,
    type: "POST",
  });
}
