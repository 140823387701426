<template>
  <div class="home-top-banner__container home-top">
    <div class="home-top-banner__container-content">
      <div>
        <div class="home-top-banner__container-entitle">
          Global Sponsor of AFA
        </div>
        <div class="home-top-banner__container-title">
          <span class="home-top-banner__container-title-left"
            >{{ translateTitle("4E交易所") }}
            <!-- <img
              class="home-top-banner__container-title-right-icon"
              src="../../assets/img/home/banners/champin.png"
              alt="" /> --> </span
          ><span class="home-top-banner__container-title-right"
            >{{ translateTitle("赢在未来") }}
            <!-- <img
              class="home-top-banner__container-title-right-icon"
              src="../../assets/img/home/banners/champin.png"
              alt=""
            /> -->
          </span>
        </div>
        <div class="home-top-banner__container-btns">
          <span
            v-if="!userInfoShow"
            @click="onRegister"
            class="home-top-banner__container-btns-register"
            >{{ translateTitle("立即注册") }}</span
          >
          <span
            v-if="userInfoShow"
            @click="onTrade"
            class="home-top-banner__container-btns-register"
            >{{ translateTitle("立即交易") }}</span
          >
          <!-- <span
            v-if="!userInfoShow"
            @click="onLogin"
            class="home-top-banner__container-btns-login mock-trade"
            >{{ translateTitle("登录") }}</span
          > -->
        </div>
        <div class="new-gifts">
          <img :src="isDarkMode ? giftIcon : giftIconLight" />
          {{ translateTitle("新人福利：首充赠100%赠金") }}
        </div>
      </div>
      <img
        class="home-top-banner__container-content-icon"
        src="../../assets/img/home/banners/bg.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import Cookies from "js-cookie";
import giftIcon from "@/assets/img/home/banners/gift.png";
import giftIconLight from "@/assets/img/home/banners/gift-light.png";
export default {
  props: {},
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      giftIcon,
      giftIconLight,
    };
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },
  methods: {
    routeSplicing,

    reportHomepageClick(module, type) {
      this.$sensors.track("Homepage_Click", {
        of_module: module,
        button_name: type ? type : "--",
      });
    },
    mockTrade() {
      // let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      // window.open(tempUrl);
      // this.downloadTipsVisible = true;
      // this.reportHomepageClick("title", "mock_trade");
      this.$emit("trigger-download");
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
      this.reportHomepageClick("title", "trade_now");
    },
    onLogin() {
      this.$router.push({
        name: "Login",
        params: {},
      });
    },
    onRegister() {
      const query = {};
      if (this.$route.query.ref) {
        query.ref = this.$route.query.ref;
      }
      this.$router.push({
        name: "Register",
        params: {},
        query,
      });

      this.$sensors.track("Register_Click", {
        invite_code: "",
        channel_type: "自然流量",
        second_channel_type: "WEB",
      });
    },
  },
};
</script>
<style lang="scss">
:root[theme="light"] {
  .home-top-banner__container {
    /* background-color: var(--homeBgColor); */
    background: linear-gradient(2deg, #fff 53.33%, #e2e5ff 98.75%);
    /* background-image: url(../../assets/img/home/banners/bg.png); */
  }
  .home-top-banner__container-btns-login.mock-trade:hover {
    background: var(--homeHoverBgColor) !important;
  }
  .home-top-banner__container-entitle {
    color: #465aff;
  }
  .home-top-banner__container-title-left {
    background: linear-gradient(90deg, #465aff 0%, #2a3699 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .home-top-banner__container-btns-register {
    background: var(--homeBtnBgColor);
    color: #fff;
    &:hover {
      background: var(--blueBtnHover);
    }
  }
}
:root[theme="dark"] {
  .home-top-banner__container {
    background-color: #000;
    /* background-image: url(../../assets/img/home/banners/bg.png); */
    .new-gifts {
      color: rgba(255, 255, 255, 0.85);
    }
  }
  .home-top-banner__container-btns-login.mock-trade:hover {
    background: rgb(255, 255, 255, 0.06) !important;
  }
  .home-top-banner__container-title-right {
    color: #fff;
  }
  .home-top-banner__container-btns-register {
    background: #fff;
    color: #000;
    &:hover {
      background: var(--whiteBtnHover);
    }
  }
}

#app:not(.zh_CN, .zh_HK) {
  .home-top-banner__container-title-left {
    position: relative;
  }
  .home-top-banner__container-title-right-icon {
    /* width: 40px;
    height: 40px;
    top: -18px;
    left: 48px; */
    right: -24px;
    left: auto;
    top: -30px;
  }
  .home-top-banner__container-title {
    display: flex;
    flex-wrap: wrap;
    line-height: 78px;
    .home-top-banner__container-title-right {
      .home-top-banner__container-title-right-icon {
        display: none;
      }
    }
  }
}
.home-top-banner__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  /* background-color: var(--homeBgColor); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--homeFontColor); */
  .new-gifts {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 25px;
    img {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      position: relative;
      top: 2px;
    }
  }
  &-content {
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-icon {
      width: 632px;
      height: 500px;
    }
  }

  &-entitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 36px;
    color: var(--Dark04-text-c_t01, #fff);
  }
  &-title {
    text-align: left;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 100% */
    &-left {
      background: linear-gradient(90deg, #465aff 0%, #ced3fd 50%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 10px;
    }
    &-right {
      position: relative;
      &-icon {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        position: absolute;
        top: -32px;
        left: 19px;
      }
    }
  }
  &-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
    &-register,
    &-login {
      cursor: pointer;
      border-radius: 26px;
      padding: 0px 16px;
      height: 52px;
      line-height: 52px;
      min-width: 150px;
      text-align: center;
    }
    &-register {
      /* padding: 10px 30px;
      border-radius: 5px; */
      /* border: 1px solid var(--homeFontColor); */
      margin-right: 16px !important;
      background: var(--homeColorBtnBgColor);
      color: var(--homeColorBtnColor);
    }
    &-login {
      /* padding: 10px 30px;
      border-radius: 5px; */
      border: 1px solid var(--homeLightBtnBorder);
      background-color: var(--homeLightBtnBgColor);
      color: var(--homeLightBtnColor);
      border-radius: 26px;
      border: 1px solid var(--Light04-border-c_s_b01, #cbcbcb);
      background: none !important;
    }
  }
}
</style>
