<template>
  <div>
    <div v-cloak class="appWrap">
      <div class="wrap pcWrap">
        <div class="body">
          <div class="topBox">
            <div class="topInfo">
              <div class="topInfoLeft">
                <div>
                  <img
                    class="titleImgPc"
                    src="../../assets/img/token2049/title_zh.png"
                    alt=""
                  />
                </div>
                <div class="title1">
                  {{ translateTitle("TOKEN2049 x 4E 周年庆") }}
                </div>
                <div class="title2">{{ translateTitle("携手赢在未来") }}</div>
                <div class="titleTag">
                  <span class="yellow marginRight">{{
                    translateTitle("4E 庆祝周年")
                  }}</span>
                  <span>{{ translateTitle("并官宣成为") }}</span>
                  <span class="yellow marginLeft">
                    {{ translateTitle("TOKEN2049 铂金赞助商") }}</span
                  >
                </div>
                <div class="date">
                  <img src="../../assets/img/token2049/top_icon1.png" alt="" />
                  <span>{{ translateTitle("2024年9月18-9月19日") }}</span>
                </div>
                <div class="address">
                  <img src="../../assets/img/token2049/top_icon2.png" alt="" />
                  <span>{{ translateTitle("新加坡滨海湾金沙酒店") }}</span>
                </div>
              </div>
              <img :src="getTopBgImg()" class="bg2" />
            </div>

            <div class="topBg"></div>
          </div>
          <div class="midBox">
            <div class="innerBox">
              <div class="title">
                {{
                  translateTitle(
                    "4E 周年将近，在我们成为阿根廷国家队全球独家赞助商之后，再次赞助 TOKEN2049，彰显 4E 全球影响力。"
                  )
                }}
              </div>
              <div class="text">
                {{
                  translateTitle(
                    "4E很高兴地宣布成为 2024 年 TOKEN2049 新加坡的铂金赞助商！TOKEN2049 是全球顶级的加密货币盛会，吸引来自100多个国家的20,000多名与会者，汇聚行业人才，共同探讨市场见解和机遇。"
                  )
                }}
              </div>
              <div class="text">
                {{
                  translateTitle(
                    "作为 TOKEN2049 的铂金赞助商，4E 致力于引领金融创新，帮助与会者更好地把握行业未来趋势。"
                  )
                }}
              </div>
              <img
                src="../../assets/img/token2049/midBox_mark.png"
                alt=""
                class="mark"
                v-if="!isdark"
              />
              <img
                src="../../assets/img/token2049/midBox_mark_dark.png"
                alt=""
                class="mark"
                v-else
              />
            </div>
          </div>
          <div class="mixBox">
            <div class="first">
              <div class="titleBox">
                <img
                  src="../../assets/img/token2049/star.png"
                  alt=""
                  v-if="!isdark"
                />
                <img
                  src="../../assets/img/token2049/star_dark.png"
                  alt=""
                  v-else
                />
                <div class="mainTitle">
                  {{ translateTitle("“E”周年 · TOKEN2049 限定活动") }}
                </div>
                <div class="subTitle">
                  {{ translateTitle("领现金红包，赢幸运大奖") }}
                </div>
              </div>
              <div class="list">
                <img
                  src="../../assets/img/token2049/moon.png"
                  class="tagIcon"
                />
                <div class="leftBox">
                  <div class="title">
                    <img src="../../assets/img/token2049/hot.png" alt="" />
                    <span>{{
                      translateTitle("“E”周年 · TOKEN2049 线下打卡")
                    }}</span>
                  </div>
                  <div class="swiperBox">
                    <div class="swiper-container">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <img
                            src="../../assets/img/token2049/banner1.png"
                            alt=""
                          />
                          <span>{{ translateTitle("4E 电梯广告") }}</span>
                        </div>
                        <div class="swiper-slide">
                          <img
                            src="../../assets/img/token2049/banner2.png"
                            alt=""
                          />
                          <span>{{ translateTitle("4E 现金红包") }}</span>
                        </div>
                        <div class="swiper-slide">
                          <img
                            src="../../assets/img/token2049/banner3.png"
                            alt=""
                          />
                          <span>4E x AFA {{ translateTitle("签名球衣") }}</span>
                        </div>
                        <div class="swiper-slide">
                          <img
                            src="../../assets/img/token2049/banner4.png"
                            alt=""
                          />
                          <span>{{ translateTitle("4E 调酒套装礼盒") }}</span>
                        </div>
                        <div class="swiper-slide">
                          <img
                            src="../../assets/img/token2049/banner5.png"
                            alt=""
                          />
                          <span>{{ translateTitle("4E 显眼包") }}</span>
                        </div>
                        <div class="swiper-slide">
                          <img
                            src="../../assets/img/token2049/banner6.png"
                            alt=""
                          />
                          <span>{{ translateTitle("4E x AFA 手持风扇") }}</span>
                        </div>
                      </div>
                      <div class="swiper-pagination"></div>
                    </div>
                    <div class="preIcon">
                      <img
                        src="../../assets/img/token2049/preIcon.png"
                        alt=""
                        @click="goBannerPre"
                        v-if="!isdark"
                      />
                      <img
                        src="../../assets/img/token2049/preIcon_dark.png"
                        alt=""
                        @click="goBannerPre"
                        v-else
                      />
                    </div>
                    <div class="nextIcon">
                      <img
                        src="../../assets/img/token2049/nextIcon.png"
                        alt=""
                        @click="goBannerNext"
                        v-if="!isdark"
                      />
                      <img
                        src="../../assets/img/token2049/nextIcon_dark.png"
                        alt=""
                        @click="goBannerNext"
                        v-else
                      />
                    </div>
                    <!-- <img src="../../assets/img/token2049/banner1.png" alt=""> -->
                  </div>
                  <div class="text">
                    {{
                      translateTitle(
                        "现场免费领取 5 SGD 现金红包，4E冰淇凌..., 来 TOKEN2049 线下打卡，寻找 4E 吉祥物，集周年徽章，赢惊喜限量周边！"
                      )
                    }}🎉
                  </div>
                </div>
                <div class="others">
                  <div class="item" @click="gotoLink('link1')">
                    <img
                      src="../../assets/img/token2049/list_img1.png"
                      alt=""
                    />
                    <div class="item-content">
                      <div class="title link">
                        {{ translateTitle("TOKEN2049 4E与你相约新加坡") }}
                      </div>
                      <div class="time">
                        {{ translateTitle("活动期限：") }}2024/8/16-2024/9/15
                      </div>
                    </div>
                  </div>
                  <div class="item" @click="gotoLink('link2')">
                    <img
                      src="../../assets/img/token2049/list_img2.png"
                      alt=""
                    />
                    <div class="item-content">
                      <div class="title link">
                        {{ translateTitle("4E周年庆万U奖励等你拿") }}
                      </div>
                      <div class="time">
                        {{ translateTitle("活动期限：") }}2024/8/15-2024/9/30
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <img
                      src="../../assets/img/token2049/list_img3.png"
                      alt=""
                    />
                    <div class="item-content">
                      <div class="title">
                        {{ translateTitle("更多惊喜，“E” 起来筹备！") }}
                      </div>
                      <div class="time">
                        {{
                          translateTitle(
                            "TOKEN2049 活动周，期待一个4E周年见面会吗？"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="second">
              <div class="titleBox">
                <div class="mainTitle">
                  <b>{{ translateTitle("TOKEN2049") }}</b>
                  <span>{{ translateTitle("限定邀请码") }}</span>
                </div>
                <div class="subTitle">
                  {{ translateTitle("下载赢取万U新人礼") }}
                </div>
              </div>
              <div class="innerBox">
                <div class="list">
                  <div class="item">
                    <img src="../../assets/img/token2049/icon1.png" alt="" />
                    <div class="text">
                      <p>{{ translateTitle("交易") }}</p>
                      <span>{{
                        translateTitle("数字货币/外汇/大宗商品/股指...")
                      }}</span>
                    </div>
                  </div>
                  <div class="item">
                    <img src="../../assets/img/token2049/icon2.png" alt="" />
                    <div class="text">
                      <p>{{ translateTitle("理财") }}</p>
                      <span>{{ translateTitle("投资赚币/借贷抵押...") }}</span>
                    </div>
                  </div>
                  <div class="btns">
                    <div class="qrCode">
                      <img
                        src="../../assets/img/token2049/icon3.png"
                        alt=""
                        class="icon"
                      />
                      <div class="qrCodeBox">
                        <img
                          src="../../assets/img/token2049/qrCode.png"
                          alt=""
                        />
                        <span>{{ translateTitle("扫码下载") }}</span>
                      </div>
                    </div>
                    <div class="btn" @click="goRegister">
                      <span>{{ translateTitle("现在开始") }}</span>
                      <img
                        src="../../assets/img/token2049/arrow.png"
                        alt=""
                        class="arrow"
                        v-if="!isdark"
                      />
                      <img
                        src="../../assets/img/token2049/arrow_dark.png"
                        alt=""
                        class="arrow"
                        v-else
                      />
                    </div>
                  </div>
                </div>
                <img
                  src="../../assets/img/token2049/second_img.png"
                  class="img"
                  v-if="!isdark"
                />
                <img
                  src="../../assets/img/token2049/second_img_dark.png"
                  class="img"
                  v-else
                />
              </div>
            </div>
          </div>
          <div class="box1" v-if="charts.length">
            <div class="innerBox">
              <div class="title">
                <span>{{ translateTitle("要交易，") }}</span>
                <span>{{ translateTitle("用4E") }}</span>
              </div>
              <div class="subTitle">
                {{ translateTitle("一站式投资全球热门资产，投资无界限") }}
              </div>
              <div class="list">
                <img
                  src="../../assets/img/token2049/box1_bg.png"
                  alt=""
                  class="bg"
                  v-if="!isdark"
                />
                <img
                  src="../../assets/img/token2049/box1_bg_dark.png"
                  alt=""
                  class="bg"
                  v-else
                />
                <ul
                  v-for="(items, index) in charts"
                  :key="index"
                  :class="'priceBox ' + (index === 1 ? 'secondPriceBox' : '')"
                >
                  <li
                    v-for="(item, sindex) in items"
                    :key="sindex"
                    @click="gotoExchange(item)"
                  >
                    <img :src="item.icon" alt="" class="icon" />
                    <div class="currency">{{ item.title }}</div>
                    <div :class="'price ' + (item.isGrowth ? 'up' : 'down')">
                      <span> {{ item.value }}</span>
                      <span>
                        {{ item.isGrowth ? "+" : "" }}{{ item.growth }}%</span
                      >
                    </div>
                    <img
                      v-if="item.isGrowth"
                      src="../../assets/img/token2049/box1_price_img.png"
                      alt=""
                      class="canvas"
                    />
                    <img
                      v-else
                      src="../../assets/img/token2049/box1_price_img2.png"
                      alt=""
                      class="canvas"
                    />
                  </li>
                </ul>
              </div>
              <div class="btn" @click="goMore">
                <span>{{ translateTitle("查看更多") }}</span>
                <img
                  src="../../assets/img/token2049/btn_arrow.png"
                  alt=""
                  v-if="!isdark"
                />
                <img
                  src="../../assets/img/token2049/btn_arrow_dark.png"
                  alt=""
                  v-else
                />
              </div>
            </div>
          </div>
          <div class="box2">
            <div class="innerBox">
              <div class="titleBox">
                <img
                  src="../../assets/img/token2049/box2_logo.png"
                  alt=""
                  v-if="!isdark"
                />
                <img
                  src="../../assets/img/token2049/box2_logo_dark.png"
                  alt=""
                  v-else
                />
                <div class="title">
                  {{ translateTitle("世界冠军之选，顶级交易体验") }}
                </div>
                <div class="subTitle">
                  {{ translateTitle("阿根廷国家足球队全球合作伙伴") }}
                </div>
              </div>
              <div class="content">
                <img
                  src="../../assets/img/token2049/box2_icon3.png"
                  alt=""
                  class="topIcon"
                />
                <div class="imgBox" @click="playVideo" v-if="!playing">
                  <img
                    src="../../assets/img/token2049/box2_renwu.png"
                    alt=""
                    class="img"
                  />
                  <img
                    src="../../assets/img/token2049/playIcon.png"
                    alt=""
                    class="playIcon"
                  />
                </div>
                <video controls class="videoBox" autoplay v-else>
                  <source
                    src="../../assets/img/token2049/video/video.mp4"
                    type="video/webm"
                  />
                </video>
                <img
                  src="../../assets/img/token2049/box2_icon1.png"
                  alt=""
                  class="rightIcon"
                />
                <img
                  src="../../assets/img/token2049/box2_icon2.png"
                  alt=""
                  class="leftIcon"
                />
              </div>
            </div>
          </div>
          <div class="box3">
            <div class="innerBox">
              <div class="title">
                {{ translateTitle("行业领先资产管理组合") }}
              </div>
              <div class="list" v-if="!isdark">
                <div
                  class="firstBox small"
                  :class="[activeBox3 === 1 ? 'active' : '']"
                  @mouseover="overItem(1)"
                >
                  <p>{{ translateTitle("一键跟单") }}</p>
                  <p v-if="activeBox3 === 1">
                    {{ translateTitle("财富不落单") }}
                  </p>
                  <img
                    src="../../assets/img/token2049/box3_arrow_active.png"
                    alt=""
                    class="arrowIcon"
                    v-if="activeBox3 === 1"
                  />
                  <img
                    src="../../assets/img/token2049/box3_arrow.png"
                    alt=""
                    class="arrowIcon"
                    v-else
                  />
                  <img
                    src="../../assets/img/token2049/box3_img1_active.png"
                    alt=""
                    class="img"
                    v-if="activeBox3 === 1"
                  />
                  <img
                    src="../../assets/img/token2049/box3_img1.png"
                    alt=""
                    class="img"
                    v-else
                  />
                </div>
                <div
                  class="firstBox small"
                  :class="[activeBox3 === 2 ? 'active' : '']"
                  @mouseover="overItem(2)"
                >
                  <p>{{ translateTitle("投资赚币") }}</p>
                  <p v-if="activeBox3 === 2">
                    {{ translateTitle("持币生币，理财有道") }}
                  </p>
                  <img
                    src="../../assets/img/token2049/box3_arrow_active.png"
                    alt=""
                    class="arrowIcon"
                    v-if="activeBox3 === 2"
                  />
                  <img
                    src="../../assets/img/token2049/box3_arrow.png"
                    alt=""
                    class="arrowIcon"
                    v-else
                  />
                  <img
                    src="../../assets/img/token2049/box3_img2_active.png"
                    alt=""
                    class="img"
                    v-if="activeBox3 === 2"
                  />
                  <img
                    src="../../assets/img/token2049/box3_img2.png"
                    alt=""
                    class="img"
                    v-else
                  />
                </div>
                <div
                  class="thirdBox small"
                  :class="[activeBox3 === 3 ? 'active' : '']"
                  @mouseover="overItem(3)"
                >
                  <p>{{ translateTitle("借贷质押") }}</p>
                  <p>{{ translateTitle("灵活满足") }}</p>
                  <p v-if="activeBox3 === 3">
                    {{ translateTitle("小资金撬动大收益") }}
                  </p>
                  <img
                    src="../../assets/img/token2049/box3_arrow_active.png"
                    alt=""
                    class="arrowIcon"
                    v-if="activeBox3 === 3"
                  />
                  <img
                    src="../../assets/img/token2049/box3_arrow.png"
                    alt=""
                    class="arrowIcon"
                    v-else
                  />
                  <img
                    src="../../assets/img/token2049/box3_img3_active.png"
                    alt=""
                    class="img"
                    v-if="activeBox3 === 3"
                  />
                  <img
                    src="../../assets/img/token2049/box3_img3.png"
                    alt=""
                    class="img"
                    v-else
                  />
                </div>
                <div
                  class="fourthBox small"
                  :class="[activeBox3 === 4 ? 'active' : '']"
                  @mouseover="overItem(4)"
                >
                  <p>{{ translateTitle("4E金融卡") }}</p>
                  <p v-if="activeBox3 === 4">
                    {{ translateTitle("前途无界 触手可及") }}
                  </p>
                  <img
                    src="../../assets/img/token2049/box3_arrow_active.png"
                    alt=""
                    class="arrowIcon"
                    v-if="activeBox3 === 4"
                  />
                  <img
                    src="../../assets/img/token2049/box3_arrow.png"
                    alt=""
                    class="arrowIcon"
                    v-else
                  />
                  <img
                    src="../../assets/img/token2049/box3_img4_active.png"
                    alt=""
                    class="img"
                    v-if="activeBox3 === 4"
                  />
                  <img
                    src="../../assets/img/token2049/box3_img4.png"
                    alt=""
                    class="img"
                    v-else
                  />
                </div>
              </div>
              <div class="list" v-else>
                <div
                  class="firstBox small"
                  :class="[activeBox3 === 1 ? 'active' : '']"
                  @mouseover="overItem(1)"
                >
                  <p>{{ translateTitle("一键跟单") }}</p>
                  <p v-if="activeBox3 === 1">
                    {{ translateTitle("财富不落单") }}
                  </p>
                  <img
                    src="../../assets/img/token2049/box3_arrow_active.png"
                    alt=""
                    class="arrowIcon"
                    v-if="activeBox3 === 1"
                  />
                  <img
                    src="../../assets/img/token2049/box3_arrow.png"
                    alt=""
                    class="arrowIcon"
                    v-else
                  />
                  <img
                    src="../../assets/img/token2049/box3_img1_active_dark.png"
                    alt=""
                    class="img"
                    v-if="activeBox3 === 1"
                  />
                  <img
                    src="../../assets/img/token2049/box3_img1_dark.png"
                    alt=""
                    class="img"
                    v-else
                  />
                </div>
                <div
                  class="firstBox small"
                  :class="[activeBox3 === 2 ? 'active' : '']"
                  @mouseover="overItem(2)"
                >
                  <p>{{ translateTitle("投资赚币") }}</p>
                  <p v-if="activeBox3 === 2">
                    {{ translateTitle("持币生币，理财有道") }}
                  </p>
                  <img
                    src="../../assets/img/token2049/box3_arrow_active.png"
                    alt=""
                    class="arrowIcon"
                    v-if="activeBox3 === 2"
                  />
                  <img
                    src="../../assets/img/token2049/box3_arrow.png"
                    alt=""
                    class="arrowIcon"
                    v-else
                  />
                  <img
                    src="../../assets/img/token2049/box3_img2_active_dark.png"
                    alt=""
                    class="img"
                    v-if="activeBox3 === 2"
                  />
                  <img
                    src="../../assets/img/token2049/box3_img2_dark.png"
                    alt=""
                    class="img"
                    v-else
                  />
                </div>
                <div
                  class="thirdBox small"
                  :class="[activeBox3 === 3 ? 'active' : '']"
                  @mouseover="overItem(3)"
                >
                  <p>{{ translateTitle("借贷质押") }}</p>
                  <p>{{ translateTitle("灵活满足") }}</p>
                  <p v-if="activeBox3 === 3">
                    {{ translateTitle("小资金撬动大收益") }}
                  </p>
                  <img
                    src="../../assets/img/token2049/box3_arrow_active.png"
                    alt=""
                    class="arrowIcon"
                    v-if="activeBox3 === 3"
                  />
                  <img
                    src="../../assets/img/token2049/box3_arrow.png"
                    alt=""
                    class="arrowIcon"
                    v-else
                  />
                  <img
                    src="../../assets/img/token2049/box3_img3_active_dark.png"
                    alt=""
                    class="img"
                    v-if="activeBox3 === 3"
                  />
                  <img
                    src="../../assets/img/token2049/box3_img3_dark.png"
                    alt=""
                    class="img"
                    v-else
                  />
                </div>
                <div
                  class="fourthBox small"
                  :class="[activeBox3 === 4 ? 'active' : '']"
                  @mouseover="overItem(4)"
                >
                  <p>{{ translateTitle("4E金融卡") }}</p>
                  <p v-if="activeBox3 === 4">
                    {{ translateTitle("前途无界 触手可及") }}
                  </p>
                  <img
                    src="../../assets/img/token2049/box3_arrow_active.png"
                    alt=""
                    class="arrowIcon"
                    v-if="activeBox3 === 4"
                  />
                  <img
                    src="../../assets/img/token2049/box3_arrow.png"
                    alt=""
                    class="arrowIcon"
                    v-else
                  />
                  <img
                    src="../../assets/img/token2049/box3_img4_active_dark.png"
                    alt=""
                    class="img"
                    v-if="activeBox3 === 4"
                  />
                  <img
                    src="../../assets/img/token2049/box3_img4_dark.png"
                    alt=""
                    class="img"
                    v-else
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="box4">
            <div class="innerBox">
              <div class="title">{{ translateTitle("全球战略布局") }}</div>
              <div class="subTitle">
                {{ translateTitle("覆盖多个国家与地区，支持法币存款及提款") }}
              </div>
              <div class="list">
                <div class="item">
                  <span>{{ translateTitle("15+") }}</span>
                  <span>{{ translateTitle("$100M+") }}</span>
                </div>
                <div class="item">
                  <span>{{ translateTitle("37") }}</span>
                  <span>{{ translateTitle("全球影响力排名") }}</span>
                </div>
                <div class="item">
                  <span>{{ translateTitle("$100M+") }}</span>
                  <span>{{ translateTitle("平台风险赔付基金") }}</span>
                </div>
                <div class="item">
                  <span>{{ translateTitle("880+") }}</span>
                  <span>{{ translateTitle("平台交易品种/资产") }}</span>
                </div>
              </div>
              <img :src="getTextImg()" alt="" class="textImg" />
              <!-- <img :src="'../../assets/img/token2049/rightText_'+lang+'.png'" alt="" class="rightText"> -->
              <!-- <div class="leftText">{{translateTitle('text46')}}</div>
              <div class="rightText">{{translateTitle('text47')}}</div> -->
            </div>
          </div>
          <div class="footer">
            <div class="innerBox">
              <div class="title">
                {{ translateTitle("与4E同行") }}
                <span style="margin-left: 10px">{{
                  translateTitle("共同掌握财富之钥")
                }}</span>
              </div>
              <div class="relatedBox">
                <div class="text">
                  {{ translateTitle("加入4E社群 了解最新动向") }}
                </div>
                <div class="list" v-if="!isdark">
                  <img
                    src="../../assets/img/token2049/discord.png"
                    alt=""
                    class="item"
                    @click="goUrl('discord')"
                  />
                  <img
                    src="../../assets/img/token2049/LinkedIn.png"
                    alt=""
                    class="item"
                    @click="goUrl('linkedIn')"
                  />
                  <img
                    src="../../assets/img/token2049/Instagram.png"
                    alt=""
                    class="item"
                    @click="goUrl('instagram')"
                  />
                  <img
                    src="../../assets/img/token2049/telegram.png"
                    alt=""
                    class="item"
                    @click="goUrl('telegram')"
                  />
                  <img
                    src="../../assets/img/token2049/twitter.png"
                    alt=""
                    class="item"
                    @click="goUrl('twitter')"
                  />
                  <img
                    src="../../assets/img/token2049/zalo.png"
                    alt=""
                    class="item"
                    @click="goUrl('zalo')"
                  />
                </div>
                <div class="list" v-else>
                  <img
                    src="../../assets/img/token2049/discord_dark.png"
                    alt=""
                    class="item"
                    @click="goUrl('discord')"
                  />
                  <img
                    src="../../assets/img/token2049/LinkedIn_dark.png"
                    alt=""
                    class="item"
                    @click="goUrl('linkedIn')"
                  />
                  <img
                    src="../../assets/img/token2049/Instagram_dark.png"
                    alt=""
                    class="item"
                    @click="goUrl('instagram')"
                  />
                  <img
                    src="../../assets/img/token2049/telegram_dark.png"
                    alt=""
                    class="item"
                    @click="goUrl('telegram')"
                  />
                  <img
                    src="../../assets/img/token2049/twitter_dark.png"
                    alt=""
                    class="item"
                    @click="goUrl('twitter')"
                  />
                  <img
                    src="../../assets/img/token2049/zalo_dark.png"
                    alt=""
                    class="item"
                    @click="goUrl('zalo')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from "swiper";
// import Swiper styles
// import "swiper/css";
import commonMixin from "@/components/common/commonMixin.vue";
import chartDataMixin from "../../pages/home/mixins/chart-data";
export default {
  components: {},
  mixins: [commonMixin, chartDataMixin],
  data() {
    return {
      // language: "zh_CN",
      colorModel: 1,
      activeBox3: 1,
      playing: false,
      mySwiper: null,
      isBarnd: true,
      links: {
        link1: {
          zh_CN:
            "https://download.eeeedex.com/activity/2024/activesg1/activity1/index.html",
          en: "https://download.eeeedex.com/activity/2024/activesg1/activity1_en/index.html",
          vi: "https://download.eeeedex.com/activity/2024/activesg1/activity1_vi/index.html",
          th: "https://download.eeeedex.com/activity/2024/activesg1/activity1_th/index.html",
        },
        link2: {
          zh_CN:
            "https://download.eeeedex.com/activity/2024/activesg2/activity1/index.html",
          en: "https://download.eeeedex.com/activity/2024/activesg2/activity1_en/index.html",
          vi: "https://download.eeeedex.com/activity/2024/activesg2/activity1_vi/index.html",
          th: "https://download.eeeedex.com/activity/2024/activesg2/activity1_th/index.html",
        },
      },
      // isdark: true,
    };
  },
  created() {
    // document.getElementById("app").className = `${this.language}App`;
    // this.setRem();
    // this.checkMatchMedia()
  },
  watch: {
    isDarkMode: {
      handler(val) {
        this.initCharts(true);
      },
      immediate: true,
    },
  },
  computed: {
    isdark() {
      return this.isDarkMode;
    },
  },
  mounted() {
    setTimeout(() => {
      this.initSwiper();
    });
  },
  watch: {
    isdark: {
      handler(newVal) {
        if (newVal) {
          document.querySelector("html").setAttribute("theme", "dark");
          document.getElementById("app").classList.add("dark");
        } else {
          document.querySelector("html").setAttribute("theme", "light");
          document.getElementById("app").classList.remove("dark");
        }
      },
      immediate: true,
    },
  },
  methods: {
    gotoLink(item) {
      window.open(
        this.links[item][this.language] || this.links[item]["en"],
        "_blank"
      );
    },
    getTextImg() {
      const langList = ["zh_CN", "pt", "vi", "es", "en", "th"];
      return langList.includes(this.language)
        ? require("../../assets/img/token2049/text/" +
            this.language +
            "-box4-text-" +
            this.getName() +
            ".png")
        : require("../../assets/img/token2049/text/" +
            "en" +
            "-box4-text-" +
            this.getName() +
            ".png");
    },
    getTopBgImg() {
      const langList = ["zh_CN", "pt", "vi", "es", "en", "th"];
      return langList.includes(this.language)
        ? require(`../../assets/img/token2049/${this.language}-top-bg.png`)
        : require(`../../assets/img/token2049/en-top-bg.png`);
    },
    initSwiper() {
      this.mySwiper = new Swiper(".swiper-container", {
        loop: true,
        autoplay: {
          delay: 1000,
        },
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
        },
      });
      setInterval(() => {
        // this.mySwiper.slideNext();
      }, 3000);
    },
    getName() {
      if (this.isdark) {
        return "dark";
      } else {
        return "light";
      }
    },
    goBannerPre() {
      this.mySwiper.slidePrev();
    },
    goBannerNext() {
      this.mySwiper.slideNext();
    },
    checkMatchMedia() {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        // 暗黑模式
        this.colorModel = 1;
        document.querySelector("html").setAttribute("theme", "dark");
        document.getElementById("app").classList.add("dark");
        this.isdark = true;
      } else {
        // 明亮模式
        this.colorModel = 2;
        document.querySelector("html").setAttribute("theme", "light");
        document.getElementById("app").classList.remove("dark");
        this.isdark = false;
      }
    },
    setLang() {
      let lang = (navigator.browserLanuage || navigator.language).toLowerCase();
      this.language =
        lang.indexOf("vi") > -1 ? "vi" : lang.indexOf("en") > -1 ? "en" : "zh";
    },
    setRem() {
      var clientWidth = document.documentElement.clientWidth;
      var rem = (clientWidth / 375) * 100; // 375是设计稿宽度，10是基准字体大小
      document.documentElement.style.fontSize = rem + "px";
    },
    back() {
      window.open("http://www.eeee.com");
    },
    // translateTitle(key, isBreak = false) {
    //   return key; //langMap[this.language][key];
    // },

    goPage(url) {
      window.open(url);
    },
    goUrl(type) {
      let url = "";
      switch (type) {
        case "discord":
          url = "https://discord.com/invite/67573MsyZc";
          break;
        case "linkedIn":
          url = "https://www.linkedin.cn/incareer/company/4e-exchange/";
          break;
        case "instagram":
          url = "https://www.instagram.com/global_4e/";
          break;
        case "telegram":
          url = "https://t.me/Official_4e";
          break;
        case "twitter":
          url = "https://twitter.com/4e_Global";
          break;
        case "zalo":
          url = "https://zalo.me/g/lxmdpj687";
          break;
      }
      window.open(url);
    },
    playVideo() {
      this.playing = true;
      setTimeout(() => {
        const videoDom = document.querySelector(".videoBox");
        videoDom.play();
      }, 50);
      // window.open("https://drive.google.com/file/d/1HzoJ-PeokUZkFxHO-NtiqJatW12E_Mgp/view?usp=sharing");
    },
    goRegister() {
      window.open("https://download.4e9swf.com/#/f1/p1/a003/MZFABX");
    },
    goMore() {
      window.open("https://www.eeee.com/markets");
    },
    overItem(index) {
      this.activeBox3 = index;
    },
  },
};
</script>

 
<style lang="scss" scoped >
@font-face {
  font-family: "Open Sans";
  src: url("../../assets/img/token2049/font/OpenSans.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* html {
  font-family: "Open Sans", "SanFranciso display", "Helvetica Neue",
    "Helvetica, Arial", "sans-serif";
}
* {
  box-sizing: border-box;
}
body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: var(--bgColorDark);
}
ul,
li,
div,
p {
  margin: 0;
  padding: 0;
}
input[type="radio"]:checked {
  margin: 0 5px 0 0;
  accent-color: #000;
}
[v-cloak] {
  display: none;
} */

#app:not(.zh_CN, .zh_HK) {
  .pcWrap .topBox .topInfo .titleTag span.yellow.marginRight {
    margin-right: 4px;
  }
  .pcWrap .topBox .topInfo .titleTag span.yellow.marginLeft {
    margin-left: 4px;
  }
  .pcWrap .mixBox .first .list .leftBox .title span {
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .pcWrap .topBox .topInfo .titleTag span {
    font-size: 14px;
  }
  .pcWrap .topBox .topInfo .title2 {
    font-size: 46px;
  }
  .pcWrap .mixBox .first .list .leftBox {
    padding-top: 20px;
  }
}
.topInfoLeft {
  flex: 1;
  /* padding-top: 60px;
  padding-bottom: 20px; */
}
.appWrap {
  background: #fff;
}
:root[theme="dark"] {
  .appWrap {
    background: #090b18;
  }
}

.wrap {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", "SanFranciso display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.ruleTitle {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 9;
  padding-top: 30px;
  padding-right: 0;
  font-family: "Open Sans", "SanFranciso display";
  text-decoration: underline;
  cursor: pointer;
  color: #133271;
  text-align: right;
  font-size: 14px;
}

/* PC */
.pcWrap {
  min-width: 1200px;
  /* padding:0 20px; */
  box-sizing: border-box;
}

.pcWrap .body {
  position: relative;
}

.pcWrap .topBox {
  position: relative;
  padding: 0 0 44px;
  padding-bottom: 0px;
  min-width: 1200px;
  min-height: 530px;
  background: url("../../assets/img/token2049/top_bg.png") no-repeat top/cover;
  /* margin-top:-49px; */
}
.pcWrap .topBox .topInfo {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  /* padding-top: 60px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}
.pcWrap .topBox .topInfo .title1 {
  margin-top: 20px;
  color: #fff;
  font-size: 29px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
.pcWrap .topBox .topInfo .title2 {
  color: #fff;
  font-family: "Open Sans";
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 12px;
  max-width: 700px;
}
.pcWrap .topBox .topInfo .titleTag {
  background: #2e44fa;
  padding: 12px 18px 12px 11px;
  border-radius: 10px;
  /* display: flex;
  align-items: center;
  justify-content: flex-start; */
  margin-bottom: 25px;
  display: inline-block;
}
.pcWrap .topBox .topInfo .titleTag span {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.pcWrap .topBox .topInfo .titleTag span.yellow {
  color: #fddf47;
  font-weight: 600;
}
.pcWrap .topBox .topInfo .date {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.pcWrap .topBox .topInfo .date img {
  width: 29px;
  height: 29px;
  margin-right: 6px;
}
.pcWrap .topBox .topInfo .date span {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pcWrap .topBox .topInfo .address {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.pcWrap .topBox .topInfo .address img {
  width: 29px;
  height: 29px;
  margin-right: 6px;
}
.pcWrap .topBox .topInfo .address span {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pcWrap .topBox .btnPc {
  width: 240px;
  height: 50px;
  line-height: 50px;
  font-family: "Open Sans", "SanFranciso display";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #133271;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  background: #ffda02;
  box-shadow: 0px 0px 20px 0px #fc8d10 inset;
}

.titleImgPc {
  height: 62px;
}
.scale .titleImgPc {
  height: 40px;
  margin-bottom: 30px;
}
.subTitleImgPc {
  height: 104px;
  margin-bottom: 30px;
}
.scale .subTitleImgPc {
  height: 70px;
  margin-bottom: 52px;
}
.dateImgPc {
  color: #782108;
  font-family: "Open Sans", "SanFranciso display";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.btnImgPc {
  height: 48px;
  cursor: pointer;
}

.pcWrap .bg2 {
  /* position: absolute; */
  right: 120px;
  top: -27px;
  height: 530px;
  z-index: 3;
  transform: translateY(4px);
}

.pcWrap .topBox .topBg {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  height: 98px;
  min-width: 1200px;
  background: url("../../assets/img/token2049/top_shadow.png") no-repeat bottom
    center;
  background-size: 100%;
}

/* 背景图 */
.pcWrap .bgBox {
  position: relative;
  min-width: 1200px;
  height: 618px;
  /* background: url("../../assets/img/token2049/bg_box.png") no-repeat 50% / cover; */
  margin-bottom: -260px;
}
.pcWrap .bgBox .bgBox-titleImg {
  display: block;
  position: relative;
  height: 150px;
  margin: 0 auto;
  top: 180px;
}
/* midBox */
.pcWrap .midBox {
  width: 100%;
  padding: 40px 0px;
  /* height: 240px; */
  /* background: url("../../assets/img/token2049/midBox_bg_light.png") no-repeat; */
  background: url("../../assets/img/token2049/midBox_bg_light.png");
  background-size: cover;
}
:root[theme="dark"] .pcWrap .midBox {
  background: url("../../assets/img/token2049/midBox_bg_dark.png") no-repeat;
  background-size: cover;
}
.pcWrap .midBox .innerBox {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  /* padding: 48px 30px 0; */
  background: url("../../assets/img/token2049/midBox_img.png") no-repeat center
    center;
  background-size: cover;
  box-sizing: border-box;
}
.pcWrap .midBox .innerBox .title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  margin-bottom: 10px;
}
:root[theme="dark"] .pcWrap .midBox .innerBox .title {
  color: #fff;
}
.pcWrap .midBox .innerBox .text {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  margin-bottom: 10px;
}
:root[theme="dark"] .pcWrap .midBox .innerBox .text {
  color: #e9e4d7;
}
.pcWrap .midBox .innerBox .mark {
  position: absolute;
  width: 388px;
  bottom: 34px;
  right: 15px;
}
/* mixBox */
.pcWrap .mixBox {
  min-width: 1200px;
  background: url("../../assets/img/token2049/mixBox_bg.png") no-repeat
    center/cover;
  background-size: auto 1475px;
  padding-bottom: 200px;
}
:root[theme="dark"] .pcWrap .mixBox {
  background: url("../../assets/img/token2049/mixBox_bg_dark.png") no-repeat
    center/cover;
  background-size: auto 1475px;
}
.pcWrap .mixBox .first {
  width: 1200px;
  margin: 76px auto 277px;
  background: url("../../assets/img/token2049/first_bg.png") no-repeat top
    center;
  background-size: 1156px 485px;
  /* margin:0 auto; */
}
:root[theme="dark"] .pcWrap .mixBox .first {
  background: url("../../assets/img/token2049/first_bg_dark.png") no-repeat top
    center;
  background-size: 1156px 485px;
}
.pcWrap .mixBox .first .titleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pcWrap .mixBox .first .titleBox img {
  width: 90px;
  height: 90px;
  margin-bottom: 30px;
}
.pcWrap .mixBox .first .titleBox .mainTitle {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}
:root[theme="dark"] .pcWrap .mixBox .first .titleBox .mainTitle {
  color: #fff;
}
.pcWrap .mixBox .first .titleBox .subTitle {
  color: #5c5f69;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 74px;
}
:root[theme="dark"] .pcWrap .mixBox .first .titleBox .subTitle {
  color: rgba(255, 255, 255, 0.65);
}
.pcWrap .mixBox .first .list {
  position: relative;
  /* height: 516px; */
  display: flex;
  justify-content: space-between;
}
.pcWrap .mixBox .first .list .tagIcon {
  position: absolute;
  left: -45px;
  top: -48px;
  width: 106px;
  height: 106px;
  z-index: 1;
}
.pcWrap .mixBox .first .list .leftBox {
  position: relative;
  width: 584px;
  /* height: 516px; */
  padding: 36px 24px 0;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 16px;
  border: 1px solid #eee;
  background: linear-gradient(180deg, #4256c3 0%, #fff 100%);
  box-sizing: border-box;
  z-index: 2;
}
:root[theme="dark"] .pcWrap .mixBox .first .list .leftBox {
  border: 1px solid #353537;
  background: linear-gradient(180deg, #203ac7 0%, #090c1a 100%);
}

.pcWrap .mixBox .first .list .leftBox .title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.pcWrap .mixBox .first .list .leftBox .title img {
  width: 32px;
  height: 32px;
  margin-right: 4px;
}
.pcWrap .mixBox .first .list .leftBox .title span {
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pcWrap .mixBox .first .list .leftBox .swiperBox {
  width: 100%;
  height: 320px;
  margin-bottom: 22px;
}
.pcWrap .mixBox .first .list .leftBox .swiperBox .swiper-container {
  width: 100%;
  height: 320px;
}
.pcWrap
  .mixBox
  .first
  .list
  .leftBox
  .swiperBox
  .swiper-container
  .swiper-slide
  img {
  width: 100%;
  height: 100%;
}
.pcWrap
  .mixBox
  .first
  .list
  .leftBox
  .swiperBox
  .swiper-container
  .swiper-slide
  span {
  position: absolute;
  left: 20px;
  bottom: 18px;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pcWrap
  .mixBox
  .first
  .list
  .leftBox
  .swiperBox
  .swiper-container
  .swiper-pagination {
  text-align: right;
  padding-right: 12px;
}
.pcWrap
  .mixBox
  .first
  .list
  .leftBox
  .swiperBox
  .swiper-container
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 7px;
  height: 4px;
  border-radius: 4px;
  background: #465aff;
  opacity: 1;
}
.pcWrap
  .mixBox
  .first
  .list
  .leftBox
  .swiperBox
  .swiper-container
  .swiper-pagination
  .swiper-pagination-bullet-active {
  width: 27px;
  background: #fff;
}
.pcWrap .mixBox .first .list .leftBox .swiperBox .preIcon,
.pcWrap .mixBox .first .list .leftBox .swiperBox .nextIcon {
  position: absolute;
  top: 50%;
  width: 34px;
  height: 34px;
  margin-top: -17px;
  cursor: pointer;
  z-index: 99;
}
.pcWrap .mixBox .first .list .leftBox .swiperBox .preIcon {
  left: 7px;
}
.pcWrap .mixBox .first .list .leftBox .swiperBox .nextIcon {
  right: 7px;
}
.pcWrap .mixBox .first .list .leftBox .swiperBox .preIcon img,
.pcWrap .mixBox .first .list .leftBox .swiperBox .nextIcon img {
  width: 100%;
  height: 100%;
}

.pcWrap .mixBox .first .list .leftBox .text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
:root[theme="dark"] .pcWrap .mixBox .first .list .leftBox .text {
  color: rgba(255, 255, 255, 0.85);
}
.pcWrap .mixBox .first .list .others {
  width: 589px;
  /* height: 516px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pcWrap .mixBox .first .list .others .item {
  width: 100%;
  height: 156px;
  padding: 18px 21px;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
:root[theme="dark"] .pcWrap .mixBox .first .list .others .item {
  border: 1px solid #282b43;
  background: #161a27;
}
.pcWrap .mixBox .first .list .others .item:hover {
  cursor: pointer;
  background: linear-gradient(90deg, #fff 0%, #2d43be 100%);
}
:root[theme="dark"] .pcWrap .mixBox .first .list .others .item:hover {
  background: linear-gradient(90deg, #223bc6 0%, #090b19 100%);
  cursor: pointer;
}
.pcWrap .mixBox .first .list .others .item:last-of-type {
  margin-bottom: 0;
}
.pcWrap .mixBox .first .list .others .item:last-of-type .item-content {
  justify-content: flex-start;
}
.pcWrap .mixBox .first .list .others .item:last-of-type .item-content .time {
  color: #000;
}
.pcWrap .mixBox .first .list .others .item img {
  width: 230px;
  height: 120px;
  margin-right: 45px;
}
.pcWrap .mixBox .first .list .others .item .item-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pcWrap .mixBox .first .list .others .item .item-content .title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
:root[theme="dark"]
  .pcWrap
  .mixBox
  .first
  .list
  .others
  .item
  .item-content
  .title {
  color: #fff;
}
.pcWrap .mixBox .first .list .others .item .item-content .time {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
:root[theme="dark"]
  .pcWrap
  .mixBox
  .first
  .list
  .others
  .item
  .item-content
  .time {
  color: rgba(255, 255, 255, 0.65);
}
.pcWrap .mixBox .first .list .others .item .item-content .title {
  position: relative;
  /* color: #fff; */
}
.pcWrap .mixBox .first .list .others .item:hover {
  .item-content .title.link {
    color: #fff;
    &::before {
      position: absolute;
      content: "→";
      font-size: 25px;
      left: -30px;
      top: -2px;
    }
  }
}

.pcWrap .mixBox .first .list .others .item:nth-of-type .item-content .time {
  color: rgba(255, 255, 255, 0.65);
}

/* second */
.pcWrap .mixBox .second {
  width: 1200px;
  margin: 0 auto;
}
.pcWrap .mixBox .second .titleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 42px;
}
.pcWrap .mixBox .second .titleBox .mainTitle {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
:root[theme="dark"] .pcWrap .mixBox .second .titleBox .mainTitle {
  color: #fff;
}
.pcWrap .mixBox .second .titleBox .mainTitle b {
  color: #3348f6;
  margin-right: 10px;
}
:root[theme="dark"] .pcWrap .mixBox .second .titleBox .mainTitle b {
  background: linear-gradient(90deg, #3348f6 0%, #c1c5de 52.88%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pcWrap .mixBox .second .titleBox .subTitle {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
:root[theme="dark"] .pcWrap .mixBox .second .titleBox .subTitle {
  color: #fff;
}
.pcWrap .mixBox .second .innerBox {
  padding: 0 40px 0 57px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pcWrap .mixBox .second .innerBox .list {
}
.pcWrap .mixBox .second .innerBox .list .item {
  display: flex;
  align-items: center;
}
.pcWrap .mixBox .second .innerBox .list .item:first-of-type {
  margin-bottom: 44px;
}
.pcWrap .mixBox .second .innerBox .list .item img {
  width: 66px;
  height: 66px;
  margin-right: 16px;
}
.pcWrap .mixBox .second .innerBox .list .item .text {
  display: flex;
  flex-direction: column;
}
.pcWrap .mixBox .second .innerBox .list .item .text p {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
:root[theme="dark"] .pcWrap .mixBox .second .innerBox .list .item .text p {
  color: #fff;
}
.pcWrap .mixBox .second .innerBox .list .item .text span {
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
:root[theme="dark"] .pcWrap .mixBox .second .innerBox .list .item .text span {
  color: rgba(255, 255, 255, 0.65);
}
.pcWrap .mixBox .second .innerBox .list .btns {
  position: relative;
  display: flex;
  margin-top: 30px;
  cursor: pointer;
}
.pcWrap .mixBox .second .innerBox .list .btns .qrCode {
  width: 54px;
  height: 54px;
  margin-right: 18px;
}
.pcWrap .mixBox .second .innerBox .list .btns .qrCode:hover .qrCodeBox {
  display: flex;
}
.pcWrap .mixBox .second .innerBox .list .btns .icon {
  width: 54px;
  height: 54px;
}

.pcWrap .mixBox .second .innerBox .list .btns .qrCode .qrCodeBox {
  display: none;
  position: absolute;
  top: calc(100% + 7px);
  left: 0;
  width: 160px;
  height: 190px;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  padding: 24px;
  background: rgba(51, 72, 246, 0.04);
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
}
:root[theme="dark"]
  .pcWrap
  .mixBox
  .second
  .innerBox
  .list
  .btns
  .qrCode
  .qrCodeBox {
  background: #090913;
  border-color: #fff;
}
.pcWrap .mixBox .second .innerBox .list .btns .qrCode .qrCodeBox img {
  width: 112px;
  height: 112px;
  margin-bottom: 12px;
}
.pcWrap .mixBox .second .innerBox .list .btns .qrCode .qrCodeBox span {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
:root[theme="dark"]
  .pcWrap
  .mixBox
  .second
  .innerBox
  .list
  .btns
  .qrCodeBox
  span {
  color: #fff;
}
.pcWrap .mixBox .second .innerBox .list .btn {
  height: 54px;
  padding: 0 30px;
  border-radius: 100px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
:root[theme="dark"] .pcWrap .mixBox .second .innerBox .list .btn {
  border-radius: 100px;
  background: #3348f6;
  box-shadow: 0px 0px 64px 0px #3348f6, 0px 0px 16px 0px #3348f6;
  border-color: transparent;
}
.pcWrap .mixBox .second .innerBox .list .btn span {
  color: #3348f6;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 127.778% */
}
:root[theme="dark"] .pcWrap .mixBox .second .innerBox .list .btn span {
  color: #fff;
}
.pcWrap .mixBox .second .innerBox .list .btn .arrow {
  width: 13px;
  margin-left: 7px;
}
.pcWrap .mixBox .second .innerBox .img {
  height: 423px;
}

/* box1 */
.pcWrap .box1 {
  padding-bottom: 95px;
  background: #fff;
}
:root[theme="dark"] .pcWrap .box1 {
  background: transparent;
}
.pcWrap .box1 .innerBox {
  width: 1200px;
  margin: 0 auto;
}
.pcWrap .box1 .innerBox .title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.pcWrap .box1 .innerBox .title span {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
:root[theme="dark"] .pcWrap .box1 .innerBox .title span {
  color: #fff;
}
.pcWrap .box1 .innerBox .title span:last-of-type {
  color: #3348f6;
}
:root[theme="dark"] .pcWrap .box1 .innerBox .title span:last-of-type {
  background: linear-gradient(90deg, #3348f667 35%, #e4e6f899 94%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "PingFang SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pcWrap .box1 .innerBox .subTitle {
  color: #5c5f69;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 70px;
}
:root[theme="dark"] .pcWrap .box1 .innerBox .subTitle {
  color: rgba(255, 255, 255, 0.85);
}
.pcWrap .box1 .innerBox .list {
  position: relative;
  width: 100%;
  height: 347px;
  margin-bottom: 60px;
}
.pcWrap .box1 .innerBox .list .bg {
  position: absolute;
  left: 50%;
  top: -50px;
  width: 824px;
  height: 456px;
  margin-left: -412px;
}
.pcWrap .box1 .innerBox .list .priceBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  margin-bottom: 20px;
}
.pcWrap .box1 .innerBox .list .priceBox li {
  list-style: none;
  width: 180px;
  height: 161px;
  padding: 16px 16px 0;
  border-radius: 16px;
  border: 1px solid #eee;
  background: linear-gradient(180deg, #dac1fd 0.06%, #fff 49.69%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}
:root[theme="dark"] .pcWrap .box1 .innerBox .list .priceBox li {
  border: 1px solid #282b43;
  background: linear-gradient(180deg, #5b01dc 0.06%, #090c1a 49.69%);
}
:root[theme="dark"] .pcWrap .box1 .innerBox .list .priceBox li:nth-of-type(2n) {
  border: 1px solid #282b43;
  background: linear-gradient(
    0deg,
    rgba(18, 47, 198, 0.8) 30.43%,
    #090c1a 62.42%
  );
}

.pcWrap .box1 .innerBox .list .priceBox li:nth-of-type(2n) {
  border: 1px solid #fff;
  background: linear-gradient(
    0deg,
    rgba(232, 235, 248, 0.8) 30.43%,
    #fff 62.42%
  );
}
.pcWrap .box1 .innerBox .list .priceBox.secondPriceBox li {
  border: 1px solid #fff;
  background: linear-gradient(
    0deg,
    rgba(232, 235, 248, 0.8) 30.43%,
    #fff 62.42%
  );
}
:root[theme="dark"] .pcWrap .box1 .innerBox .list .priceBox.secondPriceBox li {
  border: 1px solid #282b43;
  background: linear-gradient(
    0deg,
    rgba(18, 47, 198, 0.8) 30.43%,
    #090c1a 62.42%
  );
}
.pcWrap .box1 .innerBox .list .priceBox.secondPriceBox li:nth-of-type(2n) {
  border: 1px solid #eee;
  background: linear-gradient(180deg, #dac1fd 0.06%, #fff 49.69%);
}
:root[theme="dark"]
  .pcWrap
  .box1
  .innerBox
  .list
  .priceBox.secondPriceBox
  li:nth-of-type(2n) {
  border: 1px solid #282b43;
  background: linear-gradient(180deg, #5b01dc 0.06%, #090c1a 49.69%);
}

.pcWrap .box1 .innerBox .list .priceBox li .icon {
  width: 32px;
  height: 32px;
  margin-bottom: 7px;
}
.pcWrap .box1 .innerBox .list .priceBox li .currency {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}
:root[theme="dark"] .pcWrap .box1 .innerBox .list .priceBox li .currency {
  color: #fff;
}
.pcWrap .box1 .innerBox .list .priceBox li .price {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.pcWrap .box1 .innerBox .list .priceBox li .price span {
  color: #31bd50;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pcWrap .box1 .innerBox .list .priceBox li .price.down span {
  color: #f0551c;
}
.pcWrap .box1 .innerBox .list .priceBox li .price span:first-of-type {
  margin-right: 6px;
}
.pcWrap .box1 .innerBox .list .priceBox li .canvas {
  width: 80px;
  height: 40px;
}

.pcWrap .box1 .innerBox .btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  min-width: 150px;
  display: inline-flex;
  padding: 0 31px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  cursor: pointer;
}
:root[theme="dark"] .pcWrap .box1 .innerBox .btn {
  border-radius: 100px;
  background: #3348f6;
  box-shadow: 0px 0px 64px 0px #3348f6, 0px 0px 16px 0px #3348f6;
  border-color: transparent;
}
.pcWrap .box1 .innerBox .btn span {
  color: #465aff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}
:root[theme="dark"] .pcWrap .box1 .innerBox .btn span {
  color: #fff;
}
.pcWrap .box1 .innerBox .btn img {
  width: 13px;
  margin-left: 6px;
}

/* box2 */
.pcWrap .box2 {
  width: 100%;
  height: 1031px;
  padding-top: 103px;
  background: #f7f8ff;
  box-sizing: border-box;
  overflow: hidden;
}
:root[theme="dark"] .pcWrap .box2 {
  background: #0f1121;
}
.pcWrap .box2 .innerBox {
  margin: 0 auto;
  width: 1200px;
}
.pcWrap .box2 .innerBox .titleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pcWrap .box2 .innerBox .titleBox img {
  height: 65px;
  margin-bottom: 20px;
}
.pcWrap .box2 .innerBox .titleBox .title {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 17px;
}
:root[theme="dark"] .pcWrap .box2 .innerBox .titleBox .title {
  color: #fff;
}
.pcWrap .box2 .innerBox .titleBox .subTitle {
  color: #5c5f69;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
}
:root[theme="dark"] .pcWrap .box2 .innerBox .titleBox .subTitle {
  color: rgba(255, 255, 255, 0.85);
}
.pcWrap .box2 .innerBox .content {
  position: relative;
  margin-top: -90px;
  width: 1279px;
  height: 840px;
  background: url("../../assets/img/token2049/box2_bg.png") no-repeat center
    center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
:root[theme="dark"] .pcWrap .box2 .innerBox .content {
  height: 840px;
  background: url("../../assets/img/token2049/box2_bg_dark.png") no-repeat
    center center;
  background-size: 100% 100%;
}
.pcWrap .box2 .innerBox .content .imgBox {
  position: relative;
  width: 890px;
  height: 540px;
  background: url("../../assets/img/token2049/box2_img.png") no-repeat;
  background-size: 100% 100%;
  left: -20px;
  top: -2px;
  cursor: pointer;
}
.pcWrap .box2 .innerBox .content .videoBox {
  position: relative;
  width: 890px;
  height: 540px;
  left: -20px;
  top: 0;
}
:root[theme="dark"] .pcWrap .box2 .innerBox .content .imgBox {
  background: url("../../assets/img/token2049/box2_img_dark.png") no-repeat;
  background-size: 100% 100%;
}
.pcWrap .box2 .innerBox .content .imgBox .img {
  position: relative;
  top: 21px;
  height: 497px;
}
.pcWrap .box2 .innerBox .content .imgBox .playIcon {
  position: absolute;
  left: -10px;
  bottom: -10px;
  width: 225px;
  height: 225px;
}
.pcWrap .box2 .innerBox .content .topIcon {
  position: absolute;
  height: 535px;
  right: 20px;
  top: -20px;
}
.pcWrap .box2 .innerBox .content .leftIcon {
  position: absolute;
  width: 134px;
  height: 134px;
  left: 80px;
  top: 265px;
}
.pcWrap .box2 .innerBox .content .rightIcon {
  position: absolute;
  width: 204px;
  height: 204px;
  right: 100px;
  bottom: 110px;
}
/* box2 */
.pcWrap .box3 {
  height: 600px;
  padding-top: 70px;
  background: #fff;
  box-sizing: border-box;
}
:root[theme="dark"] .pcWrap .box3 {
  background: #090b18;
}
.pcWrap .box3 .innerBox {
  width: 1200px;
  margin: 0 auto;
}
.pcWrap .box3 .innerBox .title {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 44px;
}
:root[theme="dark"] .pcWrap .box3 .innerBox .title {
  color: #fff;
}
.pcWrap .box3 .innerBox .list {
  display: flex;
}
.pcWrap .box3 .innerBox .list .small {
  position: relative;
  width: 220px;
  height: 320px;
  padding: 44px 5px 0 36px;
  border-radius: 16px;
  background: #f9faff;
  margin-right: 16px;
  box-sizing: border-box;
  transition: all 0.5s;
  transform-origin: left center;
}
.pcWrap .box3 .innerBox .list .small.active {
  width: 490px;
  height: 320px;
  background: url("../../assets/img/token2049/box3_active_bg.png") no-repeat;
  background-size: 100%;
}
:root[theme="dark"] .pcWrap .box3 .innerBox .list .small {
  background: #161a27;
}
:root[theme="dark"] .pcWrap .box3 .innerBox .list .small.active {
  width: 490px;
  height: 320px;
  background: url("../../assets/img/token2049/box3_active_bg.png") no-repeat;
  background-size: 100%;
}
.pcWrap .box3 .innerBox .list .small p {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}
.pcWrap .box3 .innerBox .list .small.active p {
  color: #fff;
}
.pcWrap .box3 .innerBox .list .small .arrowIcon {
  position: absolute;
  left: 22px;
  bottom: 17px;
  width: 35px;
  height: 35px;
}
.pcWrap .box3 .innerBox .list .small .img {
  position: absolute;
  right: 0;
  bottom: 12px;
  height: 150px;
}
.pcWrap .box3 .innerBox .list .small.active .img {
  height: 224px;
  right: 0;
  bottom: 0;
}
:root[theme="dark"] .pcWrap .box3 .innerBox .list .small p {
  color: #fff;
}
.pcWrap .box3 .innerBox .list .firstBox {
  border-radius: 16px;
  background: #f9faff;
}

.pcWrap .box3 .innerBox .list .secondBox p {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}
/* .pcWrap .box3 .innerBox .list .small p:last-of-type {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; 
} */
/* :root[theme="dark"] .pcWrap .box3 .innerBox .list .small p:last-of-type {
  color:#FFF;
} */
.pcWrap .box3 .innerBox .list .small.active p:last-of-type {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.65);
}
/* box4 */
.pcWrap .box4 {
  min-width: 1200px;
  margin-top: 50px;
  height: 1091px;
  background: url("../../assets/img/token2049/box4_bg_light.png") no-repeat
    bottom center;
  background-size: auto 937px;
  box-sizing: border-box;
}
:root[theme="dark"] .pcWrap .box4 {
  background: url("../../assets/img/token2049/box4_bg_dark.png") no-repeat
    bottom center;
  background-size: auto 937px;
}
.pcWrap .box4 .innerBox {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}
.pcWrap .box4 .innerBox .title {
  margin-bottom: 16px;
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
:root[theme="dark"] .pcWrap .box4 .innerBox .title {
  color: #fff;
}
.pcWrap .box4 .innerBox .subTitle {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  margin-bottom: 563px;
}
:root[theme="dark"] .pcWrap .box4 .innerBox .subTitle {
  color: rgba(255, 255, 255, 0.65);
}
.pcWrap .box4 .innerBox .list {
  display: flex;
  justify-content: space-between;
}
.pcWrap .box4 .innerBox .list .item {
  width: 252px;
  height: 139px;
  border-radius: 8px;
  padding: 0 10px;
  background: #dfe2f9;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
:root[theme="dark"] .pcWrap .box4 .innerBox .list .item {
  background: #161a27;
}
.pcWrap .box4 .innerBox .list .item span:first-of-type {
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
:root[theme="dark"] .pcWrap .box4 .innerBox .list .item span:first-of-type {
  color: #fff;
}
.pcWrap .box4 .innerBox .list .item span:last-of-type {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
:root[theme="dark"] .pcWrap .box4 .innerBox .list .item span:last-of-type {
  color: rgba(255, 255, 255, 0.65);
}
/* .pcWrap .box4 .innerBox .leftText {
  position: absolute;
  height: 130px;
  left:-60px;
  top:185px;
}
:root[theme="dark"] .pcWrap .box4 .innerBox .leftText {
  color:#FFF;
} */
.pcWrap .box4 .innerBox .textImg {
  position: absolute;
  /* height: 228px; */
  /* left: 28px; */
  top: 152px;
}
.enApp .pcWrap .box4 .innerBox .textImg {
  left: -46px;
  top: 137px;
}
.viApp .pcWrap .box4 .innerBox .textImg {
  height: 108px;
  left: 64px;
  top: 200px;
}
.esApp .pcWrap .box4 .innerBox .textImg {
  height: 206px;
  left: 82px;
  top: 145px;
}
.ptApp .pcWrap .box4 .innerBox .textImg {
  height: 213px;
  left: -30px;
  top: 146px;
}
.thApp .pcWrap .box4 .innerBox .textImg {
  height: 206px;
  left: 54px;
  top: 146px;
}

/* footer */
.pcWrap .footer {
  margin-top: -130px;
  padding-bottom: 130px;
}
.pcWrap .footer .innerBox {
  width: 800px;
  margin: 0 auto;
}
.pcWrap .footer .innerBox .title {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 800px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #aaa;
  text-align: center;
}
:root[theme="dark"] .pcWrap .footer .innerBox .title {
  color: #fff;
  border-bottom: 1px dotted #fff;
}
.pcWrap .footer .innerBox .relatedBox {
  padding-top: 20px;
}
.pcWrap .footer .innerBox .relatedBox .text {
  color: #000;
  -webkit-text-stroke-width: 0.568328857421875;
  -webkit-text-stroke-color: #aaa;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 250% */
  text-align: center;
}
:root[theme="dark"] .pcWrap .footer .innerBox .relatedBox .text {
  color: #fff;
}
.pcWrap .footer .innerBox .relatedBox .list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pcWrap .footer .innerBox .relatedBox .list img {
  width: 36px;
  height: 36px;
  margin-right: 16px;
  cursor: pointer;
}
.pcWrap .footer .innerBox .relatedBox .list img:last-of-type {
  margin-right: 0;
}

/* 规则弹窗 */
.pop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 9;
}
.popBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 580px;
  max-height: 560px;
  transform: translate(-50%, -50%);
  padding: 20px 24px;
  border-radius: 12px;
  background-color: #fff;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popBox .title {
  font-family: "Open Sans", "SanFranciso display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #000;
  margin-bottom: 20px;
}
.popBox .content {
  flex: 1;
  margin-bottom: 20px;
  overflow-y: auto;
}
.popBox .content p {
  margin-bottom: 20px;
  font-family: "Open Sans", "SanFranciso display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #000;
}
.popBox .content p:last-of-type {
  margin-bottom: 0;
}
.popBox .btn {
  width: 200px;
  height: 44px;
  padding: 0px 16px;
  border-radius: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 26px;
  background: #ffda02;
  box-shadow: 0px 0px 20px 0px #fc8d10 inset;
}
.popBox .btn span {
  font-family: "Open Sans", "SanFranciso display";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #133271;
}
.popBox .closeIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 24px;
  top: 20px;
  cursor: pointer;
}

/* H5 */
.mWrap {
  /* background-color: #E3F1FF; */
  /* background: #96CDEF; */
  background: #fcf9ff;
  font-size: 12px;
  height: 100%;
  overflow: hidden;
}

.mWrap.out {
  padding-bottom: 84px;
}

.m_topBox {
  /* height: 550px;
  background: url('../../assets/img/token2049/m_top_bg.png') no-repeat center 100%/cover; */
  /* margin-bottom:-975px; */
  /* margin-bottom:-200px; */
}
.mWrap.out .m_topBox {
  /* height: 550px; */
  /* background: url('../../assets/img/token2049/m_top_bg.png') no-repeat center 100%/cover; */
  /* margin-bottom:-200px; */
}
.m_topBox .bg {
  width: 100%;
}
.m_ruleTitle {
  padding-top: 8px;
  padding-right: 16px;
  font-family: "Open Sans", "SanFranciso display";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
  color: #000;
  text-align: right;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
}
.m_topInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
}
.m_topInfo .bg {
  width: 100%;
  height: 7.65rem;
}
.m_topInfo .logo {
  position: absolute;
  top: 0.46rem;
  width: 1.23rem;
}
.m_topInfo .m_img {
  position: absolute;
  top: 2.35rem;
  left: 0;
  width: 100%;
}
.m_topInfo .title1 {
  position: absolute;
  top: 1rem;
  color: #133271;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.m_topInfo .title2 {
  position: absolute;
  top: 1.55rem;
  color: #133271;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.m_topInfo .m_date {
  position: absolute;
  top: 1.95rem;
  color: #133271;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
body #app:not(.zhApp) .m_topInfo .m_date {
  top: 2.1rem;
}
/* box1 */
.mWrap .box1 {
  margin-top: -40px;
}
.mWrap .box1 .title_img {
  display: block;
  height: 55px;
  margin: 0 auto 9px;
}
.mWrap .box1 .title {
  max-width: 265px;
  margin: 0 auto 9px;
  color: #0f0f4d;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#app:not(.zhApp) .mWrap .box1 .title {
  max-width: 320px;
}
.mWrap .box1 .subTitle {
  max-width: 290px;
  color: #0f0f4d;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto 18px;
}
.mWrap .box1 .innerBox {
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 23px 10px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px dashed #5d65af;
  background: linear-gradient(224deg, #f5f4ff 19.53%, #fff 66.91%);
  display: flex;
  flex-direction: column;
}
.mWrap .box1 .innerBox .itemBox {
  display: flex;
  height: 87px;
  margin-bottom: 10px;
}
.mWrap .box1 .innerBox .leftBox {
  width: 100px;
  height: 87px;
  padding: 17.5px 8px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5d65af;
  border-radius: 10px;
}
.mWrap .box1 .innerBox .leftBox .text1 {
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
}
.mWrap .box1 .innerBox .leftBox .text2 {
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.mWrap .box1 .innerBox .rightBox {
  flex: 1;
  background: #fff;
  padding: 16px 16px 0;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.25);
}
.mWrap .box1 .innerBox .rightBox .left {
  display: flex;
  flex-direction: column;
}
.mWrap .box1 .innerBox .rightBox .left .line1 {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.mWrap .box1 .innerBox .rightBox .left .line1 .mainTitle {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
}
.enApp .mWrap .box1 .innerBox .rightBox .left .line1 .mainTitle {
  font-size: 10px;
  line-height: 12px;
}
.viApp .mWrap .box1 .innerBox .rightBox .left .line1 .mainTitle {
  font-size: 11px;
  line-height: 12px;
}
.esApp .mWrap .box1 .innerBox .rightBox .left .line1 .mainTitle {
  font-size: 11px;
}
.ptApp .mWrap .box1 .innerBox .rightBox .left .line1 .mainTitle {
  font-size: 8px;
  line-height: 12px;
}
.mWrap .box1 .innerBox .rightBox .left .line1 .tag {
  border: 1px solid #5d65af;
  color: #5d65af;
  padding: 0 3px;
  margin-left: 4px;
  border-radius: 4px;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px; /* 122.222% */
}
.mWrap .box1 .innerBox .rightBox .left .line2 {
  color: #000;
  text-align: left;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-bottom: 8px;
}
body #app:not(.zhApp) .mWrap .box1 .innerBox .rightBox .left .line2 {
  font-size: 10px;
}

.mWrap .box1 .innerBox .rightBox .left .line3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* align-items: ; */
}
.mWrap .box1 .innerBox .rightBox .left .line3 span {
  font-size: 12px;
  color: #8b909c;
}
body #app:not(.zhApp) .mWrap .box1 .innerBox .rightBox .left .line3 span {
  font-size: 8px;
}
body #app.viApp .mWrap .box1 .innerBox .rightBox .left .line3 span {
  font-size: 11px;
}
.mWrap .box1 .innerBox .rightBox .left .line3 img {
  width: 12px;
  height: 12px;
}
body #app:not(.zhApp) .mWrap .box1 .innerBox .rightBox .left .line3 img {
  width: 10px;
  height: 10px;
}
.mWrap .box1 .innerBox .rightBox .right {
  position: relative;
  top: 10px;
  padding: 0px 12px;
  min-height: 24px;
  border-radius: 22px;
  background: #5d65af;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  text-align: center;
}
#app:not(.zhApp) .mWrap .box1 .innerBox .rightBox .right {
  max-width: 70px;
  font-size: 8px;
  padding: 0px 5px;
}
#app.thApp .mWrap .box1 .innerBox .rightBox .right {
  width: 70px;
}

.mFooter .logoBox .footer-subTitle {
  padding: 0 33px;
  font-family: "Open Sans", "PingFang SC";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: #0f0f4d;
  text-align: center;
}

.mFooter .relatedBox {
  padding: 20px 0 75px;
  /* background: url('../../assets/img/token2049/m_related_bg.png') no-repeat bottom center;
  background-size: 100% 100%; */
}
.mFooter .relatedBox .platformBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .mFooter .relatedBox .platformBox .title {
  margin-bottom:24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.mFooter .relatedBox .platformBox .title {
  position: relative;
  margin-bottom: 24px;
  padding: 8px 24px;
  border-radius: 24px 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #0f0f4d;
  background-color: transparent;
  z-index: 1; /* 确保内容在伪元素之上 */
}

.mFooter .relatedBox .platformBox .title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px 24px 0 0;
  padding: 1px; /* 为了显示渐变边框 */
  background: linear-gradient(to bottom, #0f0f4d, rgba(255, 255, 255, 0));
  -webkit-mask: linear-gradient(#0f0f4d 0 0) content-box,
    linear-gradient(#0f0f4d 0 0);
  mask: linear-gradient(#0f0f4d 0 0) content-box, linear-gradient(#0f0f4d 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none; /* 确保伪元素不会阻挡点击事件 */
  z-index: -1; /* 确保伪元素在内容之下 */
  box-sizing: border-box; /* 确保内边距和边框正确计算 */
}
.mFooter .relatedBox .platformBox .title .left {
  width: 50px;
  height: 11px;
}
.mFooter .relatedBox .platformBox .title .right {
  width: 50px;
  height: 11px;
}
.mFooter .relatedBox .platformBox .title span {
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: #0f0f4d;
}

/* .mFooter .relatedBox .platformBox  .title::before {
  content:'';
  position: absolute;
  width: 50px;
  height: 11px;
  left:-58px;
  top:50%;
  transform: translateY(-50%);
  background: url('../../assets/img/token2049/line_left.png') no-repeat;
  background-size: 100% 100%;
}
.mFooter .relatedBox .platformBox  .title::after {
  content:'';
  position: absolute;
  width: 50px;
  height: 11px;
  right:-58px;
  top:50%;
  transform: translateY(-50%);
  background: url('../../assets/img/token2049/line_right.png') no-repeat;
  background-size: 100% 100%;
} */
.mFooter .relatedBox .platformBox .list {
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 0 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.mFooter .relatedBox .platformBox .list .item {
  height: 52px;
}

.popupWrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.502);
  align-items: end;
}

.popupWrap .popupBox {
  position: relative;
  padding: 16px;
  width: 100%;
  max-height: calc(100% - 100px);
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.popupWrap .popupBox .popupTitle {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.popupWrap .popupBox .ruleBox {
  margin: 16px 0;
  flex: 1;
  overflow: auto;
  font-size: 16px;
  line-height: 21px;
}
.popupWrap .popupBox .ruleBox .ruleItem {
  font-size: 16px;
  line-height: 21px;
  color: #000;
}
.popupWrap .popupBox .ruleBox .ruleItem:not(last-child) {
  margin-bottom: 12px;
}
.popupWrap .popupBox .btn {
  width: 100%;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  background-color: #3348f6;
  color: #fff;
}
.popupWrap .popupBox .closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
}
.popupWrap .popupBox .closeIcon img {
  width: 14px;
  height: 14px;
}

.fixedFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 1;
}
.service {
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
}
.mWrap .service2 {
  margin: 0 8px;
}
.mWrap .joinBtn {
  flex: 1.5;
}
.fixedFooter .btn {
  height: 44px;
  line-height: 44px;
  border-radius: 42px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  background-color: #3348f6;
  color: #fff;
}
</style>
