<template>
  <div class="open-google-settings" v-loading="gooleLoading">
    <div class="setGoogle">
      <!-- 左侧信息提示 -->
      <div class="infoTip">
        <div class="content-tip">
          <div class="scar-tips">
            <span class="red-start">*</span>
            <span class="title">{{
              translateTitle("请扫描下方二维码或输入下述密钥添加验证令牌")
            }}</span>
          </div>
          <div class="qr_code">
            <qrcode-vue
              class="qr_code_canvas"
              v-show="googleCode"
              :value="googleCode"
              :size="90"
              level="H"
            ></qrcode-vue>
            <p>
              <span>{{ translateTitle("密钥：") }}</span>
              <span class="secret-key">{{ secret }}</span>
              <i
                class="iconfont iconicon-copy"
                style="color: #9aa1b1"
                v-clipboard:copy="secret"
                v-clipboard:success="copySuccess"
                v-clipboard:error="copyError"
              ></i>
            </p>
          </div>
        </div>
      </div>
      <!-- 右侧表单 -->
      <div class="form">
        <!-- 未绑定谷歌 -->
        <DGoogle
          v-if="googleStatus == 0"
          :label="translateTitle('谷歌验证码')"
          :verificate="true"
          ref="google_ref"
        ></DGoogle>
        <!-- 已绑定谷歌 -->
        <DGoogle
          v-if="googleStatus != 0"
          :label="translateTitle('新谷歌验证码')"
          :verificate="true"
          ref="new_google_ref"
        ></DGoogle>
        <DGoogle
          v-if="googleStatus != 0"
          :label="translateTitle('原谷歌验证码')"
          :verificate="true"
          ref="google_ref"
        ></DGoogle>
        <DBind
          :accountInfo="accountInfo"
          v-if="mobileStatus"
          key="2"
          type="mobile"
          :type_num="mobileType"
          :disabled="true"
          :verificate="true"
          ref="mobile_ref"
        ></DBind>
        <DBind
          :accountInfo="accountInfo"
          v-if="emailStatus"
          key="1"
          type="email"
          :type_num="emailType"
          :disabled="true"
          :verificate="true"
          ref="email_ref"
        ></DBind>

        <div class="open-footer">
          <el-button @click="goBack" class="common-cancel">{{
            translateTitle("取消")
          }}</el-button>
          <el-button class="common-save" @click="confirmBtn">{{
            translateTitle("确定")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DBind from "../DBind";
import DGoogle from "../DGoogle";
import encryption from "@/assets/js/encryption";
import { showMessage } from "@/components/dialog/message";
import {
  getGoogleSecret,
  bindGoogleVerify,
  updateGoogleVerify,
} from "@/model/ucenter/security";
import QrcodeVue from "qrcode.vue";
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  mixins: [commonMixin],
  components: { DBind, DGoogle, QrcodeVue },
  props: ["accountInfo", "emailStatus", "mobileStatus", "googleStatus"],
  data() {
    return {
      secret: "", // 谷歌密钥
      gooleLoading: true,
      appDownload: [
        {
          img: require("./../../../../assets/img/Apple-store.png"),
          apk: require("./../../../../assets/img/Apple-qrCode.png"),
          name: "Apple",
        },
        {
          img: require("./../../../../assets/img/Android-market.png"),
          apk: require("./../../../../assets/img/Android-qrCode.png"),
          name: "Android",
        },
        {
          img: require("./../../../../assets/img/Google-play.png"),
          apk: require("./../../../../assets/img/Google-qrCode.png"),
          name: "Google",
        },
      ],
    };
  },
  created() {
    this.getGoogleSecret();
  },
  methods: {
    goBack() {
      this.$emit("backClick");
    },
    // 复制成功
    copySuccess() {
      showMessage({
        message: this.translateTitle(`内容复制成功`),
        type: "success",
      });
    },
    // 复制失败
    copyError() {
      showMessage({ message: this.translateTitle(`内容复制失败`) });
    },
    // 获取谷歌验证
    getGoogleSecret() {
      getGoogleSecret().then(res => {
        if (res.status === 200) {
          this.secret = res.data.secret;
        } else {
          showMessage({ message: res.msg });
        }
      });
    },
    // 确认提交
    async confirmBtn() {
      let params = {};
      let emailCheck,
        mobileCheck,
        googleCheck,
        newGoogleCheck = false;
      emailCheck = this.$refs.email_ref
        ? this.$refs.email_ref.submitForm()
        : true;
      mobileCheck = this.$refs.mobile_ref
        ? this.$refs.mobile_ref.submitForm()
        : true;
      googleCheck = this.$refs.google_ref
        ? this.$refs.google_ref.submitForm()
        : true;
      newGoogleCheck = this.$refs.new_google_ref
        ? this.$refs.new_google_ref.submitForm()
        : true;
      if (!(emailCheck && mobileCheck && googleCheck && newGoogleCheck)) return;

      /* 参数 */
      if (this.$refs.email_ref) {
        let data = this.$refs.email_ref.submitForm();
        delete data.email;
        params = Object.assign({}, { ...data });
      }
      if (this.$refs.mobile_ref) {
        let data = this.$refs.mobile_ref.submitForm();
        delete data.mobile;
        params = Object.assign(params, { ...data });
      }
      if (this.$refs.google_ref) {
        let data = this.$refs.google_ref.submitForm();
        params = Object.assign(params, { ...data });
      }
      if (this.$refs.new_google_ref) {
        let data = this.$refs.new_google_ref.submitForm();
        data.code = data.google_code;
        delete data.google_code;
        params = Object.assign(params, { ...data });
      }
      params.google_secret = this.secret;

      /* 提交请求 */
      // 未绑定谷歌
      if (this.googleStatus == 0) {
        let res = await bindGoogleVerify(params);
        this.successType(res, this.translateTitle("绑定谷歌验证成功"));
        // 已绑定谷歌
      } else {
        params["old_google_code"] = params["google_code"];
        params["google_code"] = params["code"];
        let res = await updateGoogleVerify(params);
        this.successType(res, this.translateTitle("修改谷歌验证成功"));
      }
    },
    successType(res, message) {
      let { status, msg } = res;
      if (status !== 200) {
        showMessage({ message: msg ? msg : status });
        return;
      }
      showMessage({ message: message, type: "success" });
      // 提交成功
      this.$emit("subSuccess", true);
    },
  },
  watch: {
    googleCode() {
      this.gooleLoading = false;
    },
  },
  computed: {
    emailType() {
      return this.googleStatus != 0 ? "21" : "17";
    },
    mobileType() {
      return this.googleStatus != 0 ? "22" : "18";
    },
    btnText() {
      return this.googleStatus != 0
        ? this.translateTitle("修改验证")
        : this.translateTitle("开启验证");
    },
    googleCode() {
      let name, $encodeURI;
      let {
        mobileStatus,
        emailStatus,
        accountInfo,
        secret,
        gooleLoading,
      } = this;
      name = encryption(accountInfo.username);
      if (mobileStatus) {
        name = encryption(accountInfo.mobile);
      }
      if (emailStatus) {
        name = name
          ? " " + encryption(accountInfo.email)
          : encryption(accountInfo.email);
      }
      if (secret) {
        let str = secret.slice(secret.length - 6);
        let val = `otpauth://totp/${this.projectEnglishName}(${name}-${str})?secret=${secret}`;
        val = val.replace(/\s+/g, "");

        return val;
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss">
.open-google-settings {
  border-radius: 4px;
  .el-form-item__label {
    color: #9aa1b1;
    font-size: 14px;
    font-weight: 400;
  }
  /* 头部 */
  .google-title {
    height: 72px;
    padding: 0 20px;
    display: flex;
    .title-text {
      width: 80px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #354052;
      padding: 22px 0;
    }
    .goBack {
      width: 40px;
      height: 28px;
      font-weight: 500;
      color: #27c683;
      font-size: 20px;
      padding-top: 22px;
      cursor: pointer;
    }
    .line {
      width: 1px;
      color: #e4e4e4;
      font-size: 24px;
      padding-top: 20px;
      margin: 0 11px;
    }
    h3 {
      color: #354052;
      font-size: 24px;
      font-weight: 500;
      padding-top: 20px;
    }
  }
  .lines {
    padding: 0 20px;
    box-sizing: border-box;
    p {
      width: 100%;
      height: 1px;
      background-color: #f3f5f7;
    }
  }

  /* 设置谷歌 */
  .setGoogle {
    /* 左侧信息提示 */
    .infoTip {
      display: flex;
      /* 点 */
      .dots {
        width: 37px;
        position: relative;
        span {
          display: block;
          width: 17px;
          height: 17px;
          background: url("./../../../../assets/img/dot.png") no-repeat center;
          background-size: 100% 100%;
          position: absolute;
          z-index: 2;
          &:first-child {
            top: 9px;
          }
          &:nth-child(2) {
            top: 114px;
          }
          &:nth-child(3) {
            top: 333px;
          }
        }
        /* 线 */
        .line {
          display: block;
          width: 326px;
          height: 1px;
          transform: rotateZ(90deg);
          background-color: #b4bac6;
          position: absolute;
          top: 178px;
          left: -155px;
        }
      }
      /* 提示内容 */
      .content-tip {
        .scar-tips {
          margin-top: 10px;
          .red-start {
            margin-right: 5px;
            color: #f56c6c;
          }
          .title {
            color: #9aa1b1;
            font-size: 14px;
            font-weight: 400;
          }
        }
        text-align: left;
        p {
          height: 14px;
          color: #717985;
          font-size: 14px;
          &.tip {
            color: #ff2f68;
            margin-top: 10px;
          }
        }
        .download {
          height: 98px;
          display: flex;
          align-items: center;
          .appDownload {
            width: 120px;
            height: 32px;
            margin-right: 20px;
            position: relative;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
            &:hover {
              .qr-code {
                display: block;
              }
            }
            .apk {
              width: 100%;
              height: 100%;
              display: block;
            }
            .qr-code {
              display: none;
              position: absolute;
              right: -120px;
              top: -30px;
              width: 120px;
              height: 120px;
              padding: 10px;
              background-color: #fff;
              border: 1px solid #e7ecf0;
              border-radius: 2px;
              transition: all 0.3s;
              box-sizing: border-box;
              z-index: 3;
              &:before {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                top: 40px;
                left: -6px;
                transform: rotate(-45deg);
                background: #fff;
                border-top: 1px solid #e7ecf0;
                border-left: 1px solid #e7ecf0;
              }
              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }
          }
        }
        .qr_code {
          height: 120px;
          display: flex;
          align-items: flex-end;
          // padding-bottom: 44px;
          box-sizing: border-box;
          .qr_code_canvas {
            width: 100px;
            height: 100px;
            padding: 5px;

            // border: 1px solid #b4bac6;
            // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
            background-color: #e2e2e4;
            margin-right: 20px;
          }
          i {
            font-size: 15px;
            cursor: pointer;
            color: #9aa1b1;
            margin-left: 5px;
            &:hover {
              color: #9aa1b1;
            }
          }
        }
      }
    }
    /* 右侧表单 */
    .form {
      margin-top: 10px;
      // width: 300px;
      // 按钮
      .open-footer {
        padding-top: 20px;
        display: flex;
        justify-content: center;
        button {
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
