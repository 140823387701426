<template>
  <div id="cooperation-page">
    <coop-top-panel />
    <reason-panel />
    <service-panel />
    <join-panel />
    <security-panel />
  </div>
</template>
<script>
import DownloadPanel from "@/components/home/download-panel.vue";

import CoopTopPanel from "@/components/home/coop-top-panel.vue";
import ReasonPanel from "@/components/home/reason-panel.vue";
import ServicePanel from "@/components/home/service-panel.vue";
import SecurityPanel from "@/components/home/security-panel.vue";
import JoinPanel from "@/components/home/join-panel.vue";
export default {
  props: {},
  components: {
    CoopTopPanel,
    ReasonPanel,
    ServicePanel,
    SecurityPanel,
    JoinPanel,
  },
  mixins: [],
  data() {
    return {};
  },
  async created() {},
  destroyed() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
</style>
