<template>
  <div class="error-page">
    <img class="error-img" src="../../assets/img/404.png" alt />
    <div class="error-text">
      <p>{{ translateTitle("哎呀… 当前访问的页面不存在！请尝试返回首页") }}</p>
    </div>
    <router-link active-class="none-active" tag="span" to="/">
      <span class="home-btn"> {{ translateTitle("返回首页") }} </span>
    </router-link>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
export default {
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss">
.ja {
  .error-page .home-btn {
    width: 195px;
  }
}
.error-page {
  width: 100%;
  height: calc(100vh - 281px);
  background-color: #ffffff;
  text-align: center;
  .error-img {
    width: 360px;
    height: 139px;
    margin-top: 202px;
  }
  .error-text {
    margin-top: 20px;
  }
  .home-btn {
    border: none;
    display: block;
    cursor: pointer;
    width: 160px;
    height: 40px;
    margin: 56px 113px 0 114px;
    padding: 12px 16px;
    border-radius: 3px;
    background-image: linear-gradient(74deg, #c61b4e 21%, #ee244c 82%);
    color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    margin: 60px auto;
  }
}
</style>
