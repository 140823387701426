<template>
  <div class="page-content">
    <router-view class="page-content-view"></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.page-content {
  height: 100%;
  background: #fafafa;
  box-sizing: border-box;
  overflow: hidden;
  // margin-bottom: 20px;
  // margin-left: 12px;
  flex: 1;
  &-view {
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
